import { ExternalLinkIcon } from '@chakra-ui/icons'
import { MenuItem, useDisclosure } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  UiMenu,
  UiMenuMore,
  UiNavbar,
  UiNavbarLink,
  UiNavbarLinkProps,
  UiNavbarProfile,
  UiSkeleton,
  useColor,
} from '@postal-io/postal-ui'
import {
  MeDocument,
  NamedProductAccessListDocument,
  ProductAccessFragment,
  SearchDraftMarketplaceProductDocument,
} from 'api'
import { useAcl, useSession } from 'hooks'
import { CustomColors } from 'lib'
import isEmpty from 'lodash/isEmpty'
import React, { useMemo } from 'react'
import { NavLink, NavLink as RouterLink, useLocation, useMatch } from 'react-router-dom'
import { AccountPicker } from './AccountPicker'
import { Logo } from './Logo'

const PRODUCT_ID = process.env.REACT_APP_PRODUCT_ID

export const Navbar = () => {
  const { hasFeature } = useAcl()
  const hasManualUpload = hasFeature('productManual')
  const { logout, session } = useSession()
  const accountPickerDisclosure = useDisclosure()
  const isHidden = useMatch('/products/import')

  const { hasPermission } = useAcl()

  const isManager = hasPermission('integrations.read')

  const meQuery = useGraphqlQuery(MeDocument)
  const me = meQuery.data?.me

  const namedAccessListQuery = useGraphqlQuery(NamedProductAccessListDocument)
  const accountName = useMemo(() => {
    return (
      namedAccessListQuery.data?.namedProductAccessList?.find((a) => a?.productAccess?.id === session.productAccessId)
        ?.accountName ?? ''
    )
  }, [namedAccessListQuery.data?.namedProductAccessList, session.productAccessId])

  const filteredProductAccess = (me?.productAccess || []).filter(
    ({ product, roles, teamId }: ProductAccessFragment) => product === PRODUCT_ID && !isEmpty(roles) && !teamId
  )
  const showAccountPicker = filteredProductAccess.length > 1

  const searchDraftMarketplaceProduct = useGraphqlQuery(
    SearchDraftMarketplaceProductDocument,
    {},
    {
      enabled: !!isManager,
    }
  )
  const showItemsLink = useMemo(
    () => (searchDraftMarketplaceProduct?.data?.searchDraftMarketplaceProduct?.length ?? 0) > 0,
    [searchDraftMarketplaceProduct?.data?.searchDraftMarketplaceProduct]
  )

  const openHelp = () => {
    window.open(
      'https://help.postal.com/helpcenter/s/topic/0TO8X000000hTJYWA2/vendor',
      'postal-help',
      'noopener=true,noreferrer=true'
    )
  }

  return isHidden ? null : (
    <UiSkeleton isLoaded={!meQuery.isLoading}>
      <UiNavbar
        overflowX="auto"
        px={4}
        left={<Logo variant="dark" />}
        center={
          <>
            <VendorNavbarLink to="/orders">Orders</VendorNavbarLink>
            <VendorNavbarLink to="/events">Events</VendorNavbarLink>
            {(showItemsLink || hasManualUpload) && isManager && (
              <VendorNavbarLink to="/products">Products</VendorNavbarLink>
            )}
            <VendorNavbarLink to="/account">Account</VendorNavbarLink>
          </>
        }
        right={
          <>
            <UiNavbarProfile
              user={{
                firstName: me?.firstName ?? '',
                lastName: me?.lastName ?? '',
                accountName,
                profilePicture: me?.profilePicture ?? '',
              }}
              to="/account"
              as={NavLink}
            />
            <UiMenu>
              <UiMenuMore color="white">
                {showAccountPicker && <MenuItem onClick={accountPickerDisclosure.onOpen}>Switch Account</MenuItem>}
                <MenuItem onClick={openHelp}>
                  Help{' '}
                  <ExternalLinkIcon
                    ml={2}
                    boxSize="12px"
                  />
                </MenuItem>
                <MenuItem onClick={logout}>Logout</MenuItem>
              </UiMenuMore>
            </UiMenu>
          </>
        }
      />
      {showAccountPicker && accountPickerDisclosure.isOpen && (
        <AccountPicker
          isOpen={accountPickerDisclosure.isOpen}
          onClose={accountPickerDisclosure.onClose}
        />
      )}
    </UiSkeleton>
  )
}

export interface VendorNavbarLinkProps extends UiNavbarLinkProps {
  to: string
  exact?: boolean
  extraPaths?: string[]
}

export const VendorNavbarLink: React.FC<VendorNavbarLinkProps> = ({
  color = CustomColors.Turquoise,
  to,
  exact,
  extraPaths = [],
  children,
  ...rest
}) => {
  const { pathname } = useLocation()

  const { Color } = useColor()
  const deactiveColor = Color('navbarLink').lighten(0.3).hex()
  const allPaths = useMemo(() => [to, ...(extraPaths || [])], [extraPaths, to])

  const isActive = useMemo(
    () => allPaths.some((p) => (exact ? pathname === p : pathname.startsWith(p))),
    [allPaths, exact, pathname]
  )
  return (
    <UiNavbarLink
      data-testid="nav-link-test"
      as={RouterLink}
      to={to}
      exact={exact}
      color={isActive ? color : deactiveColor}
      fontWeight="bold"
      {...rest}
    >
      {children}
    </UiNavbarLink>
  )
}
