import { Grid, Stack } from '@chakra-ui/react'
import { DraftMarketplaceProductFragment } from 'api'
import { Layout } from 'components/Common/Layout'
import { FormEvent } from 'react'
import { Updater } from 'use-immer'
import { DraftFormProps } from '.'
import { DraftProductsDetailsCard } from './DraftProductDetailsCard'
import { DraftProductMediaCard } from './DraftProductMediaCard'
import { DraftProductOrganizationCard } from './DraftProductOrganizationCard'
import { DraftProductOtherCard } from './DraftProductOtherCard'
import { DraftVariantListV2 } from './DraftVariantListV2'

interface DraftProductPageFormProps {
  onSubmit: (e: FormEvent) => void
  draft?: DraftMarketplaceProductFragment
  form: DraftFormProps
  setForm: Updater<DraftFormProps>
  isLoading?: boolean
  productId?: string
  handleShopifyUpdate?: (input: string) => void
}

export const DraftProductPageForm: React.FC<DraftProductPageFormProps> = ({
  form,
  setForm,
  onSubmit,
  isLoading,
  productId,
  draft,
  handleShopifyUpdate,
}) => {
  return (
    <Layout pt={0}>
      <form
        onSubmit={onSubmit}
        id="draftProductForm"
      >
        <Grid
          templateColumns={'0.5fr 1fr'}
          gap={8}
        >
          <Stack spacing={8}>
            <DraftProductMediaCard
              form={form}
              draft={draft}
              setForm={setForm}
              isLoading={isLoading}
              handleShopifyUpdate={handleShopifyUpdate}
            />
            <DraftProductOrganizationCard
              form={form}
              setForm={setForm}
              isLoading={isLoading}
            />
            <DraftProductOtherCard
              draft={draft}
              isLoading={isLoading}
            />
          </Stack>
          <Stack spacing={8}>
            <DraftProductsDetailsCard
              form={form}
              setForm={setForm}
              productId={productId}
              isLoading={isLoading}
              handleShopifyUpdate={handleShopifyUpdate}
            />
            <DraftVariantListV2
              draft={draft}
              isLoading={isLoading}
            />
          </Stack>
        </Grid>
      </form>
    </Layout>
  )
}
