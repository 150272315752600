import { DraftStatus, FulfillmentStatus, ItemStatus, MagicEventStatus, Status, VendorPayableExternalStatus } from 'api'

export interface StatusMapResult {
  type: string
  label: string
  color?: string
  colorScheme?: string
  bg?: string
}
export interface StatusMapProps {
  [key: string]: StatusMapResult
}

/* NEW PROPS */
const highlightProps = {
  type: 'success',
  color: 'atomicGray.900',
  bg: 'yellow.200',
}

const successProps = {
  type: 'success',
  color: 'atomicGray.900',
  bg: 'vendorGreen.200',
}

const errorProps = {
  type: 'error',
  color: 'atomicGray.900',
  bg: 'red.200',
}

const lowlightProps = {
  type: 'info',
  color: 'atomicGray.900',
  bg: 'gray.200',
}

const defaultProps = {
  type: 'info',
  color: 'atomicGray.900',
  bg: 'atomicBlue.200',
}

const DEFAULT = { ...defaultProps, label: 'Status Unknown' }

// FulfillmentStatus Map

export const FULFILLMENT_STATUS_MAP: StatusMapProps = {
  [FulfillmentStatus.None]: { ...defaultProps, label: 'None' },
  [FulfillmentStatus.Pending]: { ...defaultProps, label: 'Processing' },
  [FulfillmentStatus.PostalAddressRefresh]: {
    ...defaultProps,
    label: 'Pending Address',
  },
  [FulfillmentStatus.PostalDeliveryEmail]: {
    ...defaultProps,
    label: 'Postal Gift Email',
  },
  [FulfillmentStatus.PostalDeliveryEmailError]: {
    ...errorProps,
    label: 'Postal Gift Email Error',
  },
  [FulfillmentStatus.PostalDeliveryRejected]: {
    ...errorProps,
    label: 'Postal Gift Declined',
  },
  [FulfillmentStatus.PostalDeliveryDonated]: {
    ...defaultProps,
    label: 'Postal Gift Donated',
  },
  [FulfillmentStatus.PostalDeliveryExpired]: {
    ...errorProps,
    label: 'Postal Gift Expired',
  },
  [FulfillmentStatus.Processing]: { ...defaultProps, label: 'Processing' },
  [FulfillmentStatus.ProcessingError]: {
    ...errorProps,
    label: 'Processing Error',
  },
  [FulfillmentStatus.Placed]: { ...defaultProps, label: 'Placed' },
  [FulfillmentStatus.Confirmed]: { ...highlightProps, label: 'Confirmed' },
  [FulfillmentStatus.ServerErrorBackoff]: {
    ...errorProps,
    label: 'Error',
  },
  [FulfillmentStatus.Shipped]: {
    ...defaultProps,
    label: 'Shipped',
  },
  [FulfillmentStatus.PendingDelivery]: { ...defaultProps, label: 'In Transit' },
  [FulfillmentStatus.OutForDelivery]: {
    ...defaultProps,
    label: 'Out for Delivery',
  },
  [FulfillmentStatus.Delivered]: { ...successProps, label: 'Delivered' },
  [FulfillmentStatus.DeliveredAssumed]: {
    ...successProps,
    label: 'Delivered',
  },
  [FulfillmentStatus.DeliveryError]: {
    ...errorProps,
    label: 'Delivery Error',
  },
  [FulfillmentStatus.Cancelled]: { ...errorProps, label: 'Cancelled' },
}

// MagicEventStatus Map

export const MAGIC_EVENT_STATUS_MAP: StatusMapProps = {
  [MagicEventStatus.AcceptingInvites]: {
    ...defaultProps,
    label: 'Accepting Invitations',
  },
  [MagicEventStatus.Cancelled]: { ...errorProps, label: 'Cancelled' },
  [MagicEventStatus.Complete]: { ...successProps, label: 'Complete' },
  [MagicEventStatus.ConfirmedBillingIssue]: {
    ...errorProps,
    label: 'Confirmed - Billing Issue',
  },
  [MagicEventStatus.ConfirmedNeedsData]: {
    ...highlightProps,
    label: 'Confirmed - Needs Data',
  },
  [MagicEventStatus.PendingConfirmation]: {
    ...defaultProps,
    label: 'Pending Confirmation',
  },
  [MagicEventStatus.RegistrationClosed]: {
    ...defaultProps,
    label: 'Registration Closed',
  },
}

// DraftStatus Map

export const DRAFT_STATUS_MAP: any = {
  [DraftStatus.Active]: {
    ...successProps,
    label: 'Active',
  },
  [DraftStatus.RequestListing]: {
    ...defaultProps,
    label: 'Listing Requested',
  },
  [DraftStatus.ListingRejected]: {
    ...errorProps,
    label: 'Listing Rejected',
  },
  [DraftStatus.WithdrawListing]: {
    ...defaultProps,
    label: 'Listing Withdrawn',
  },
  [DraftStatus.Draft]: {
    ...lowlightProps,
    label: 'Draft',
    backgroundColor: 'gray.200',
  },
  [DraftStatus.NeedsData]: {
    ...highlightProps,
    label: 'Needs Data',
  },
}

// Status Map

export const STATUS_MAP: any = {
  [Status.Pending]: {
    ...defaultProps,
    label: 'Pending',
  },
  [Status.Active]: {
    ...successProps,
    label: 'Active',
  },
  [Status.Disabled]: {
    ...defaultProps,
    label: 'Disabled',
  },
  [Status.Delete]: {
    ...errorProps,
    label: 'Deleted',
  },
  [Status.OutOfStock]: {
    ...highlightProps,
    label: 'Out of Stock',
  },
  [Status.VendorDisabled]: {
    ...errorProps,
    label: 'Vendor Disabled',
  },
}

// ItemStatus Map

export const ITEM_STATUS_MAP: any = {
  [ItemStatus.Pending]: {
    ...defaultProps,
    label: 'Pending',
  },
  [ItemStatus.Placed]: {
    ...defaultProps,
    label: 'Placed',
  },
  [ItemStatus.Confirmed]: {
    ...highlightProps,
    label: 'Confirmed',
  },
  [ItemStatus.Failed]: {
    ...errorProps,
    label: 'Failed',
  },
  [ItemStatus.Shipped]: {
    ...defaultProps,
    label: 'Shipped',
  },
  [ItemStatus.OutForDelivery]: {
    ...defaultProps,
    label: 'Out for Delivery',
  },
  [ItemStatus.Delivered]: {
    ...successProps,
    label: 'Delivered',
  },
  [ItemStatus.DeliveredAssumed]: {
    ...successProps,
    label: 'Delivery Assumed',
  },
  [ItemStatus.DeliveryFailed]: {
    ...errorProps,
    label: 'Delivery Failed',
  },
  [ItemStatus.Cancelled]: {
    ...errorProps,
    label: 'Cancelled',
  },
}

export const VENDOR_PAYABLE_EXTERNAL_STATUS_MAP: any = {
  [VendorPayableExternalStatus.Created]: {
    ...lowlightProps,
    label: 'Created',
  },
  [VendorPayableExternalStatus.Processing]: {
    ...lowlightProps,
    label: 'Processing',
  },
  [VendorPayableExternalStatus.Completed]: {
    ...lowlightProps,
    label: 'Completed',
  },
  [VendorPayableExternalStatus.Canceled]: {
    ...lowlightProps,
    label: 'Cancelled',
  },
}

// status mappers

export const fulfillmentStatusMapper = (status?: null | FulfillmentStatus): StatusMapResult => {
  return FULFILLMENT_STATUS_MAP[status as FulfillmentStatus] || DEFAULT
}

export const magicEventStatusMapper = (status?: null | MagicEventStatus): StatusMapResult => {
  return MAGIC_EVENT_STATUS_MAP[status as MagicEventStatus] || DEFAULT
}

export const draftStatusMapper = (status?: null | DraftStatus): StatusMapResult => {
  return DRAFT_STATUS_MAP[status as DraftStatus] || DEFAULT
}

export const statusMapper = (status?: null | Status): StatusMapResult => {
  return (status && STATUS_MAP[status]) || DEFAULT
}

export const itemStatusMapper = (status?: null | ItemStatus): StatusMapResult => {
  return (status && ITEM_STATUS_MAP[status]) || DEFAULT
}

export const vendorPayableExternalStatusMapper = (status?: null | VendorPayableExternalStatus): StatusMapResult => {
  return (status && VENDOR_PAYABLE_EXTERNAL_STATUS_MAP[status]) || DEFAULT
}
