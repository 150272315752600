import { LockIcon } from '@chakra-ui/icons'
import { Flex, Grid, Image, useDisclosure } from '@chakra-ui/react'
import {
  UiCard,
  UiCardHeader,
  UiChangeEvent,
  UiFormControl,
  UiFormLabel,
  UiInfoTooltip,
  UiInput,
  UiInputMoney,
  UiSelect,
  UiSwitch,
  UiTextarea,
} from '@postal-io/postal-ui'
import { systemImageMap } from 'components/Integrations'
import { useAcl } from 'hooks'
import { ChangeEvent, useState } from 'react'
import { DraftCardProps, DraftResyncFieldModal } from '.'
import { FulfillmentType } from '../../api'
import { DraftVariantFormProps } from './DraftVariantEditV2'

const TYPES = Object.values(FulfillmentType)
export const DraftProductsVariantDetailsCard: React.FC<DraftCardProps<DraftVariantFormProps>> = ({
  form,
  setForm,
  draft,
  isLoading,
  isShopify,
  handleShopifyUpdate,
}) => {
  const resyncVariantName = useDisclosure()
  const resyncModalDisclosure = useDisclosure()
  const [syncField, setSyncField] = useState('')
  const { hasFeature } = useAcl()

  const handleShopifyResync = (field: string) => {
    setSyncField(field)
    resyncModalDisclosure.onOpen()
  }

  const handleInput = ({ target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = target
    setForm((draft: any) => void (draft[name] = value))
  }

  const handleInputMoney = ({ key, value }: UiChangeEvent<number>) => {
    setForm((draft: any) => void (draft[key] = value))
  }

  const handleSwitchInput = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = target
    setForm((draft: any) => void (draft[name] = checked))
  }

  const handleNoInventoryToggle = () => {
    if (form.quantity === null) {
      setForm((draft) => void (draft.quantity = 0))
    } else {
      setForm((draft) => void (draft.quantity = null))
    }
  }

  const noInventoryLimit = form.quantity === null

  return (
    <>
      <UiCard
        isLoading={isLoading}
        boxShadow="none"
      >
        <UiCardHeader>Details</UiCardHeader>
        <Grid gap={4}>
          <Grid
            gridTemplateColumns={'repeat(auto-fit, minmax(250px, 1fr))'}
            gap={4}
          >
            <UiFormControl
              id="variantName"
              isRequired
              isDisabled={isShopify && !form.overrideVariantName}
              onMouseEnter={resyncVariantName.onOpen}
              onMouseLeave={resyncVariantName.onClose}
            >
              <Flex>
                <UiFormLabel>Variant Name</UiFormLabel>
                {draft?.systemName === 'shopify' && resyncVariantName.isOpen && !!form.overrideVariantName ? (
                  <Image
                    src={systemImageMap[draft?.systemName]}
                    display="block"
                    boxSize={'20px'}
                    onClick={() => handleShopifyResync('variantName')}
                    _hover={{ cursor: 'pointer' }}
                  />
                ) : isShopify && !form.overrideVariantName ? (
                  <LockIcon
                    color={'gray.300'}
                    boxSize={'18px'}
                  />
                ) : null}
              </Flex>
              <UiInput
                isDisabled={isShopify && !form.overrideVariantName}
                size={'lg'}
                name="variantName"
                onChange={handleInput}
                value={form.variantName}
              />
            </UiFormControl>

            <UiFormControl
              id="price"
              isDisabled={isShopify}
            >
              <UiFormLabel>Price (in dollars)</UiFormLabel>
              <UiInputMoney
                size="lg"
                name="price"
                value={form.price}
                onChange={handleInputMoney}
              />
            </UiFormControl>
          </Grid>
          <UiFormControl
            id="description"
            isRequired
          >
            <UiFormLabel>Description</UiFormLabel>
            <UiTextarea
              size={'lg'}
              name="description"
              onChange={handleInput}
              value={form.description}
            />
          </UiFormControl>
          {hasFeature('marketplaceFields') && (
            <UiFormControl
              id="fulfillmentType"
              isRequired
            >
              <UiFormLabel>Fulfillment Type</UiFormLabel>
              <UiSelect
                name="fulfillmentType"
                value={form.fulfillmentType ?? ''}
                onChange={handleInput}
              >
                <option value="">-- Select Type --</option>
                {TYPES.map((type) => {
                  return (
                    <option
                      key={type}
                      value={type}
                    >
                      {type}
                    </option>
                  )
                })}
              </UiSelect>
            </UiFormControl>
          )}
          <Grid
            gridTemplateColumns={'repeat(auto-fit, minmax(250px, 1fr))'}
            gap={4}
          >
            <UiFormControl
              id="sku"
              mr={4}
              isRequired
              isDisabled={isShopify}
            >
              <UiFormLabel>sku</UiFormLabel>
              <UiInput
                size={'lg'}
                name="sku"
                onChange={handleInput}
                value={form.sku || ''}
              />
            </UiFormControl>
            <UiFormControl
              id="upcCode"
              isDisabled={isShopify}
            >
              <UiFormLabel>upcCode</UiFormLabel>
              <UiInput
                size={'lg'}
                name="upcCode"
                onChange={handleInput}
                value={form.upcCode || ''}
              />
            </UiFormControl>
            <UiFormControl>
              <UiFormControl
                id="quantity"
                isDisabled={isShopify || noInventoryLimit}
              >
                <UiFormLabel>Quantity</UiFormLabel>
                <UiInput
                  type="number"
                  size={'lg'}
                  name="quantity"
                  value={form.quantity ?? ''}
                  onChange={({ target }) => setForm((draft) => void (draft.quantity = target.valueAsNumber))}
                  min="0"
                  onWheel={(e) => e.currentTarget.blur()}
                />
              </UiFormControl>
              <UiFormControl
                id="noInventoryLimit"
                isDisabled={isShopify}
              >
                <Flex
                  alignItems={'center'}
                  marginTop={2}
                >
                  <UiFormLabel>No Inventory Limit</UiFormLabel>
                  <UiSwitch
                    display="block"
                    name="taxable"
                    isChecked={noInventoryLimit}
                    onChange={handleNoInventoryToggle}
                  />
                </Flex>
              </UiFormControl>
            </UiFormControl>
          </Grid>
          <Grid
            gridTemplateColumns={'repeat(auto-fit, minmax(225px, 1fr))'}
            gridGap={4}
          >
            <UiFormControl
              id="requiresShipping"
              isDisabled={isShopify}
            >
              <UiFormLabel>
                Requires Shipping{' '}
                <UiInfoTooltip
                  hasArrow
                  placement="right"
                  label="All physical items will require shipping."
                />
              </UiFormLabel>

              <UiSwitch
                display="block"
                name="requiresShipping"
                size="lg"
                isChecked={!!form?.requiresShipping}
                onChange={handleSwitchInput}
              />
            </UiFormControl>

            <UiFormControl
              id="physicalMessageSupported"
              isDisabled={isShopify}
            >
              <UiFormLabel>Physical Message Supported</UiFormLabel>
              <UiSwitch
                display="block"
                name="physicalMessageSupported"
                size="lg"
                isChecked={!!form?.physicalMessageSupported}
                onChange={handleSwitchInput}
              />
            </UiFormControl>

            <UiFormControl id="containsPerishables">
              <UiFormLabel>Contains Perishables</UiFormLabel>
              <UiSwitch
                display="block"
                name="containsPerishables"
                size="lg"
                isChecked={!!form?.containsPerishables}
                onChange={handleSwitchInput}
              />
            </UiFormControl>
          </Grid>
        </Grid>
      </UiCard>
      {resyncModalDisclosure.isOpen && (
        <DraftResyncFieldModal
          handleShopifyUpdate={handleShopifyUpdate}
          syncField={syncField}
          {...resyncModalDisclosure}
        />
      )}
    </>
  )
}
