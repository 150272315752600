import { ButtonGroup, VStack } from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import {
  UiButton,
  UiFormControl,
  UiFormLabel,
  UiInput,
  UiModal,
  UiModalBody,
  UiModalCloseButton,
  UiModalContent,
  UiModalHeader,
  UiModalOverlay,
  useAlerts,
} from '@postal-io/postal-ui'
import { ConnectShopifyDocument, SelectShopifyCollectionDocument } from 'api'
import React, { ChangeEvent, FormEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import { useImmer } from 'use-immer'

interface IntegrationEditModalProps {
  isOpen: boolean
  onClose: () => void
  handleNoCollection: () => void
}

interface FormProps {
  shopifyUrl: string
  accessToken: string
}

export const IntegrationEditModal: React.FC<IntegrationEditModalProps> = ({ isOpen, onClose, handleNoCollection }) => {
  const Alert = useAlerts()

  const navigate = useNavigate()

  const connectIntegration = useGraphqlMutation(ConnectShopifyDocument)

  const [form, setForm] = useImmer<FormProps>({ shopifyUrl: '', accessToken: '' })

  const syncShopifyCollection = useGraphqlMutation(SelectShopifyCollectionDocument, {
    onSuccess: (data) => {
      if (!data.selectShopifyCollection.taskErrored) {
        Alert.success('Integration Connected')
      } else Alert.error('Connection Error')
    },
  })

  const handleConnectIntegration = async (e: FormEvent) => {
    e.preventDefault()
    try {
      const res = await connectIntegration.mutateAsync(form)
      if (!!res.connectShopify.collectionList?.length) {
        navigate('/products/shopifycollection')
      } else {
        handleNoCollection()
      }
      onClose()
    } catch (err) {
      Alert.error(err)
    }
  }

  const handleInput = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = target
    setForm((draft: any) => void (draft[name] = value))
  }

  const isLoading = connectIntegration.isLoading || syncShopifyCollection.isLoading

  return (
    <UiModal
      size="2xl"
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="outside"
      closeOnEsc
    >
      <UiModalOverlay />
      <UiModalContent>
        <UiModalCloseButton />
        <UiModalHeader>Connect to Shopify</UiModalHeader>
        <UiModalBody p={{ base: 4, md: 16 }}>
          <form onSubmit={handleConnectIntegration}>
            <VStack spacing={4}>
              <UiFormControl
                id="shopifyUrl"
                isRequired
              >
                <UiFormLabel>URL</UiFormLabel>
                <UiInput
                  name="shopifyUrl"
                  onChange={handleInput}
                  value={form.shopifyUrl}
                />
              </UiFormControl>
              <UiFormControl
                id="accessToken"
                isRequired
              >
                <UiFormLabel>Access Token</UiFormLabel>
                <UiInput
                  name="accessToken"
                  onChange={handleInput}
                  value={form.accessToken}
                  data-private
                />
              </UiFormControl>
            </VStack>
            <ButtonGroup
              justifyContent={'center'}
              w="100%"
              mt={6}
            >
              <UiButton
                size="sm"
                colorScheme="turquoise"
                color="primary.900"
                fontSize="md"
                letterSpacing="0.5px"
                px={6}
                type="submit"
                isLoading={isLoading}
                isDisabled={isLoading}
              >
                Connect
              </UiButton>
              <UiButton
                size="sm"
                color="primary.900"
                fontSize="md"
                letterSpacing="0.5px"
                px={6}
                variant="outline"
                onClick={onClose}
              >
                Cancel
              </UiButton>
            </ButtonGroup>
          </form>
        </UiModalBody>
      </UiModalContent>
    </UiModal>
  )
}
