import { Stack } from '@chakra-ui/react'
import { useGraphqlInfiniteQuery } from '@postal-io/postal-graphql'
import { UiCard, UiCardHeader, UiCardProps, UiSSDataTable, useAlertError, useGraphqlFilter } from '@postal-io/postal-ui'
import { SearchVendorPayablesDocument, SearchVendorPayablesQueryVariables, VendorPayableFragment } from 'api'
import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDebounce } from 'use-debounce'
import { VENDOR_PAYABLE_COLUMNS, VENDOR_PAYABLE_FILTER_OPTIONS } from './data'
import { VendorPayablesFilter } from './VendorPayablesFilter'

export const VendorPayablesSearchCard: React.FC<UiCardProps> = (props) => {
  const navigate = useNavigate()

  const graphqlFilter = useGraphqlFilter<SearchVendorPayablesQueryVariables>(VENDOR_PAYABLE_FILTER_OPTIONS)
  // to accommodate typing
  const [debouncedVariables] = useDebounce(graphqlFilter.variables, 400)

  const { mergedData, isFetching, hasNextPage, error, refetch, fetchNextPage } = useGraphqlInfiniteQuery(
    SearchVendorPayablesDocument,
    debouncedVariables,
    {
      keepPreviousData: true,
    }
  )
  useAlertError(error)

  const vendorPayables = useMemo(() => {
    return (mergedData?.searchVendorPayables ?? []) as any
  }, [mergedData?.searchVendorPayables])

  useEffect(() => {
    graphqlFilter.transform()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphqlFilter.orderBy])

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    graphqlFilter.transform() || refetch()
  }

  const handleRowClick = (row: VendorPayableFragment) => navigate(`/account/payables/${row.id}`)

  return (
    <UiCard
      isFetching={isFetching}
      {...props}
    >
      <UiCardHeader>Payables Search</UiCardHeader>
      <Stack spacing={4}>
        <VendorPayablesFilter
          filter={graphqlFilter.filter}
          setFilter={graphqlFilter.setFilter}
          onSubmit={handleSubmit}
          isFetching={isFetching}
        />
        <UiSSDataTable
          rowKey="id"
          variant="list"
          columns={VENDOR_PAYABLE_COLUMNS}
          rows={vendorPayables}
          onClick={handleRowClick}
          fetchMore={fetchNextPage}
          hasMore={hasNextPage}
          filter={graphqlFilter.variables.filter}
          orderBy={graphqlFilter.orderBy}
          onOrderBy={graphqlFilter.setOrderBy}
        />
      </Stack>
    </UiCard>
  )
}
