import { Box } from '@chakra-ui/react'
import { UiLoading } from '@postal-io/postal-ui'
import { SignIn, Sso } from 'components/Session'
import { Welcome } from 'components/Welcome'
import { useSession } from 'hooks'
import { StorageKeys } from 'lib'
import React, { Suspense, useEffect, useState } from 'react'
import { Route, Routes, useLocation, useMatch } from 'react-router-dom'
import { Main } from './Main'
import { ResetPassword } from './Session/ResetPassword'
const GraphiQL = React.lazy(() => import('./Main/GraphiQL'))

const SessionRoutes = () => {
  return (
    <>
      <Routes>
        <Route
          path="/sign-in"
          element={<SignIn />}
        />
        <Route
          path="/forgotpw/:requestId"
          element={<ResetPassword />}
        />

        {/* go to signin if none of the above paths are hit */}
        <Route
          path="*"
          element={<SignIn />}
        />
      </Routes>
    </>
  )
}

// insecure paths that we do not want to save
const insecurePaths = ['/sign-in', '/sign-up', '/email', '/verify', '/forgotpw', '/welcome', '/sso', '/logout']
// we can let welcome invites pass through
const sessionPaths = insecurePaths.filter((p) => p !== '/welcome')

// should we save this path for a redirect?
const shouldSavePath = (path: string) => {
  return path !== '/' && !insecurePaths.find((p) => path.startsWith(p))
}

export const App = () => {
  const { session, loadSession, removeSession } = useSession()
  const [loading, setLoading] = useState(true)
  const { pathname, search } = useLocation()

  const sso = useMatch('/sso/:token')
  const token = sso?.params?.token

  const welcome = useMatch('/welcome/:inviteId')
  const inviteId = welcome?.params?.inviteId

  const graphqiql = useMatch('/graphiql')

  useEffect(() => {
    // if we are already logged in then skip loading
    if (session) {
      setLoading(false)
      return
    }

    // session paths should have the current token cleared out and skip loading
    if (sessionPaths.some((p) => pathname.startsWith(p))) {
      removeSession().then(() => setLoading(false))
      return
    }

    // if we are not logged in and have an interesting path then save it
    if (shouldSavePath(pathname)) {
      localStorage.setItem(StorageKeys.AppRedirectPath, [pathname, search].join(''))
    }

    // try to load a session from storage
    loadSession()
      .catch(() => {})
      .finally(() => setLoading(false))
  }, [loadSession, pathname, removeSession, search, session])

  // intercept SSO Login
  if (token) return <Sso token={token} />

  // intercept Invitation Acceptance
  if (inviteId) return <Welcome inviteId={inviteId} />

  // show spinner while loading session
  if (loading) return <UiLoading />

  if (process.env.NODE_ENV !== 'production' && session && graphqiql) {
    return (
      <Suspense fallback={<Box>Loading...</Box>}>
        <GraphiQL />
      </Suspense>
    )
  }

  return <main>{!session ? <SessionRoutes /> : <Main />}</main>
}
