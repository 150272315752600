import { useSession } from 'hooks'
import { compact } from 'lodash'
import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

const SEGMENT_KEY = process.env.REACT_APP_SEGMENT_KEY
const ENVIRONMENT = process.env.REACT_APP_DEPLOY_ENV

const isExcludedUser = (userName?: string) => {
  if (!userName) return true
  if (userName.includes('monitor@postal.io')) return true
  return false
}

export const Analytics = () => {
  const { pathname } = useLocation()
  const { session } = useSession()
  const initRef = useRef(null)

  useEffect(() => {
    if (!SEGMENT_KEY) return
    if (!session?.userId) return
    if (initRef.current === session.userId) return
    if (isExcludedUser(session.userName)) return

    initRef.current = session.userId
    window.analytics?.identify(session.userId, {
      full_name: compact([session.firstName, session.lastName]).join(' '),
      email: session.userName,
      partnerId: session.partnerId,
      partnerName: session.partnerName,
      plan: session.plan,
      product: session.product,
      roles: session.roles,
      firstName: session.firstName,
      lastName: session.lastName,
      environment: ENVIRONMENT,
    })

    window.analytics?.group(session.partnerId, {
      partnerId: session.partnerId,
      partnerName: session.partnerName,
      name: session.partnerName,
      plan: session.plan,
      product: session.product,
      environment: ENVIRONMENT,
    })
  }, [
    session.firstName,
    session.lastName,
    session.partnerId,
    session.partnerName,
    session.plan,
    session.product,
    session.roles,
    session.userId,
    session.userName,
  ])

  // track each page request when the route changes
  useEffect(() => {
    if (!SEGMENT_KEY) return
    if (!session?.sessionUuid) return
    if (!pathname) return
    if (isExcludedUser(session?.userName)) return

    window.analytics?.page({ sessionUuid: session?.sessionUuid })
  }, [pathname, session?.sessionUuid, session?.userName])

  return null
}
