import { UiLoading } from '@postal-io/postal-ui'
import {
  DraftProductEditV2,
  DraftProductsPage,
  DraftVariantEditV2,
  Event,
  EventsPage,
  Order,
  OrderSavedExports,
  Welcome,
} from 'components'
import { DraftShopifyCollectionList } from 'components/Drafts'
import { OrdersPage } from 'components/Orders/OrdersPage'
import { VendorPayableDetailsPage } from 'components/Payables/VendorPayableDetailsPage'
import { Profile } from 'components/Profile'
import { UseRouteProps, useRoutesAcl } from 'hooks/useRoutesAcl'
import { RouteObject, useRoutes } from 'react-router-dom'
import { VendorPayablesSearchPage } from '../Payables/VendorPayablesSearchPage'
import { Home } from './Home'

export const AppRoutes = () => {
  const routeList: UseRouteProps[] = [
    { path: '/account', element: <Profile /> },
    {
      path: '/account/payables',
      element: <VendorPayablesSearchPage />,
      module: 'vendorPayable.read',
    },
    { path: '/orders', element: <OrdersPage /> },
    { path: '/order-exports', element: <OrderSavedExports /> },
    { path: '/orders/:orderId', element: <Order /> },
    { path: '/events', element: <EventsPage /> },
    { path: '/events/:eventId', element: <Event /> },
    {
      path: '/products',
      element: <DraftProductsPage />,
      module: 'integrations.read',
    },
    {
      path: '/products/shopifycollection',
      element: <DraftShopifyCollectionList />,
      module: 'integrations.read',
    },
    {
      path: '/products/edit',
      element: <DraftProductEditV2 />,
      feature: 'productManual',
      module: 'integrations.read',
    },
    {
      path: '/products/:productId',
      element: <DraftProductEditV2 />,
      module: 'integrations.read',
    },
    {
      path: '/products/:productId/variants/edit',
      element: <DraftVariantEditV2 />,
      features: 'productManual',
      module: 'integrations.read',
    },
    {
      path: '/products/:productId/variants/:variantId',
      element: <DraftVariantEditV2 />,
      module: 'integrations.read',
    },
    {
      path: '/account/payables/:vendorPayableId',
      element: <VendorPayableDetailsPage />,
      module: 'vendorPayable.read',
    },
    {
      path: '/welcome/:inviteId',
      element: <Welcome />,
    },
    {
      path: '/',
      element: <Home />,
    },
  ]

  return <VendorRoutes routes={routeList} />
}

interface RoutesProps {
  routes: UseRouteProps[]
}

export const VendorRoutes: React.FC<RoutesProps> = ({ routes }) => {
  const filteredRoutes = useRoutesAcl(routes) as RouteObject[]

  const Page = useRoutes(filteredRoutes)

  return !filteredRoutes.length ? <UiLoading /> : Page
}
