import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { isEmptyValue } from '@postal-io/postal-ui'
import { ModulesDocument, Role } from 'api'
import { useFlags } from 'launchdarkly-react-client-sdk'
import camelCase from 'lodash/camelCase'
import React, { useCallback, useMemo } from 'react'
import { useSession } from './useSession'

/**
 * Launch Darkly helpers.
 *
 * The feature flags come over as camelCase from LD
 * and they are organized by a prefix of `module` or `feature` depending on
 * the type of flag.
 */
const ldModule = (name: string) => camelCase(`module-${name}`)
const ldFeature = (name: string) => camelCase(`feature-${name}`)

/**
 * Acl Filter
 *
 * Generic interface used to filter an object based on
 * module, feature, flag, or role properties
 */
export interface AclFilter extends Record<string, any> {
  module?: ModulePermission | ModulePermission[] | null
  feature?: string | string[] | null
  flag?: string | string[] | null
  role?: Role | Role[] | null
  enabled?: boolean
}

type ModuleName = string
type PermissionName = string
export type ModulePermission = `${ModuleName}.${PermissionName}`

/**
 * useAcl
 *
 * a Hook for checking the currently logged in user's permissions
 *
 * const {
 *   aclCheck,
 *   aclFilter,
 *   error,
 *   flags,
 *   getMeta,
 *   getModule,
 *   hasFeature,
 *   hasFlag,
 *   hasPermission,
 *   hasRole,
 *   isLoading,
 *   modules,
 *   refetch
 * } = useAcl()
 *
 * const { hasPermission, aclCheck, getMeta } = useAcl()
 * const canRead = hasPermission('profile.read')
 * const canDoThing = aclCheck({ feature: 'thing', module: 'postals.send' })
 * const maxItems = getMeta('collections')?.maximumItems
 *
 */
export const useAcl = () => {
  const { session } = useSession()
  const flags = useFlags()
  const { error, isLoading, refetch, data } = useGraphqlQuery(ModulesDocument)
  const modules = useMemo(() => data?.modules || [], [data?.modules])
  const hasRole = useCallback((role: Role) => !!session?.roles?.includes(role), [session?.roles])
  const hasFeature = useCallback((name: string) => flags[ldFeature(name)] !== false, [flags])
  const hasFlag = useCallback((name: string) => flags[name] !== false, [flags])
  const getModule = useCallback(
    (name: string) => {
      return flags[ldModule(name)] === false ? undefined : modules.find((m: any) => m?.name === name)
    },
    [flags, modules]
  )
  const hasPermission = useCallback(
    (name: ModulePermission) => {
      const [mod, perm] = name.split('.')
      return !!getModule(mod)?.permissions?.[perm]
    },
    [getModule]
  )
  const getMeta = useCallback((name: string) => (getModule(name)?.meta ?? {}) as Record<string, any>, [getModule])

  const aclCheck = useCallback(
    (permObj: AclFilter) => {
      return Object.keys(permObj).every((key) => {
        const value = permObj[key]
        if (isEmptyValue(value)) return true
        switch (key) {
          case 'feature':
            return Array.isArray(value) ? value.every(hasFeature) : hasFeature(value)
          case 'flag':
            return Array.isArray(value) ? value.every(hasFlag) : hasFlag(value)
          case 'role':
            return Array.isArray(value) ? value.every(hasRole) : hasRole(value)
          case 'module':
            return Array.isArray(value) ? value.every(hasPermission) : hasPermission(value)
          case 'enabled':
            return Array.isArray(value) ? value.every((v) => !!v) : !!value
          default:
            return true
        }
      })
    },
    [hasFeature, hasFlag, hasRole, hasPermission]
  )
  const aclFilter = useCallback(<T extends AclFilter>(items: T[]) => items.filter(aclCheck), [aclCheck])
  return {
    aclCheck,
    aclFilter,
    error,
    flags,
    getMeta,
    getModule,
    hasFeature,
    hasFlag,
    hasPermission,
    hasRole,
    isLoading,
    modules,
    refetch,
  }
}

/**
 * Acl Component to show/hide children based on any aclCheck params.
 *
 * <Acl module="profile.create" feature="theFeature">
 *   <SomeCreateThing />
 * </Acl>
 */
export interface AclProps extends AclFilter {
  children?: any
}
export const Acl: React.FC<AclProps> = ({ module, feature, flag, role, type, children }) => {
  const { aclCheck } = useAcl()
  if (!children) return null
  return aclCheck({ module, feature, role, flag, type }) ? children : null
}
