import { CheckCircleIcon } from '@chakra-ui/icons'
import { Stack, Text } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { UiButton, UiDialog, useUiAlerts } from '@postal-io/postal-ui'
import { isEmpty } from 'lodash'
import React, { useMemo } from 'react'
import { NamedProductAccessFragment, NamedProductAccessListDocument } from '../../api'
import { useSession } from '../../hooks'

interface AccountPickerProps {
  isOpen: boolean
  onClose: () => void
}

export const AccountPicker: React.FC<AccountPickerProps> = ({ isOpen, onClose }) => {
  const Alert = useUiAlerts()

  const namedAccessListQuery = useGraphqlQuery(NamedProductAccessListDocument)

  const filteredAccessAccounts = useMemo(() => {
    return (
      (namedAccessListQuery.data?.namedProductAccessList?.filter((item) => {
        return (
          item?.productAccess.product === process.env.REACT_APP_PRODUCT_ID &&
          !isEmpty(item?.productAccess.roles) &&
          item?.productAccess?.teamId === null
        )
      }) as NamedProductAccessFragment[]) || []
    )
  }, [namedAccessListQuery.data?.namedProductAccessList])

  const { session, switchSession } = useSession()

  const { productAccessId } = session

  const switchAccount = async (account: NamedProductAccessFragment) => {
    if (account.productAccess.id === productAccessId) return
    try {
      await switchSession(account?.productAccess.id)
    } catch (err) {
      Alert.error(err)
    } finally {
      onClose()
    }
  }

  return (
    <UiDialog
      title="Switch Account"
      isOpen={isOpen}
      onClose={onClose}
    >
      <Text mb={2}>Please select the Account or Team you would like to switch to.</Text>
      <Stack
        spacing={4}
        my={8}
      >
        {filteredAccessAccounts?.map((account) => {
          return (
            <UiButton
              key={account.productAccess.id}
              variant="outline"
              borderWidth="1px"
              colorScheme="gray"
              color="gray.800"
              borderColor="gray.200"
              boxShadow="postal"
              fontWeight="semibold"
              onClick={() => switchAccount(account)}
              isDisabled={account.productAccess.id === productAccessId}
            >
              {account.productAccess.id === productAccessId && (
                <CheckCircleIcon
                  color="green.500"
                  position="absolute"
                  top="-12px"
                  left="-12px"
                  boxSize="24px"
                  bg="white"
                />
              )}
              {account.teamName ? `${account.accountName} - ${account.teamName}` : `${account.accountName} - Account`}
            </UiButton>
          )
        })}
      </Stack>
    </UiDialog>
  )
}
