import { UiLoading, useUiAlerts } from '@postal-io/postal-ui'
import { useSession } from 'hooks'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Main } from '../Session'
import { InviteInfo } from './types'
import { WelcomeExistingUser } from './WelcomeExistingUser'
import { WelcomeNewUser } from './WelcomeNewUser'
import { WelcomePassword } from './WelcomePassword'

interface WelcomeProps {
  inviteId?: string
}

export const Welcome: React.FC<WelcomeProps> = ({ inviteId: inviteIdProp }) => {
  const [token, setToken] = useState('')
  const [inviteInfo, setInviteInfo] = useState<InviteInfo>()
  const [loading, setLoading] = useState(true)
  const { getInviteInfo } = useSession()

  const { inviteId } = useParams()

  //the invite id can come in as a prop directly or from as a parameter
  const newUserId = useMemo(() => {
    return inviteId || inviteIdProp || ''
  }, [inviteId, inviteIdProp])

  const Alert = useUiAlerts()
  const navigate = useNavigate()
  const ref = useRef(false)
  useEffect(() => {
    if (inviteInfo || ref.current) return
    ref.current = true
    getInviteInfo(newUserId)
      .then((info: InviteInfo) => setInviteInfo(info))
      .catch((err: Error) => {
        console.log('ERROR', err)
        Alert.error(`This link has Expired.`)
        navigate('/')
      })
      .finally(() => setLoading(false))
  }, [Alert, getInviteInfo, inviteId, inviteInfo, navigate, newUserId])

  const existingUser = inviteInfo?.type === 'EXISTING_USER'

  if (loading || !inviteInfo) return <UiLoading />

  return (
    <Main>
      {token ? (
        <WelcomePassword token={token} />
      ) : existingUser ? (
        <WelcomeExistingUser
          inviteId={newUserId}
          inviteInfo={inviteInfo as any}
        />
      ) : (
        <WelcomeNewUser
          inviteId={newUserId}
          inviteInfo={inviteInfo as any}
          onComplete={setToken}
        />
      )}
    </Main>
  )
}
