import { Box, Grid, Heading, Image, Text } from '@chakra-ui/react'
import { GraphqlError } from '@postal-io/postal-graphql'
import { UiButton } from '@postal-io/postal-ui'
import image from 'assets/app-error.svg'
import * as LogRocket from 'logrocket'
import React, { ReactElement } from 'react'
import { ErrorBoundary as ErrorBoundaryProvider, FallbackProps } from 'react-error-boundary'

enum ErrorType {
  NotFound = 'NotFound',
  SessionExpired = 'SessionExpired',
  AuthError = 'AuthError',
  SystemError = 'SystemError',
}

const resetApp = () => {
  window.location.href = `${process.env.PUBLIC_URL}/`
}

const getErrorType = (error?: Error) => {
  const err = error as GraphqlError
  if (err?.message === 'Session Expired') return ErrorType.SessionExpired
  if (!err?.status) return ErrorType.SystemError
  if ([401, 402, 403].includes(err.status)) return ErrorType.AuthError
  if (err.status === 404 || err.extensions?.httpCode === 404) return ErrorType.NotFound
  return ErrorType.SystemError
}

export const ErrorBoundary: React.FC<{ children: ReactElement }> = ({ children }) => {
  const handleError = (error: Error) => {
    switch (getErrorType(error)) {
      case ErrorType.SessionExpired:
        break
      case ErrorType.NotFound:
        LogRocket.captureMessage(error.message)
        break
      case ErrorType.AuthError:
      case ErrorType.SystemError:
      default:
        LogRocket.captureException(error)
    }
  }

  return (
    <ErrorBoundaryProvider
      FallbackComponent={Fallback}
      onError={handleError}
    >
      {children}
    </ErrorBoundaryProvider>
  )
}

export const Fallback: React.FC<FallbackProps> = ({ error }) => {
  switch (getErrorType(error)) {
    case ErrorType.AuthError:
    case ErrorType.SessionExpired:
      return <SessionExpired />
    case ErrorType.NotFound:
      return <NotFound />
    case ErrorType.SystemError:
    default:
      return <SystemError />
  }
}

const NotFound: React.FC = () => {
  return (
    <Grid
      templateColumns="1fr 2fr"
      alignItems="center"
      justifyItems="center"
      h="100vh"
      maxW="90rem"
      marginX="auto"
    >
      <Grid
        gridTemplateRows="repeat(3, auto)"
        alignContent="center"
        gridGap={8}
        p={8}
      >
        <Heading
          as="h1"
          fontSize="5xl"
          lineHeight={1}
          color="primary.500"
          whiteSpace="nowrap"
          textTransform="capitalize"
        >
          Not Found
        </Heading>
        <Text
          fontSize="lg"
          color="gray.600"
        >
          Oh no. The page you requested was not found. Please click the button below to go back home.
        </Text>
        <UiButton
          onClick={resetApp}
          colorScheme="primary"
          size="lg"
          maxW="300px"
        >
          Back to Home
        </UiButton>
      </Grid>
      <Box>
        <Image src={image} />
      </Box>
    </Grid>
  )
}

const SessionExpired: React.FC = () => {
  return (
    <Grid
      templateColumns="1fr 2fr"
      alignItems="center"
      justifyItems="center"
      h="100vh"
      maxW="90rem"
      marginX="auto"
    >
      <Grid
        gridTemplateRows="repeat(3, auto)"
        alignContent="center"
        gridGap={8}
        p={8}
      >
        <Heading
          as="h1"
          fontSize="5xl"
          lineHeight={1}
          color="primary.500"
          whiteSpace="nowrap"
          textTransform="capitalize"
        >
          Session Expired
        </Heading>
        <Text
          fontSize="lg"
          color="gray.600"
        >
          Your session has expired. Please click the button below to log back in.
        </Text>
        <UiButton
          onClick={resetApp}
          colorScheme="primary"
          size="lg"
          maxW="200px"
        >
          Login Again
        </UiButton>
      </Grid>
      <Box>
        <Image src={image} />
      </Box>
    </Grid>
  )
}

export const SystemError: React.FC = () => {
  return (
    <Grid
      templateColumns="1fr 2fr"
      alignItems="center"
      justifyItems="center"
      h="100vh"
      maxW="90rem"
      marginX="auto"
    >
      <Grid
        gridTemplateRows="repeat(3, auto)"
        alignContent="center"
        gridGap={8}
        p={8}
      >
        <Heading
          as="h1"
          fontSize="5xl"
          lineHeight={1}
          color="primary.500"
          whiteSpace="nowrap"
          textTransform="capitalize"
        >
          System Error
        </Heading>
        <Text
          fontSize="lg"
          color="gray.600"
        >
          Oh no. We have experienced an unexpected error. Please click the button below to reload the page.
        </Text>
        <UiButton
          onClick={resetApp}
          colorScheme="tertiary"
          size="lg"
          maxW="200px"
        >
          Reset Page
        </UiButton>
      </Grid>
      <Box>
        <Image src={image} />
      </Box>
    </Grid>
  )
}
