import { Box, BoxProps, Grid, Link, Stack, Text } from '@chakra-ui/react'
import { humanize, UiCheckboxIcon } from '@postal-io/postal-ui'
import { ItemCustomizationType } from 'api'
import { Row } from 'components/Common/Row'
import { format } from 'date-fns'
import { useMemo } from 'react'

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL || '/api/user/image'

const getAssetUrl = (value: string, accountId?: string) => {
  if (value.startsWith('http:') || value.startsWith('https:')) return value
  if (value.startsWith('asset:')) {
    const [_, assetId] = value.split('asset://')
    if (assetId.includes('/')) return `${IMAGE_URL}/${assetId}`
    const isMarketplace = assetId.startsWith('PMA')
    const id = isMarketplace ? assetId.replace(/^PMA/, '') : assetId
    return isMarketplace ? `${IMAGE_URL}/marketplace/${id}` : `${IMAGE_URL}/${accountId}/${id}`
  }
}

const dateStringPattern = /[12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])/

export const sectionProps = {
  boxShadow: 'sm',
  borderColor: 'gray.200',
  borderWidth: '1px',
  borderRadius: '0.625rem',
}

//TODO: add to UiLib
interface ListItemProps extends BoxProps {
  label?: string
}

export const ListItem: React.FC<ListItemProps> = ({ label, children, ...rest }) => {
  if (!children) return null
  return (
    <Box {...rest}>
      <Text
        as="span"
        fontWeight="semibold"
        display="block"
      >
        {label}
      </Text>
      <Text
        as="span"
        whiteSpace="break-spaces"
        display="block"
      >
        {children}
      </Text>
    </Box>
  )
}

interface OrderItemCustomFieldsProps {
  customItemList: any[]
  accountId?: string
}

export const OrderItemCustomFields: React.FC<OrderItemCustomFieldsProps> = ({ customItemList, accountId }) => {
  return (
    <Stack spacing={4}>
      {customItemList.map((field, idx) => {
        return (
          <ListItem
            label={humanize(field.id)}
            key={`${idx}-${field.id}`}
          >
            {field.type === ItemCustomizationType.Text || field.type === ItemCustomizationType.Location ? (
              <Text>{field.value}</Text>
            ) : field.type === File || field.type === ItemCustomizationType.Image ? (
              <Link
                href={getAssetUrl(field.value, accountId)}
                isExternal
              >
                {`${field.type === File ? 'File' : 'Image'} Link`}
              </Link>
            ) : null}
          </ListItem>
        )
      })}
    </Stack>
  )
}

interface OrderItemFormFieldsProps extends BoxProps {
  formFields: Record<string, any>
}

export const OrderItemFormFields: React.FC<OrderItemFormFieldsProps> = ({ formFields }) => {
  const formattedObj = useMemo(() => {
    const obj: Record<string, any> = {}
    Object.keys(formFields).forEach((key) => {
      if (Array.isArray(formFields[key])) {
        obj[key] = formFields[key].join(', ')
      } else if (dateStringPattern.test(formFields[key])) {
        const newDate = new Date(formFields[key])
        obj[key] = format(newDate, 'MM/dd/yyyy')
      } else if (formFields[key] === true || formFields[key] === '') {
        obj[key] = !!formFields[key] ? (
          <UiCheckboxIcon
            fontSize="1rem"
            isChecked
          />
        ) : (
          <UiCheckboxIcon fontSize="1rem" />
        )
      } else {
        obj[key] = formFields[key]
      }
    })
    return obj
  }, [formFields])

  return (
    <Grid
      templateColumns="repeat(auto-fill, minmax(250px, 1fr))"
      minH="250px"
      justifyContent="stretch"
      gap={5}
    >
      {Object.keys(formFields)?.map((key: any, idx: number) => {
        return (
          <Row
            key={`${key}-${idx}`}
            label={key}
          >
            {formattedObj[key]}
          </Row>
        )
      })}
    </Grid>
  )
}
