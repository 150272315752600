import { Alert, AlertIcon, BoxProps, Stack } from '@chakra-ui/react'
import { UiFormControl, UiFormLabel, UiInputPassword, UiPasswordChecklist } from '@postal-io/postal-ui'
import React from 'react'
import { Updater } from 'use-immer'

export interface PasswordState {
  currentPassword: string
  newPassword1: string
  newPassword2: string
}

interface ProfileEditPasswordProps extends BoxProps {
  form: PasswordState
  setForm: Updater<PasswordState>
  emailAddress: string
  requireCurrentPassword?: boolean
}

export const ProfileEditPassword: React.FC<ProfileEditPasswordProps> = ({
  form,
  setForm,
  emailAddress,
  requireCurrentPassword,
  ...rest
}) => {
  return (
    <>
      {!requireCurrentPassword && (
        <Alert
          status="info"
          mt={6}
        >
          <AlertIcon />
          You are currently logging in with SSO. You may set a password below to login with that password instead of
          SSO. If you wish to change your SSO password, please login to your provider.
        </Alert>
      )}
      <Stack
        spacing={6}
        {...rest}
      >
        {requireCurrentPassword && (
          <UiFormControl
            id="currentPassword"
            mb={0}
          >
            <UiFormLabel>Current Password</UiFormLabel>
            <UiInputPassword
              name="currentPassword"
              placeholder="Current Password"
              onChange={(e) => setForm((draft) => void (draft.currentPassword = e.target.value))}
              value={form.currentPassword}
              autoComplete="current-password"
              data-private
            />
          </UiFormControl>
        )}
        <UiFormControl
          id="newPassword1"
          mb={0}
        >
          <UiFormLabel>New Password</UiFormLabel>
          <UiInputPassword
            id="newPassword1"
            name="newPassword1"
            placeholder="New Password"
            value={form.newPassword1}
            autoComplete="new-password"
            onChange={(e) => setForm((draft) => void (draft.newPassword1 = e.target.value))}
            data-private
          />
        </UiFormControl>
        <UiFormControl
          id="newPassword2"
          mb={0}
        >
          <UiFormLabel>Re-enter Password</UiFormLabel>
          <UiInputPassword
            name="newPassword2"
            placeholder="Re-enter Password"
            value={form.newPassword2}
            autoComplete="new-password"
            onChange={(e) => setForm((draft) => void (draft.newPassword2 = e.target.value))}
            data-private
          />
        </UiFormControl>
        <UiPasswordChecklist
          password={form.newPassword1}
          password2={form.newPassword2}
          emailAddress={emailAddress}
          my={16}
        />
      </Stack>
    </>
  )
}
