import { Box, Heading, Stack } from '@chakra-ui/react'
import {
  Passwords,
  UiButton,
  UiFormControl,
  UiFormLabel,
  UiInputPassword,
  UiPasswordChecklist,
  validatePassword,
} from '@postal-io/postal-ui'
import React from 'react'
import { useImmer } from 'use-immer'
import { SsoOption } from './SsoOption'

interface SetPasswordProps {
  onSubmit: (props: Passwords) => void
  isLoading?: boolean
  emailAddress?: string
  showSso?: boolean
}

export const SetPassword: React.FC<SetPasswordProps> = ({ onSubmit, isLoading, emailAddress, showSso = false }) => {
  const [form, setForm] = useImmer<Passwords>({ password: '', password2: '' })
  const { password, password2 } = form

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    onSubmit(form)
  }

  const isDisabled = !validatePassword({ password, password2, emailAddress })

  return (
    <>
      {showSso && <SsoOption my={12} />}
      {!showSso && (
        <Heading
          fontWeight={600}
          as="h1"
          size="md"
          my={12}
          textAlign="center"
          whiteSpace="nowrap"
        >
          Please set your password
        </Heading>
      )}
      <Box w="100%">
        <form onSubmit={handleSubmit}>
          <Stack spacing={4}>
            <UiFormControl
              id="password"
              isRequired
            >
              <UiFormLabel>Password</UiFormLabel>
              <UiInputPassword
                name="password"
                placeholder="Password"
                autoComplete="new-password"
                value={password}
                onChange={(e) => setForm((draft) => void (draft.password = e.target.value))}
                data-private
              />
            </UiFormControl>
            <UiFormControl
              id="password2"
              isRequired
            >
              <UiFormLabel>Re-enter Password</UiFormLabel>
              <UiInputPassword
                name="password2"
                placeholder="Re-enter Password"
                autoComplete="new-password"
                value={password2}
                onChange={(e) => setForm((draft) => void (draft.password2 = e.target.value))}
                data-private
              />
            </UiFormControl>
          </Stack>
          <UiPasswordChecklist
            password={form.password}
            password2={form.password2}
            emailAddress={emailAddress}
            my={16}
          />
          <UiButton
            type="submit"
            colorScheme="tertiary"
            w="100%"
            size="lg"
            isLoading={isLoading}
            isDisabled={isLoading || isDisabled}
          >
            Continue
          </UiButton>
        </form>
      </Box>
    </>
  )
}
