import { Heading } from '@chakra-ui/react'
import { Layout, SubNavbar } from 'components/Common'
import { SidebarNav } from 'components/Common/SidebarNav'
import { ProfileSidebar } from 'components/Profile'
import { VendorPayablesSearchCard } from './VendorPayablesSearchCard'

export const VendorPayablesSearchPage = () => {
  return (
    <>
      <Layout pt={0}>
        <SidebarNav blocks={<ProfileSidebar />}>
          <SubNavbar
            left={<Heading fontSize="2xl">Vendor Payables</Heading>}
            boxShadow="none"
            border="none"
            maxW="1800px"
            gridProps={{ py: 6, px: 0 }}
            containerProps={{ p: 0 }}
            mb={0}
          />
          <VendorPayablesSearchCard boxShadow={'none'} />
        </SidebarNav>
      </Layout>
    </>
  )
}
