import { LockIcon } from '@chakra-ui/icons'
import { Flex, Grid, Image, useDisclosure } from '@chakra-ui/react'
import {
  humanize,
  UiAutoCompleteMultiSelect,
  UiCard,
  UiCardHeader,
  UiFormControl,
  UiFormLabel,
  UiInput,
  UiSelect,
  UiSwitch,
  UiTextarea,
} from '@postal-io/postal-ui'
import { EstimatedShippingTime } from 'api'
import { systemImageMap } from 'components/Integrations'
import { useAcl } from 'hooks'
import { set } from 'lodash'
import { ChangeEvent, useMemo, useState } from 'react'
import { KEYWORDS, OCCASION_KEYWORDS, SEASON_KEYWORDS } from './draftProductDataV2'
import { DraftCardProps, DraftFormProps } from './DraftProductEditV2'
import { DraftProductTags } from './DraftProductTags'
import { DraftResyncFieldModal } from './DraftResyncFieldModal'

export const DraftProductsDetailsCard: React.FC<DraftCardProps<DraftFormProps>> = ({
  form,
  setForm,
  productId,
  isLoading,
  handleShopifyUpdate,
}) => {
  const resyncDesc = useDisclosure()
  const resyncTitle = useDisclosure()
  const resyncModalDisclosure = useDisclosure()
  const [syncField, setSyncField] = useState('')
  const { hasFeature } = useAcl()

  const isShopify = useMemo(() => {
    return form?.systemName === 'shopify'
  }, [form?.systemName])

  const handleShopifyResync = (field: string) => {
    setSyncField(field)
    resyncModalDisclosure.onOpen()
  }

  const handleMultiSelectValue = (name: string, value: any) => {
    setForm((draft) => {
      set(draft, name, value)
    })
  }

  const handleInput = ({ target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = target
    setForm((draft: any) => void (draft[name] = value))
  }

  const handleSwitchInput = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = target
    setForm((draft: any) => void (draft[name] = checked))
  }

  return (
    <>
      <UiCard
        isLoading={isLoading}
        boxShadow="none"
      >
        <UiCardHeader>Details</UiCardHeader>
        <Grid gap={4}>
          <Flex justifyContent={'space-between'}>
            <UiFormControl
              isDisabled={isShopify && !form.overrideName}
              id="name"
              isRequired
              onMouseEnter={resyncTitle.onOpen}
              onMouseLeave={resyncTitle.onClose}
            >
              <Flex>
                <UiFormLabel>Title</UiFormLabel>
                {isShopify && resyncTitle.isOpen && !!form.overrideName ? (
                  <Image
                    src={systemImageMap[form?.systemName]}
                    display="block"
                    boxSize={'20px'}
                    onClick={() => handleShopifyResync('name')}
                    _hover={{ cursor: 'pointer' }}
                  />
                ) : isShopify && !form.overrideName ? (
                  <LockIcon
                    color={'gray.300'}
                    boxSize={'18px'}
                  />
                ) : null}
              </Flex>
              <UiInput
                h="40px"
                name="name"
                onChange={handleInput}
                value={form.name}
              />
            </UiFormControl>
          </Flex>

          <UiFormControl
            id="description"
            isRequired
            isDisabled={isShopify && !form.overrideDescription}
            onMouseEnter={resyncDesc.onOpen}
            onMouseLeave={resyncDesc.onClose}
          >
            <Flex>
              <UiFormLabel>Description</UiFormLabel>
              {isShopify && resyncDesc.isOpen && !!form.overrideDescription ? (
                <Image
                  src={systemImageMap[form?.systemName]}
                  display="block"
                  boxSize={'20px'}
                  onClick={() => handleShopifyResync('description')}
                  _hover={{ cursor: 'pointer' }}
                />
              ) : isShopify && !form.overrideDescription ? (
                <LockIcon
                  color={'gray.300'}
                  boxSize={'18px'}
                />
              ) : null}
            </Flex>
            <UiTextarea
              name="description"
              onChange={handleInput}
              value={form.description || ''}
            />
          </UiFormControl>
          <Grid
            gridTemplateColumns={{ base: '1f', lg: !!productId ? '1fr 1fr' : '1fr' }}
            gap={4}
          >
            <UiFormControl
              id="tags"
              isDisabled={isShopify}
            >
              <UiFormLabel>Tags</UiFormLabel>
              <DraftProductTags
                isDisabled={isShopify}
                form={form}
                setForm={setForm}
              />
            </UiFormControl>

            {!!productId && (
              <>
                <UiFormControl id="estimatedShippingTime">
                  <UiFormLabel>Time to ship</UiFormLabel>
                  <UiSelect
                    name="estimatedShippingTime"
                    value={form.estimatedShippingTime}
                    onChange={handleInput}
                  >
                    <option value={EstimatedShippingTime.None}>-- Estimated Shipping Time --</option>
                    {Object.values(EstimatedShippingTime)
                      .filter((item) => item !== EstimatedShippingTime.None)
                      .map((item, idx) => (
                        <option
                          key={`${item}-${idx}`}
                          value={item}
                        >
                          {humanize(item)}
                        </option>
                      ))}
                  </UiSelect>
                </UiFormControl>
              </>
            )}
          </Grid>
          {!!productId && (
            <Grid
              templateColumns={'1fr 1fr 1fr'}
              gap={4}
            >
              <UiFormControl
                id="keywords"
                isDisabled={isShopify}
              >
                <UiFormLabel>Keywords</UiFormLabel>
                <UiAutoCompleteMultiSelect
                  isDisabled={isShopify}
                  options={KEYWORDS}
                  name="keywords"
                  placeholder="General Keywords"
                  value={form?.keywords}
                  onChange={(val) => handleMultiSelectValue('keywords', val)}
                  isMulti
                />
              </UiFormControl>
              <UiFormControl
                id="keywords_occasion"
                isDisabled={isShopify}
              >
                <UiFormLabel>Occasion</UiFormLabel>
                <UiAutoCompleteMultiSelect
                  isDisabled={isShopify}
                  options={OCCASION_KEYWORDS}
                  name="keywords_occasion"
                  placeholder="Occasion Keywords"
                  value={form?.keywords_occasion}
                  onChange={(val) => handleMultiSelectValue('keywords_occasion', val)}
                  isMulti
                />
              </UiFormControl>
              <UiFormControl
                id="keywords_season"
                isDisabled={isShopify}
              >
                <UiFormLabel>Season</UiFormLabel>
                <UiAutoCompleteMultiSelect
                  isDisabled={isShopify}
                  options={SEASON_KEYWORDS}
                  name="keywords_season"
                  placeholder="Season Keywords"
                  value={form.keywords_season}
                  onChange={(val) => handleMultiSelectValue('keywords_season', val)}
                  isMulti
                />
              </UiFormControl>
            </Grid>
          )}
          {hasFeature('marketplaceFields') && (
            <>
              <UiFormLabel>Other Options</UiFormLabel>
              <Grid
                gridTemplateColumns={'repeat(auto-fit, minmax(250px, 200px))'}
                gap={4}
              >
                <UiFormControl id="Phone Required">
                  <Flex alignItems={'center'}>
                    <UiSwitch
                      mr={2}
                      display="block"
                      name="phoneNumberRequired"
                      size="md"
                      isChecked={!!form?.phoneNumberRequired}
                      onChange={handleSwitchInput}
                    />
                    <UiFormLabel m={0}>Phone Number Required</UiFormLabel>
                  </Flex>
                </UiFormControl>
                <UiFormControl id="Variant Flexibility">
                  <Flex alignItems={'center'}>
                    <UiSwitch
                      mr={2}
                      display="block"
                      name="variantOrderFlexibility"
                      size="md"
                      isChecked={!!form?.variantOrderFlexibility}
                      onChange={handleSwitchInput}
                    />
                    <UiFormLabel m={0}>Variant Flexibility</UiFormLabel>
                  </Flex>
                </UiFormControl>
              </Grid>
            </>
          )}
        </Grid>
      </UiCard>
      {resyncModalDisclosure.isOpen && (
        <DraftResyncFieldModal
          handleShopifyUpdate={handleShopifyUpdate}
          syncField={syncField}
          {...resyncModalDisclosure}
        />
      )}
    </>
  )
}
