import { UiSidebarLink, UiSidebarLinkProps } from '@postal-io/postal-ui'
import React, { useMemo } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { AclFilter, useAcl } from '../../hooks'

export interface SidebarLinkProps extends UiSidebarLinkProps {
  to: string
  exact?: boolean
  extraPaths?: string[]
}

export const SidebarLink: React.FC<SidebarLinkProps> = ({ to, exact, extraPaths, ...rest }) => {
  const { pathname } = useLocation()
  const allPaths = useMemo(() => [to, ...(extraPaths || [])], [extraPaths, to])
  const isActive = useMemo(
    () => allPaths.some((p) => (exact ? pathname === p : pathname.startsWith(p))),
    [allPaths, exact, pathname]
  )

  return (
    <UiSidebarLink
      as={NavLink}
      to={to}
      isActive={isActive}
      {...rest}
    />
  )
}

export interface SidebarDataLink extends AclFilter {
  to: string
  extraPaths?: string[]
  title: string
  exact?: boolean
  children?: SidebarDataLink[]
  isHidden?: boolean
}

export interface SidebarDataLinksProps {
  links: SidebarDataLink[]
}

export const SidebarDataLinks: React.FC<SidebarDataLinksProps> = ({ links }) => {
  const { aclFilter } = useAcl()
  const filteredLinks = useMemo(() => {
    return aclFilter(links)
      .filter((link) => !link.isHidden)
      .map((link) => ({ ...link, children: aclFilter(link.children || []) }))
  }, [aclFilter, links]) as SidebarDataLink[]

  return (
    <>
      {filteredLinks.map((link, idx) => {
        return (
          <SidebarLink
            key={`link-${idx}`}
            as={NavLink}
            to={link.to}
            extraPaths={link.extraPaths}
            exact={link.exact}
            subLinks={
              <>
                {link.children?.map((child, idxx) => (
                  <SidebarLink
                    key={`link-${idx}-${idxx}`}
                    as={NavLink}
                    to={child.to}
                    extraPaths={child.extraPaths}
                    exact={child.exact}
                  >
                    {child.title}
                  </SidebarLink>
                ))}
              </>
            }
          >
            {link.title}
          </SidebarLink>
        )
      })}
    </>
  )
}
