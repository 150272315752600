import { Heading, useDisclosure } from '@chakra-ui/react'
import { useGraphqlInfiniteQuery, useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  UiButton,
  UiCard,
  UiLoading,
  UiMenu,
  UiMenuButton,
  UiMenuItemOption,
  UiMenuList,
  UiModal,
  UiModalBody,
  UiModalCloseButton,
  UiModalContent,
  UiModalFooter,
  UiModalFooterButtons,
  UiModalHeader,
  UiModalOverlay,
  UiSSDataTable,
  UiSubNavbar,
  UiText,
  useAlertError,
  useAlerts,
  useGraphqlFilter,
} from '@postal-io/postal-ui'
import { DraftStatus, SearchDraftMarketplaceProductDocument, SearchDraftMarketplaceProductQueryVariables } from 'api'
import { DraftMarketplaceProductFragment } from 'api/index'
import { Layout } from 'components/Common/Layout'
import { useAcl } from 'hooks/useAcl'
import { StorageKeys } from 'lib'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDebounce } from 'use-debounce'
import { useCurrentPage } from '../../hooks/useCurrentPage'
import { COLUMNS, FILTER_OPTIONS } from './draftProductDataV2'
import { DraftProductsBulkActionsCard } from './DraftProductsBulkActionsCard'
import { DraftProductsEmpty } from './DraftProductsEmpty'
import { DraftProductsFilterV2 } from './DraftProductsFilterV2'
import { DraftProductsImportModal } from './DraftProductsImportModal'
import { DraftProductsPageSendForApprovalAlert } from './DraftProductsPageAlerts'

export const DraftProductsPage = () => {
  const Alert = useAlerts()
  const navigate = useNavigate()
  const { hasFeature, hasPermission } = useAcl()
  // V1 drawer for creating a product
  const createDraftDisclosure = useDisclosure()
  // confirm before sending for approval
  const sendForApprovalDisclosure = useDisclosure()

  const { state: useSavedPage } = useLocation()
  const [currentPage, setCurrentPage] = useCurrentPage(
    StorageKeys.DraftMarketplaceProductCurrentPage,
    useSavedPage as boolean
  )

  const uploadProduct = useDisclosure()

  // bulk select product drafts
  const [selectedDrafts, setSelectedDrafts] = useState<DraftMarketplaceProductFragment[]>([])
  // drafts that were just adjusted and now ready for approval
  const [readyDrafts, setReadyDrafts] = useState<DraftMarketplaceProductFragment[]>([])

  const hasManualUpload = hasFeature('productManual')
  const hasIntegrationImport = hasFeature('productIntegrationImport')
  // const hasProductBulkActions = hasFeature('productBulkActions')

  const graphqlFilter = useGraphqlFilter<SearchDraftMarketplaceProductQueryVariables>(FILTER_OPTIONS)

  // debounce to accomodate typing
  const [debouncedVariables] = useDebounce(graphqlFilter.variables, 400)

  const isManager = hasPermission('integrations.read')

  const searchDrafts = useGraphqlInfiniteQuery(SearchDraftMarketplaceProductDocument, debouncedVariables, {
    keepPreviousData: true,
    enabled: !!isManager,
  })
  useAlertError(searchDrafts.error)
  const drafts = useMemo(
    () => searchDrafts.mergedData?.searchDraftMarketplaceProduct ?? [],
    [searchDrafts.mergedData?.searchDraftMarketplaceProduct]
  )

  // to avoid redirecting to DraftProductsEmpty component when regular search filter returns 0
  const totalDrafts =
    useGraphqlQuery(SearchDraftMarketplaceProductDocument)?.data?.searchDraftMarketplaceProduct?.length ?? 0

  useEffect(() => {
    graphqlFilter.transform()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphqlFilter.variables.orderBy])

  const handleRowClick = ({ id }: DraftMarketplaceProductFragment) => navigate(`/products/${id}`)

  // after certain bulk select actions are completed, set the "ready" drafts to those that now have status DRAFT
  const handleBulkActionSuccess = async () => {
    setSelectedDrafts([])
  }

  useEffect(() => {
    setReadyDrafts(drafts.filter((product) => product.status === DraftStatus.Draft))
  }, [drafts, selectedDrafts])

  const readyDraftsText = `${readyDrafts.length} product${readyDrafts.length > 1 ? 's' : ''}`

  const handleCreate = () => {
    if (hasManualUpload) navigate('/products/edit')
    else createDraftDisclosure.onOpen()
  }

  // will be used when bulk actions are usable
  const handleSendForApproval = (e: React.FormEvent) => {
    e.preventDefault()
    sendForApprovalDisclosure.onClose()
    Alert.success(`${readyDraftsText} have been sent for approval.`)
    setReadyDrafts([])
  }

  const isEmpty = useMemo(
    () => totalDrafts === 0 && !!(hasManualUpload || hasIntegrationImport),
    [hasIntegrationImport, hasManualUpload, totalDrafts]
  )

  return searchDrafts.isLoading ? (
    <UiLoading />
  ) : isEmpty ? (
    <DraftProductsEmpty />
  ) : (
    <>
      <DraftProductsPageSendForApprovalAlert
        readyDrafts={readyDrafts}
        onClick={sendForApprovalDisclosure.onOpen}
        onClose={() => setReadyDrafts([])}
      />
      <UiSubNavbar
        left={<Heading fontSize="2xl">Products</Heading>}
        right={
          <UiMenu>
            <UiMenuButton
              borderRadius="4px"
              border="none"
              colorScheme="turquoise"
              color="primary.900"
              fontWeight="bold"
              letterSpacing="0.5px"
            >
              ADD PRODUCTS
            </UiMenuButton>
            <UiMenuList
              borderRadius={0}
              fontSize="md"
            >
              <UiMenuItemOption onClick={handleCreate}>Add Manually</UiMenuItemOption>
              <UiMenuItemOption onClick={uploadProduct.onOpen}>Upload from file</UiMenuItemOption>
            </UiMenuList>
          </UiMenu>
        }
        boxShadow="none"
        border="none"
        mb={0}
        maxW="1800px"
        gridProps={{ p: 4 }}
      />

      <Layout pt={0}>
        <UiCard
          p={0}
          boxShadow="none"
          paddingBottom={2}
        >
          <DraftProductsFilterV2
            filter={graphqlFilter.filter}
            setFilter={graphqlFilter.setFilter}
          />
          <DraftProductsBulkActionsCard
            selectedDrafts={selectedDrafts}
            drafts={drafts}
            onSuccess={handleBulkActionSuccess}
            onClose={() => setSelectedDrafts([])}
          />
          <UiSSDataTable
            variant="list"
            isLoading={searchDrafts.isFetching}
            columns={COLUMNS}
            rows={drafts}
            rowKey="id"
            onClick={handleRowClick}
            fetchMore={searchDrafts.fetchNextPage}
            hasMore={searchDrafts.hasNextPage}
            orderBy={graphqlFilter.orderBy}
            onOrderBy={graphqlFilter.setOrderBy}
            filter={graphqlFilter.variables.filter}
            pageSize={10}
            onSelect={setSelectedDrafts}
            showSelect
            page={currentPage}
            setPage={setCurrentPage}
            pagerOptions={{ showFirstPage: true, hotkeysEnabled: true }}
          />
        </UiCard>
      </Layout>
      <UiModal
        size="xl"
        {...sendForApprovalDisclosure}
      >
        <UiModalOverlay />
        <UiModalContent>
          <form onSubmit={handleSendForApproval}>
            <UiModalHeader>Send for Approval - {readyDraftsText}</UiModalHeader>
            <UiModalCloseButton />
            <UiModalBody p={16}>
              <UiText textAlign="center">You are about to send {readyDraftsText}</UiText>
            </UiModalBody>
            <UiModalFooter>
              <UiModalFooterButtons>
                <UiButton
                  type="submit"
                  color="primary.900"
                  colorScheme="turquoise"
                  size="lg"
                >
                  Send For Approval
                </UiButton>
              </UiModalFooterButtons>
            </UiModalFooter>
          </form>
        </UiModalContent>
      </UiModal>
      {uploadProduct.isOpen && <DraftProductsImportModal {...uploadProduct} />}
    </>
  )
}
