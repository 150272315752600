import { Image } from '@chakra-ui/react'
import { UiCard, UiCardHeader, UiCardProps } from '@postal-io/postal-ui'
import { assetUrlToSrc } from 'api/rest'
import React from 'react'

interface OrderPreviewProps extends UiCardProps {
  previewImage: string
  accountId?: string
}

export const OrderPreview: React.FC<OrderPreviewProps> = ({ previewImage, accountId, ...rest }) => {
  return (
    <UiCard
      boxShadow={'none'}
      {...rest}
    >
      <UiCardHeader>Preview Image</UiCardHeader>
      <Image
        src={
          assetUrlToSrc({
            assetUrl: previewImage,
            accountId: accountId || null,
          }) as string
        }
      />
    </UiCard>
  )
}
