import { UiButton, UiCard, UiCardHeader, UiDropzone, UiText, useUiAlerts } from '@postal-io/postal-ui'
import api from 'api/rest'
import React, { useState } from 'react'
import { ResponsiveDrawer } from '../Main/Responsive'

interface OrderImportProps {
  isOpen: boolean
  onClose: () => void
  onSuccess: () => void
}

export const OrderImports: React.FC<OrderImportProps> = ({ isOpen, onClose, onSuccess }) => {
  const Alert = useUiAlerts()
  const [isLoading, setIsLoading] = useState(false)

  const onDrop = async (files: any) => {
    if (files.length < 1) return Alert.warning('Please drop only supported file types')
    setIsLoading(true)
    try {
      await api.importOrders(files)
      onSuccess()
      Alert.success('Orders imported')
      onClose()
      onSuccess()
    } catch (err) {
      Alert.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const ImportFile = () => {
    return (
      <UiCard
        mb={{ base: 2, md: 4 }}
        borderRadius="0px"
        boxShadow="none"
      >
        <UiCardHeader textAlign="center">Import from a file</UiCardHeader>
        <UiText mb={4}>
          Only the shipping column can be updated at this time. Only .xlsx files are supported and column name must be
          identical to previously exported data.
        </UiText>
        <UiDropzone
          onDrop={onDrop}
          isLoading={isLoading}
          accept={{
            'text/csv': ['.csv'],
            'application/vnd.ms-excel': ['.xlsx'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
          }}
          data-testid="dropZone"
        >
          <p>Drag files here or click to select</p>
          <em>(Only .xlsx files are supported)</em>
          <UiButton mt={4}>Upload Files</UiButton>
        </UiDropzone>
      </UiCard>
    )
  }

  return (
    <>
      <ResponsiveDrawer
        size="lg"
        header="Bulk Import Orders"
        isOpen={isOpen}
        onClose={onClose}
        buttons={
          <>
            <UiButton
              variant="outline"
              onClick={onClose}
            >
              Cancel
            </UiButton>
          </>
        }
      >
        <UiText
          mb={4}
          mt={2}
          textAlign="center"
          fontStyle="italic"
        >
          Tip: Hit escape to easily close this drawer
        </UiText>
        <ImportFile />
      </ResponsiveDrawer>
    </>
  )
}
