import { BoxProps, Flex, FlexProps, SimpleGrid, Text } from '@chakra-ui/react'
import {
  UiButton,
  UiDrawer,
  UiDrawerBody,
  UiDrawerCloseButton,
  UiDrawerContent,
  UiDrawerFooter,
  UiDrawerFooterButtons,
  UiDrawerHeader,
  UiDrawerOverlay,
  UiDrawerProps,
  UiLink,
} from '@postal-io/postal-ui'
import React, { ReactElement } from 'react'

export const ResponsiveContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDir="column"
      w="100%"
      maxW="1400px"
      mx="auto"
      h="100%"
      mt={{ base: 4, lg: 8 }}
      px={{ base: 4, lg: 8 }}
      mb={{ base: '150px', lg: 8 }}
    >
      {children}
    </Flex>
  )
}

export const ResponsiveTwoColumn: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <SimpleGrid
      columns={{ base: 1, lg: 2 }}
      mt={{ base: 8, lg: 16 }}
      w="100%"
      maxW={{ base: '600px', lg: '1200px' }}
      justifyContent="center"
      spacing={16}
    >
      {children}
    </SimpleGrid>
  )
}

interface ResponsiveSubmitProps extends FlexProps {
  buttonText?: string
  onConfirm: (e: React.MouseEvent<any, MouseEvent>) => void
  onReject?: (e: React.MouseEvent<any, MouseEvent>) => void
}
export const ResponsiveSubmit: React.FC<ResponsiveSubmitProps> = ({ onConfirm, onReject, buttonText, ...rest }) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDir="column"
      position={{ base: 'fixed', lg: 'inherit' }}
      bottom={{ base: 0, lg: undefined }}
      left={{ base: 0, lg: undefined }}
      right={{ base: 0, lg: undefined }}
      bg={{ base: 'white', lg: 'inherit' }}
      p={{ base: 4, lg: 0 }}
      textAlign="center"
      {...rest}
    >
      <UiButton
        colorScheme="tertiary"
        size="md"
        fontSize="md"
        onClick={onConfirm}
        w="100%"
        py={{ base: 2, lg: 6 }}
        maxW={{ base: '400px', lg: 'inherit' }}
      >
        {buttonText || 'Confirm'}
      </UiButton>
      {onReject && (
        <UiLink
          onClick={onReject}
          mt={{ base: 2, lg: 8 }}
          display="block"
          fontSize={{ base: 'sm', lg: 'lg' }}
        >
          Kindly Decline Gift
        </UiLink>
      )}
      <Text
        fontSize={{ base: '10px', lg: 'sm' }}
        mt={{ base: 1, lg: onReject ? 8 : 2 }}
      >
        Your data will not be shared. See our{' '}
        <UiLink
          href="https://postal.io/privacy-policy/"
          isExternal
          fontSize={{ base: '10px', lg: 'sm' }}
        >
          privacy policy
        </UiLink>
        .
      </Text>
    </Flex>
  )
}

interface ResponsiveDrawerProps extends UiDrawerProps {
  header: string
  buttons?: ReactElement
  bodyProps?: BoxProps
  handleSubmit?: (data?: any) => void
}

export const ResponsiveDrawer: React.FC<ResponsiveDrawerProps> = ({
  header,
  buttons,
  bodyProps,
  children,
  handleSubmit,
  ...rest
}) => {
  return (
    <UiDrawer
      size="xl"
      closeOnEsc
      {...rest}
    >
      <UiDrawerOverlay />
      <UiDrawerContent>
        <form onSubmit={handleSubmit}>
          <UiDrawerCloseButton />
          <UiDrawerHeader>{header}</UiDrawerHeader>
          <UiDrawerBody {...bodyProps}>
            {children}
            {buttons && (
              <SimpleGrid
                columns={2}
                w="100%"
                spacing={4}
                mt={8}
                mb={16}
                display={{ base: 'grid', lg: 'none' }}
              >
                {buttons}
              </SimpleGrid>
            )}
          </UiDrawerBody>
          {buttons && (
            <UiDrawerFooter
              display={{ base: 'none', lg: 'flex' }}
              pos="absolute"
              bottom="0"
              w="100%"
              justifyContent="center"
            >
              <UiDrawerFooterButtons>{buttons}</UiDrawerFooterButtons>
            </UiDrawerFooter>
          )}
        </form>
      </UiDrawerContent>
    </UiDrawer>
  )
}
