import { useSession } from 'hooks'
import { compact } from 'lodash'
import { useCallback } from 'react'
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect'

export enum AnalyticsEvent {}

export const sendAnalyticsEvent = (event: AnalyticsEvent, payload?: Record<string, any>) => {
  window.analytics?.track(event, payload || {})
}

export interface UseAnalyticsEventProps {
  event: AnalyticsEvent
  data?: Record<string, any>
  disabled?: boolean
}
export const useAnalyticsEvent = ({ event, data, disabled }: UseAnalyticsEventProps) => {
  const { session } = useSession()
  useDeepCompareEffectNoCheck(() => {
    const payload = {
      email: session.userName,
      firstName: session.firstName,
      full_name: compact([session.firstName, session.lastName]).join(' '),
      groupId: session.partnerId,
      lastName: session.lastName,
      partnerId: session.partnerId,
      partnerName: session.partnerName,
      plan: session.plan,
      product: session.product,
      roles: session.roles,
      ...(data || {}),
    }
    if (!disabled) sendAnalyticsEvent(event, payload)
  }, [event, data, session, disabled])
}

export const useAnalyticsSend = () => {
  const { session } = useSession()
  const send = useCallback(
    ({ event, data }: Omit<UseAnalyticsEventProps, 'disabled'>) => {
      const payload = {
        email: session.userName,
        firstName: session.firstName,
        full_name: compact([session.firstName, session.lastName]).join(' '),
        groupId: session.partnerId,
        lastName: session.lastName,
        partnerId: session.partnerId,
        partnerName: session.partnerName,
        plan: session.plan,
        product: session.product,
        roles: session.roles,
        ...(data || {}),
      }
      sendAnalyticsEvent(event, payload)
    },
    [
      session.firstName,
      session.lastName,
      session.partnerId,
      session.partnerName,
      session.plan,
      session.product,
      session.roles,
      session.userName,
    ]
  )
  return send
}
