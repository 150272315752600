import { localStorage, UiLoading } from '@postal-io/postal-ui'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAcl } from '../../hooks'
import { StorageKeys } from '../../lib'

export const Home: React.FC = () => {
  const navigate = useNavigate()
  const { isLoading: aclLoading } = useAcl()

  useEffect(() => {
    if (aclLoading) return

    // see if we have a saved redirect path
    const redirectPath = localStorage.getItem(StorageKeys.AppRedirectPath)
    localStorage.removeItem(StorageKeys.AppRedirectPath)

    if (redirectPath) {
      navigate(redirectPath)
    } else {
      navigate('/orders')
    }
  }, [aclLoading, navigate])

  return <UiLoading />
}
