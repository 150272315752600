import { GraphqlBackgroundTaskInvalidations } from '@postal-io/postal-graphql'
import { BackgroundTask, BackgroundTaskStatus } from 'api'
import isEmpty from 'lodash/isEmpty'
import React, { createContext, useCallback, useContext, useState } from 'react'
import { useQueryClient } from 'react-query'

export const useBackgroundTaskInvalidation = () => {
  const queryClient = useQueryClient()
  return useCallback(
    (action: string) => {
      const operations = GraphqlBackgroundTaskInvalidations.get(action)
      if (operations) operations.forEach((op) => queryClient.invalidateQueries(op))
    },
    [queryClient]
  )
}

export interface QueuedBackgroundTask {
  task: BackgroundTask
  callback?: (task: BackgroundTask) => void
  triggers?: BackgroundTaskStatus[]
}

const useBackgroundQueueContext = () => {
  const queryClient = useQueryClient()
  const [tasks, setTasks] = useState<QueuedBackgroundTask[]>([])
  const queue = useCallback(
    (
      task?: QueuedBackgroundTask['task'] | null,
      callback?: QueuedBackgroundTask['callback'],
      triggers?: QueuedBackgroundTask['triggers']
    ) => {
      task && !isEmpty(task) && setTasks([...tasks, { task, callback, triggers }])
    },
    [tasks]
  )

  const remove = useCallback(
    (task: BackgroundTask) => {
      setTasks(tasks.filter((t) => t.task.id !== task.id))
    },
    [tasks]
  )

  const invalidate = useCallback(
    (queries: string | string[], delay = 500) => {
      const operations = [queries].flat()
      setTimeout(() => {
        operations.forEach((op) => queryClient.invalidateQueries(op))
      }, delay)
    },
    [queryClient]
  )

  return { tasks, queue, remove, invalidate }
}

type BackgroundQueueContext = ReturnType<typeof useBackgroundQueueContext>

const BackgroundContext = createContext<BackgroundQueueContext | null>(null)

export const BackgroundProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const value = useBackgroundQueueContext()
  return <BackgroundContext.Provider value={value}>{children}</BackgroundContext.Provider>
}
export const useBackgroundQueue = () => {
  const context = useContext(BackgroundContext) as BackgroundQueueContext
  return context
}
