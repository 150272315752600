import {
  addGraphqlBackgroundTaskInvalidations,
  addGraphqlMutationInvalidations,
  addGraphqlQueryStaleTimes,
  handleUseErrorBoundary,
  setGraphqlEndpoint,
  setGraphqlFetchMiddleware,
} from '@postal-io/postal-graphql'
import { MutationCache, QueryCache, QueryClient } from 'react-query'
import { getAuthToken } from './rest'

const queryCache = new QueryCache()
const mutationCache = new MutationCache()
export const queryClient = new QueryClient({
  queryCache,
  mutationCache,
  defaultOptions: {
    queries: {
      retry: 1,
      retryDelay: 500,
      notifyOnChangeProps: 'tracked',
      staleTime: 10 * 1000,
      useErrorBoundary: handleUseErrorBoundary,
    },
  },
})

/*
  The default staleTime is set above.  We can override it on certain operations
  that we run often but change rarely.  With the proper invalidations below it should
  not cause any issues
*/
addGraphqlQueryStaleTimes({
  me: 60 * 1000,
  modules: 60 * 1000,
  searchVendorPayables: 60 * 1000,
})

export const GRAPHQL_ENDPOINT = '/api/vendor/graphql'

export const authMiddleware = async (headers: Headers) => {
  const token = await getAuthToken()
  headers.set('authorization', `Bearer ${token}`)
  return headers
}
/*
  Configure useGraphql
*/
setGraphqlEndpoint(GRAPHQL_ENDPOINT)
setGraphqlFetchMiddleware(authMiddleware)

/*
  Set Mutation to Query invalidations

  This is done manually because its too inaccurate to fully understand the relationship
  between the data sets.

  The first argument is the mutation name, the second argument is one or more queries
  that should be invalidated.
 */
addGraphqlMutationInvalidations({
  updatePostalFulfillmentStatus: ['getPostalFulfillment', 'searchPostalFulfillments'],
  bulkDeleteDraftMarketplaceProducts: ['searchDraftMarketplaceProduct'],
  bulkUpdatePostalFulfillmentStatus: ['searchPostalFulfillments'],
  importOrders: ['searchPostalFulfillments'],
  updatePostalFulfillmentTrackingNumber: ['getPostalFulfillment', 'searchPostalFulfillments'],
  generateOrderReport: ['searchReportOutputInstances'],
  changePassword: ['me'],
  updateSelf: ['me'],
  updateFulfillmentPartner: ['getFulfillmentPartner'],
  connectShopify: ['searchIntegrationSync', 'searchDraftMarketplaceProduct'],
  syncShopify: ['searchDraftMarketplaceProduct'],
  createDraftMarketplaceProduct: ['searchDraftMarketplaceProduct'],
  updateDraftMarketplaceProduct: ['getDraftMarketplaceProduct', 'searchDraftMarketplaceProduct'],
  deleteDraftMarketplaceProduct: ['searchDraftMarketplaceProduct'],
  requestPublicListing: ['getDraftMarketplaceProduct', 'searchDraftMarketplaceProduct'],
  requestPrivateListing: ['getDraftMarketplaceProduct', 'searchDraftMarketplaceProduct'],
  addDraftMarketplaceProductVariant: ['getDraftMarketplaceProduct'],
  updateDraftMarketplaceProductVariant: ['getDraftMarketplaceProduct'],
  removeDraftMarketplaceProductVariant: ['getDraftMarketplaceProduct'],
  createStoreInstance: ['searchStoreInstance'],
  deleteStoreInstance: ['searchStoreInstance', 'getStoreInstance'],
  updateStoreInstance: ['searchStoreInstance', 'getStoreInstance'],
  markOrderShipped: ['getPostalFulfillment', 'searchPostalFulfillments'],
  markOrderDelivered: ['getPostalFulfillment', 'searchPostalFulfillments'],
  markOrderCancelled: ['getPostalFulfillment', 'searchPostalFulfillments'],
  markOrderPlaced: ['getPostalFulfillment', 'searchPostalFulfillments'],
  markOrderConfirmed: ['getPostalFulfillment', 'searchPostalFulfillments'],
})

/*
  Set Background Query Mutation to Query invalidations
 */
addGraphqlBackgroundTaskInvalidations({
  generateOrderReport: ['searchReportOutputInstances'],
  bulkUpdateDraftMarketplaceProducts: ['searchDraftMarketplaceProduct'],
  selectShopifyCollection: ['searchDraftMarketplaceProduct'],
})
