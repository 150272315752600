import { useGraphqlMutation } from '@postal-io/postal-graphql'
import {
  UiButton,
  UiDrawer,
  UiDrawerBody,
  UiDrawerCloseButton,
  UiDrawerContent,
  UiDrawerFooter,
  UiDrawerFooterButtons,
  UiDrawerHeader,
  UiDrawerOverlay,
  useUiAlerts,
  validatePassword,
} from '@postal-io/postal-ui'
import { ChangePasswordDocument, UpdateSelfDocument, UserAccountFragment } from 'api'
import React, { FormEvent } from 'react'
import { useImmer } from 'use-immer'
import { PasswordState, ProfileEditPassword } from './ProfileEditPassword'

interface ProfileUpdatePasswordProps {
  user: UserAccountFragment
  isOpen: boolean
  onClose: () => void
  onEdit?: () => void
}

export const ProfileUpdatePassword: React.FC<ProfileUpdatePasswordProps> = ({
  user,
  isOpen,
  onClose,
  onEdit = () => {},
}) => {
  const [passwordForm, setPasswordForm] = useImmer<PasswordState>({
    currentPassword: '',
    newPassword1: '',
    newPassword2: '',
  })

  const isPasswordValid = validatePassword({
    password: passwordForm.newPassword1,
    password2: passwordForm.newPassword2,
    emailAddress: user.emailAddress,
  })

  const hasPasswordAuth = user.authTypes?.includes('password')

  const updateSelf = useGraphqlMutation(UpdateSelfDocument)
  const updatePassword = useGraphqlMutation(ChangePasswordDocument)

  const Alert = useUiAlerts()

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()

    try {
      await updatePassword.mutateAsync({ data: passwordForm })
      Alert.success('Password updated')

      onEdit()
      onClose()
    } catch (err) {
      if (err.message.includes('UsernamePasswordException')) {
        Alert.error('Your current password was entered incorrectly.')
      } else if (err.message.includes('BadPasswordException')) {
        Alert.error('Please enter a new password that has not been used before.')
      } else {
        Alert.error(err)
      }
    }
  }

  return (
    <UiDrawer
      size="lg"
      isOpen={isOpen}
      onClose={onClose}
    >
      <UiDrawerOverlay />
      <form
        onSubmit={onSubmit}
        autoComplete="off"
      >
        <UiDrawerContent>
          <UiDrawerCloseButton />
          <UiDrawerHeader>Update Your Profile</UiDrawerHeader>
          <UiDrawerBody>
            <ProfileEditPassword
              form={passwordForm}
              setForm={setPasswordForm}
              emailAddress={user.emailAddress}
              requireCurrentPassword={hasPasswordAuth}
              mt={6}
            />
          </UiDrawerBody>

          <UiDrawerFooter>
            <UiDrawerFooterButtons>
              <UiButton
                variant="outline"
                onClick={onClose}
              >
                Cancel
              </UiButton>
              <UiButton
                type="submit"
                isDisabled={updateSelf.isLoading || updatePassword.isLoading || !isPasswordValid}
                isLoading={updateSelf.isLoading || updatePassword.isLoading}
              >
                Update Password
              </UiButton>
            </UiDrawerFooterButtons>
          </UiDrawerFooter>
        </UiDrawerContent>
      </form>
    </UiDrawer>
  )
}
