import { Flex, FlexProps } from '@chakra-ui/react'

export const CellWrapper: React.FC<FlexProps> = ({ children, ...rest }) => {
  return (
    <Flex
      minH="40px"
      align="center"
      {...rest}
    >
      {children}
    </Flex>
  )
}
