import { DeleteIcon, DragHandleIcon } from '@chakra-ui/icons'
import { Flex, Grid, Image, Text, useDisclosure } from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import {
  arrayMoveImmutable,
  UiConfirm,
  UiDropzone,
  UiIconButton,
  UiSortableDragHandle,
  UiSortableItem,
  UiSortableList,
  UiTooltip,
  useAlerts,
} from '@postal-io/postal-ui'
import { AssetType, ImageReference, SaveAssetDocument } from 'api'
import { assetUrlToSrc, uploadAsset } from 'api/rest'
import { useSession } from 'hooks'
import { isNumber } from 'lodash'
import React, { useState } from 'react'
import { DraftCardProps, DraftFormProps, DraftVariantFormProps } from '.'

export const DraftImagesCard: React.FC<DraftCardProps<DraftVariantFormProps | DraftFormProps>> = ({
  form,
  setForm,
  isShopify,
}) => {
  const Alert = useAlerts()
  const { session } = useSession()

  const [isUploading, setIsUploading] = useState(false)
  const saveAsset = useGraphqlMutation(SaveAssetDocument)

  const [imageUrlRemoveIndex, setImageUrlRemoveIndex] = useState<number>()
  const removeImageUrl = useDisclosure()
  const confirmRemoveImageUrl = (idx: number) => {
    setImageUrlRemoveIndex(idx)
    removeImageUrl.onOpen()
  }
  const handleRemoveImageUrl = () => {
    if (!isNumber(imageUrlRemoveIndex)) return
    setForm((draft: any) => {
      draft.imageUrls = draft.imageUrls || []
      draft.imageUrls.splice(imageUrlRemoveIndex, 1)
    })
    removeImageUrl.onClose()
  }

  const onDrop = async (files: any) => {
    const [{ name }] = files
    setIsUploading(true)
    try {
      const res = (await uploadAsset(files)) as any

      const { requestId } = res.find(Boolean)

      const created = await saveAsset.mutateAsync({ assetType: AssetType.Image, name, requestId })
      const newAssetId = created?.saveAsset?.id
      if (newAssetId) {
        setForm((draft: any) => {
          draft.imageUrls = draft.imageUrls || []
          draft.imageUrls.push({ url: `asset://PMA${newAssetId}` })
        })
      }
      setIsUploading(false)
    } catch (err) {
      console.error(err)
      Alert.warning(err)
      setIsUploading(false)
    }
  }

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    setForm((draft: any) => {
      draft.imageUrls = draft.imageUrls || []
      draft.imageUrls = arrayMoveImmutable(draft.imageUrls, oldIndex, newIndex)
    })
  }

  return (
    <>
      <UiSortableList
        items={form.imageUrls?.map((imageUrl) => imageUrl.url) ?? []}
        onSortEnd={onSortEnd}
        restrict="p"
      >
        <Grid
          templateColumns="repeat(auto-fit, minmax(150px, 1fr))"
          gridGap={2}
          w="100%"
        >
          {form?.imageUrls?.map((imageUrl: ImageReference, idx: number) => {
            return (
              <UiSortableItem
                key={imageUrl.url}
                id={imageUrl.url}
                useDragHandle
              >
                <Flex
                  p={2}
                  h="100%"
                  display="flex"
                  flexDirection="column"
                  borderRadius={'lg'}
                  border="2px"
                  borderColor={'gray.200'}
                >
                  {form.overrideImageUrls ? (
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      w="100%"
                    >
                      <UiSortableDragHandle id={imageUrl.url}>
                        <UiTooltip
                          label="Sort image"
                          aria-label="Sort image"
                          hasArrow
                          placement="top"
                        >
                          <DragHandleIcon
                            cursor="grab"
                            boxSize="20px"
                          />
                        </UiTooltip>
                      </UiSortableDragHandle>

                      <UiTooltip
                        label="Delete image"
                        aria-label="Delete image"
                        hasArrow
                        placement="top"
                        zIndex={9999}
                        closeOnClick
                      >
                        <UiIconButton
                          aria-label="delete image button"
                          icon={<DeleteIcon />}
                          size="xs"
                          fontSize="md"
                          variant="ghost"
                          onClick={() => confirmRemoveImageUrl(idx)}
                        />
                      </UiTooltip>
                    </Flex>
                  ) : null}
                  <Image
                    w="100%"
                    opacity={!form.overrideImageUrls ? 0.5 : 1}
                    objectFit={'cover'}
                    my="auto"
                    // my={2}
                    src={
                      assetUrlToSrc({
                        assetUrl: imageUrl.url,
                        accountId: session.accountId,
                      }) as string
                    }
                    alt=""
                  />
                </Flex>
              </UiSortableItem>
            )
          })}
          {((isShopify && form.overrideImageUrls) || !isShopify) && (
            <DropzoneFormElement
              onDrop={onDrop}
              isUploading={isUploading}
            />
          )}
        </Grid>
      </UiSortableList>

      <UiConfirm
        title="Remove Image"
        isOpen={removeImageUrl.isOpen}
        onConfirm={handleRemoveImageUrl}
        onClose={removeImageUrl.onClose}
        buttonColor="red"
        buttonText="Remove"
      >
        <Text>
          Are you sure you want to <strong>Remove</strong> this image?
        </Text>
      </UiConfirm>
    </>
  )
}

//TODO --> move to postal-ui
export interface DropzoneFormElementProps {
  isUploading: boolean
  onDrop: (e: any) => void
}

export const DropzoneFormElement: React.FC<DropzoneFormElementProps> = ({ onDrop, isUploading }) => {
  return (
    <UiDropzone
      onDrop={onDrop}
      isLoading={isUploading}
      accept={{
        'image/jpeg': ['.jpg', '.jpeg'],
        'image/png': ['.png'],
        'image/gif': ['.gif'],
        'image/svg+xml': ['.svg'],
      }}
      multiple={false}
      borderRadius="md"
      minH="200px"
      backgroundColor="white"
      borderWidth={3}
    >
      <Text
        color="blue.500"
        textAlign="center"
        fontSize={'md'}
      >
        Add Media
      </Text>
      <Text
        color="gray.500"
        textAlign="center"
      >
        or drag files to upload
      </Text>
    </UiDropzone>
  )
}
