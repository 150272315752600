import { Grid, Text } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { UiCard, UiCardHeader, UiDangerous, UiDate, UiTime, UiTruncate } from '@postal-io/postal-ui'
import {
  ApprovedPostalFragment,
  SearchPostalFulfillmentsDocument,
  SearchPostalFulfillmentsQueryVariables,
  Status,
} from 'api'
import { Row } from 'components/Common/Row'
import { VendorStatusTag } from 'components/Main'
import { SELECT_FULFILLMENT_STATUSES } from 'components/Orders/ordersData'
import { magicEventStatusMapper } from 'lib'
import React, { useMemo } from 'react'

interface EventProfileProps {
  approvedPostal?: ApprovedPostalFragment
}

export const EventProfile: React.FC<EventProfileProps> = ({ approvedPostal, ...rest }) => {
  const { name, description, event, variants } = approvedPostal || {}
  const { label: statusLabel, colorScheme: statusColorScheme } = magicEventStatusMapper(event?.status)

  const variables: SearchPostalFulfillmentsQueryVariables = useMemo(
    () => ({
      filter: {
        approvedPostalId: { eq: approvedPostal?.id },
        status: { in: SELECT_FULFILLMENT_STATUSES },
      },
      limit: 100,
    }),
    [approvedPostal?.id]
  )

  // search postal fulfillments
  const searchFulfillments = useGraphqlQuery(SearchPostalFulfillmentsDocument, variables, {
    enabled: !!approvedPostal?.id,
  })
  const postalFulfillments = useMemo(
    () => searchFulfillments.data?.searchPostalFulfillments || [],
    [searchFulfillments.data?.searchPostalFulfillments]
  )
  const confirmedAttendees = postalFulfillments.length

  const variantName = useMemo(() => {
    return (
      variants
        ?.filter((v) => v.status === Status.Active)
        ?.map((v) => v.variantName)
        .join(', ') || '-'
    )
  }, [variants])

  return (
    <UiCard
      isLoading={!approvedPostal}
      boxShadow={'none'}
      {...rest}
    >
      <UiCardHeader>Event Details</UiCardHeader>
      <Grid
        templateColumns="repeat(auto-fit, 250px)"
        minH="250px"
        justifyContent="stretch"
        gap={5}
      >
        <Row label="Name">{name || '-'}</Row>
        <Row label="Variant Name">{variantName}</Row>

        <Row label="Event Status">
          <VendorStatusTag
            type="info"
            label={statusLabel}
            colorScheme={statusColorScheme}
          />
        </Row>

        <Row label="Event Date">
          <Text>
            <UiDate
              date={event?.eventDateTime}
              fallback="-"
            />
          </Text>
        </Row>

        <Row label="Event Time">
          <Text>
            <UiTime
              date={event?.eventDateTime}
              fallback="-"
            />
          </Text>
        </Row>
        <Row label="Last Registration">
          <UiDate
            date={event?.lastRegistrationDateTime}
            fallback="-"
          />
        </Row>
        <Row label="Meeting Link">{event?.meetingLink || '-'}</Row>
        <Row label="Confirmed Attendees">
          {confirmedAttendees}/{event?.requestedAttendeeCount || '-'} requested
        </Row>
        <Row label="Requested By Name">{event?.requestedByName || '-'}</Row>
        <Row label="Requested By Phone">{event?.requestedByPhone || '-'}</Row>
        <Row label="Requested By Email">{event?.requestedByEmail || '-'}</Row>
        <Row label="Request Message">
          {(
            <UiTruncate
              text={event?.requestedByMessage}
              length={100}
              showTooltip
            />
          ) || '-'}
        </Row>
        <Row label="Customer Account Name">{event?.accountName || '-'}</Row>
        <Row label="Description">
          <UiDangerous html={description} />
        </Row>
      </Grid>
    </UiCard>
  )
}
