import { RepeatIcon } from '@chakra-ui/icons'
import { Flex, Grid, Heading, Text, useDisclosure } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import { UiButton, UiCard, UiIconBox, UiIconExcel, UiIconShopify, useAlerts } from '@postal-io/postal-ui'
import {
  SearchIntegrationSyncDocument,
  SearchShopifyCollectionsDocument,
  SelectShopifyCollectionDocument,
  ShopifyCollection,
} from 'api'
import { IntegrationEditModal } from 'components/Integrations/IntegrationEditModal'
import { useAcl } from 'hooks'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useBackgroundQueue } from '../../hooks/useBackground'
import { DraftNoShopifyCollectionModal } from './DraftNoShopifyCollectionModal'
import { DraftProductsImportModal } from './DraftProductsImportModal'

export const DraftProductsEmpty = () => {
  const navigate = useNavigate()
  const uploadProduct = useDisclosure()

  return (
    <>
      <Flex
        direction={'column'}
        justifyContent={'space-between'}
        alignItems={'center'}
        maxW={'1880px'}
        mx="auto"
      >
        <Heading
          fontSize={'xl'}
          mt={10}
          mb={20}
          color={'gray.700'}
        >
          How would you like to add products?
        </Heading>
        <Grid
          justifyContent={'center'}
          templateColumns="repeat(auto-fit, minmax(200px, 300px))"
          gap={8}
          width={'100%'}
          height={'100%'}
        >
          <IntegrationActionCard
            buttonText={'CONNECT TO SHOPIFY'}
            icon={
              <UiIconShopify
                w="150px"
                h="75px"
              />
            }
            description="Seamlessly connect with Shopify and select which products you want to bring into Postal."
          />

          <ActionCard
            icon={
              <UiIconExcel
                w="150px"
                h="75px"
              />
            }
            description="Easily upload your products using our upload template."
            buttonText={'Upload From A File'}
            onClick={uploadProduct.onOpen}
          />

          <ActionCard
            icon={
              <UiIconBox
                w="150px"
                h="75px"
              />
            }
            description="Add products using our simple product builder."
            buttonText={'Add Manually'}
            onClick={() => navigate('/products/edit')}
          />
        </Grid>
      </Flex>
      {uploadProduct.isOpen && <DraftProductsImportModal {...uploadProduct} />}
    </>
  )
}

export interface ActionCardProps {
  buttonText: string
  description: string
  icon: JSX.Element
  onClick?: () => void
  system?: string
}

export const ActionCard: React.FC<ActionCardProps> = ({ icon, description, buttonText, onClick }) => {
  return (
    <UiCard
      maxW={'300px'}
      maxH={'400px'}
      borderRadius={'4px'}
    >
      <Flex
        justifyContent={'space-between'}
        direction={'column'}
        w={'100%'}
        h="100%"
      >
        <Flex
          direction={'column'}
          alignItems={'center'}
        >
          {icon}
          <Text
            color={'gray.400'}
            fontSize={'small'}
            fontWeight="bold"
            mt={5}
            textAlign={'center'}
          >
            {description}
          </Text>
        </Flex>
        <UiButton
          size="sm"
          colorScheme="turquoise"
          color="primary.900"
          fontSize="md"
          letterSpacing="0.5px"
          px={6}
          onClick={onClick}
        >
          {buttonText.toUpperCase()}
        </UiButton>
      </Flex>
    </UiCard>
  )
}

export const IntegrationActionCard: React.FC<ActionCardProps> = ({ icon, description, buttonText, system }) => {
  const { queue, tasks, invalidate } = useBackgroundQueue()
  const navigate = useNavigate()
  const Alert = useAlerts()
  const { hasPermission } = useAcl()

  const noShopifyCollectionDisclosure = useDisclosure()
  const editIntegrationDisclosure = useDisclosure()

  const isManager = hasPermission('integrations.create')
  const [isSyncLoading, setIsSyncLoading] = useState<boolean>(
    tasks.some((task) => task.task.taskAction === 'ProductSync')
  )

  const searchCollections = useGraphqlQuery(SearchShopifyCollectionsDocument, {
    before: '',
    after: '',
  })

  const { collectionList } = searchCollections.data?.searchShopifyCollections || ({} as ShopifyCollection)

  const syncShopifyCollection = useGraphqlMutation(SelectShopifyCollectionDocument, {
    onSuccess: (data) => {
      queue(data.selectShopifyCollection, () => {
        setIsSyncLoading(false)
        navigate('/products')
        invalidate('searchDraftMarketplaceProduct')
        Alert.success('Products imported')
      })
    },
  })

  const searchIntegrationSync = useGraphqlQuery(
    SearchIntegrationSyncDocument,
    {
      filter: { system: { eq: system } },
    },
    { enabled: isManager }
  )

  const isConnected = (searchIntegrationSync.data?.searchIntegrationSync?.length ?? 0) > 0

  const handleSync = async () => {
    try {
      if (isConnected && !!collectionList?.length) {
        navigate('/products/shopifycollection')
      } else {
        setIsSyncLoading(true)
        const res = await syncShopifyCollection.mutateAsync({ shopifyCollectionId: '' })
        if (!res.selectShopifyCollection.errors?.length)
          Alert.info('Integration synced, products will be imported shortly')
      }
    } catch (err) {
      setIsSyncLoading(false)
      Alert.error(err)
    } finally {
      if (noShopifyCollectionDisclosure.isOpen) noShopifyCollectionDisclosure.onClose()
    }
  }

  const handleSyncClick = () => {
    if (!collectionList?.length) {
      noShopifyCollectionDisclosure.onOpen()
    } else {
      handleSync()
    }
  }

  return (
    <>
      <UiCard
        maxW={'300px'}
        maxH={'400px'}
        borderRadius={'4px'}
      >
        <Flex
          alignItems={'center'}
          justifyContent={'center'}
          direction={'column'}
          width={'100%'}
        >
          {icon}
          <Text
            color={'gray.400'}
            fontSize={'small'}
            fontWeight="bold"
            mt={5}
            mb={10}
            textAlign={'center'}
          >
            {description}
          </Text>
          <Flex
            w="100%"
            flexDirection={'column'}
          >
            {isConnected ? (
              <UiButton
                variant={'outline'}
                colorScheme="turquoise"
                mt={2}
                px={6}
                fontSize="md"
                letterSpacing="0.5px"
                w="100%"
                size="sm"
                onClick={handleSyncClick}
                leftIcon={<RepeatIcon />}
                isLoading={isSyncLoading}
                isDisabled={!isManager || isSyncLoading}
              >
                Sync Now
              </UiButton>
            ) : (
              <UiButton
                size="sm"
                colorScheme="turquoise"
                color="primary.900"
                fontSize="md"
                letterSpacing="0.5px"
                px={6}
                isLoading={isSyncLoading}
                isDisabled={!isManager || isSyncLoading}
                onClick={() => editIntegrationDisclosure.onOpen()}
              >
                {buttonText.toUpperCase()}
              </UiButton>
            )}
          </Flex>
        </Flex>
      </UiCard>

      {editIntegrationDisclosure.isOpen && (
        <IntegrationEditModal
          handleNoCollection={noShopifyCollectionDisclosure.onOpen}
          {...editIntegrationDisclosure}
        />
      )}

      {noShopifyCollectionDisclosure.isOpen && (
        <DraftNoShopifyCollectionModal
          handleConfirm={handleSync}
          isSyncLoading={isSyncLoading}
          {...noShopifyCollectionDisclosure}
        />
      )}
    </>
  )
}
