import { Grid } from '@chakra-ui/react'
import {
  humanize,
  UiMenu,
  UiMenuButton,
  UiMenuDivider,
  UiMenuItemOption,
  UiMenuList,
  UiMenuOptionGroup,
} from '@postal-io/postal-ui'
import {} from 'components'
import { VENDOR_FULFILLMENT_STATUSES } from './data'
export interface VendorPayableFulfillmentsProps {
  filter: {
    id?: string
    itemName?: string
    status?: string
    stage?: string
    created?: string
  }
  setFilter: (data: { key: string; value: any }) => void
  isFetching?: boolean
}

export const VendorFulfillmentsFilter: React.FC<VendorPayableFulfillmentsProps> = ({ filter, setFilter }) => {
  const handleMultiSelect = ({ key, value }: { key: string; value?: string | string[] }) => {
    setFilter({
      key,
      value,
    })
  }
  const isStatusFull = (filter?.status?.length ?? 0) === VENDOR_FULFILLMENT_STATUSES.length

  return (
    <Grid
      templateColumns={{ base: '1fr', xl: '350px 350px min-content' }}
      gap={4}
    >
      <UiMenu closeOnSelect={false}>
        <UiMenuButton
          h="48px"
          w="200px"
          bg="white"
          _focusVisible={{ borderColor: 'blue.500' }}
        >
          {filter.status?.length ? `(${filter.status?.length}) Fulfillment Status` : 'Fulfillment Status'}
        </UiMenuButton>
        <UiMenuList
          borderRadius={0}
          fontSize="sm"
        >
          <UiMenuItemOption
            isChecked={isStatusFull}
            onClick={() =>
              handleMultiSelect({
                key: 'status',
                value: isStatusFull ? undefined : VENDOR_FULFILLMENT_STATUSES,
              })
            }
          >
            Any Status
          </UiMenuItemOption>
          <UiMenuDivider />
          <UiMenuOptionGroup
            type="checkbox"
            onChange={(value: string | string[]) => handleMultiSelect({ key: 'status', value })}
            value={filter.status?.length ? filter.status : []}
          >
            {VENDOR_FULFILLMENT_STATUSES.map((value, idx) => (
              <UiMenuItemOption
                key={idx}
                value={value}
              >
                {humanize(value)}
              </UiMenuItemOption>
            ))}
          </UiMenuOptionGroup>
        </UiMenuList>
      </UiMenu>
    </Grid>
  )
}
