import { Box, Flex, Grid, Text } from '@chakra-ui/react'
import { UiDescriptionList, UiDescriptionListItem } from '@postal-io/postal-ui'
import { AddressFragment } from 'api'
import { getAddress } from 'lib'
import compact from 'lodash/compact'
import React from 'react'
export interface ContactAddressProps {
  address?: AddressFragment
  fullMode?: boolean
}

export const ContactAddress: React.FC<ContactAddressProps> = ({ address, fullMode }) => {
  const contactAddress = getAddress(address)
  return (
    <>
      <Box p={{ base: 0, md: 0 }}>
        <Flex direction="column">
          {contactAddress?.address1 && <Text data-private>{contactAddress?.address1}</Text>}
          {contactAddress?.address2 && <Text data-private>{contactAddress?.address2}</Text>}
          {(contactAddress?.city || contactAddress?.state || contactAddress?.postalCode) && (
            <Text data-private>
              {compact([contactAddress?.city, contactAddress?.state]).join(', ')}
              {(contactAddress?.city || contactAddress?.state) && ' '}
              {contactAddress?.postalCode}
            </Text>
          )}
        </Flex>

        {fullMode && contactAddress && (
          <UiDescriptionList>
            <Grid
              mt={8}
              templateColumns="1fr 1fr 1fr"
              gridGap={2}
            >
              <UiDescriptionListItem
                title="Address 1"
                data-private
              >
                {contactAddress.address1}
              </UiDescriptionListItem>
              <UiDescriptionListItem
                title="Address 2"
                data-private
              >
                {contactAddress.address2}
              </UiDescriptionListItem>
              <UiDescriptionListItem
                title="Address 3"
                data-private
              >
                {contactAddress.address2}
              </UiDescriptionListItem>
              <UiDescriptionListItem
                title="City"
                data-private
              >
                {contactAddress.city}
              </UiDescriptionListItem>
              <UiDescriptionListItem
                title="State"
                data-private
              >
                {contactAddress.state}
              </UiDescriptionListItem>
              <UiDescriptionListItem
                title=""
                data-private
              ></UiDescriptionListItem>
              <UiDescriptionListItem
                title="Postal Code"
                data-private
              >
                {contactAddress.postalCode}
              </UiDescriptionListItem>
              <UiDescriptionListItem
                title="Plus 4"
                data-private
              >
                {contactAddress.plus4}
              </UiDescriptionListItem>
              <UiDescriptionListItem
                title="Delivery Point"
                data-private
              >
                {contactAddress.deliveryPoint}
              </UiDescriptionListItem>
            </Grid>
          </UiDescriptionList>
        )}
      </Box>
    </>
  )
}
