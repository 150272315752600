import { Box, BoxProps, Text } from '@chakra-ui/react'

interface RowProps extends BoxProps {
  label?: string
}
export const Row: React.FC<RowProps> = ({ label, children, ...rest }) => {
  return (
    <Box {...rest}>
      <Text
        fontWeight="bold"
        whiteSpace="nowrap"
      >
        {label}
      </Text>
      <Text>{children || '-'}</Text>
    </Box>
  )
}
