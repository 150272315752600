import { Box, Flex, Heading, Stack, Text } from '@chakra-ui/react'
import {
  UiButton,
  UiDivider,
  UiFormControl,
  UiFormLabel,
  UiInput,
  UiLink,
  UiSwitch,
  useUiAlerts,
} from '@postal-io/postal-ui'
import { SsoOption } from 'components/Session/SsoOption'
import { useSession } from 'hooks'
import React, { useState } from 'react'
import { useImmer } from 'use-immer'
import { BodyText } from './BodyText'
import { InviteInfo } from './types'

interface FormState {
  firstName: string
  lastName: string
  terms: boolean
}

interface WelcomeNewUserProps {
  inviteId: string
  inviteInfo: InviteInfo
  onComplete: (e: string) => void
}

export const WelcomeNewUser: React.FC<WelcomeNewUserProps> = ({ inviteId, inviteInfo, onComplete }) => {
  const Alert = useUiAlerts()
  const [loading, setLoading] = useState(false)
  const { inviteComplete } = useSession()

  const [form, setForm] = useImmer<FormState>({ firstName: '', lastName: '', terms: true })

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = e.target
    switch (name) {
      case 'terms':
        setForm((draft) => void (draft.terms = checked))
        break
      case 'firstName':
      case 'lastName':
        setForm((draft) => void (draft[name] = value))
    }
  }

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    const { firstName, lastName } = form
    setLoading(true)
    try {
      const { token } = await inviteComplete({ id: inviteId, firstName, lastName })
      setLoading(false)
      onComplete(token)
    } catch (err) {
      Alert.warning(err)
      setLoading(false)
    }
  }

  const { accountName, inviterFirstName, inviterLastName, teamName } = inviteInfo

  return (
    <>
      <Flex
        alignItems="center"
        flexDir="column"
        textAlign="center"
      >
        <Heading
          fontWeight={600}
          as="h1"
          size="md"
          my={12}
          textAlign="center"
          whiteSpace="nowrap"
        >
          Welcome!
        </Heading>
        <BodyText>
          You have been invited by{' '}
          <strong>
            {inviterFirstName} {inviterLastName}
          </strong>{' '}
          to join{' '}
          {teamName && (
            <>
              the <strong>{teamName}</strong> Team of{' '}
            </>
          )}
          <strong>{accountName}</strong> @ Postal.io. Please login with your SSO provider or complete the form below to
          complete registration.
        </BodyText>
      </Flex>

      <SsoOption
        maxWidth="450px"
        justifySelf="center"
        mt={12}
        params={{ inviteId }}
        isDisabled={!form.terms}
      />

      <Text
        textAlign="center"
        fontWeight="bold"
        fontSize="2xl"
        color="gray.600"
        mt={4}
      >
        or
      </Text>

      <Box
        mt={8}
        w="100%"
      >
        <form onSubmit={onSubmit}>
          <Stack spacing={4}>
            <UiFormControl
              id="firstName"
              isRequired
            >
              <UiFormLabel>First Name</UiFormLabel>
              <UiInput
                name="firstName"
                type="text"
                placeholder="First Name"
                value={form.firstName}
                onChange={handleInput}
              />
            </UiFormControl>

            <UiFormControl
              id="lastName"
              isRequired
            >
              <UiFormLabel>Last Name</UiFormLabel>
              <UiInput
                name="lastName"
                type="text"
                placeholder="Last Name"
                value={form.lastName}
                onChange={handleInput}
              />
            </UiFormControl>
          </Stack>

          <UiDivider my={8} />

          <UiFormLabel fontSize="1rem">
            <UiSwitch
              name="terms"
              size="lg"
              color="tertiary"
              mr={2}
              isChecked={form.terms}
              onChange={handleInput}
            />
            <span>Agree to </span>
            <UiLink
              href="https://postal.io/terms-of-service/"
              isExternal
            >
              Terms &amp; Conditions
            </UiLink>
            <span> / </span>
            <UiLink
              href="https://postal.io/privacy-policy/"
              isExternal
            >
              Privacy Policy
            </UiLink>
          </UiFormLabel>

          <Box textAlign="center">
            <UiButton
              type="submit"
              colorScheme="tertiary"
              mt={8}
              size="lg"
              w="100%"
              isDisabled={loading}
              isLoading={loading}
            >
              Continue
            </UiButton>
          </Box>
        </form>
      </Box>
    </>
  )
}
