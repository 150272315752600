import { ModalProps } from '@chakra-ui/react'
import {
  UiButton,
  UiModal,
  UiModalBody,
  UiModalCloseButton,
  UiModalContent,
  UiModalFooter,
  UiModalFooterButtons,
  UiModalHeader,
  UiModalOverlay,
  useAlerts,
} from '@postal-io/postal-ui'
import { useMemo } from 'react'

interface DraftResyncFieldModalProps extends Omit<ModalProps, 'children'> {
  isOpen: boolean
  onClose: () => void
  syncField: string
  handleShopifyUpdate?: (input: string) => void
}

export const DraftResyncFieldModal: React.FC<DraftResyncFieldModalProps> = ({
  isOpen,
  onClose,
  syncField,
  handleShopifyUpdate,
}) => {
  const Alert = useAlerts()

  //TODO - if successful resync, should convert overrideFields to TRUE

  const handleConfirm = async () => {
    try {
      handleShopifyUpdate && handleShopifyUpdate(syncField)
    } catch (err) {
      Alert.error(err)
    } finally {
      onClose()
    }
  }

  const syncFieldNameMap = useMemo(() => {
    switch (syncField) {
      case 'name':
        return 'TITLE'
      case 'imageUrls':
        return 'IMAGES'
      case 'variantName':
        return 'VARIANT NAME'
      default:
        return syncField.toUpperCase()
    }
  }, [syncField])

  return (
    <UiModal
      scrollBehavior="inside"
      isOpen={isOpen}
      onClose={onClose}
      size={'4xl'}
    >
      <UiModalOverlay />
      <UiModalContent>
        <UiModalCloseButton
          size="sm"
          color="gray.400"
          fontSize={'sm'}
        />
        <form>
          <UiModalHeader
            backgroundColor={'white'}
            color="gray.800"
            fontSize={'2xl'}
          >
            Reconnect field via Shopify
          </UiModalHeader>

          <UiModalBody overflow="visible">
            You are about to reconnect the <strong>{syncFieldNameMap}</strong> field to the current Shopify instance.
            This action will cause your draft product to be updated on the next Shopify sync and can no longer be edited
            in Postal.
          </UiModalBody>
          <UiModalFooter
            border={'0px'}
            mx={8}
          >
            <UiModalFooterButtons justifyContent={'space-between'}>
              {' '}
              <UiButton
                fontSize={'md'}
                size="md"
                colorScheme="turquoise"
                color="gray.700"
                maxW={'200px'}
                textTransform="none"
                onClick={handleConfirm}
              >
                Connect Field
              </UiButton>
              <UiButton
                size="sm"
                fontSize={'lg'}
                variant="link"
                onClick={onClose}
                color="gray.500"
              >
                Cancel
              </UiButton>
            </UiModalFooterButtons>
          </UiModalFooter>
        </form>
      </UiModalContent>
    </UiModal>
  )
}
