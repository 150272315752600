import { Stack } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  UiAlert,
  UiAutoCompleteSelect,
  UiCard,
  UiCardHeader,
  UiFormControl,
  UiFormLabel,
  UiInput,
  UiSelect,
} from '@postal-io/postal-ui'
import { CategoryTreeEntryOrderByInput, SearchCategoryTreeEntriesDocument } from 'api'
import { set } from 'lodash'
import { ChangeEvent, useMemo } from 'react'
import { DraftCardProps, DraftFormProps } from '.'

export const DraftProductOrganizationCard: React.FC<DraftCardProps<DraftFormProps>> = ({
  form,
  setForm,
  isLoading,
}) => {
  const categoryQuery = useGraphqlQuery(SearchCategoryTreeEntriesDocument, {
    filter: { hidden: { eq: false } },
    orderBy: CategoryTreeEntryOrderByInput.NameAsc,
    limit: -1,
  })

  const categoryMap = useMemo(() => {
    const categories = categoryQuery.data?.searchCategoryTreeEntries || []
    const hashTable = Object.create(null)
    const categoryTree = Object.create(null)

    //Initialize the hash table
    categories.forEach((entry) => (hashTable[entry.id] = { ...entry, children: [] }))

    //Set up the tree
    //Note: the hashtable will not contain a parent entry if it has been hidden
    categories.forEach((entry) => {
      if (entry.parentId === undefined || entry.parentId === null) {
        categoryTree[entry.name] = hashTable[entry.id].children
      } else if (hashTable[entry.parentId] !== undefined) {
        hashTable[entry.parentId].children.push(hashTable[entry.id].name)
      }
    })

    return categoryTree
  }, [categoryQuery.data?.searchCategoryTreeEntries])

  const handleInput = ({ target }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = target

    //reset type field if category changes
    if (name === 'category') {
      setForm((draft: any) => void (draft.type = ''))
    }
    setForm((draft: any) => void (draft[name] = value))
  }

  const handleMultiSelectValue = (name: string, value: any) => {
    setForm((draft) => {
      set(draft, name, value)
    })
  }

  const isTypeRequired = !!form.category && (categoryMap[form.category]?.length ?? 0) > 0

  return (
    <UiCard
      isLoading={isLoading}
      boxShadow="none"
    >
      <UiCardHeader>Organization</UiCardHeader>
      <Stack gap={4}>
        <UiFormControl
          id="brandName"
          isRequired
        >
          <UiFormLabel>Brand Name</UiFormLabel>
          <UiInput
            name="brandName"
            onChange={handleInput}
            value={form.brandName || ''}
          />
        </UiFormControl>
        <UiFormControl
          id="category"
          isRequired
        >
          <UiFormLabel>Category</UiFormLabel>
          <UiSelect
            name="category"
            onChange={handleInput}
            value={form.category || ''}
          >
            <option value="">Choose a category</option>
            {Object.keys(categoryMap).map((category, idx) => (
              <option
                key={`${category}-${idx}`}
                value={category}
              >
                {category}
              </option>
            ))}
          </UiSelect>
        </UiFormControl>
        {!!form.category && (
          <UiFormControl
            id="type"
            isRequired={isTypeRequired}
          >
            <UiFormLabel>Subcategory</UiFormLabel>
            <UiAutoCompleteSelect
              options={categoryMap[form.category]}
              name="type"
              placeholder="Type"
              value={form.type}
              onChange={(val) => handleMultiSelectValue('type', val)}
              isDisabled={!isTypeRequired}
            />
            {!isTypeRequired && (
              <UiAlert
                status="info"
                mt={4}
                hideClose
              >
                No types are defined for this category. Please submit for approval and your Marketplace contact will
                assist you.
              </UiAlert>
            )}
          </UiFormControl>
        )}
      </Stack>
    </UiCard>
  )
}
