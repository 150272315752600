import { Grid } from '@chakra-ui/react'
import {
  UiCard,
  UiCardHeader,
  UiCardProps,
  UiDateTime,
  UiDescriptionList,
  UiDescriptionListItem,
  UiMoney,
} from '@postal-io/postal-ui'
import { VendorStatusTag } from 'components/Main'
import { vendorPayableExternalStatusMapper } from 'lib'
import { VendorPayableFragment } from '../../api/index'

interface VendorPayableDetailsCardProps extends UiCardProps {
  vendorPayable?: VendorPayableFragment
}

export const VendorPayableDetailsCard: React.FC<VendorPayableDetailsCardProps> = ({ vendorPayable, ...rest }) => {
  return (
    <>
      <UiCard {...rest}>
        <UiCardHeader>Payable Details</UiCardHeader>
        <UiDescriptionList>
          <Grid
            templateColumns="1fr 1fr 1fr"
            gap={8}
          >
            <UiDescriptionListItem
              title="Status"
              width="50%"
              fontSize="md"
            >
              {vendorPayable?.externalStatus ? (
                <VendorStatusTag {...vendorPayableExternalStatusMapper(vendorPayable?.externalStatus)} />
              ) : (
                '-'
              )}
            </UiDescriptionListItem>

            <UiDescriptionListItem
              title="Start Date"
              fontSize="md"
            >
              <UiDateTime
                date={vendorPayable?.owedAtStart}
                format={{ dateStyle: 'medium', timeStyle: 'short' }}
                fallback="-"
              />
            </UiDescriptionListItem>

            <UiDescriptionListItem
              title="End Date"
              fontSize="md"
            >
              <UiDateTime
                date={vendorPayable?.owedAtEnd}
                format={{ dateStyle: 'medium', timeStyle: 'short' }}
                fallback="-"
              />
            </UiDescriptionListItem>

            <UiDescriptionListItem
              title="External ID"
              fontSize="md"
            >
              {vendorPayable?.externalId ?? '-'}
            </UiDescriptionListItem>

            <UiDescriptionListItem
              title="Total Fulfillments"
              fontSize="md"
            >
              {vendorPayable?.totalFulfillments ?? '-'}
            </UiDescriptionListItem>

            <UiDescriptionListItem
              title="Total Amount"
              fontSize="md"
            >
              <UiMoney
                cents={vendorPayable?.totalAmount}
                currency={vendorPayable?.currency}
                placeholder="-"
              />
            </UiDescriptionListItem>
          </Grid>
        </UiDescriptionList>
      </UiCard>
    </>
  )
}
