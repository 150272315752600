import { GraphqlFilterTransform, OrderByDirection, UiDateTime, UiLink, UiTruncate } from '@postal-io/postal-ui'
import { ApprovedPostalFragment, MagicEventStatus } from 'api'
import { VendorStatusTag } from 'components/Main'
import { magicEventStatusMapper, StorageKeys } from 'lib'
import { CellWrapper } from '../Main/Table'

export const EVENT_STATUSES = Object.values(MagicEventStatus)

export const STATIC_VARIABLES = { limit: 100 }

export const EVENT_STATUS_EXTRA_VALUES = [MagicEventStatus.Cancelled, MagicEventStatus.Complete]

export const EVENT_STATUS_DEFAULT_VALUES = Object.values(MagicEventStatus).filter(
  (status) => !EVENT_STATUS_EXTRA_VALUES.includes(status)
)

const INITIAL_STATE = {
  filter: { event_status: EVENT_STATUS_DEFAULT_VALUES },
  orderBy: { key: 'created', direction: OrderByDirection.Descending },
}

const TRANSFORMS = {
  name: GraphqlFilterTransform.Contains,
  event_eventDateTime: GraphqlFilterTransform.Between,
  event_status: GraphqlFilterTransform.In,
}

export const FILTER_OPTIONS = {
  transforms: TRANSFORMS,
  initialState: INITIAL_STATE,
  staticVariables: STATIC_VARIABLES,
  persistKey: StorageKeys.EventsFilter,
}

interface RowProps extends ApprovedPostalFragment {
  handleRowClick: () => void
  handleCopy?: (text: string) => void
  variant?: string
}

export const SEARCH_COLUMNS = [
  {
    key: 'name',
    label: 'Item Name',
    render: ({ name, handleRowClick }: RowProps) => (
      <CellWrapper>
        <UiLink onClick={handleRowClick}>
          <UiTruncate
            text={name || '-'}
            length={50}
            showTooltip
          />
        </UiLink>
      </CellWrapper>
    ),
  },
  {
    key: 'variantName',
    label: 'Variant',
    render: ({ variant }: RowProps) => (
      <CellWrapper>
        <UiTruncate
          text={variant || '-'}
          length={50}
          showTooltip
        />
      </CellWrapper>
    ),
  },
  {
    key: 'eventDate',
    label: 'Event Date',
    orderBy: 'event_eventDateTime',
    render: ({ event }: RowProps) => (
      <CellWrapper>
        <UiDateTime
          date={event?.eventDateTime}
          fallback="-"
        />
      </CellWrapper>
    ),
  },
  {
    key: 'accountName',
    label: 'Customer Account',
    render: ({ event }: RowProps) => (
      <CellWrapper>
        <UiTruncate
          text={event?.accountName || '-'}
          length={50}
        />
      </CellWrapper>
    ),
  },
  {
    label: 'Last Registration',
    render: ({ event }: RowProps) => (
      <CellWrapper>
        <UiDateTime
          date={event?.lastRegistrationDateTime}
          fallback="-"
        />
      </CellWrapper>
    ),
  },
  {
    key: 'status',
    label: 'Status',
    render: ({ event }: RowProps) => (
      <CellWrapper>
        <VendorStatusTag {...magicEventStatusMapper(event?.status)} />
      </CellWrapper>
    ),
  },
]
