import { UiSelectTypeaheadCreatable } from '@postal-io/postal-ui'
import React, { useMemo, useState } from 'react'
import { Updater } from 'use-immer'
import { DraftFormProps } from '.'

export interface DraftProductTagsProps {
  form: DraftFormProps
  setForm: Updater<DraftFormProps>
  isDisabled?: boolean
}

type TagOption = {
  name: string
  label: string
  value: string
}

export const DraftProductTags: React.FC<DraftProductTagsProps> = ({ form, setForm, isDisabled }) => {
  const [inputVal, setInputVal] = useState('')

  const normalizeTags = useMemo(() => {
    if (!!form?.tags?.length) {
      return form?.tags?.map((tag: string) => {
        return { name: tag, label: tag, value: tag }
      })
    } else {
      return []
    }
  }, [form.tags])

  const handleCreate = (value: string) => {
    setForm((draft: any) => void draft.tags.push(value))
  }

  const handleInputChange = (input: string) => {
    setInputVal(input)
  }

  const handleOnChange = (tagList: TagOption[]) => {
    setForm((draft: any) => void (draft.tags = tagList.map((t) => t.name)))
  }

  const handleKeyDown = (event: any) => {
    if (!inputVal) return
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        handleCreate(inputVal)
        setInputVal('')

        //stop form submission when hitting enter or tab
        event.preventDefault()
    }
  }

  return (
    <UiSelectTypeaheadCreatable
      onKeyDown={handleKeyDown}
      isDisabled={isDisabled}
      data-testid="VendorTagInput"
      components={{ DropdownIndicator: null }}
      placeholder="Drink, Summer, Party"
      value={normalizeTags || null}
      onChange={(t: any) => handleOnChange(t)}
      inputValue={inputVal}
      onInputChange={handleInputChange}
      isMulti
      isClearable
      menuIsOpen={false}
    />
  )
}
