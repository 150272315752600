import { Grid, InputGroup } from '@chakra-ui/react'
import { UiFormControl, UiInput, UiInputDate } from '@postal-io/postal-ui'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'

const inputProps = {
  borderRadius: 0,
  size: 'md',
  fontSize: 'sm',
  fontWeight: 'semibold',
}

export interface VendorPayablesFilterProps {
  filter: {
    id?: string
    externalId?: string
    created?: string
    owedAtStart?: string
    owedAtEnd?: string
  }
  setFilter: (data: { key: string; value: any }) => void
  onSubmit: (e: React.SyntheticEvent) => void
  isFetching?: boolean
}

export const VendorPayablesFilter: React.FC<VendorPayablesFilterProps> = ({ filter, setFilter, onSubmit }) => {
  const handleInput = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = target
    const val = isEmpty(value) ? undefined : value

    if (name === 'id' && value?.length !== 24) return

    if (name === 'owedAt') {
      const owedAtStart = value[0] as any
      const owedAtEnd = value[1]
      setFilter({ key: 'owedAtStart', value: owedAtStart })
      setFilter({ key: 'owedAtEnd', value: owedAtEnd })
    } else {
      setFilter({ key: name, value: val })
    }
  }

  const owedAt = useMemo(
    () => (!!filter.owedAtStart && !!filter.owedAtEnd ? [filter.owedAtStart, filter.owedAtEnd] : []),
    [filter.owedAtEnd, filter.owedAtStart]
  )

  return (
    <form onSubmit={onSubmit}>
      <Grid
        templateColumns={{ base: '1fr', sm: '1fr 1fr', md: '400px 400px' }}
        gap={4}
      >
        <UiFormControl id="owedAt">
          <UiInputDate
            isRange
            name="owedAt"
            value={owedAt}
            placeholder="Owed At"
            aria-label="Owed At"
            onChange={handleInput}
            {...inputProps}
          />
        </UiFormControl>

        <UiFormControl id="externalId">
          <InputGroup>
            <UiInput
              name="externalId"
              placeholder="External ID"
              defaultValue={filter.externalId}
              onChange={handleInput}
              {...inputProps}
            />
          </InputGroup>
        </UiFormControl>
      </Grid>
    </form>
  )
}
