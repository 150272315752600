import { BoxProps, Text } from '@chakra-ui/react'
import React from 'react'

export const BodyText: React.FC<BoxProps> = ({ children, ...rest }) => (
  <Text
    fontSize="md"
    fontWeight={400}
    {...rest}
  >
    {children}
  </Text>
)
