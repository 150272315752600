import { SearchIcon } from '@chakra-ui/icons'
import { Flex, Grid, GridProps, InputGroup, InputRightElement } from '@chakra-ui/react'
import {
  humanize,
  UiInput,
  UiMenu,
  UiMenuButton,
  UiMenuDivider,
  UiMenuItemOption,
  UiMenuList,
  UiMenuOptionGroup,
} from '@postal-io/postal-ui'
import { DraftStatus, Status } from 'api'
import isEmpty from 'lodash/isEmpty'
import React from 'react'
import { CATEGORIES_V2 } from './draftProductDataV2'

const SYSTEM_NAME_VALUES = ['manual', 'shopify']

const EXCLUDE_DRAFT_STATUS = [DraftStatus.Synced, DraftStatus.Active]
const EXCLUDE_MARKETPLACE_STATUS = [Status.Delete, Status.VendorDisabled, Status.OutOfStock]

const DRAFT_STATUSES = Object.entries(DraftStatus).filter(([_key, value]) => !EXCLUDE_DRAFT_STATUS.includes(value))
const MARKETPLACE_STATUSES = Object.entries(Status).filter(
  ([_key, value]) => !EXCLUDE_MARKETPLACE_STATUS.includes(value)
)
const ALL_STATUSES = [...MARKETPLACE_STATUSES, ...DRAFT_STATUSES]

const STATUS_OPTIONS = ALL_STATUSES.map(([key, value]) => ({
  label: humanize(key),
  value,
}))

export interface DraftProductsFilterV2Props extends GridProps {
  filter: any
  setFilter: (data: { key: string; value: any }) => void
}

export const DraftProductsFilterV2: React.FC<DraftProductsFilterV2Props> = ({ filter, setFilter, ...rest }) => {
  const handleInput = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = target
    const val = isEmpty(value) ? undefined : value
    setFilter({ key: name, value: val })
  }

  const handleMultiSelect = ({ key, value }: { key: string; value: string | string[] }) => {
    setFilter({
      key,
      value,
    })
  }

  const handleStatusSelect = (status: string | string[]) => {
    const statuses = [...(status || [])]
    const marketplaceStatuses = MARKETPLACE_STATUSES.map(([_key, value]) => value.valueOf()).filter((st) =>
      statuses.includes(st)
    )
    const draftStatuses = DRAFT_STATUSES.map(([_key, value]) => value.valueOf()).filter((st) => statuses.includes(st))

    // If we're filtering for marketplace statuses, we want to make sure we include Active as a Draft status so we don't miss values
    if (marketplaceStatuses.length > 0) {
      draftStatuses.push(Status.Active)
    }
    setFilter({ key: 'marketplaceStatus', value: marketplaceStatuses })
    setFilter({ key: 'status', value: draftStatuses })
  }

  const allStatuses = Array.from(
    new Set([
      ...(filter.status?.filter((st: DraftStatus) => st !== DraftStatus.Active) || []), // Filter out the Active draft status if its included so as not to show Active as selected
      ...(filter.marketplaceStatus || []),
    ])
  )

  const isSystemNameFull = filter.systemName?.length === SYSTEM_NAME_VALUES.length
  const isCategoryFull = filter.category?.length === CATEGORIES_V2.length
  const isStatusFull = allStatuses.length === ALL_STATUSES.length

  return (
    <Grid
      templateColumns="300px 1fr"
      gap={6}
      p={6}
      {...rest}
    >
      <InputGroup>
        <UiInput
          placeholder="Filter Products"
          name="name"
          borderRadius={0}
          fontSize="sm"
          fontWeight="bold"
          value={filter.name ?? ''}
          onChange={handleInput}
        />
        <InputRightElement children={<SearchIcon fontSize="xl" />} />
      </InputGroup>
      <Flex>
        <UiMenu closeOnSelect={false}>
          <UiMenuButton
            h="40px"
            w="150px"
            bg="white"
            borderRightWidth={0}
            _focusVisible={{ borderColor: 'blue.500', borderRightWidth: '1px' }}
          >
            {filter.systemName?.length ? `(${filter.systemName?.length}) Systems` : 'System'}
          </UiMenuButton>
          <UiMenuList
            borderRadius={0}
            fontSize="sm"
          >
            <UiMenuItemOption
              isChecked={isSystemNameFull}
              onClick={() =>
                setFilter({
                  key: 'systemName',
                  value: isSystemNameFull ? undefined : SYSTEM_NAME_VALUES,
                })
              }
            >
              Any System
            </UiMenuItemOption>
            <UiMenuDivider />
            <UiMenuOptionGroup
              type="checkbox"
              onChange={(value: string | string[]) => handleMultiSelect({ key: 'systemName', value })}
              value={filter.systemName?.length ? filter.systemName : []}
            >
              {SYSTEM_NAME_VALUES.map((value) => (
                <UiMenuItemOption
                  key={value}
                  value={value}
                >
                  {humanize(value)}
                </UiMenuItemOption>
              ))}
            </UiMenuOptionGroup>
          </UiMenuList>
        </UiMenu>

        <UiMenu closeOnSelect={false}>
          <UiMenuButton
            h="40px"
            w="150px"
            bg="white"
            borderRightWidth={0}
            _focusVisible={{ borderColor: 'blue.500', borderRightWidth: '1px' }}
          >
            {filter.category?.length ? `(${filter.category?.length}) Categories` : 'Category'}
          </UiMenuButton>
          <UiMenuList
            borderRadius={0}
            fontSize="sm"
          >
            <UiMenuItemOption
              isChecked={isCategoryFull}
              onClick={() => setFilter({ key: 'category', value: isCategoryFull ? undefined : CATEGORIES_V2 })}
            >
              Any Category
            </UiMenuItemOption>
            <UiMenuDivider />
            <UiMenuOptionGroup
              type="checkbox"
              onChange={(value: string | string[]) => handleMultiSelect({ key: 'category', value })}
              value={filter.category?.length ? filter.category : []}
            >
              {CATEGORIES_V2.map((cat, idx) => (
                <UiMenuItemOption
                  key={idx}
                  value={cat}
                >
                  {humanize(cat)}
                </UiMenuItemOption>
              ))}
            </UiMenuOptionGroup>
          </UiMenuList>
        </UiMenu>

        <UiMenu closeOnSelect={false}>
          <UiMenuButton
            h="40px"
            w="150px"
            bg="white"
            _focusVisible={{ borderColor: 'blue.500' }}
          >
            {allStatuses.length ? `(${allStatuses.length}) Status` : 'Status'}
          </UiMenuButton>
          <UiMenuList
            borderRadius={0}
            fontSize="sm"
          >
            <UiMenuItemOption
              isChecked={isStatusFull}
              onClick={() =>
                handleStatusSelect(isStatusFull ? [] : ALL_STATUSES.map(([_key, value]) => value.valueOf()))
              }
            >
              Any Status
            </UiMenuItemOption>
            <UiMenuDivider />
            <UiMenuOptionGroup
              type="checkbox"
              onChange={(value: string | string[]) => handleStatusSelect(value)}
              value={allStatuses}
            >
              {STATUS_OPTIONS.map((option, idx) => (
                <UiMenuItemOption
                  key={idx}
                  value={option.value}
                >
                  {option.label}
                </UiMenuItemOption>
              ))}
            </UiMenuOptionGroup>
          </UiMenuList>
        </UiMenu>
      </Flex>
    </Grid>
  )
}
