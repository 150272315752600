import { SearchIcon } from '@chakra-ui/icons'
import { Grid, InputGroup, InputRightElement } from '@chakra-ui/react'
import {
  humanize,
  UiFormControl,
  UiInput,
  UiInputDate,
  UiInputProps,
  UiMenu,
  UiMenuButton,
  UiMenuDivider,
  UiMenuItemOption,
  UiMenuList,
  UiMenuOptionGroup,
} from '@postal-io/postal-ui'
import { isEmpty } from 'lodash'
import { EVENT_STATUSES } from './data'

const inputProps: UiInputProps = {
  borderRadius: 0,
  fontSize: 'sm',
  fontWeight: 'semibold',
}

export interface EventFilterProps {
  filter: {
    name?: string
    event_eventDateTime?: string
    event_status?: string[]
  }
  setFilter: (data: { key: string; value: any }) => void
  onSubmit?: (e: React.SyntheticEvent) => void
  isFetching?: boolean
}

export const EventsFilter: React.FC<EventFilterProps> = ({ filter, setFilter }) => {
  const handleInput = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { name: key, value } = target
    const val = isEmpty(value) ? undefined : value
    setFilter({ key, value: val })
  }

  const handleMultiSelect = ({ key, value }: { key: string; value?: string | string[] }) => {
    setFilter({
      key,
      value,
    })
  }
  const isEventStatusFull = (filter?.event_status?.length ?? 0) === EVENT_STATUSES.length

  return (
    <Grid
      templateColumns={{ base: '1fr', sm: '1fr 1fr 1fr', lg: '350px 350px min-content' }}
      gap={4}
    >
      <UiFormControl id="name">
        <InputGroup>
          <UiInput
            name="name"
            placeholder="Event name"
            aria-label="Event name"
            value={filter.name || ''}
            onChange={handleInput}
            {...inputProps}
          />
          <InputRightElement children={<SearchIcon fontSize="xl" />} />
        </InputGroup>
      </UiFormControl>

      <UiFormControl id="event_eventDateTime">
        <UiInputDate
          isRange
          name="event_eventDateTime"
          value={filter.event_eventDateTime}
          placeholder="Search Date"
          aria-label="Search Date"
          onChange={handleInput}
          {...inputProps}
        />
      </UiFormControl>

      <UiFormControl id="eventStatus">
        <UiMenu closeOnSelect={false}>
          <UiMenuButton
            h="40px"
            w="200px"
            bg="white"
            _focusVisible={{ borderColor: 'blue.500' }}
          >
            {filter.event_status?.length ? `(${filter.event_status?.length}) Event Status` : 'Event Status'}
          </UiMenuButton>
          <UiMenuList
            borderRadius={0}
            fontSize="sm"
          >
            <UiMenuItemOption
              isChecked={isEventStatusFull}
              onClick={() =>
                handleMultiSelect({
                  key: 'event_status',
                  value: isEventStatusFull ? undefined : EVENT_STATUSES,
                })
              }
            >
              Any Status
            </UiMenuItemOption>

            <UiMenuDivider />

            <UiMenuOptionGroup
              type="checkbox"
              onChange={(value: string | string[]) => handleMultiSelect({ key: 'event_status', value })}
              value={filter.event_status?.length ? filter.event_status : []}
            >
              {EVENT_STATUSES.map((value, idx) => (
                <UiMenuItemOption
                  key={idx}
                  value={value}
                >
                  {humanize(value)}
                </UiMenuItemOption>
              ))}
            </UiMenuOptionGroup>
          </UiMenuList>
        </UiMenu>
      </UiFormControl>
    </Grid>
  )
}
