import { CopyIcon } from '@chakra-ui/icons'
import { UiCopyLink, UiCopyLinkProps, useUiAlerts } from '@postal-io/postal-ui'
import React from 'react'

interface VendorCopyLinkProps extends UiCopyLinkProps {
  variant: 'text' | 'card'
}

export const VendorCopyLink: React.FC<VendorCopyLinkProps> = ({ variant, text, children, ...rest }) => {
  const Alert = useUiAlerts()
  const handleCopy = () => Alert.success('Text copied to clipboard')
  return text ? (
    <UiCopyLink
      display={'block'}
      color="shades.900"
      fontWeight="regular"
      onCopy={handleCopy}
      text={text}
      {...rest}
    >
      <CopyIcon boxSize={variant === 'text' ? '16px' : '24px'} /> {children}
    </UiCopyLink>
  ) : null
}
