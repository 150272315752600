import { Box, BoxProps, Container, ContainerProps, Flex, Grid, GridProps, useBreakpointValue } from '@chakra-ui/react'
import React, { ReactElement } from 'react'

const ROOT_PROPS = {
  bg: 'white',
  borderBottomColor: 'gray.200',
  boxShadow: 'postal',
  borderBottomWidth: '1px',
  mb: { base: 2, md: 8 },
}

const GRID_PROPS = {
  gridGap: 4,
  minH: '52px',
  justifyContent: 'center',
  alignItems: 'center',
  px: 8,
  py: 1,
}

export interface SubNavbarProps extends Omit<BoxProps, 'left' | 'right' | 'center'> {
  left?: ReactElement<any>
  center?: ReactElement<any>
  right?: ReactElement<any>
  gridProps?: GridProps
  containerProps?: ContainerProps
}

export const SubNavbar: React.FC<SubNavbarProps> = ({
  left,
  center,
  right,
  maxW,
  maxWidth,
  gridProps,
  containerProps,
  ...rest
}) => {
  const isLarge = useBreakpointValue({ lg: true })
  const showGrid = React.useMemo(() => !!center && isLarge, [center, isLarge])

  return (
    <Box
      {...ROOT_PROPS}
      {...rest}
    >
      <Container
        maxW={maxW || maxWidth || 'full'}
        {...containerProps}
      >
        {showGrid ? (
          <Grid
            templateColumns="1fr 1fr 1fr"
            {...GRID_PROPS}
            {...gridProps}
          >
            <Box
              data-testid="ui-subnavbar-left"
              justifySelf="start"
            >
              {left}
            </Box>
            <Box
              data-testid="ui-subnavbar-center"
              justifySelf="center"
            >
              {center}
            </Box>
            <Box
              data-testid="ui-subnavbar-right"
              justifySelf="end"
            >
              {right}
            </Box>
          </Grid>
        ) : (
          <Flex
            {...GRID_PROPS}
            {...gridProps}
          >
            {!!left && (
              <Box
                data-testid="ui-subnavbar-left"
                mr="auto"
              >
                {left}
              </Box>
            )}
            {!!center && (
              <Box
                data-testid="ui-subnavbar-center"
                mx={!left && !right ? 'auto' : undefined}
              >
                {center}
              </Box>
            )}
            {!!right && (
              <Box
                data-testid="ui-subnavbar-right"
                ml="auto"
              >
                {right}
              </Box>
            )}
          </Flex>
        )}
      </Container>
    </Box>
  )
}
