import qs from 'query-string'
import { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

/**
 * # useQueryParams
 * Works with react router to enable routing with query params instead of
 * path params
 *
 * ## Usage
 *
 * ```
 * const { params, path, push } = useQueryParams()
 * ```
 *
 * ### Params
 * Get the query params for the current location.
 *
 * Returns the query params as an object.  For example,
 * if the path is `/asdf?something=this&other=that`
 *
 * ```
 * console.log(params)            // {something: 'this', other: 'that'}
 * ```
 *
 * ### Path
 * Generates a path including the query string.
 *
 * Pass in the desired path and a query params object. If the route
 * is empty or missing, then location.pathname is used.
 *
 * ```
 * path({asdf: 3})                // `${location.pathname}?asdf=3`
 * path()                         // `${location.pathname}`
 * path(undefined, { asdf: 3 })   // `${location.pathname}?asdf=3`
 * path('/other', { asdf: 3})     // `/other?asdf=3`
 * ```
 *
 * ### Push
 * Routes to a path including a query string.  If the route is
 * empty or missing, then location.pathname is used.
 *
 * push({asdf: 3})                // route to: `${location.pathname}?asdf=3`
 * push()                         // route to: `${location.pathname}`
 * push(undefined, { asdf: 3 })   // route to: `${location.pathname}?asdf=3`
 * push('/other', { asdf: 3})     // route to: `/other?asdf=3`
 */
export const useQueryParams = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const params = useMemo(() => qs.parse(location.search), [location.search])

  const path = useCallback(
    (...args: [any, any]) => {
      const [first, second] = args
      let path
      let params
      if (typeof first === 'object') {
        params = first
        path = location.pathname
      } else {
        path = first || location.pathname
        params = second || {}
      }
      const query = qs.stringify(params)
      return path + (query && `?${query}`)
    },
    [location.pathname]
  )

  const push = useCallback((...args: [any, any]) => navigate(path(...args)), [navigate, path])
  const replace = useCallback((...args: [any, any]) => navigate(path(...args), { replace: true }), [navigate, path])

  return { params, path, push, replace }
}
