import { SearchIcon } from '@chakra-ui/icons'
import { Box, Flex, Grid, InputGroup, InputRightElement, TooltipProps, useDisclosure } from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import {
  humanize,
  UiConfirm,
  UiInput,
  UiInputDate,
  UiInputProps,
  UiMenu,
  UiMenuButton,
  UiMenuDivider,
  UiMenuItemOption,
  UiMenuList,
  UiMenuOptionGroup,
  UiSelectButton,
  UiText,
  useAlerts,
} from '@postal-io/postal-ui'
import { useReport } from 'hooks'
import isEmpty from 'lodash/isEmpty'
import { useMemo, useState } from 'react'
import { BulkUpdatePostalFulfillmentStatusDocument, FulfillmentStatus, ReportType } from '../../api/index'
import { ActionType, BUTTONS, SELECT_FULFILLMENT_STATUSES } from './ordersData'

const inputProps: UiInputProps = {
  borderRadius: 0,
  fontSize: 'sm',
  fontWeight: 'semibold',
}

export interface OrdersFilterProps {
  filter: {
    fulfillmentEvents_placed?: string[]
    itemName?: string
    status?: string[]
    created?: string[]
  }
  setFilter: (data: { key: string; value: any }) => void
  selectedIds: string[]
  refetch: () => void
}

export const OrdersFilter: React.FC<OrdersFilterProps> = ({ filter, setFilter, selectedIds, refetch }) => {
  const Alert = useAlerts()
  const { createReport, isLoading: reportIsLoading } = useReport(ReportType.PostalFulfillmentPartner)
  const [actionType, setActionType] = useState<ActionType>()

  const disclosure = {
    [ActionType.CONFIRM_ORDER]: useDisclosure(),
    [ActionType.CANCEL_ORDER]: useDisclosure(),
    [ActionType.EXPORT_ORDER]: useDisclosure(),
  }

  const bulkUpdatePostalFulfillmentStatus = useGraphqlMutation(BulkUpdatePostalFulfillmentStatusDocument)

  const handleInput = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = target
    const val = isEmpty(value) ? undefined : value
    setFilter({ key: name, value: val })
  }

  const handleMultiSelect = ({ key, value }: { key: string; value?: string | string[] }) => {
    setFilter({
      key,
      value,
    })
  }
  const isStatusFull = (filter?.status?.length ?? 0) === SELECT_FULFILLMENT_STATUSES.length

  const handleBulkUpdate = async () => {
    try {
      if (actionType === ActionType.CONFIRM_ORDER) {
        await bulkUpdatePostalFulfillmentStatus.mutateAsync({
          status: FulfillmentStatus.Confirmed,
          ids: selectedIds,
        })
        Alert.success('Selected Orders updated to Confirmed')
      }
      if (actionType === ActionType.CANCEL_ORDER) {
        await bulkUpdatePostalFulfillmentStatus.mutateAsync({
          status: FulfillmentStatus.Cancelled,
          ids: selectedIds,
        })
        Alert.success('Selected Orders updated to Cancelled')
      } else if (actionType === ActionType.EXPORT_ORDER) {
        // export based on selected ids only
        await createReport({ id: { in: selectedIds } })
      }
      refetch()
    } catch (err) {
      Alert.error(err)
    } finally {
      disclosure[actionType as ActionType].onClose()
    }
  }
  const isDisabled = useMemo(() => reportIsLoading || selectedIds?.length < 1, [reportIsLoading, selectedIds])

  const handleConfirmBulkUpdate = (type: ActionType) => {
    setActionType(type)
    disclosure?.[type]?.onOpen()
  }

  return (
    <>
      <Grid
        templateColumns={{ base: '1fr', xl: 'max-content 350px 350px min-content' }}
        gap={4}
      >
        <Flex justifyContent="space-between">
          <Box>
            {BUTTONS.map(({ type, placement, icon, ...button }, idx) => (
              <UiSelectButton
                mr={idx === BUTTONS.length - 1 ? 0 : 2}
                key={type}
                borderColor="gray.300"
                isDisabled={isDisabled}
                placement={placement as TooltipProps['placement']}
                onClick={() => handleConfirmBulkUpdate(type)}
                boxShadow="postal"
                h={'40px'}
                icon={icon}
                {...button}
              />
            ))}
          </Box>
        </Flex>
        <InputGroup>
          <UiInput
            placeholder="Filter Products"
            name="itemName"
            value={filter?.itemName}
            onChange={handleInput}
            {...inputProps}
          />
          <InputRightElement children={<SearchIcon fontSize="xl" />} />
        </InputGroup>
        <Box bg="white">
          <UiInputDate
            isRange
            name="fulfillmentEvents_placed"
            id="fulfillmentEvents_placed"
            value={filter.fulfillmentEvents_placed}
            placeholder="Search Date"
            aria-label="Search Date"
            onChange={handleInput}
            {...inputProps}
          />
        </Box>
        <UiMenu closeOnSelect={false}>
          <UiMenuButton
            h="40px"
            w="200px"
            bg="white"
            _focusVisible={{ borderColor: 'blue.500' }}
          >
            {filter.status?.length ? `(${filter.status?.length}) Fulfillment Status` : 'Fulfillment Status'}
          </UiMenuButton>
          <UiMenuList
            borderRadius={0}
            fontSize="sm"
          >
            <UiMenuItemOption
              isChecked={isStatusFull}
              onClick={() =>
                handleMultiSelect({
                  key: 'status',
                  value: isStatusFull ? undefined : SELECT_FULFILLMENT_STATUSES,
                })
              }
            >
              Any Status
            </UiMenuItemOption>
            <UiMenuDivider />
            <UiMenuOptionGroup
              type="checkbox"
              onChange={(value: string | string[]) => handleMultiSelect({ key: 'status', value })}
              value={filter.status?.length ? filter.status : []}
            >
              {SELECT_FULFILLMENT_STATUSES.map((value, idx) => (
                <UiMenuItemOption
                  key={idx}
                  value={value}
                >
                  {humanize(value)}
                </UiMenuItemOption>
              ))}
            </UiMenuOptionGroup>
          </UiMenuList>
        </UiMenu>
      </Grid>
      <UiConfirm
        title="Confirm Bulk Update"
        isOpen={disclosure[ActionType.CONFIRM_ORDER].isOpen}
        onConfirm={handleBulkUpdate}
        onClose={disclosure[ActionType.CONFIRM_ORDER].onClose}
      >
        <UiText>
          Confirm {selectedIds.length} selected order{selectedIds.length > 1 ? 's' : ''}? This will only affect orders
          in the Placed status.
        </UiText>
      </UiConfirm>
      <UiConfirm
        title="Confirm Bulk Update"
        isOpen={disclosure[ActionType.CANCEL_ORDER].isOpen}
        onConfirm={handleBulkUpdate}
        onClose={disclosure[ActionType.CANCEL_ORDER].onClose}
      >
        <UiText>
          Cancel {selectedIds.length} selected order{selectedIds.length > 1 ? 's' : ''}?
        </UiText>
      </UiConfirm>
      <UiConfirm
        title="Confirm Export Orders"
        isOpen={disclosure[ActionType.EXPORT_ORDER].isOpen}
        onConfirm={handleBulkUpdate}
        onClose={disclosure[ActionType.EXPORT_ORDER].onClose}
        buttonText="Export"
      >
        <UiText>
          Export {selectedIds.length} selected order{selectedIds.length > 1 ? 's' : ''}?
        </UiText>
      </UiConfirm>
    </>
  )
}
