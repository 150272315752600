import { extendTheme } from '@chakra-ui/react'
import { AlertProvider, generatePalette, uiTheme, UiThemeProvider } from '@postal-io/postal-ui'
import { ErrorBoundary } from 'components/ErrorBoundary'
import 'flatpickr/dist/flatpickr.min.css'
import { SessionProvider } from 'hooks'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import { AtomicColors, CustomColors } from 'lib'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter } from 'react-router-dom'
import './api/query'
import { queryClient } from './api/query'
import { App } from './components/App'
import './index.css'

const ENVIRONMENT = process.env.NODE_ENV || 'development'
const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY
const VERSION = process.env.REACT_APP_TAG_NAME || process.env.REACT_APP_SHORT_SHA
const LAUNCH_DARKLY_KEY = process.env.REACT_APP_LAUNCH_DARKLY_KEY

window.postalVersion = VERSION || ENVIRONMENT
window.postalEnvironment = ENVIRONMENT

const overrides = {
  shadows: {
    // override default "postal" shadow
    postal: '0 0 6px 0 rgba(0,0,0,0.1)',
  },
  colors: {
    sky: generatePalette(CustomColors.Sky),
    lavender: generatePalette(CustomColors.Lavender),
    turquoise: generatePalette(CustomColors.Turquoise),
    ...AtomicColors,
  },
  radii: {
    postal: '0.375rem',
  },
}

const vendorTheme = extendTheme(uiTheme, overrides)
if (ENVIRONMENT === 'development') console.log(vendorTheme)

const initLaunchDarkly = async () => {
  if (!LAUNCH_DARKLY_KEY) return React.Fragment
  return asyncWithLDProvider({
    clientSideID: LAUNCH_DARKLY_KEY,
    user: { key: 'anonymous' },
    options: { useReport: true },
  })
}

const container = document.getElementById('root')
const root = createRoot(container!)

initLaunchDarkly().then((LDProvider) => {
  root.render(
    <QueryClientProvider client={queryClient}>
      <UiThemeProvider theme={vendorTheme as any}>
        <SessionProvider>
          <AlertProvider>
            <ErrorBoundary>
              <GoogleReCaptchaProvider
                reCaptchaKey={RECAPTCHA_KEY as string}
                scriptProps={{ nonce: '44622d3c3844778f8426564cdc10baae' }}
              >
                <LDProvider>
                  <BrowserRouter basename={process.env.PUBLIC_URL}>
                    <App />
                  </BrowserRouter>
                </LDProvider>
              </GoogleReCaptchaProvider>
            </ErrorBoundary>
          </AlertProvider>
        </SessionProvider>
      </UiThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
})

declare global {
  interface Window {
    analytics: any
    google: any
    segment: any
    postalVersion: string
    postalEnvironment: string
  }
}
