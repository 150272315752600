import { Flex } from '@chakra-ui/react'
import { UiIconPostalLogo } from '@postal-io/postal-ui'

export const LogInHeaderWhite: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Flex
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      w="100%"
      maxW="600px"
      mx="auto"
      mt={12}
      px={5}
    >
      <UiIconPostalLogo
        color="primary.500"
        fontSize="200px"
        h="auto"
      />

      {children}
    </Flex>
  )
}
