import { Box } from '@chakra-ui/react'
import { UiLoading } from '@postal-io/postal-ui'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import React, { useEffect, useRef, useState } from 'react'
import { useSession } from '../../hooks'

export const LaunchDarkly: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { session } = useSession()
  const ldClient = useLDClient()
  const ldRef = useRef(false)
  const [ldLoading, setLdLoading] = useState(!!ldClient)

  const loading = ldLoading

  useEffect(() => {
    if (session && ldClient && !ldRef.current) {
      ldClient
        .identify({
          key: session.userId,
          email: session.userName,
          custom: {
            accountId: session.accountId,
            productAccessId: session.productAccessId,
            plan: session.plan,
            roles: session.roles,
          },
        })
        .finally(() => {
          ldRef.current = true
          setLdLoading(false)
        })
      return () => {
        ldRef.current = false
      }
    }
  }, [ldClient, session])

  return loading ? <UiLoading /> : <Box>{children}</Box>
}
