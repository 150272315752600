import { DraftMarketplaceProductFragment, DraftStatus } from 'api'
import { VendorStatusTag } from 'components/Main'
import { draftStatusMapper, statusMapper } from 'lib'
import { useMemo } from 'react'

export const useGetDraftStatus = (draft?: DraftMarketplaceProductFragment) => {
  const statusTag = useMemo(
    () => () =>
      draft?.status === DraftStatus.Active && !!draft.marketplaceStatus ? (
        <VendorStatusTag {...statusMapper(draft.marketplaceStatus)} />
      ) : !!draft?.status ? (
        <VendorStatusTag {...draftStatusMapper(draft.status)} />
      ) : null,
    [draft?.marketplaceStatus, draft?.status]
  )
  return statusTag
}
