import { UiCard, UiCardHeader, UiCardProps } from '@postal-io/postal-ui'
import { PostalFulfillmentFragment } from 'api'
import React from 'react'
import { StatusEdit } from './OrderActions/StatusEdit'
import { TrackingNumberEdit } from './OrderActions/TrackingNumberEdit'

interface OrderActionsProps extends UiCardProps {
  postalFulfillment?: PostalFulfillmentFragment
  isLoading?: boolean
}

export const OrderActions: React.FC<OrderActionsProps> = ({ postalFulfillment, isLoading: postalLoading, ...rest }) => {
  return (
    <>
      <UiCard
        isLoading={postalLoading}
        {...rest}
      >
        <UiCardHeader>Order Actions</UiCardHeader>

        {/* the status and notes form */}
        <StatusEdit
          postalFulfillment={postalFulfillment}
          isLoading={postalLoading}
        />

        {/* the tracking number is updated using a different form/mutation */}
        <TrackingNumberEdit
          mt={8}
          postalFulfillmentId={postalFulfillment?.id}
          trackingNumber={postalFulfillment?.trackingNumber as string}
          deliveryPartner={postalFulfillment?.deliveryPartner}
        />
      </UiCard>
    </>
  )
}
