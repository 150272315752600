import { Flex, Grid, Heading } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { UiSubNavbar } from '@postal-io/postal-ui'

import { GetEventDocument, Status } from 'api'
import { BackButton, Layout } from 'components/Common'
import React, { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { OrderPreview } from '../Orders/OrderPreview'
import { EventOrders } from './EventOrders'
import { EventProfile } from './EventProfile'

export const Event: React.FC = () => {
  const { eventId }: { eventId?: string } = useParams()
  const navigate = useNavigate()

  // get order using id derived from route
  const getEvent = useGraphqlQuery(GetEventDocument, { id: eventId }, { enabled: !!eventId })
  const approvedPostal = getEvent.data?.getEvent

  const variantName = useMemo(() => {
    return (
      approvedPostal?.variants
        ?.filter((v) => v.status === Status.Active)
        ?.map((v) => v.variantName)
        .join(', ') || '-'
    )
  }, [approvedPostal?.variants])

  const handleBack = () => navigate('/events', { state: { useSavedPage: true } })

  return (
    <>
      <UiSubNavbar
        left={
          <Flex alignItems="center">
            <BackButton onClick={handleBack} />
            <Heading
              mr={4}
              fontSize="2xl"
            >
              {getEvent.isLoading ? 'Loading...' : 'Event: ' + approvedPostal?.name}
            </Heading>
          </Flex>
        }
        boxShadow="none"
        border="none"
        mb={0}
        maxW="1800px"
        gridProps={{ p: 4 }}
      />
      <Layout pt={0}>
        <Grid
          templateColumns={{ base: '1fr', md: '1fr 3fr' }}
          gap={8}
        >
          <OrderPreview previewImage={approvedPostal?.imageUrls?.[0]?.url as string} />
          <EventProfile approvedPostal={approvedPostal} />
        </Grid>
        <Grid
          templateColumns={{ base: '1fr' }}
          gap={8}
          mt={8}
        >
          <EventOrders
            approvedPostalId={approvedPostal?.id}
            variantName={variantName}
          />
        </Grid>
      </Layout>
    </>
  )
}
