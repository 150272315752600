import { UiIconPostalLogo } from '@postal-io/postal-ui'
import React from 'react'

export const Logo = ({ variant }: { variant: string }) => {
  return (
    <UiIconPostalLogo
      width="150px"
      height="auto"
      color={variant === 'dark' ? 'white' : 'secondary.500'}
    />
  )
}
