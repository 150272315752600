import { Box, Fade, Flex, FlexProps, Grid } from '@chakra-ui/react'
import {
  UiCard,
  UiCardProps,
  UiHeading2,
  UiHeading5,
  UiMenu,
  UiMenuButton,
  UiMenuItemOption,
  UiMenuList,
  UiMenuOptionGroup,
} from '@postal-io/postal-ui'
import { FulfillmentStatus, PostalFulfillmentFragment } from '../../api/index'

const dayInMilliseconds = 1000 * 60 * 60 * 24

const Column: React.FC<FlexProps> = ({ children, ...rest }) => {
  return (
    <Flex
      h="100%"
      p={4}
      flexDir="column"
      justifyContent="space-between"
      borderRight="1px solid"
      borderRightColor="gray.500"
      {...rest}
    >
      {children}
    </Flex>
  )
}

interface StatProps extends FlexProps {
  label?: string
}

const Stat: React.FC<StatProps> = ({ label, children, ...rest }) => {
  return (
    <Flex
      flexDir="column"
      justifyContent="space-between"
      h="100%"
      {...rest}
    >
      <UiHeading5
        color="white"
        fontSize="xs"
        mb={0}
      >
        {label}
      </UiHeading5>
      <UiHeading2
        color="turquoise.500"
        fontSize="lg"
        fontWeight={'extrabold'}
        letterSpacing={'0.25px'}
        mb={0}
      >
        {children}
      </UiHeading2>
    </Flex>
  )
}

const NUM_COLUMNS = 30
interface SimpleChartProps {
  postalFulfillments?: PostalFulfillmentFragment[]
}

const SimpleChart: React.FC<SimpleChartProps> = ({ postalFulfillments = [] }) => {
  let max = 5

  // reduce orders according to created dateTime
  const data = postalFulfillments.reduce((map, pf) => {
    if (!pf?.created?.dateTime) return map
    const created = new Date(pf.created.dateTime)
    created.setHours(0, 0, 0, 0)
    const createdStr = String(created)
    if (!map[createdStr]) map[createdStr] = 0
    map[createdStr]++
    return map
  }, {} as { [k: string]: number })

  const counts = Object.keys(data)
    .slice(0, NUM_COLUMNS)
    .sort((a, b) => {
      return new Date(a) < new Date(b) ? -1 : 1
    })
    .map((date) => {
      const count = data[date] || 1
      if (count > max) max = count
      return count
    })

  if (counts.length < 30) {
    const diff = 30 - counts.length
    counts.push(...new Array(diff).fill(1))
  }

  return (
    <Fade in={counts.length > 0}>
      <Grid
        templateColumns={`repeat(${NUM_COLUMNS}, 1fr)`}
        w="200px"
        gap="3px"
        alignItems="flex-end"
        h="40px"
      >
        {counts.map((count, idx) => (
          <Box
            key={idx}
            bg="lavender.500"
            h={`${(count / max) * 40}px`}
            borderRadius="5px"
          />
        ))}
      </Grid>
    </Fade>
  )
}

interface OrdersStatsCardProps extends Omit<UiCardProps, 'filter'> {
  filter: {
    itemName?: string
    status?: string[]
    created?: string[]
  }
  setFilter: (data: { key: string; value: any }) => void
  postalFulfillments?: PostalFulfillmentFragment[]
  isLoading?: boolean
}

export const OrdersStatsCard: React.FC<OrdersStatsCardProps> = ({ filter, setFilter, postalFulfillments, ...rest }) => {
  const today = new Date()
  today.setUTCHours(0, 0, 0, 0)

  const todayISO = today.toISOString()
  const lastWeekISO = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - today.getDay() - 8
  ).toISOString()
  const lastMonthISO = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate()).toISOString()
  const lastYearISO = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate()).toISOString()

  const ordersCount = postalFulfillments?.length ?? 0

  const fulfilledOrders = postalFulfillments?.filter((pf) => pf.status === FulfillmentStatus.Delivered) ?? []

  const fulfilledOrdersCount = fulfilledOrders.length

  const avgDaysToFulfill = Math.round(
    fulfilledOrders.reduce((sum, order) => {
      const firstDate = order?.history?.[0]?.created
      const lastDate = order?.history?.[(order?.history?.length ?? 0) - 1]?.created
      if (!firstDate || !lastDate) return 0
      const differenceInDays = (new Date(lastDate).getTime() - new Date(firstDate).getTime()) / dayInMilliseconds
      return sum + differenceInDays
    }, 0) / fulfilledOrders.length
  )

  const currentValue = filter.created?.filter((v) => v !== todayISO)
  const currentLabel = currentValue?.includes(lastWeekISO)
    ? '7 DAYS'
    : currentValue?.includes(lastMonthISO)
    ? '1 MONTH'
    : '1 YEAR'

  const handleDateRange = (value: string | string[]) => {
    if (value.length === 0) return
    if (Array.isArray(value) && value.length > 1) {
      value = value?.filter((v) => !filter.created?.includes(v))
    }
    setFilter({
      key: 'created',
      value: [...value, todayISO],
    })
  }

  return (
    <UiCard
      bg="header.dark"
      w="100%"
      minH={0}
      p={0}
      {...rest}
    >
      <Grid templateColumns={{ base: '1fr', lg: '1fr 3fr 2fr 8fr' }}>
        <Column justifyContent="center">
          <Stat>
            <UiMenu>
              <UiMenuButton
                w="125px"
                color="white"
                bg="none"
                border="none"
                _hover={{ border: 'none' }}
                _active={{ border: 'none' }}
                _focusVisible={{ borderColor: 'blue.500' }}
              >
                {currentLabel}
              </UiMenuButton>
              <UiMenuList
                borderRadius={0}
                fontSize="md"
                color="shades.900"
                p={0}
              >
                <UiMenuOptionGroup
                  type="checkbox"
                  onChange={handleDateRange}
                  value={filter.created?.filter((v) => v !== todayISO)}
                >
                  <UiMenuItemOption
                    p={4}
                    value={lastWeekISO}
                  >
                    7 DAYS
                  </UiMenuItemOption>
                  <UiMenuItemOption
                    p={4}
                    value={lastMonthISO}
                  >
                    1 MONTH
                  </UiMenuItemOption>
                  <UiMenuItemOption
                    p={4}
                    value={lastYearISO}
                  >
                    1 YEAR
                  </UiMenuItemOption>
                </UiMenuOptionGroup>
              </UiMenuList>
            </UiMenu>
          </Stat>
        </Column>

        <Column>
          <Grid
            templateColumns="1fr 3fr"
            h="100%"
            alignItems="center"
            gap={4}
          >
            <Stat label={'ORDERS'}>{ordersCount}</Stat>
            <SimpleChart postalFulfillments={postalFulfillments} />
          </Grid>
        </Column>

        <Column>
          <Stat label={'FULFILLED ORDERS'}>{fulfilledOrdersCount}</Stat>
        </Column>

        <Column borderRight="none">
          <Stat
            label={'AVG TIME TO FULFILL'}
            borderRight="none"
          >
            {avgDaysToFulfill ? `${avgDaysToFulfill} days` : '-'}
          </Stat>
        </Column>
      </Grid>
    </UiCard>
  )
}
