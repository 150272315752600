import { ModalProps, Stack, Text } from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import {
  humanize,
  UiAlert,
  UiAutoCompleteSelect,
  UiButton,
  UiFormControl,
  UiFormLabel,
  UiModal,
  UiModalBody,
  UiModalCloseButton,
  UiModalContent,
  UiModalFooter,
  UiModalFooterButtons,
  UiModalHeader,
  UiModalOverlay,
  UiSelect,
  useAlerts,
} from '@postal-io/postal-ui'
import { useMemo } from 'react'
import { useImmer } from 'use-immer'
import { BulkUpdateDraftMarketplaceProductsDocument, DraftMarketplaceProductFragment } from '../../api/index'
import { useBackgroundQueue } from '../../hooks/useBackground'
import { CATEGORIES_V2, TYPES_V2 } from './draftProductDataV2'

export enum BulkUpdateType {
  Default = 'DEFAULT',
  SetMissingData = 'SET_MISSING_DATA',
}

interface DraftProductsBulkUpdateModalProps extends Omit<ModalProps, 'children'> {
  type: BulkUpdateType
  selectedDrafts: DraftMarketplaceProductFragment[]
  onSuccess(): void
}

export const DraftProductsBulkUpdateModal: React.FC<DraftProductsBulkUpdateModalProps> = ({
  type,
  selectedDrafts,
  onSuccess,
  ...rest
}) => {
  const Alert = useAlerts()
  const { queue, tasks } = useBackgroundQueue()
  const [form, setForm] = useImmer<{ category: string; type?: string }>({ category: '' })

  const bulkUpdate = useGraphqlMutation(BulkUpdateDraftMarketplaceProductsDocument, {
    onSuccess: (data) => {
      queue(data.bulkUpdateDraftMarketplaceProducts, () => {
        Alert.success('Products updated')
        rest.onClose()
        setForm({ category: '' })
        onSuccess()
      })
    },
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    //reset type field if category changes
    if (e.target.name === 'category') {
      setForm((draft: any) => void (draft.type = ''))
    }
    setForm((draft: any) => void (draft[e.target.name] = e.target.value))
  }

  const handleMultiSelectValue = (name: string, value: any) => {
    setForm((draft: any) => void (draft[name] = value))
  }

  const bulkUpdateIds = useMemo(() => {
    if (type === BulkUpdateType.SetMissingData) {
      return selectedDrafts.filter((draft) => !draft.category || !draft.type).map((draft) => draft.id)
    }
    return selectedDrafts
  }, [selectedDrafts, type])

  const bulkUpdateTitle = useMemo(() => {
    if (type === BulkUpdateType.SetMissingData) {
      return `Set Missing Data - ${bulkUpdateIds.length} product${bulkUpdateIds.length > 1 ? 's' : ''}`
    }
    return 'Bulk Update Products'
  }, [bulkUpdateIds.length, type])

  const bulkUpdateFields = useMemo(() => {
    if (type === BulkUpdateType.SetMissingData) {
      return ['category', 'type']
    }
    return []
  }, [type])

  const handleUpdateMissingData = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      await bulkUpdate.mutateAsync({ data: form, ids: bulkUpdateIds })
    } catch (err) {
      Alert.error(err)
    }
  }

  const bulkUpdateIsLoading =
    bulkUpdate.isLoading || tasks.some((task) => task.task.taskAction === 'bulkUpdateDraftMarketplaceProducts')

  const isTypeRequired = !!form.category && (TYPES_V2[form.category]?.length ?? 0) > 0

  return (
    <UiModal
      size="xl"
      {...rest}
      scrollBehavior="inside"
    >
      <UiModalOverlay />
      <UiModalContent>
        <form onSubmit={handleUpdateMissingData}>
          <UiModalHeader
            fontSize="lg"
            fontWeight="bold"
          >
            {bulkUpdateTitle}
          </UiModalHeader>
          <UiModalCloseButton />
          <UiModalBody overflow="visible">
            <Stack spacing={4}>
              {/* for setting missing data, explain that only products actually missing the data will be updated */}
              {type === BulkUpdateType.SetMissingData && bulkUpdateIds.length < selectedDrafts.length && (
                <Text>
                  <strong>{bulkUpdateIds.length}</strong> out of <strong>{selectedDrafts.length}</strong> selected
                  products are missing required data. Only these products will be updated, the others will be ignored.
                </Text>
              )}
              {bulkUpdateFields.includes('category') && (
                <UiFormControl
                  id="category"
                  isRequired
                >
                  <UiFormLabel
                    color="shades.800"
                    fontWeight="bold"
                  >
                    Category
                  </UiFormLabel>
                  <UiSelect
                    name="category"
                    value={form.category}
                    color="shades.800"
                    placeholder="Select..."
                    onChange={handleChange}
                  >
                    {CATEGORIES_V2.map((cat, idx) => (
                      <option
                        key={idx}
                        value={cat}
                      >
                        {humanize(cat)}
                      </option>
                    ))}
                  </UiSelect>
                </UiFormControl>
              )}
              {bulkUpdateFields.includes('type') && (
                <UiFormControl
                  id="type"
                  isRequired={isTypeRequired}
                  isDisabled={!form.category}
                >
                  <UiFormLabel
                    color="shades.800"
                    fontWeight="bold"
                  >
                    Type
                  </UiFormLabel>
                  <UiAutoCompleteSelect
                    options={TYPES_V2[form.category || 'Everything Else']}
                    name="type"
                    placeholder="Examples: Postcards, Gift Cards"
                    value={form.type}
                    onChange={(val) => handleMultiSelectValue('type', val)}
                    isDisabled={!form.category || !isTypeRequired}
                  />
                  {!isTypeRequired && form.category !== '' && (
                    <UiAlert
                      status="info"
                      mt={4}
                      hideClose
                    >
                      No types are defined for this category. Please submit for approval and your Marketplace contact
                      will assist you.
                    </UiAlert>
                  )}
                </UiFormControl>
              )}
            </Stack>
          </UiModalBody>
          <UiModalFooter>
            <UiModalFooterButtons>
              <UiButton
                type="submit"
                color="primary.900"
                colorScheme="turquoise"
                isLoading={bulkUpdateIsLoading}
                isDisabled={bulkUpdateIsLoading || !form.category || (isTypeRequired && !form.type)}
              >
                Save
              </UiButton>
            </UiModalFooterButtons>
          </UiModalFooter>
        </form>
      </UiModalContent>
    </UiModal>
  )
}
