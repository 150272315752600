import { clearProductAccessId, getProductAccessId, getRefreshToken, getToken, saveSession } from 'hooks'
import jwtDecode from 'jwt-decode'
import qs from 'query-string'

const PRODUCT_ID = process.env.REACT_APP_PRODUCT_ID
const IMAGE_URL = process.env.REACT_APP_IMAGE_URL || '/api/vendor/image'
const TOKEN_DRIFT_SECONDS = 120

const GENERIC_ERROR = 'Unknown Error'

// Returns true if we have a valid AND not expired token
const isValidToken = (token: string, drift = 0) => {
  if (!token) return false
  try {
    const { exp } = jwtDecode(token) as any
    return exp > Date.now() / 1000 + drift
  } catch (_) {
    return false
  }
}

// Get a valid token, refresh if possible
export const getAuthToken = async () => {
  const token = getToken()
  if (isValidToken(token, TOKEN_DRIFT_SECONDS)) return token
  const res = await accessToken()
  return res.token
}

export interface ApiRequest {
  url: Request['url']
  method?: RequestInit['method']
  body?: BodyInit
  json?: Record<string, any>
  params?: Record<string, any>
  token?: string | null
  skipAuth?: boolean
  headers?: Headers
}
export const apiFetch = async ({ url, method, body, json, params, token, skipAuth, headers }: ApiRequest) => {
  // get an authToken to add to the request
  const authToken = token || skipAuth ? token : await getAuthToken()

  // construct fetch call
  const reqBody = json ? JSON.stringify(json) : body
  const reqUrl = params ? `${url}?${qs.stringify(params)}` : url
  const reqHeaders = headers || new Headers()
  if (authToken) reqHeaders.append('authorization', `Bearer ${authToken}`)
  if (json) reqHeaders.append('content-type', 'application/json')
  const res = await fetch(reqUrl, { method, headers: reqHeaders, body: reqBody })
  const responseType = res.headers.get('content-type')

  // construct response
  if (responseType?.includes('json')) {
    // this is to prevent trying to parse an empty body with res.json()
    const text = await res.text()
    const data = text ? JSON.parse(text) : ''
    if (!res.ok) {
      throw new Error(data.errorMessage || data.error || res.statusText || GENERIC_ERROR)
    }
    return data
  }

  if (responseType?.includes('image')) {
    const data = await res.blob()
    if (!res.ok) throw new Error(res.statusText || GENERIC_ERROR)
    return URL.createObjectURL(data)
  }

  const data = await res.text()
  if (!res.ok) throw new Error(res.statusText || GENERIC_ERROR)
  return data
}

export interface LoginPasswordRequest {
  userName: string
  password: string
}
export const loginPassword = async (req: LoginPasswordRequest) => {
  const { refreshToken } = await apiFetch({
    url: `/api/auth/password`,
    method: 'POST',
    json: { ...req, product: PRODUCT_ID },
    skipAuth: true,
  })
  return accessToken({ refreshToken })
}

export const loginSso = async (ssoToken: string) => {
  const { refreshToken } = await apiFetch({
    url: `/api/auth/sso`,
    method: 'POST',
    token: ssoToken,
    skipAuth: true,
  })
  return accessToken({ refreshToken })
}

export interface ForgotPasswordRequest {
  userName: string
  siteVerify: string
}
export const forgotPassword = async (req: ForgotPasswordRequest) => {
  return apiFetch({
    url: `/api/auth/password/forgot`,
    method: 'POST',
    json: { ...req, product: PRODUCT_ID },
    skipAuth: true,
  })
}

interface ResetPasswordRequest {
  requestId: string
  password: string
}
export const resetPassword = async (req: ResetPasswordRequest) => {
  return apiFetch({
    url: `/api/auth/password/reset`,
    method: 'POST',
    json: req,
    skipAuth: true,
  })
}

interface AccessTokenRequest {
  refreshToken?: string | null
  productAccessId?: string
}
interface AccessTokenResponse {
  token?: string
  refreshToken?: string
}
/**
  request an accessToken for:
  - a specified productAccessId
  - the most recent one from storage
  - let the API pick the first one

  if the request fails and we have a productAccessId
  - drop the productAccessId and try again
*/
export const accessToken = async (req?: AccessTokenRequest): Promise<AccessTokenResponse> => {
  const refreshToken = req?.refreshToken || getRefreshToken()
  if (!isValidToken(refreshToken)) throw new Error('Session Expired')
  const id = req?.productAccessId || getProductAccessId()
  const url = id ? `/api/session/accesstoken/${id}` : `/api/session/accesstoken`
  try {
    const res = await apiFetch({ url, method: 'POST', token: refreshToken })
    saveSession(res)
    return res
  } catch (err) {
    if (!id) throw err
    clearProductAccessId()
    return accessToken()
  }
}

export const logout = async () => {
  const token = getToken()
  return token ? apiFetch({ url: '/api/session/logout', method: 'POST', token }).catch(() => true) : true
}

type SignUpRequest = {
  firstName: string
  lastName: string
  password: string
  password2: string
  emailAddress: string
  siteVerify: string
}
export const signUp = (req: SignUpRequest) => {
  return apiFetch({
    url: '/api/signup/new',
    method: 'POST',
    json: { ...req, userName: req.emailAddress, product: PRODUCT_ID },
    skipAuth: true,
  })
}

type SignUpVerifyRequest = {
  requestId: string
  siteVerify: string
}
export const signUpVerify = (req: SignUpVerifyRequest) => {
  return apiFetch({ url: '/api/signup/verify', method: 'POST', json: req, skipAuth: true })
}

export type SignUpPasswordRequest = {
  token: string
  password: string
  password2: string
}
export const signUpPassword = async ({ token, ...req }: SignUpPasswordRequest) => {
  const { refreshToken } = await apiFetch({
    url: '/api/signup/password',
    method: 'POST',
    json: { ...req, product: PRODUCT_ID },
    token,
  })
  return accessToken({ refreshToken })
}

type InviteCompleteRequest = {
  id: string
  firstName: string
  lastName: string
}
export const inviteComplete = (req: InviteCompleteRequest) => {
  return apiFetch({ url: '/api/invite/complete', method: 'POST', json: req, skipAuth: true })
}

export const importOrders = async (files: File[]) => {
  if (!files?.length) throw new Error('At least one file must be provided')
  const url = '/api/vandelay/vendor/import/server'
  const body = new FormData()
  files.forEach((file, idx) => body.append(`upload[${idx}]`, file))
  return apiFetch({
    url,
    method: 'POST',
    body,
  })
}

// POST /api/vendor/upload/:type
export const uploadAsset = async (files: File[] = []) => {
  if (files.length < 1) throw new Error('At least one image must be provided')
  const body = new FormData()
  files.forEach((file, idx) => body.append(`upload[${idx}]`, file))
  return apiFetch({
    url: '/api/vendor/upload/assets',
    method: 'POST',
    body,
  })
}

interface AssetRequest {
  id: string
}
export const assetUrl = (asset: AssetRequest | string) => {
  const id = typeof asset === 'string' ? asset : asset.id
  return `asset://${id}`
}

interface AssetSrcRequest extends Record<string, unknown> {
  assetId?: string | null
  accountId?: string | null
}
export const assetSrc = ({ assetId, accountId, ...ops }: AssetSrcRequest) => {
  if (!assetId) return
  const isMarketplace = assetId.startsWith('PMA')
  const id = isMarketplace ? assetId.replace(/^PMA/, '') : assetId
  const url = isMarketplace ? `${IMAGE_URL}/marketplace/${id}` : `${IMAGE_URL}/${accountId}/${id}`
  return `${url}?${qs.stringify(ops)}`
}

interface AssetUrlToSrc extends Record<string, unknown> {
  assetUrl?: string | null
  accountId: string | null
}
export const assetUrlToSrc = ({ assetUrl, accountId, ...ops }: AssetUrlToSrc) => {
  if (!assetUrl) return
  const [_, assetId] = assetUrl.split('asset://')
  return assetId ? assetSrc({ assetId, accountId, ...ops }) : assetUrl
}

export const getInviteInfo = async (id: string) => {
  return apiFetch({ url: `/api/invite/${id}`, skipAuth: true })
}

export const getAccountLogoUrl = (accountId: string) => {
  return `${IMAGE_URL}/${accountId}/primary_logo`
}

//DRAFT PRODUCT UPLOAD
export const uploadBulkProducts = async (files = []) => {
  if (files.length < 1) throw new Error('At least one file must be provided')
  const body = new FormData()
  files.forEach((file, idx) => body.append(`upload[${idx}]`, file))
  return apiFetch({
    url: '/api/vandelay/vendor/import/server?template=bulkDraftMarketplaceProduct',
    method: 'POST',
    body,
  })
}

export const rest = {
  fetch: apiFetch,
  signUp,
  signUpPassword,
  signUpVerify,
  accessToken,
  loginPassword,
  loginSso,
  forgotPassword,
  resetPassword,
  logout,
  inviteComplete,
  getInviteInfo,
  uploadAsset,
  uploadBulkProducts,
  assetUrl,
  assetSrc,
  importOrders,
  getAccountLogoUrl,
}

export default rest
