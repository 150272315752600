import { Text } from '@chakra-ui/react'
import { UiConfirm } from '@postal-io/postal-ui'
interface DraftNoShopifyCollectionModalProps {
  isOpen: boolean
  onClose: () => void
  handleConfirm: () => void
  isSyncLoading?: boolean
}
export const DraftNoShopifyCollectionModal: React.FC<DraftNoShopifyCollectionModalProps> = ({
  isOpen,
  onClose,
  handleConfirm,
  isSyncLoading,
}) => {
  return (
    <UiConfirm
      size={'4xl'}
      title="Import Shopify Products"
      variant="outline"
      isOpen={isOpen}
      onConfirm={handleConfirm}
      onClose={onClose}
      buttonColor="primary"
      buttonText="Import all products"
      isDisabled={!!isSyncLoading}
      isLoading={!!isSyncLoading}
    >
      <Text>
        No Shopify collections were found. By confirming this action, <strong>ALL</strong> of your Shopify products will
        be imported. If you would like to import a selection, create a collection in Shopify first, and then reconnect
        to Shopify in Postal.
      </Text>
    </UiConfirm>
  )
}
