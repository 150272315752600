import { compact } from 'lodash'
import * as LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'
import React, { useEffect, useRef } from 'react'
import { useSession } from '../../hooks'

const LOG_ROCKET_KEY = process.env.REACT_APP_LOG_ROCKET_KEY

const isExcludedUser = (userName?: string) => {
  if (!userName) return true
  if (userName.includes('monitor@postal.io')) return true
  return false
}

export const LogRocketInit: React.FC = () => {
  const { session } = useSession()
  const initRef = useRef(null)

  useEffect(() => {
    if (!LOG_ROCKET_KEY) return
    if (!session?.userName || !session?.userId) return
    if (initRef.current === session.userId) return
    if (isExcludedUser(session.userName)) return

    initRef.current = session.userId
    LogRocket.init(LOG_ROCKET_KEY, {
      release: window.postalVersion,
    })
    setupLogRocketReact(LogRocket)
    LogRocket.identify(session.userId, {
      name: compact([session.firstName, session.lastName]).join(' '),
      email: session.userName,
      partnerId: session.partnerId,
      partnerName: session.partnerName,
      productAccessId: session.productAccessId,
      plan: session.plan,
      product: session.product,
      roles: session.roles?.join(', '),
    })
  }, [
    session.firstName,
    session.lastName,
    session.partnerId,
    session.partnerName,
    session.plan,
    session.product,
    session.productAccessId,
    session.roles,
    session.userId,
    session.userName,
  ])

  return null
}
