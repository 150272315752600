import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { UiButton, UiDataTable, UiText, useAlertError } from '@postal-io/postal-ui'
import { ReportOutputInstanceFragment, ReportType, SearchReportOutputInstancesDocument } from 'api'
import { useReport } from 'hooks'
import React from 'react'
import { ResponsiveDrawer } from '../Main/Responsive'

type DownloadButtonProps = {
  reportOutputInstanceId: string
  fileName: string
}

const DownloadButton = ({ reportOutputInstanceId, fileName }: DownloadButtonProps) => {
  const { downloadReport, isLoading } = useReport(ReportType.PostalFulfillmentPartner)
  const handleDownload = async () => {
    await downloadReport(reportOutputInstanceId, fileName)
  }
  return (
    <UiButton
      onClick={handleDownload}
      isLoading={isLoading}
      isDisabled={isLoading}
    >
      Download
    </UiButton>
  )
}

const columns = [
  { key: 'name', label: 'NAME' },
  {
    label: 'DOWNLOAD',
    render: ({ id, name }: ReportOutputInstanceFragment) => (
      <DownloadButton
        reportOutputInstanceId={id}
        fileName={name}
      />
    ),
  },
]

const LIMIT = 100
const variables = { filter: { type: ReportType.PostalFulfillmentPartner }, limit: LIMIT }

interface OrderSavedExportProps {
  isOpen?: boolean
  onClose?: () => void
}

export const OrderSavedExports: React.FC<OrderSavedExportProps> = ({ isOpen, onClose }) => {
  const searchReports = useGraphqlQuery(SearchReportOutputInstancesDocument, variables)
  useAlertError(searchReports.error)
  const reportOutputInstances = searchReports.data?.searchReportOutputInstances ?? []

  const handleClose = () => {
    onClose && onClose()
  }

  return (
    <ResponsiveDrawer
      size="lg"
      header="Saved Exports"
      isOpen={!!isOpen}
      onClose={handleClose}
      buttons={
        <>
          <UiButton
            variant="outline"
            onClick={onClose}
          >
            Cancel
          </UiButton>
        </>
      }
    >
      <UiText
        mt={2}
        textAlign="center"
        fontStyle="italic"
      >
        Tip: Hit escape to easily close this drawer
      </UiText>
      <UiDataTable
        variant="list"
        isLoading={searchReports.isLoading}
        mt={4}
        columns={columns}
        rows={reportOutputInstances}
        rowKey="id"
        pageSize={30}
      />
    </ResponsiveDrawer>
  )
}
