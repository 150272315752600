import { CheckIcon } from '@chakra-ui/icons'
import { Box, Flex, Text } from '@chakra-ui/react'
import {
  GraphqlFilterState,
  GraphqlFilterTransform,
  OrderByDirection,
  UiDateTime,
  UiIconDownload,
  UiLink,
  UiMoney,
  UiTruncate,
} from '@postal-io/postal-ui'
import { FulfillmentHistoryFragment, FulfillmentStatus, PostalFulfillmentFragment } from 'api'
import { assetUrlToSrc } from 'api/rest'
import { fulfillmentStatusMapper } from 'lib/statusMapper'
import { StorageKeys } from 'lib/storageKeys'
import { VendorStatusTag } from '../Main'
import { CellWrapper } from '../Main/Table'

export const SELECT_FULFILLMENT_STATUSES = [
  FulfillmentStatus.Placed,
  FulfillmentStatus.Confirmed,
  FulfillmentStatus.PendingDelivery,
  FulfillmentStatus.OutForDelivery,
  FulfillmentStatus.Shipped,
  FulfillmentStatus.Delivered,
  FulfillmentStatus.DeliveredAssumed,
  FulfillmentStatus.DeliveryError,
]

const INITIAL_STATE: GraphqlFilterState = {
  orderBy: { key: 'fulfillmentEvents_placed', direction: OrderByDirection.Descending },
  filter: {},
  meta: {},
}

const STATIC_VARIABLES = {
  limit: 200,
}

const TRANSFORMS = {
  fulfillmentEvents_placed: GraphqlFilterTransform.Between,
  itemName: GraphqlFilterTransform.Contains,
  status: GraphqlFilterTransform.In,
  created: GraphqlFilterTransform.Between,
}

export const FILTER_OPTIONS = {
  initialState: INITIAL_STATE,
  staticVariables: STATIC_VARIABLES,
  transforms: TRANSFORMS,
  persistKey: StorageKeys.PostalFulfillmentsFilter,
}

interface ColumnProps extends PostalFulfillmentFragment {
  handleRowClick: () => void
  placed?: string
  variantName?: string
}

export const SEARCH_COLUMNS = [
  {
    key: 'itemName',
    label: 'Items',
    render: ({ itemName, previewImage, handleRowClick }: ColumnProps) => (
      <UiLink onClick={handleRowClick}>
        <Flex alignItems="center">
          {previewImage && (
            <Box
              mr={4}
              boxShadow="postal"
              h="50px"
              w="70px"
              overflow="hidden"
              backgroundImage={`url(${assetUrlToSrc({ assetUrl: previewImage, accountId: '' })})`}
              backgroundSize="cover"
              borderRadius="postal"
            />
          )}
          <UiTruncate
            text={itemName || '-'}
            length={50}
            showTooltip
          />
        </Flex>
      </UiLink>
    ),
  },
  {
    key: 'placed',
    label: 'Order Placed',
    orderBy: 'fulfillmentEvents_placed',
    render: ({ placed }: ColumnProps) => {
      return (
        <UiDateTime
          date={placed}
          format={{
            weekday: 'short',
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          }}
          fallback="-"
        />
      )
    },
  },
  {
    key: 'trackingNumber',
    label: 'Tracking #',
    render: ({ trackingNumber }: ColumnProps) =>
      !!trackingNumber ? <Text data-private>{trackingNumber}</Text> : <Text>-----</Text>,
  },
  {
    key: 'cost.gmvExpense',
    label: 'Total',
    render: ({ cost }: ColumnProps) => (
      <UiMoney
        cents={cost?.gmvExpense}
        placeholder="-"
      />
    ),
  },

  {
    key: 'shipToName',
    label: 'Customer',
    render: ({ shipToName }: ColumnProps) => <span data-private>{shipToName}</span>,
  },
  {
    key: 'status',
    label: 'Status',
    render: ({ status }: ColumnProps) => {
      return <VendorStatusTag {...fulfillmentStatusMapper(status)} />
    },
  },
]

export const EVENT_COLUMNS = [
  {
    key: 'itemName',
    label: 'Items',
    render: ({ itemName, previewImage, handleRowClick }: ColumnProps) => (
      <UiLink onClick={handleRowClick}>
        <Flex alignItems="center">
          {previewImage && (
            <Box
              mr={4}
              boxShadow="postal"
              h="50px"
              w="70px"
              overflow="hidden"
              backgroundImage={`url(${assetUrlToSrc({ assetUrl: previewImage, accountId: '' })})`}
              backgroundSize="cover"
              borderRadius="postal"
            />
          )}
          <UiTruncate
            text={itemName || '-'}
            length={50}
            showTooltip
          />
        </Flex>
      </UiLink>
    ),
  },
  {
    key: 'variantName',
    label: 'Variant Name',
    render: ({ variantName }: ColumnProps) => <span data-private>{variantName}</span>,
  },
  {
    key: 'placed',
    label: 'Order Placed',
    orderBy: 'fulfillmentEvents_placed',
    render: ({ placed }: ColumnProps) => {
      return (
        <UiDateTime
          date={placed}
          format={{
            weekday: 'short',
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          }}
          fallback="-"
        />
      )
    },
  },
  {
    key: 'trackingNumber',
    label: 'Tracking #',
    render: ({ trackingNumber }: ColumnProps) =>
      !!trackingNumber ? <Text data-private>{trackingNumber}</Text> : <Text>-----</Text>,
  },
  {
    key: 'cost.gmvExpense',
    label: 'Total',
    render: ({ cost }: ColumnProps) => (
      <UiMoney
        cents={cost?.gmvExpense}
        placeholder="-"
      />
    ),
  },

  {
    key: 'shipToName',
    label: 'Customer',
    render: ({ shipToName }: ColumnProps) => <span data-private>{shipToName}</span>,
  },
  {
    key: 'status',
    label: 'Status',
    render: ({ status }: ColumnProps) => {
      return <VendorStatusTag {...fulfillmentStatusMapper(status)} />
    },
  },
]

export enum ActionType {
  CONFIRM_ORDER,
  CANCEL_ORDER,
  EXPORT_ORDER,
}

export const BUTTONS = [
  {
    type: ActionType.CONFIRM_ORDER,
    icon: <CheckIcon />,
    label: 'Confirm Selected',
    placement: 'top',
  },
  {
    type: ActionType.EXPORT_ORDER,
    icon: <UiIconDownload />,
    label: 'Export Selected',
    placement: 'top',
  },
]

export const HISTORY_COLUMNS = [
  {
    key: 'created',
    label: 'Date/Time',
    render: ({ created }: FulfillmentHistoryFragment) => (
      <CellWrapper>
        <UiDateTime
          date={created}
          format={{ dateStyle: 'medium', timeStyle: 'short' }}
          fallback="-"
        />
      </CellWrapper>
    ),
  },
  {
    key: 'notes',
    label: 'Notes',
    render: ({ notes }: FulfillmentHistoryFragment) => <CellWrapper data-private>{notes}</CellWrapper>,
  },
  {
    key: 'status',
    label: 'Status',
    render: ({ status }: FulfillmentHistoryFragment) => {
      return (
        <CellWrapper>
          <VendorStatusTag {...fulfillmentStatusMapper(status)} />
        </CellWrapper>
      )
    },
  },
]

export const CARRIERS: { value: string; label: string }[] = [
  { value: 'Amazon', label: 'Amazon Logistics' },
  { value: 'AmazonMws', label: 'AmazonMws' },
  { value: 'APC', label: 'APC' },
  { value: 'Asendia', label: 'Asendia' },
  { value: 'AsendiaUsa', label: 'Asendia USA' },
  { value: 'ATSHealthcareCA', label: 'ATS Healthcare (Canada)' },
  { value: 'AustraliaPost', label: 'Australia Post' },
  { value: 'AxlehireV3', label: 'AxlehireV3' },
  { value: 'BetterTrucks', label: 'Better Trucks' },
  { value: 'Bond', label: 'Bond' },
  { value: 'Cainiao', label: 'Cainiao' },
  { value: 'CanadaPost', label: 'Canada Post' },
  { value: 'Canpar', label: 'Canpar' },
  { value: 'ColumbusLastMile', label: 'CDL Last Mile Solutions' },
  { value: 'Chronopost', label: 'Chronopost' },
  { value: 'CourierExpress', label: 'Courier Express' },
  { value: 'CouriersPlease', label: 'CouriersPlease' },
  { value: 'DaiPost', label: 'Dai Post' },
  { value: 'DeutschePost', label: 'DeutschePost' },
  { value: 'DeutschePostUK', label: 'Deutsche Post UK' },
  { value: 'DHLEcommerceAsia', label: 'DHL eCommerce Asia' },
  { value: 'DhlEcs', label: 'DHL eCommerce Solutions' },
  { value: 'DHLExpress', label: 'DHL Express' },
  { value: 'DPD', label: 'DPD' },
  { value: 'DPDUK', label: 'DPDUK' },
  { value: 'ePostGlobal', label: 'ePost Global' },
  { value: 'Estafeta', label: 'Estafeta' },
  { value: 'Fastway', label: 'Fastway' },
  { value: 'FedEx', label: 'FedEx' },
  { value: 'FedExCrossBorder', label: 'FedEx Cross Border' },
  { value: 'FedExMailview', label: 'FedEx Mailview' },
  { value: 'FedExSameDayCity', label: 'FedEx SameDay City' },
  { value: 'FedexSmartPost', label: 'Fedex SmartPost' },
  { value: 'FirstMile', label: 'FirstMile' },
  { value: 'Globegistics', label: 'Globegistics' },
  { value: 'GSO', label: 'GSO' },
  { value: 'Hermes', label: 'Hermes' },
  { value: 'InterlinkExpress', label: 'InterlinkExpress' },
  { value: 'JPPost', label: 'JPPost' },
  { value: 'KuronekoYamato', label: 'Kuroneko Yamato' },
  { value: 'LaPoste', label: 'La Poste' },
  { value: 'LaserShipV2', label: 'LaserShip' },
  { value: 'LoomisExpress', label: 'Loomis Express' },
  { value: 'LSO', label: 'LSO' },
  { value: 'Newgistics', label: 'Newgistics' },
  { value: 'OnTrac', label: 'OnTrac' },
  { value: 'OsmWorldwide', label: 'Osm Worldwide' },
  { value: 'Parcelforce', label: 'Parcelforce' },
  { value: 'PassportGlobal', label: 'Passport' },
  { value: 'PcfFinalMile', label: 'PCF Final Mile' },
  { value: 'PostNL', label: 'PostNL' },
  { value: 'Purolator', label: 'Purolator' },
  { value: 'RoyalMail', label: 'Royal Mail' },
  { value: 'OmniParcel', label: 'OmniParcel' },
  { value: 'SFExpress', label: 'SFExpress' },
  { value: 'SpeeDee', label: 'SpeeDee' },
  { value: 'StarTrack', label: 'StarTrack' },
  { value: 'TForce', label: 'TForce' },
  { value: 'UDS', label: 'UDS' },
  { value: 'UPS', label: 'UPS' },
  { value: 'UPSIparcel', label: 'UPS i-parcel' },
  { value: 'UPSMailInnovations', label: 'UPS Mail Innovations' },
  { value: 'USPS', label: 'USPS' },
  { value: 'Veho', label: 'Veho' },
  { value: 'Yanwen', label: 'Yanwen' },
]
