import { ArrowBackIcon } from '@chakra-ui/icons'
import { Container, ContainerProps, IconButtonProps, IconProps } from '@chakra-ui/react'
import { UiIconButton } from '@postal-io/postal-ui'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export const Layout: React.FC<ContainerProps> = ({ children, ...rest }) => {
  return (
    <Container
      p={8}
      maxW="1800px"
      {...rest}
    >
      {children}
    </Container>
  )
}

interface BackButtonProps extends Omit<IconButtonProps, 'aria-label'> {
  iconProps?: IconProps
}

export const BackButton: React.FC<BackButtonProps> = ({ iconProps, ...rest }) => {
  const navigate = useNavigate()
  const { state } = useLocation() as any

  const handleBack = () => {
    return !!state.returnTo ? navigate(state.returnTo) : navigate(-1)
  }

  return (
    <UiIconButton
      p={4}
      border={'2px'}
      borderColor={'gray.300'}
      mr={4}
      maxW={'50px'}
      size={'sm'}
      colorScheme={'whiteAlpha'}
      icon={
        <ArrowBackIcon
          boxSize={'30px'}
          color={'primary.500'}
          {...iconProps}
        />
      }
      aria-label={'Go Back'}
      onClick={handleBack}
      {...rest}
    />
  )
}
