import { DownloadIcon } from '@chakra-ui/icons'
import { Image, Link } from '@chakra-ui/react'
import { UiCard, UiCardHeader, UiCardProps, UiIconButton, UiText } from '@postal-io/postal-ui'
import { assetUrlToSrc, getAccountLogoUrl } from 'api/rest'
import React, { useMemo } from 'react'

interface OrderCustomerLogoProps extends UiCardProps {
  accountId?: string
  orderId?: string
}

export const OrderCustomerLogo: React.FC<OrderCustomerLogoProps> = ({ accountId, orderId, ...rest }) => {
  const src = useMemo(() => {
    return accountId
      ? assetUrlToSrc({
          assetUrl: getAccountLogoUrl(accountId),
          accountId,
        })
      : null
  }, [accountId])

  return (
    <UiCard {...rest}>
      <UiCardHeader
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        Customer Logo
        {src && (
          <Link
            href={src}
            download={`${orderId}_Customer-Logo.png`}
          >
            <UiIconButton
              size="xs"
              colorScheme="secondary"
              aria-label="Download Logo"
              isDisabled={!src}
              icon={<DownloadIcon />}
            />
          </Link>
        )}
      </UiCardHeader>
      {src ? <Image src={src} /> : <UiText>Account logo not available</UiText>}
    </UiCard>
  )
}
