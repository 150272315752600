import { Stack } from '@chakra-ui/react'
import { useGraphqlInfiniteQuery } from '@postal-io/postal-graphql'
import { UiCard, UiSSDataTable, useAlertError, useGraphqlFilter } from '@postal-io/postal-ui'
import {
  PostalFulfillmentFilterInput,
  PostalFulfillmentFragment,
  SearchPostalFulfillmentsDocument,
  SearchPostalFulfillmentsQueryVariables,
} from 'api'
import { Layout } from 'components/Common/Layout'
import { useAcl, useCurrentPage, useQueryParams } from 'hooks'
import { StorageKeys } from 'lib'
import { cloneDeep, isEmpty, set } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDebounce } from 'use-debounce'
import { FILTER_OPTIONS, SEARCH_COLUMNS, SELECT_FULFILLMENT_STATUSES } from './ordersData'
import { OrdersFilter } from './OrdersFilter'
import { OrdersStatsCard } from './OrdersStatsCard'
import { OrdersSubNavbar } from './OrdersSubNavbar'

export const OrdersPage = () => {
  const navigate = useNavigate()
  const { hasFeature } = useAcl()
  const { state: useSavedPage } = useLocation()
  const [currentPage, setCurrentPage] = useCurrentPage(
    StorageKeys.VendorFulfillmentsCurrentPage,
    useSavedPage as boolean
  )

  const { params } = useQueryParams()

  // for bulk export
  const [selectedIds, setSelectedIds] = useState<string[]>([])

  // graphql filter hook
  const graphqlFilter = useGraphqlFilter<SearchPostalFulfillmentsQueryVariables>(FILTER_OPTIONS)

  const paramStatusList = useMemo(() => {
    return !!params.status ? (params.status as String).split(',') : []
  }, [params.status])

  useEffect(() => {
    if (!!params.status) {
      graphqlFilter.setFilter({ key: 'status', value: paramStatusList })
    }
    // prevent render loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  // debounce to accomodate typing
  const [debouncedVariables] = useDebounce(graphqlFilter.variables, 400)

  // and then make sure status filters are set
  const variables = useMemo(() => {
    const vars = cloneDeep(debouncedVariables)
    if (isEmpty(vars.filter?.status)) {
      set(vars, 'filter.status.in', SELECT_FULFILLMENT_STATUSES)
    }
    return vars
  }, [debouncedVariables])

  // search postal fulfillments
  const searchPostalFulfillments = useGraphqlInfiniteQuery(
    SearchPostalFulfillmentsDocument,
    variables,
    { refetchInterval: 300000, refetchIntervalInBackground: true, keepPreviousData: true } // 5 minutes
  )
  const postalFulfillments = useMemo(
    () => searchPostalFulfillments.mergedData?.searchPostalFulfillments ?? [],
    [searchPostalFulfillments.mergedData?.searchPostalFulfillments]
  )
  useAlertError(searchPostalFulfillments.error)

  const cleanedPostalFulfillments = useMemo(() => {
    return (
      postalFulfillments
        // only show orders with specific statuses
        ?.filter((pf) => SELECT_FULFILLMENT_STATUSES.includes(pf.status))
        ?.map((pf: PostalFulfillmentFragment) => ({
          id: pf.id,
          shipToName: pf.shipToName,
          itemName: pf.itemName,
          previewImage: pf.previewImage,
          cost: pf.cost,
          status: pf.status,
          trackingNumber: pf.trackingNumber,
          created: pf.created,
          placed: pf?.fulfillmentEvents?.placed,
          handleRowClick: () => navigate(`/orders/${pf.id}`),
        })) ?? []
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postalFulfillments])

  return (
    <>
      <OrdersSubNavbar
        refetch={searchPostalFulfillments.refetch}
        exportFilter={graphqlFilter.variables.filter as PostalFulfillmentFilterInput}
      />
      <Layout pt={0}>
        <Stack spacing={8}>
          {hasFeature('ordersStats') && (
            <OrdersStatsCard
              filter={graphqlFilter.filter}
              setFilter={graphqlFilter.setFilter}
              postalFulfillments={postalFulfillments}
              isLoading={searchPostalFulfillments.isLoading}
            />
          )}
          <UiCard
            boxShadow={'none'}
            paddingBottom="1rem !important"
          >
            <Stack spacing={4}>
              <OrdersFilter
                filter={graphqlFilter.filter}
                setFilter={graphqlFilter.setFilter}
                selectedIds={selectedIds}
                refetch={searchPostalFulfillments.refetch}
              />
              <UiSSDataTable
                variant="list"
                isLoading={searchPostalFulfillments.isFetching}
                columns={SEARCH_COLUMNS}
                rows={cleanedPostalFulfillments}
                rowKey="id"
                onSelect={(rows: any[]) => setSelectedIds(rows.map((row) => row.id))}
                showSelect
                filter={graphqlFilter.variables.filter}
                fetchMore={searchPostalFulfillments.fetchNextPage}
                hasMore={searchPostalFulfillments.hasNextPage}
                orderBy={graphqlFilter.orderBy}
                onOrderBy={graphqlFilter.setOrderBy}
                page={currentPage}
                setPage={setCurrentPage}
                pagerOptions={{ showFirstPage: true, hotkeysEnabled: true }}
              />
            </Stack>
          </UiCard>
        </Stack>
      </Layout>
    </>
  )
}
