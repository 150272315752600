/* eslint-disable no-useless-concat */
import { Flex, Grid, Text } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  UiCard,
  UiCardHeader,
  UiCardProps,
  UiDescriptionList,
  UiDescriptionListItem,
  UiDivider,
} from '@postal-io/postal-ui'
import {
  AddressFragment,
  Contact,
  ContactFragment,
  GetAccountByPostalFulfillmentDocument,
  PhoneType,
  PostalFulfillmentFragment,
} from 'api'
import { getAddress } from 'lib'
import React, { useMemo } from 'react'
import { VendorCopyLink } from '../Main'

export const generateFullNameString = (firstName?: string | null, lastName?: string | null) => {
  return firstName || lastName ? `${firstName ? firstName + ' ' : ''}${lastName ? lastName : ''}` : 'No Name'
}

export const generateRegionString = (address: AddressFragment) => {
  const { city, state, postalCode } = address || {}
  return address ? `${city}, ${state} ${postalCode}` : ''
}

const generateContactString = (contact: ContactFragment, shippingAddress?: any, accountAddress?: any) => {
  const { firstName, lastName } = contact ?? {}

  let result = generateFullNameString(firstName, lastName)

  result += '\n\n' + 'Shipping Address:'
  if (shippingAddress.address1) result += '\n' + shippingAddress.address1
  if (shippingAddress.address2) result += '\n' + shippingAddress.address2
  if (shippingAddress.address3) result += '\n' + shippingAddress.address3
  // eslint-disable-next-line no-useless-concat
  result += '\n' + generateRegionString(shippingAddress)
  if (shippingAddress.country) result += '\n' + shippingAddress.country
  result += accountAddress ? '\n\n' + 'Account Address:' : ''
  if (accountAddress?.address1) result += '\n' + accountAddress?.address1
  if (accountAddress?.address2) result += '\n' + accountAddress?.address2
  if (accountAddress?.address3) result += '\n' + accountAddress?.address3
  // eslint-disable-next-line no-useless-concat
  result += '\n' + generateRegionString(accountAddress)
  if (accountAddress?.country) result += '\n' + accountAddress?.country

  return result
}
interface ContactProfileProps extends UiCardProps {
  contact?: Contact
  postalFulfillment?: PostalFulfillmentFragment
}

export const ContactProfile: React.FC<ContactProfileProps> = ({ contact, postalFulfillment, ...rest }) => {
  // general contact information
  const { firstName, lastName, companyName, phones } = contact || {}

  // create full name string
  const fullNameStr = generateFullNameString(firstName, lastName)

  // primary address (from account)
  const getAccountByPostalFulfillment = useGraphqlQuery(
    GetAccountByPostalFulfillmentDocument,
    { id: postalFulfillment?.id },
    { enabled: !!postalFulfillment?.id }
  )
  const accountAddress = getAccountByPostalFulfillment?.data?.getAccountByPostalFulfillment?.address
  const accountAddressRegion = useMemo(() => generateRegionString(accountAddress as AddressFragment), [accountAddress])

  // shipping address (from the order)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const shippingAddress = getAddress(postalFulfillment?.shipTo)
  const shippingAddressRegion = useMemo(
    () => generateRegionString(shippingAddress as AddressFragment),
    [shippingAddress]
  )

  const {
    WORK: workPhone,
    CELL: cellPhone,
    HOME: homePhone,
    OTHER: otherPhone,
  }: any = useMemo(
    () => phones?.reduce((obj, p) => ({ ...obj, [p?.type as PhoneType]: p?.phoneNumber }), {} as any) ?? {},
    [phones]
  )

  return (
    <>
      <UiCard
        isLoading={!contact || !postalFulfillment}
        minH="200px"
        {...rest}
      >
        <Flex
          flexDir="column"
          h="100%"
        >
          <Flex justify="space-between">
            <UiCardHeader>Contact Info</UiCardHeader>

            <VendorCopyLink
              data-private
              text={generateContactString(contact as ContactFragment, shippingAddress, accountAddress)}
              variant="card"
            />
          </Flex>

          <UiDivider mt={2} />

          <UiDescriptionList>
            <Grid
              mt={4}
              templateColumns="1fr"
              gridGap={4}
            >
              <UiDescriptionListItem title="Name">
                <VendorCopyLink
                  text={fullNameStr}
                  variant="text"
                  data-private
                >
                  {fullNameStr}
                </VendorCopyLink>
              </UiDescriptionListItem>

              {companyName && (
                <UiDescriptionListItem title="Company">
                  <VendorCopyLink
                    text={companyName}
                    variant="text"
                  >
                    {companyName}
                  </VendorCopyLink>
                </UiDescriptionListItem>
              )}

              {workPhone && (
                <UiDescriptionListItem title="Work Phone">
                  <VendorCopyLink
                    text={workPhone}
                    variant="text"
                    data-private
                  >
                    {workPhone}
                  </VendorCopyLink>
                </UiDescriptionListItem>
              )}

              {homePhone && (
                <UiDescriptionListItem title="Home Phone">
                  <VendorCopyLink
                    text={homePhone}
                    variant="text"
                    data-private
                  >
                    {homePhone}
                  </VendorCopyLink>
                </UiDescriptionListItem>
              )}

              {cellPhone && (
                <UiDescriptionListItem title="Cell Phone">
                  <VendorCopyLink
                    text={cellPhone}
                    variant="text"
                    data-private
                  >
                    {cellPhone}
                  </VendorCopyLink>
                </UiDescriptionListItem>
              )}

              {otherPhone && (
                <UiDescriptionListItem title="Other Phone">
                  <VendorCopyLink
                    text={otherPhone}
                    variant="text"
                    data-private
                  >
                    {otherPhone}
                  </VendorCopyLink>
                </UiDescriptionListItem>
              )}

              <UiDivider mt={2} />

              <UiDescriptionListItem title="Shipping Address">
                {shippingAddress?.address1 && (
                  <VendorCopyLink
                    text={shippingAddress.address1}
                    variant="text"
                    data-private
                  >
                    {shippingAddress?.address1}
                  </VendorCopyLink>
                )}

                {shippingAddress?.address2 && (
                  <VendorCopyLink
                    text={shippingAddress.address2}
                    variant="text"
                    data-private
                  >
                    {shippingAddress.address2}
                  </VendorCopyLink>
                )}

                {shippingAddress?.address3 && (
                  <VendorCopyLink
                    text={shippingAddress.address3}
                    variant="text"
                    data-private
                  >
                    {shippingAddress.address3}
                  </VendorCopyLink>
                )}

                {shippingAddressRegion && (
                  <VendorCopyLink
                    text={shippingAddressRegion}
                    variant="text"
                    data-private
                  >
                    {shippingAddressRegion}
                  </VendorCopyLink>
                )}

                {shippingAddress?.country && (
                  <VendorCopyLink
                    text={shippingAddress.country}
                    variant="text"
                    data-private
                  >
                    {shippingAddress.country}
                  </VendorCopyLink>
                )}
              </UiDescriptionListItem>

              <UiDivider mt={2} />

              <UiDescriptionListItem title="Account Address">
                {accountAddress ? (
                  <>
                    <VendorCopyLink
                      text={accountAddress?.address1}
                      variant="text"
                    >
                      {accountAddress?.address1}
                    </VendorCopyLink>

                    <VendorCopyLink
                      text={accountAddress?.address2}
                      variant="text"
                    >
                      {accountAddress?.address2}
                    </VendorCopyLink>

                    <VendorCopyLink
                      text={accountAddress?.address3}
                      variant="text"
                    >
                      {accountAddress?.address3}
                    </VendorCopyLink>

                    <VendorCopyLink
                      text={accountAddressRegion}
                      variant="text"
                    >
                      {accountAddressRegion}
                    </VendorCopyLink>

                    <VendorCopyLink
                      text={accountAddress?.country}
                      variant="text"
                    >
                      {accountAddress?.country}
                    </VendorCopyLink>
                  </>
                ) : (
                  <Text fontStyle="italic">not available</Text>
                )}
              </UiDescriptionListItem>
            </Grid>
          </UiDescriptionList>
        </Flex>
      </UiCard>
    </>
  )
}
