import { Grid } from '@chakra-ui/react'
import { UiCard, UiCardHeader, UiCardProps, UiInfoTooltip } from '@postal-io/postal-ui'
import React from 'react'
import { IntegrationCard } from '../Integrations'

type System = 'shopify'

const SYSTEMS: System[] = ['shopify']

export const ProfileIntegrationsCard: React.FC<UiCardProps> = () => {
  return (
    <UiCard boxShadow={'none'}>
      <UiCardHeader>
        Integrations
        <UiInfoTooltip
          hasArrow
          ml={2}
          placement="right"
          label="Managers can connect & sync integrations"
        />
      </UiCardHeader>
      <Grid templateColumns="1fr 1fr 1fr">
        {SYSTEMS.map((s) => (
          <IntegrationCard
            key={s}
            system={s}
            onClick={() => {}}
          />
        ))}
      </Grid>
    </UiCard>
  )
}
