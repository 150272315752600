import { AddIcon, RepeatIcon } from '@chakra-ui/icons'
import { Box, BoxProps, ButtonGroup, Image, ScaleFade, useDisclosure, VStack } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import { UiButton, UiCard, UiIconPostalEdit, useAlerts } from '@postal-io/postal-ui'

import {
  SearchIntegrationSyncDocument,
  SearchShopifyCollectionsDocument,
  SelectShopifyCollectionDocument,
  ShopifyCollection,
} from 'api'
import shopifyLogo from 'assets/shopify.png'
import { DraftNoShopifyCollectionModal } from 'components/Drafts'
import { useAcl } from 'hooks'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useBackgroundQueue } from '../../hooks/useBackground'
import { IntegrationEditModal } from './IntegrationEditModal'

export const systemImageMap: Record<string, string> = {
  shopify: shopifyLogo,
}

interface IntegrationCardProps extends BoxProps {
  system: 'shopify'
  onClick: () => void
}

export const IntegrationCard: React.FC<IntegrationCardProps> = ({ system }) => {
  const noShopifyCollectionDisclosure = useDisclosure()
  const { invalidate } = useBackgroundQueue()
  const Alert = useAlerts()
  const navigate = useNavigate()
  const { queue, tasks } = useBackgroundQueue()
  const { hasPermission } = useAcl()
  const isManager = hasPermission('integrations.create')
  const editIntegrationDisclosure = useDisclosure()
  const [isSyncLoading, setIsSyncLoading] = useState<boolean>(
    tasks.some((task) => task.task.taskAction === 'ProductSync')
  )

  const syncShopifyCollection = useGraphqlMutation(SelectShopifyCollectionDocument, {
    onSuccess: (data) => {
      queue(data.selectShopifyCollection, () => {
        setIsSyncLoading(false)
        navigate('/products')
        invalidate('searchDraftMarketplaceProduct')
        Alert.success('Products imported')
      })
    },
  })

  const searchIntegrationSync = useGraphqlQuery(
    SearchIntegrationSyncDocument,
    {
      filter: { system: { eq: system } },
    },
    {
      enabled: isManager,
    }
  )
  const isConnected = (searchIntegrationSync.data?.searchIntegrationSync?.length ?? 0) > 0

  const searchCollections = useGraphqlQuery(SearchShopifyCollectionsDocument, {
    before: '',
    after: '',
  })

  const { collectionList } = searchCollections.data?.searchShopifyCollections || ({} as ShopifyCollection)

  const handleSync = async () => {
    try {
      if (isConnected && !!collectionList?.length) {
        navigate('/products/shopifycollection')
      } else {
        setIsSyncLoading(true)
        const res = await syncShopifyCollection.mutateAsync({ shopifyCollectionId: '' })
        if (!res.selectShopifyCollection.errors?.length)
          Alert.info('Integration synced, products will be imported shortly')
      }
    } catch (err) {
      setIsSyncLoading(false)
      Alert.error(err)
    } finally {
      if (noShopifyCollectionDisclosure.isOpen) noShopifyCollectionDisclosure.onClose()
    }
  }

  const handleSyncClick = () => {
    if (!collectionList?.length) {
      noShopifyCollectionDisclosure.onOpen()
    } else {
      handleSync()
    }
  }

  return (
    <ScaleFade in>
      <UiCard
        boxShadow="none"
        w="325px"
        isLoading={searchIntegrationSync.isLoading}
      >
        <Box w="100%">
          <Image
            mb={4}
            src={systemImageMap[system]}
            display="block"
            mx="auto"
          />
          <ButtonGroup
            w="100%"
            mt={2}
          >
            <VStack w="100%">
              <UiButton
                w="100%"
                colorScheme={isConnected ? 'primary' : 'gray'}
                size="md"
                onClick={editIntegrationDisclosure.onOpen}
                isDisabled={!isManager}
                leftIcon={isConnected ? <UiIconPostalEdit /> : <AddIcon />}
              >
                {isConnected ? 'Edit Connection' : 'Connect'}
              </UiButton>

              {isConnected && (
                <UiButton
                  mt={2}
                  w="100%"
                  colorScheme={isConnected ? 'secondary' : 'gray'}
                  size="md"
                  onClick={handleSyncClick}
                  leftIcon={<RepeatIcon />}
                  isLoading={isSyncLoading || searchCollections.isLoading}
                  isDisabled={!isManager || isSyncLoading}
                >
                  Sync Now
                </UiButton>
              )}
            </VStack>
          </ButtonGroup>
        </Box>
      </UiCard>

      {editIntegrationDisclosure.isOpen && (
        <IntegrationEditModal
          handleNoCollection={noShopifyCollectionDisclosure.onOpen}
          {...editIntegrationDisclosure}
        />
      )}

      {noShopifyCollectionDisclosure.isOpen && (
        <DraftNoShopifyCollectionModal
          handleConfirm={handleSync}
          isSyncLoading={isSyncLoading}
          {...noShopifyCollectionDisclosure}
        />
      )}
    </ScaleFade>
  )
}
