import { AddIcon } from '@chakra-ui/icons'
import { Flex, Grid, useClipboard, useDisclosure } from '@chakra-ui/react'
import { useGraphqlInfiniteQuery } from '@postal-io/postal-graphql'
import {
  UiButton,
  UiCard,
  UiCardHeader,
  UiIconDownload,
  UiSSDataTable,
  useAlertError,
  useUiAlerts,
} from '@postal-io/postal-ui'

import { ReportType, SearchPostalFulfillmentsDocument, SearchPostalFulfillmentsQueryVariables } from 'api'
import { OrderImports } from 'components/Orders/OrderImports'
import { useReport } from 'hooks'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { EVENT_COLUMNS, SELECT_FULFILLMENT_STATUSES } from '../Orders/ordersData'

interface EventOrderProps {
  approvedPostalId: string
  variantName: string
}

export const EventOrders: React.FC<EventOrderProps> = ({ approvedPostalId, variantName }) => {
  const Alert = useUiAlerts()
  const navigate = useNavigate()
  const orderImportsDisclosure = useDisclosure()
  const { createReport, isLoading: reportIsLoading } = useReport(ReportType.PostalFulfillmentPartner)

  // copy formatted contact string to clipboard
  const [clipboardContent, setClipboardContent] = useState<string>('')
  const { onCopy } = useClipboard(clipboardContent)
  useEffect(() => {
    if (clipboardContent) {
      onCopy && onCopy()
      Alert.success('Text copied to clipboard')
      setClipboardContent('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clipboardContent])

  const variables: SearchPostalFulfillmentsQueryVariables = useMemo(
    () => ({
      filter: {
        approvedPostalId: { eq: approvedPostalId },
        status: { in: SELECT_FULFILLMENT_STATUSES },
      },
      limit: 100,
    }),
    [approvedPostalId]
  )

  // search postal fulfillments
  const searchFulfillments = useGraphqlInfiniteQuery(SearchPostalFulfillmentsDocument, variables, {
    enabled: !!approvedPostalId,
  })
  const postalFulfillments = useMemo(
    () => searchFulfillments.mergedData?.searchPostalFulfillments || [],
    [searchFulfillments.mergedData?.searchPostalFulfillments]
  )

  const cleanedPostalFulfillments = useMemo(() => {
    return (
      postalFulfillments.map((pf) => ({
        id: pf.id,
        itemName: pf.itemName,
        history: pf.history,
        shipToName: pf.shipToName,
        trackingNumber: pf.trackingNumber,
        status: pf.status,
        variantName: variantName,
        handleRowClick: () => navigate(`/orders/${pf.id}`),
        handleCopy: (text: string) => setClipboardContent(text),
      })) ?? []
    )
  }, [navigate, postalFulfillments, variantName])

  const handleReport = async () => {
    await createReport(variables.filter)
  }

  useAlertError(searchFulfillments.error)

  return (
    <>
      <UiCard boxShadow={'none'}>
        <UiCardHeader
          as={Flex}
          alignItems="center"
          justifyContent="space-between"
        >
          Event Orders
          <Grid
            gap={4}
            templateColumns="200px 200px"
          >
            <UiButton
              colorScheme="primary"
              leftIcon={<AddIcon />}
              onClick={() => orderImportsDisclosure.onOpen()}
              variant="outline"
            >
              Bulk Import
            </UiButton>

            <UiButton
              variant="outline"
              colorScheme="secondary"
              leftIcon={<UiIconDownload />}
              onClick={handleReport}
              isLoading={reportIsLoading}
              isDisabled={reportIsLoading}
            >
              Bulk Export
            </UiButton>
          </Grid>
        </UiCardHeader>
        <UiSSDataTable
          variant="list"
          isLoading={searchFulfillments.isFetching}
          columns={EVENT_COLUMNS}
          rows={cleanedPostalFulfillments}
          rowKey="id"
          filter={variables.filter}
          fetchMore={searchFulfillments.fetchNextPage}
          hasMore={searchFulfillments.hasNextPage}
          pageSize={10}
        />
      </UiCard>
      {orderImportsDisclosure.isOpen && (
        <OrderImports
          isOpen={orderImportsDisclosure.isOpen}
          onClose={orderImportsDisclosure.onClose}
          onSuccess={searchFulfillments.refetch}
        />
      )}
    </>
  )
}
