import { ModalProps } from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import {
  UiButton,
  UiModal,
  UiModalBody,
  UiModalCloseButton,
  UiModalContent,
  UiModalFooter,
  UiModalFooterButtons,
  UiModalHeader,
  UiModalOverlay,
  UiText,
  useAlerts,
} from '@postal-io/postal-ui'
import { DraftMarketplaceProductFragment } from 'api/index'
import { BulkDeleteDraftMarketplaceProductsDocument } from '../../api/index'

interface DraftProductsBulkDeleteModalProps extends Omit<ModalProps, 'children'> {
  drafts: DraftMarketplaceProductFragment[]
  selectedDrafts: DraftMarketplaceProductFragment[]
  idsFilter: 'in' | 'nin'
  onSuccess(): void
  onClose(): void
}

export const DraftProductsBulkDeleteModal: React.FC<DraftProductsBulkDeleteModalProps> = ({
  drafts,
  selectedDrafts,
  idsFilter,
  onSuccess,
  ...rest
}) => {
  const Alert = useAlerts()

  const deleteDrafts = useGraphqlMutation(BulkDeleteDraftMarketplaceProductsDocument)

  const handleDeleteDrafts = async () => {
    rest.onClose()
    const selectedIds = selectedDrafts.map((draft) => draft.id)
    const notSelectedIds = drafts.filter((draft) => !selectedIds.includes(draft.id)).map((draft) => draft.id)
    try {
      const { bulkDeleteDraftMarketplaceProducts: numDeleted } = await deleteDrafts.mutateAsync({
        filter: { id: { [idsFilter]: idsFilter === 'in' ? selectedIds : notSelectedIds } },
      })
      if (numDeleted === 0) Alert.warning('No products were deleted')
      else Alert.success(`Deleted ${numDeleted} product${numDeleted > 1 ? 's' : ''}`)
      onSuccess()
    } catch (err) {
      Alert.error(err)
    } finally {
      onSuccess()
    }
  }

  return (
    <UiModal
      size="xl"
      {...rest}
      scrollBehavior="inside"
    >
      <UiModalOverlay />
      <UiModalContent>
        <form onSubmit={handleDeleteDrafts}>
          <UiModalHeader
            fontSize="lg"
            fontWeight="bold"
          >
            Bulk Delete Products
          </UiModalHeader>
          <UiModalCloseButton />
          <UiModalBody
            overflow="visible"
            textAlign="center"
          >
            <UiText>
              {selectedDrafts.length} <strong>product{selectedDrafts.length > 1 ? 's' : ''}</strong> will be{' '}
              <strong>deleted</strong> from the Vendor application.
              <br />
              <br />
              Please confirm product deletion. This cannot be undone.
            </UiText>
          </UiModalBody>
          <UiModalFooter>
            <UiModalFooterButtons>
              <UiButton
                colorScheme="gray"
                onClick={rest.onClose}
              >
                Cancel
              </UiButton>
              <UiButton
                type="submit"
                colorScheme="red"
                isLoading={deleteDrafts.isLoading}
                isDisabled={deleteDrafts.isLoading}
              >
                Confirm
              </UiButton>
            </UiModalFooterButtons>
          </UiModalFooter>
        </form>
      </UiModalContent>
    </UiModal>
  )
}
