import { Flex, Grid, Heading, useDisclosure } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  UiActionMenu,
  UiCard,
  UiDivider,
  UiFormControl,
  UiFormLabel,
  UiInput,
  UiSwitch,
  UiTextarea,
  useAlertError,
  useUiAlerts,
} from '@postal-io/postal-ui'
import {
  FulfillmentPartnerFragment,
  GetFulfillmentPartnerDocument,
  MeDocument,
  UpdateFulfillmentPartnerDocument,
  UpdateSelfDocument,
  UserAccountFragment,
} from 'api'
import {
  CancelFormModal,
  DropBar,
  DropBarPrimaryButton,
  DropBarSecondaryButton,
  Layout,
  SubNavbar,
} from 'components/Common'
import { SidebarNav } from 'components/Common/SidebarNav'
import { useAcl } from 'hooks'
import { isEqual } from 'lodash'
import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react'
import { useImmer } from 'use-immer'
import { ProfileIntegrationsCard } from './ProfileIntegrationsCard'
import { ProfileNotifications } from './ProfileNotifications'
import { ProfileSidebar } from './ProfileSidebar'
import { ProfileUpdatePassword } from './ProfileUpdatePassword'

interface FormProps {
  companyName: string
  description?: string
  firstName: string
  lastName: string
  keywords: any[]
  emailAddress?: string
  phoneNumber?: string
}

export const Profile: React.FC = () => {
  const cancelDisclosure = useDisclosure()
  const passwordDisclosure = useDisclosure()
  const Alert = useUiAlerts()
  const { hasPermission } = useAcl()

  //User Info
  const isManager = hasPermission('profile.create')
  const updateSelf = useGraphqlMutation(UpdateSelfDocument)
  const meQuery = useGraphqlQuery(MeDocument)
  const user = meQuery.data?.me as UserAccountFragment
  useAlertError(meQuery.error)

  //Company (fulfillment partner) info
  const companyQuery = useGraphqlQuery(GetFulfillmentPartnerDocument)
  const company = companyQuery.data?.getFulfillmentPartner as FulfillmentPartnerFragment
  const updateCompany = useGraphqlMutation(UpdateFulfillmentPartnerDocument)
  useAlertError(companyQuery.error)

  const actionItems = [{ title: 'Update Password', onClick: passwordDisclosure.onOpen }]

  //Form Management
  const [form, setForm] = useImmer<FormProps>({} as FormProps)
  const [isDirty, setIsDirty] = useState(false)
  const formRef = useRef({} as FormProps)

  const handleOnSubmit = async (e: FormEvent) => {
    e.preventDefault()
    const { description, keywords, companyName, ...userFields } = form
    try {
      await updateSelf.mutateAsync({ data: userFields })
      isManager && (await updateCompany.mutateAsync({ data: { description, name: companyName, keywords } }))
      Alert.success('Profile updated')
    } catch (err) {
      Alert.error(err)
    }
  }

  useEffect(() => {
    const dataObject = {
      companyName: company?.name ?? '',
      description: company?.description ?? '',
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      keywords: company?.keywords ?? [],
      emailAddress: user?.emailAddress ?? '',
      phoneNumber: user?.phoneNumber ?? '',
    }
    setForm(dataObject)
    formRef.current = dataObject
  }, [setForm, user, company])

  // checking to see if form was modified
  useEffect(() => {
    if (!isEqual(form, formRef.current)) {
      setIsDirty(true)
    } else {
      setIsDirty(false)
    }
  }, [form])

  const handleFormInput = ({ target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = target
    setForm((draft: any) => void (draft[name] = value))
  }

  const handleSwitch = (value: string) => {
    let keywordList = [...form.keywords]
    if (!!keywordList.length && keywordList.includes(value)) {
      keywordList = keywordList.filter((term) => term !== value)
    } else {
      keywordList.push(value)
    }
    setForm((draft) => void (draft.keywords = keywordList))
  }

  const handleCancel = () => {
    if (Object.keys(formRef.current).length > 0) {
      setForm(formRef.current as FormProps)
    }
    setIsDirty(false)
    cancelDisclosure.onClose()
  }

  const isLoading = meQuery.isLoading || companyQuery.isLoading

  return (
    <>
      <Layout pt={0}>
        <DropBar isDirty={isDirty}>
          <DropBarSecondaryButton onClick={cancelDisclosure.onOpen}>Cancel</DropBarSecondaryButton>
          <DropBarPrimaryButton
            type="submit"
            form="profileUpdateForm"
          >
            Update Profile
          </DropBarPrimaryButton>
        </DropBar>
        <SidebarNav blocks={<ProfileSidebar />}>
          <SubNavbar
            left={<Heading fontSize="2xl">Company Information</Heading>}
            right={<UiActionMenu actionItems={actionItems} />}
            boxShadow="none"
            border="none"
            maxW="1800px"
            containerProps={{ p: 0 }}
            gridProps={{ py: 4, px: 0 }}
            mb={0}
          />
          <UiCard
            boxShadow={'none'}
            isLoading={isLoading}
          >
            <form
              onSubmit={handleOnSubmit}
              id="profileUpdateForm"
            >
              <Grid gap={8}>
                <Grid
                  gap={8}
                  gridTemplateColumns={'repeat(auto-fit, minmax(250px, 1fr))'}
                >
                  <UiFormControl
                    id="companyName"
                    isDisabled={!isManager}
                  >
                    <UiFormLabel>Company Name</UiFormLabel>
                    <UiInput
                      name="companyName"
                      onChange={handleFormInput}
                      value={form.companyName || ''}
                    />
                  </UiFormControl>
                  <UiFormControl id="firstName">
                    <UiFormLabel>First Name</UiFormLabel>
                    <UiInput
                      name="firstName"
                      onChange={handleFormInput}
                      value={form.firstName || ''}
                    />
                  </UiFormControl>
                  <UiFormControl id="lastName">
                    <UiFormLabel>Last Name</UiFormLabel>
                    <UiInput
                      name="lastName"
                      onChange={handleFormInput}
                      value={form.lastName || ''}
                    />
                  </UiFormControl>
                </Grid>
                <Grid
                  gap={8}
                  gridTemplateColumns={'repeat(auto-fit, minmax(250px, 1fr))'}
                >
                  <UiFormControl id="emailAddress">
                    <UiFormLabel>Email</UiFormLabel>
                    <UiInput
                      name="emailAddress"
                      onChange={handleFormInput}
                      value={form.emailAddress || ''}
                    />
                  </UiFormControl>
                  <UiFormControl id="phoneNumber">
                    <UiFormLabel>Phone</UiFormLabel>
                    <UiInput
                      name="phoneNumber"
                      onChange={handleFormInput}
                      value={form.phoneNumber || ''}
                    />
                  </UiFormControl>
                </Grid>
                <UiFormControl
                  id="description"
                  isDisabled={!isManager}
                >
                  <UiFormLabel>Company Bio</UiFormLabel>
                  <UiTextarea
                    name="description"
                    onChange={handleFormInput}
                    value={form.description || ''}
                    maxLength={500}
                  />
                </UiFormControl>
                <UiDivider my={4} />
                <Grid
                  gridTemplateColumns={'repeat(auto-fit, minmax(100px, 200px))'}
                  gap={4}
                >
                  <UiFormControl
                    id="BIPOC Owned"
                    isDisabled={!isManager}
                  >
                    <Flex alignItems={'center'}>
                      <UiSwitch
                        mr={2}
                        display="block"
                        name="BIPOC Owned"
                        size="md"
                        isChecked={!!form.keywords?.length && form?.keywords?.indexOf('BIPOC Owned') !== -1}
                        onChange={({ target }) => handleSwitch(target.name)}
                      />
                      <UiFormLabel m={0}>BIPOC Owned</UiFormLabel>
                    </Flex>
                  </UiFormControl>
                  <UiFormControl
                    id="Veteran Owned"
                    isDisabled={!isManager}
                  >
                    <Flex alignItems={'center'}>
                      <UiSwitch
                        mr={2}
                        display="block"
                        name="Veteran Owned"
                        size="md"
                        isChecked={!!form.keywords?.length && form.keywords.indexOf('Veteran Owned') !== -1}
                        onChange={({ target }) => handleSwitch(target.name)}
                      />
                      <UiFormLabel m={0}>Veteran Owned</UiFormLabel>
                    </Flex>
                  </UiFormControl>
                  <UiFormControl
                    id="Veteran Owned"
                    isDisabled={!isManager}
                  >
                    <Flex alignItems={'center'}>
                      <UiSwitch
                        mr={2}
                        display="block"
                        name="Woman Owned"
                        size="md"
                        isChecked={!!form.keywords?.length && form.keywords.indexOf('Woman Owned') !== -1}
                        onChange={({ target }) => handleSwitch(target.name)}
                      />
                      <UiFormLabel m={0}>Woman Owned</UiFormLabel>
                    </Flex>
                  </UiFormControl>
                </Grid>
              </Grid>
            </form>
          </UiCard>
          <Grid
            templateColumns={'1fr 1fr'}
            gap={8}
            mt={8}
          >
            <ProfileIntegrationsCard />
            <ProfileNotifications />
          </Grid>
        </SidebarNav>
        {cancelDisclosure.isOpen && (
          <CancelFormModal
            onConfirm={handleCancel}
            {...cancelDisclosure}
          />
        )}
        {passwordDisclosure.isOpen && (
          <ProfileUpdatePassword
            user={user}
            {...passwordDisclosure}
          />
        )}
      </Layout>
    </>
  )
}
