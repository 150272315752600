import { Flex } from '@chakra-ui/react'
import { UiCard, UiCardHeader, UiCardProps, UiDataTable } from '@postal-io/postal-ui'
import { FulfillmentHistoryFragment } from 'api'
import React from 'react'
import { HISTORY_COLUMNS } from './ordersData'

interface OrderHistoryProps extends UiCardProps {
  history: FulfillmentHistoryFragment[]
  isLoading?: boolean
}

export const OrderHistory: React.FC<OrderHistoryProps> = ({ history, isLoading, ...rest }) => {
  return (
    <>
      <UiCard
        isLoading={isLoading}
        {...rest}
      >
        <Flex
          flexDir="column"
          h="100%"
        >
          <UiCardHeader>Order History</UiCardHeader>
          <UiDataTable
            isLoading={isLoading}
            variant="list"
            rowKey="id"
            columns={HISTORY_COLUMNS}
            rows={history}
            pageSize={10}
          />
        </Flex>
      </UiCard>
    </>
  )
}
