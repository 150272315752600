import { useGraphqlFetch, useGraphqlMutation } from '@postal-io/postal-graphql'
import { downloadLink, useUiAlerts } from '@postal-io/postal-ui'
import {
  BackgroundTask,
  DownloadReportDocument,
  GenerateOrderReportDocument,
  GenerateVendorFulfillmentsReportDocument,
  ReportType,
} from 'api'
import { format } from 'date-fns'
import { useBackgroundQueue } from 'hooks'
import { useState } from 'react'

const FILE_NAME_MAP = {
  [ReportType.PostalFulfillmentPartner]: 'Orders',
  [ReportType.VendorFulfillments]: 'PayableFulfillments',
}

export const useReport = (reportType: ReportType) => {
  const Alert = useUiAlerts()
  const { queue } = useBackgroundQueue()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const generateOrderReportMutation = useGraphqlMutation(GenerateOrderReportDocument)
  const generateVendorFulfillmentsMutation = useGraphqlMutation(GenerateVendorFulfillmentsReportDocument)

  const downloadReportFetch = useGraphqlFetch(DownloadReportDocument)

  // download existing report
  const downloadReport = async (reportOutputInstanceId: string, fileName: string) => {
    try {
      setIsLoading(true)
      const res = await downloadReportFetch({ reportOutputInstanceId })
      if (res.downloadReport) await downloadLink(res.downloadReport, fileName)
    } catch (err) {
      Alert.error(err)
    } finally {
      setIsLoading(false)
      Alert.success('The report is downloading')
    }
  }

  // create new report and download too
  const createReport = async (filter: any, id?: string) => {
    setIsLoading(true)
    const date = format(new Date(), 'yyyy-MM-dd HHmmss')
    const fileName = `${FILE_NAME_MAP[reportType]}${id ? `-${id}-` : '-'}${date}.xlsx`

    // callback to fire when the report task is complete
    const handleComplete = async (task: BackgroundTask) => {
      const reportOutputInstanceId = task.outputs?.reportOutputInstanceId
      if (!reportOutputInstanceId) return
      await downloadReport(reportOutputInstanceId, fileName)
    }
    // generate the report and pass the download callback to it
    try {
      let res
      switch (reportType) {
        case ReportType.PostalFulfillmentPartner:
          res = await generateOrderReportMutation.mutateAsync({
            filter: filter || {},
            name: fileName,
          })
          queue(res?.generateOrderReport, handleComplete)
          break

        case ReportType.VendorFulfillments:
          res = await generateVendorFulfillmentsMutation.mutateAsync({
            filter: filter || {},
            name: fileName,
          })
          queue(res?.generateVendorFulfillmentsReport, handleComplete)
          break

        default:
          throw new Error('Unsupported report type: ' + reportType)
      }
      Alert.success('The report is being generated and will download shortly')
    } catch (err) {
      Alert.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  return { createReport, downloadReport, isLoading }
}
