import { QuestionIcon } from '@chakra-ui/icons'
import { Flex } from '@chakra-ui/react'
import { GraphqlFilterTransform, humanize, OrderByDirection, UiTruncate } from '@postal-io/postal-ui'
import { DraftMarketplaceProductFragment, DraftStatus, Status } from 'api'
import { assetUrlToSrc } from 'api/rest'
import { VendorStatusTag } from 'components/Main'
import { StorageKeys } from 'lib'
import { draftStatusMapper, statusMapper } from 'lib/statusMapper'
import { isNumber } from 'lodash'

const STATIC_VARIABLES = { limit: 100 }

const INITIAL_STATE = {
  meta: {},
  orderBy: { key: 'id', direction: OrderByDirection.Descending },
}

const TRANSFORMS = {
  externalId: GraphqlFilterTransform.Contains,
  name: GraphqlFilterTransform.Contains,
  marketplaceStatus: GraphqlFilterTransform.In,
  status: GraphqlFilterTransform.In,
  category: GraphqlFilterTransform.In,
  systemName: GraphqlFilterTransform.In,
}

export const FILTER_OPTIONS = {
  transforms: TRANSFORMS,
  initialState: INITIAL_STATE,
  persistKey: StorageKeys.DraftMarketplaceProductFilter,
  staticVariables: STATIC_VARIABLES,
}

interface Row extends DraftMarketplaceProductFragment {
  combinedStatus: DraftStatus | Status
}

export const COLUMNS = [
  {
    key: 'itemName',
    label: 'Product',
    render: ({ name, imageUrls }: Row) => (
      <Flex alignItems="center">
        <Flex
          mr={4}
          h="50px"
          minW="70px"
          overflow="hidden"
          backgroundImage={`url(${assetUrlToSrc({
            assetUrl: imageUrls?.[0]?.url,
            accountId: '',
          })})`}
          bgColor="white"
          borderWidth="1px"
          borderColor="gray.100"
          backgroundSize="cover"
          backgroundPosition="center"
          borderRadius="postal"
          justifyContent="center"
          alignItems="center"
        >
          {!imageUrls?.[0]?.url && (
            <QuestionIcon
              color="gray.400"
              fontSize="2xl"
            />
          )}
        </Flex>
        <UiTruncate
          text={name || '-'}
          length={50}
          showTooltip
        />
      </Flex>
    ),
  },
  // status is being derived from marketplace product instead of draft under certain conditions
  {
    key: 'status',
    label: 'Status',
    render: ({ status, marketplaceStatus }: Row) =>
      status === DraftStatus.Active && !!marketplaceStatus ? (
        <VendorStatusTag {...statusMapper(marketplaceStatus)} />
      ) : (
        <VendorStatusTag {...draftStatusMapper(status)} />
      ),
  },
  {
    key: 'systemName',
    label: 'System',
    render: ({ systemName }: Row) => humanize(systemName),
  },
  {
    key: 'inventory',
    label: 'Inventory',
    render: ({ variants }: Row) => {
      const numVariants = variants?.length
      return isNumber(numVariants)
        ? `${variants?.reduce((sum, variant) => {
            return sum + (variant?.quantity ?? 0)
          }, 0)} in Stock${numVariants > 1 ? ` for ${numVariants} variants` : ''}`
        : '-'
    },
  },
  { key: 'type', label: 'Type', render: ({ type }: Row) => (type ? type : '-') },
  {
    key: 'category',
    label: 'Category',
    render: ({ category }: Row) => (category ? category : '-'),
  },
  {
    key: 'externalId',
    label: 'External Id',
    render: ({ externalId }: Row) => (externalId ? externalId : '-'),
  },
]

export enum Categories_V2 {
  'Books' = 'Books',
  'Charities' = 'Charities',
  'DirectMail' = 'Direct Mail',
  'DrinksAccessories' = 'Drinks & Accessories',
  'Electronics' = 'Electronics',
  'Events' = 'Events',
  'EverythingElse' = 'Everything Else',
  'FlowersPlants' = 'Flowers & Plants',
  'Food' = 'Food',
  'GiftCards' = 'Gift Cards',
  'HealthWellness' = 'Health & Wellness',
  'Holiday' = 'Holiday',
  'Home' = 'Home',
  'Sports' = 'Sports',
  'Swag' = 'Swag',
  'GreetingCards' = 'Greeting Cards',
  'Games' = 'Games',
  'Pets' = 'Pets',
  'GiftBoxesBaskets' = 'Gift Boxes & Baskets',
  'Apparel' = 'Apparel',
  'AccessoriesJewelry' = 'Accessories & Jewelry',
  'OfficeEssentials' = 'Office Essentials',
}

// export const CATEGORIES = Object.values(Categories)
export const CATEGORIES_V2 = Object.values(Categories_V2)

export const PLACE_PRODUCT_MAP: { [key: string]: string } = {
  'Gift Cards': 'GIFTCARD',
  'Books': 'BOOK',
  'Postcards': 'POSTCARD',
  'Notecards': 'NOTECARD',
  'Brochures': 'BROCHURE',
}

export const SHIPPING_NAMES = [
  'USPS',
  'Amazon Prime',
  'UPS Next Day',
  'UPS 3-Day',
  'FedEx Next Day',
  'FedEx 3-Day',
  'Electronic',
]

export const KEYWORDS = [
  'Appreciation',
  'Black owned',
  'Boss',
  'CEO',
  'Cheap gifts',
  'Cheap',
  'Client',
  'Confections',
  'Coworker',
  'Customizable',
  'Eco-Friendly',
  'Entertainment',
  'Fast shipping',
  'For the Office',
  'Free shipping',
  'Gift baskets',
  'Gifts for coworkers',
  'Gifts for Families',
  'Gifts for men',
  'Gifts for prospects',
  'Gifts for women',
  'Gives Back',
  'HR',
  'Lead',
  'Marketing',
  'Minority owned',
  'New Baby',
  'New Parent',
  'Popular',
  'Prospect',
  'Relaxation',
  'RevOps',
  'Sales',
  'Self care',
  'Small business',
  'Sustainably made',
  'Sympathy',
  'Thank You',
  'Unique gifts',
  'Unique',
  'Vacation',
  'Wine',
].sort()

export const OCCASION_KEYWORDS = [
  'Wedding',
  'Anniversary',
  'Birthday',
  'Christmas',
  'Congratulations',
  'Graduation',
  'Holiday',
  'Holidays',
].sort()

export const SEASON_KEYWORDS = ['Winter', 'Spring', 'Summer', 'Fall'].sort()

export const GEOGRAPHIC_MARKETS = [
  'Africa',
  'Australia',
  'Austria',
  'Bangladesh',
  'Belgium',
  'Brazil',
  'Bulgaria',
  'Canada',
  'China',
  'Croatia',
  'Cyprus',
  'Czechia',
  'Denmark',
  'Estonia',
  'Finland',
  'France',
  'Germany',
  'Greece',
  'Hungary',
  'India',
  'Indonesia',
  'Ireland',
  'Israel',
  'Italy',
  'Japan',
  'Jordan',
  'Latvia',
  'Lithuania',
  'Luxembourg',
  'Malaysia',
  'Malta',
  'Mexico',
  'Myanmar',
  'Nepal',
  'Netherlands',
  'New Zealand',
  'Norway',
  'Philippines',
  'Poland',
  'Portugal',
  'Romania',
  'Russia',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'South Korea',
  'Spain',
  'Sweden',
  'Switzerland',
  'Taiwan',
  'Thailand',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'Vietnam',
].sort()

export const TYPES_V2: Record<string, string[]> = {
  'Books': [
    'Anthropology',
    'Biographies',
    'Business & Finance',
    "Children's",
    'Coffee Table',
    'Computers & Technology',
    'Cookbooks',
    'Food & Wine',
    'Engineering',
    'Fiction',
    'Government',
    'Home',
    'Leadership',
    'Literature',
    'Marketing & Sales',
    'Nature',
    'Pets & Animal Care',
    'Philanthropy',
    'Psychology',
    'Science Fiction & Fantasy',
    'Self Help',
    'Sports',
    'Travel',
    'Religous',
    'Hobbies',
    'Humor',
  ].sort(),
  'Charities': [
    'Animal',
    'Arts & Culture',
    'Community Development',
    'Education',
    'Environmental',
    'Health',
    'Human Services',
    'International NGOs',
  ].sort(),
  'Direct Mail': ['Brochures', 'Notecards & Postcards', 'Letters'].sort(),
  'Drinks & Accessories': [
    'Alcoholic Beverages-Beer',
    'Alcoholic Beverages-Hard Cider',
    'Alcoholic Beverages-Hard Seltzers',
    'Alcoholic Beverages-Hard Kombucha',
    'Alcoholic Beverages-Cocktails',
    'Alcoholic Beverages-Liquor & Spirits-Absinthe',
    'Alcoholic Beverages-Liquor & Spirits-Brandy',
    'Alcoholic Beverages-Liquor & Spirits-Gin',
    'Alcoholic Beverages-Liquor & Spirits-Liqueurs',
    'Alcoholic Beverages-Liquor & Spirits-Rum',
    'Alcoholic Beverages-Liquor & Spirits-Tequila',
    'Alcoholic Beverages-Liquor & Spirits-Vodka',
    'Alcoholic Beverages-Liquor & Spirits-Whiskey',
    'Alcoholic Beverages-Liquor & Spirits-Bourbon',
    'Alcoholic Beverages-Liquor & Spirits-Mezcal',
    'Alcoholic Beverages-Liquor & Spirits-Scotch',
    'Alcoholic Beverages-Wine-Red Wine-Cabernet Sauvignon',
    'Alcoholic Beverages-Wine-Red Wine-Merlot',
    'Alcoholic Beverages-Wine-Red Wine-Malbec',
    'Alcoholic Beverages-Wine-Red Wine-Syrah',
    'Alcoholic Beverages-Wine-Red Wine-Petite Sirah',
    'Alcoholic Beverages-Wine-Red Wine-Pinot Noir',
    'Alcoholic Beverages-Wine-Red Wine-Zinfandel',
    'Alcoholic Beverages-Wine-Red Wine-Bordeaux',
    'Alcoholic Beverages-Wine-Red Wine-Chianti',
    'Alcoholic Beverages-Wine-Red Wine-Berbera',
    'Alcoholic Beverages-Wine-Red Wine-Rioja',
    'Alcoholic Beverages-Wine-Red Wine-Rhone',
    'Alcoholic Beverages-Wine-Red Wine-Burgundy',
    'Alcoholic Beverages-Wine-Red Wine-Barolo',
    'Alcoholic Beverages-Wine-Red Wine-Cabernet Franc',
    'Alcoholic Beverages-Wine-Red Wine-Sangiovese',
    'Alcoholic Beverages-Wine-Red Wine-Tempranillo',
    'Alcoholic Beverages-Wine-Red Wine-Grenache',
    'Alcoholic Beverages-Wine-Red Wine-Other Reds',
    'Alcoholic Beverages-Wine-White Wine-Chardonnay',
    'Alcoholic Beverages-Wine-White Wine-Chenin Blanc',
    'Alcoholic Beverages-Wine-White Wine-Moscato',
    'Alcoholic Beverages-Wine-White Wine-Gewurztraminer',
    'Alcoholic Beverages-Wine-White Wine-Riesling',
    'Alcoholic Beverages-Wine-White Wine-Viognier',
    'Alcoholic Beverages-Wine-White Wine-Pinot Grigio',
    'Alcoholic Beverages-Wine-White Wine-Sauvignon Blanc',
    'Alcoholic Beverages-Wine-White Wine-Other Whites',
    'Alcoholic Beverages-Wine-Bubbles-Champagne',
    'Alcoholic Beverages-Wine-Bubbles-Sparkling',
    'Alcoholic Beverages-Wine-Rose',
    'Non-Alcoholic-Beer & Hard Cider',
    'Non-Alcoholic-Mixers',
    'Non-Alcoholic-Wine',
    'Non-Alcoholic-Kombucha',
    'Water',
    'Coffee-Ground',
    'Coffee-Whole Beans',
    'Hot Chocolate',
    'Juice',
    'Sports & Energy Drinks',
    'Tea & Infusions',
    'Accessories & Drinkware-Coffee Mugs',
    'Accessories & Drinkware-Coffee Accessories',
    'Accessories & Drinkware-Wine Glasses',
    'Accessories & Drinkware-Wine Accessories',
    'Accessories & Drinkware-Beer Glasses',
    'Accessories & Drinkware-Beer Accessories',
    'Accessories & Drinkware-Shot Glasses',
    'Accessories & Drinkware-Water Bottles',
    'Homebrewing & Winemaking Kits-Beer Brewing',
    'Homebrewing & Winemaking Kits-Wine Making',
    'Subscriptions - Wine',
    'Subscriptions - Beer',
  ].sort(),
  'Electronics': [
    'Camera & Optics',
    'Cell Phones',
    'Wearables-Watches',
    'Wearables-Fitness Trackers',
    'Wearables-Headphones',
    'Wearables-VR',
    'Wearables-Eyewear',
    'Audio-Audio Accessories',
    'Audio-Headphones & Headsets',
    'Audio-Microphones',
    'Audio-Speakers',
    'Audio-Voice Recorders',
    'Audio-Stereo Systems',
    'Audio-Turntable & Record Players',
    'Audio-Home Theater Systems',
    'Audio-Radios',
    'Computers-Handheld Devices-Data Collectors',
    'Computers-Handheld Devices-E-Book Readers',
    "Computers-Handheld Devices-PDA's",
    'Computers-Laptops',
    'Computers-Tablet Computers',
    'Electronic Accessories',
    'Print, Copy & Scan',
    'Video-Computer Monitors',
    'Video-Projectors',
    'Video-Televisions',
    'Video-Video Accessories',
    'Video-Video Players & Recorders',
    'Home Accessories',
    'Home Security',
    'Video Game Console Accessories',
    'Video Game Consoles',
    'DVDs & Videos',
    'Drones',
    'Car Electronics',
    'Portable Chargers',
    'Cell Phone Accessories',
  ].sort(),
  'Everything Else': [
    'Bags',
    'Clothing',
    'Grilling',
    'Jewelry',
    'Kids',
    'New Parent',
    'Outdoors',
    'Pets',
    'Puzzles & Games',
    'Sunglasses',
    'Tools',
    'Travel',
  ].sort(),
  'Flowers & Plants': [
    'Plants-Aquatic Plants',
    'Plants-Indoor Plants',
    'Plants-Outdoor Plants',
    'Plants-Plant & Herb Growing Kits',
    'Plants-Seeds',
    'Plants-Trees',
    'Flowers-Floral Arrangements',
    'Flowers-Dried Flowers',
    'Gardening Tools',
    'Subscriptions',
  ].sort(),
  'Food': [
    'Bakery-Baked Goods',
    'Bakery-Cakes & Cupcakes',
    'Bakery-Bagels, Breads, & Buns',
    'Bakery-Cookies',
    'Bakery-Donuts',
    'Bakery-Pastries & Scones',
    'Bakery-Pies & Tarts',
    'Cheese, Meat, and Charcuterie',
    'Chocolate & Confections',
    'Condiments, Sauces & Spices',
    'Dips & Spreads',
    'Ice Cream & Novelties',
    'Fruits & Nuts',
    'Meat & Seafood',
    'Popcorn',
    'Snacks',
    'Meals',
    'Soups & Broths',
    'Subscriptions',
  ].sort(),
  'Health & Wellness': [
    'Fitness & Nutrition',
    'Personal Care-Bath and Body',
    'Personal Care-Perfume & Cologne',
    'Personal Care-Skin Care',
    'Personal Care-Beauty products',
    'Personal Care-Massage & Relaxation',
    'Personal Care-Shaving & Grooming',
    'Personal Care-Wellness',
    'Mindfulness',
    'Candles & Fragrances-Single Candles',
    'Candles & Fragrances-Candle Sets',
    'Candles & Fragrances-Fragrances',
  ].sort(),
  'Holiday': [
    'Food',
    'Drinks & Accessories',
    'Electronics',
    'Flowers & Plants',
    'Health & Wellness',
    'Home',
    'Sports',
    'Greeting Cards',
    'Games',
    'Pets',
    'Gift Boxes & Baskets',
    'Apparel',
    'Accessories & Jewelry',
    'Office Essentials',
  ].sort(),
  'Home': [
    'Furniture',
    'Decor',
    'Bathroom Accessories',
    'Fireplaces',
    'Household Appliances',
    'Lighting',
    'Linens & Bedding',
    'Gift Sets',
    'Kitchen and Dining-Barware',
    'Kitchen and Dining-Cookware & Bakeware',
    'Kitchen and Dining-Food Storage',
    'Kitchen and Dining-Kitchen Appliances',
    'Kitchen and Dining-Kitchen Tools and Utensils',
    'Kitchen and Dining-Tableware-Coffee & Tea Sets',
    'Kitchen and Dining-Tableware-Coffee Servers & Tea Pots',
    'Kitchen and Dining-Tableware-Dinnerware',
    'Kitchen and Dining-Tableware-Drinkware',
    'Kitchen and Dining-Tableware-Flatware',
    'Kitchen and Dining-Tableware-Serveware',
    'Outdoor Living-Gardening',
    'Outdoor Living-Lawn & Garden',
    'Outdoor Living-Outdoor Power Equipment',
    'Outdoor Living-Grilling',
    'Outdoor Living-Coolers',
    'Outdoor Living-Outdoor Accessories',
    'Cleaning',
    'Tools',
    'Candles & Fragrances-Single Candles',
    'Candles & Fragrances-Candle Sets',
    'Candles & Fragrances-Fragrances',
  ].sort(),
  'Sports': [
    'Sporting Goods',
    'Exercise & Fitness',
    'Indoor Games',
    'Outdoor Recreation',
    'Office Sports',
    'Pro Sport-NFL',
    'Pro Sports-MLB',
    'Pro Sports-NBA',
    'Pro Sports-NHL',
    'Pro Sports-MLS',
    'Pro Sports-PGA',
    'College Sports',
  ].sort(),
  'Pets': ['Dog', 'Cat', 'Other Pets'].sort(),
  'Apparel': ["Women's Clothing", "Men's Clothing", 'Baby Clothing', 'Kids Clothing', 'Costumes'].sort(),
  'Swag': ['Concierge', 'Tradeshow/Events'].sort(),
  'Games': [
    'Arts & Crafts',
    'Party & Celebration',
    'Trophies & Awards',
    'Indoor Games',
    'Board Games',
    'Outdoor Games',
    'Puzzles',
    'Toys',
    'Outdoor Play Equipment',
    'Baby Toys',
    'Gag Gifts',
  ].sort(),
  'Gift Cards': [
    'Apparel & Accessories',
    'Beauty',
    'Books',
    'Entertainment',
    'Food & Drink',
    'Food Delivery',
    'Gaming',
    'General',
    'Home',
    'New Baby',
    'Outdoors',
    'Pets',
    'Sports',
    'Travel',
  ],
  'Greeting Cards': ['Holiday', 'Notecards', 'Gift Boxes', 'Handwritten'].sort(),
  'Gift Boxes & Baskets': [
    'New Baby',
    'New Home',
    'Congratulations',
    'Sympathy',
    'Entertainment',
    'Bath & Body',
    'Coffee & Tea',
    'Sweet Treats',
    'Snack',
    'Wine',
    'Beer',
    'Liquor',
    'Office Essentials',
    'Food',
    'Wellness',
  ].sort(),
  'Accessories & Jewelry': [
    'Clothing Accessories-Belts',
    'Clothing Accessories-Hats',
    'Clothing Accessories-Scarves and Shawls',
    'Clothing Accessories-Gloves and Mittens',
    'Clothing Accessories-Eyewear',
    'Handbag & Wallet Accessories',
    'Jewelry-Anklets',
    'Jewelry-Body Jewelry',
    'Jewelry-Bracelets',
    'Jewelry-Brooches & Lapel Pins',
    'Jewelry-Charms and Pendants',
    'Jewelry-Earrings',
    'Jewelry-Jewelry Sets',
    'Jewelry-Necklaces',
    'Jewelry-Rings',
    'Jewelry-Watch Accessories',
    'Jewelry-Watches',
    'Shoe Accessories',
    'Shoes',
    'Jewelry Cleaning & Care',
    'Umbrellas',
    'Luggage & Bags-Backpacks',
    'Luggage & Bags-Briefcases',
    'Luggage & Bags-Cosmetic & Toiletry Bags',
    'Luggage & Bags-Diaper Bags',
    'Luggage & Bags-Dry Boxes',
    'Luggage & Bags-Duffel Bags',
    'Luggage & Bags-Fanny Packs',
    'Luggage & Bags-Garment Bags',
    'Luggage & Bags-Luggage Accessories',
    'Luggage & Bags-Messenger Bags',
    'Luggage & Bags-Shopping Totes',
    'Luggage & Bags-Suitcases',
    'Luggage & Bags-Train Cases',
  ].sort(),
  'Office Essentials': [
    'Office Supplies-Book Accessories',
    'Office Supplies-Writing & Notepads',
    'Office Supplies-Filing & Organization',
    'Office Supplies-General Office Supplies',
    'Office Supplies-Impulse Sealers',
    'Office Supplies-Lap Desks',
    'Office Supplies-Name Plates',
    'Office Supplies-Office & Chair Mats',
    'Office Supplies-Office Equipment',
    'Office Games',
  ].sort(),
  'Events': [
    'Seasonal',
    'Wine Tasting-Vineyards',
    'Wine Tasting-Varietal-Red',
    'Wine Tasting-Varietal-White',
    'Wine Tasting-Varietal-Rose',
    'Wine Tasting-Varietal-Sparkling',
    'Wine Tasting-Wine Pairing',
    'Wine Tasting-Regions-Old World',
    'Wine Tasting-Regions-New World',
    'Wine Tasting-Regions-California',
    'Wine Tasting-Flights',
    'Wine Tasting-Full Bottle',
    'Wine Tasting-Half Bottle',
    'Culinary Experiences-Baking',
    'Culinary Experiences-Charcuterie',
    'Culinary Experiences-Cheese',
    'Culinary Experiences-Cooking',
    'Culinary Experiences-Olive Oil & Balsamic',
    'Culinary Experiences-Seasonal',
    'Culinary Experiences-Tasting',
    'Beer & Spirits-Craft Beer',
    'Beer & Spirits-Bitters',
    'Beer & Spirits-Bourbon',
    'Beer & Spirits-Cider',
    'Beer & Spirits-Cocktails',
    'Beer & Spirits-Flights',
    'Beer & Spirits-Mezcal',
    'Beer & Spirits-Tequila',
    'Beer & Spirits-Vodka',
    'Beer & Spirits-Whiskey',
    'Health & Wellness-Boot Camp',
    'Health & Wellness-Mindfulness',
    'Health & Wellness-Tai Chi',
    'Health & Wellness-Jump Rope',
    'Health & Wellness-Fitness',
    'Health & Wellness-Pilates',
    'Health & Wellness-Yoga',
    'Health & Wellness-Stretching',
    'Arts & Crafts-Candle Making',
    'Arts & Crafts-Shibori Dying',
    'Arts & Crafts-Plants & Florals',
    'Arts & Crafts-Painting',
    'Arts & Crafts-Crafts',
    'Learning & Development-Unhosted',
    'Learning & Development-Professional Development',
    'Learning & Development-DE&I',
    'Team Building-Escape Room',
    'Team Building-Ice Breakers',
    'Team Building-Mystery',
    'Team Building-Trivia',
    'Team Building-Fun & Games',
    'Team Building-Comedy',
    'Non-Alcoholic Beverages-Mocktails',
    'Non-Alcoholic Beverages-Coffee & Tea',
    'Virtual Experiences',
  ].sort(),
}
