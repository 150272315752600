import { Box, Flex, Grid, Heading } from '@chakra-ui/react'
import { createId } from '@paralleldrive/cuid2'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { UiSubNavbar } from '@postal-io/postal-ui'
import { GetPostalFulfillmentDocument, GetProductByPostalFulfillmentDocument } from 'api'
import React, { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { BackButton, Layout } from '../Common/Layout'
import { OrderActions } from './OrderActions'
import { OrderCustomerLogo } from './OrderCustomerLogo'
import { OrderHistory } from './OrderHistory'
import { OrderPreview } from './OrderPreview'
import { OrderProfile } from './OrderProfile'

export const Order: React.FC = () => {
  const { orderId }: { orderId?: string } = useParams()
  const navigate = useNavigate()

  // get order using id derived from route
  const getPostalFulfillment = useGraphqlQuery(GetPostalFulfillmentDocument, { id: orderId }, { enabled: !!orderId })
  const postalFulfillment = getPostalFulfillment.data?.getPostalFulfillment
  const { variantId, previewImage, accountId } = postalFulfillment || {}

  const history = useMemo(
    () => postalFulfillment?.history?.map((h) => ({ ...h, id: createId() })) || [],
    [postalFulfillment?.history]
  )

  const getProduct = useGraphqlQuery(GetProductByPostalFulfillmentDocument, {
    id: orderId,
  })

  const variant = useMemo(
    () => getProduct.data?.getProductByPostalFulfillment?.variants?.find((v) => v.id === variantId),
    [getProduct?.data, variantId]
  )

  const handleBack = () => navigate('/orders', { state: { useSavedPage: true } })

  return (
    <>
      <UiSubNavbar
        left={
          <Flex alignItems="center">
            <BackButton onClick={handleBack} />
            <Heading
              mr={4}
              fontSize="2xl"
            >
              {postalFulfillment?.id
                ? `Order #${postalFulfillment.id} for ${postalFulfillment?.userLink?.fullName}`
                : ''}
            </Heading>
          </Flex>
        }
        boxShadow="none"
        border="none"
        mb={0}
        maxW="1800px"
        gridProps={{ p: 4 }}
      />
      <Layout pt={0}>
        <Grid
          templateColumns={{ base: '1fr', md: '1fr 1fr 1fr 1fr' }}
          gap={8}
        >
          <OrderProfile
            postalFulfillment={postalFulfillment}
            isLoading={getPostalFulfillment.isLoading}
            variant={variant}
            gridColumn="2 / 5"
            gridRow="1 / 2"
            boxShadow={'none'}
          />

          <OrderPreview
            isLoading={getPostalFulfillment.isLoading}
            previewImage={(variant?.imageUrls?.[0]?.url ?? previewImage) as string}
            accountId={accountId as string}
            gridColumn="1 / 2"
            gridRow="1 / 2"
            boxShadow={'none'}
          />

          <Box
            gridColumn="1 / 2"
            gridRow="2 / 3"
          >
            <OrderCustomerLogo
              accountId={accountId}
              orderId={postalFulfillment?.id}
              h="100%"
              boxShadow={'none'}
            />
          </Box>

          <OrderActions
            postalFulfillment={postalFulfillment}
            isLoading={getPostalFulfillment.isLoading}
            gridColumn="2 / 5"
            gridRow="2 / 3"
            boxShadow={'none'}
          />

          <OrderHistory
            history={history}
            isLoading={getPostalFulfillment.isLoading}
            gridColumn="1 / 5"
            gridRow="3"
            boxShadow={'none'}
          />
        </Grid>
      </Layout>
    </>
  )
}
