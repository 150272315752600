import { SidebarDataLink, SidebarDataLinks } from 'components/Common/SidebarLink'
import React, { useMemo } from 'react'
import { useAcl } from '../../hooks/useAcl'

export const ProfileSidebar: React.FC = () => {
  const { hasFeature, hasPermission } = useAcl()
  const hasVendorPayables = hasFeature('vendorPayables') && hasPermission('vendorPayable.read')

  const links: SidebarDataLink[] = useMemo(() => {
    return [
      {
        title: 'Company Information',
        to: '/account',
        exact: true,
      },
      {
        title: 'Vendor Payables',
        to: '/account/payables',
        exact: true,
        isHidden: !hasVendorPayables,
      },
    ]
  }, [hasVendorPayables])

  return (
    <>
      <SidebarDataLinks links={links} />
    </>
  )
}
