import { BoxProps, Flex, Heading, Image, Text } from '@chakra-ui/react'
import { UiLink } from '@postal-io/postal-ui'
import mailIcon from 'assets/images/mail-icon.png'
import React from 'react'

const BodyText: React.FC<BoxProps> = ({ children, ...rest }) => (
  <Text
    fontSize="md"
    fontWeight={400}
    {...rest}
  >
    {children}
  </Text>
)

interface ConfirmEmailProps {
  firstName?: string
  emailAddress: string
  isReset?: boolean
  resend?: () => void
}

export const ConfirmEmail: React.FC<ConfirmEmailProps> = ({ firstName, emailAddress, resend }) => (
  <Flex
    flexDirection="column"
    justifyContent="center"
  >
    <Heading
      fontWeight={600}
      as="h1"
      size="md"
      mt={5}
      textAlign="center"
    >
      {firstName ? `Hi ${firstName}, please confirm your email` : `Thank you, please confirm your email`}
    </Heading>
    <Image
      src={mailIcon}
      alt="Email Icon"
      py={20}
      mx="auto"
    />
    <BodyText textAlign="center">
      We’ve sent an email to <strong>{emailAddress}</strong>
    </BodyText>
    <BodyText
      py={8}
      textAlign="center"
    >
      Click the link in the email to confirm your address and get postal.
    </BodyText>
    <BodyText textAlign="center">
      Can’t see the email? Check your spam folder or{' '}
      <UiLink
        color="primary.500"
        onClick={resend}
      >
        Resend Email
      </UiLink>
      .
    </BodyText>
  </Flex>
)
