import { Box, Flex, Grid, Image, Text, useDisclosure } from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import {
  UiAlert,
  UiButton,
  UiCard,
  UiCardHeader,
  UiConfirm,
  UiDataTable,
  UiIconButton,
  UiIconDelete,
  UiIconPostalEdit,
  UiMoney,
  UiTextTruncate,
  useAlerts,
} from '@postal-io/postal-ui'
import {
  DraftMarketplaceProductFragment,
  DraftVariantFragment,
  RemoveDraftMarketplaceProductVariantDocument,
} from 'api'
import { assetUrlToSrc } from 'api/rest'
import { useSession } from 'hooks'
import { isNumber } from 'lodash'
import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

interface DraftVariantListV2Props {
  // we need the entire product for the update - no updateVariant mutation available yet
  // this is why we didn't just pass the variants to this component
  draft?: DraftMarketplaceProductFragment
  isLoading?: boolean
}

export const DraftVariantListV2: React.FC<DraftVariantListV2Props> = ({ draft, isLoading }) => {
  const { session } = useSession()
  const navigate = useNavigate()
  const Alert = useAlerts()
  const removeVariantDisclosure = useDisclosure()
  const [variantId, setVariantId] = useState<string | null>(null)

  const removeVariant = useGraphqlMutation(RemoveDraftMarketplaceProductVariantDocument)

  // TODO - clean the variants
  const cleanedVariants = useMemo(() => draft?.variants?.map((v) => v), [draft?.variants])

  // shopify draft variants cannot be created or edited
  const createVariantDisabled = useMemo(() => draft?.systemName === 'shopify', [draft?.systemName])

  const handleConfirmRemoveVariant = async (draftId: string, variantId: string) => {
    try {
      await removeVariant.mutateAsync({ draftProductId: draftId, id: variantId })
      Alert.success('Variant removed')
      setVariantId(null)
    } catch (e) {
      Alert.error('Error removing variant: ', e)
    }
  }

  const handleRemoveVariant = (variantId: string) => {
    setVariantId(variantId)
    removeVariantDisclosure.onOpen()
  }

  const COLUMNS = [
    {
      key: '',
      label: 'Item',
      render: ({ imageUrls }: DraftVariantFragment) => {
        const imageUrl = imageUrls?.find(Boolean)

        return !!imageUrl ? (
          <Image
            boxSize={'75px'}
            my={2}
            src={
              assetUrlToSrc({
                assetUrl: imageUrl?.url,
                accountId: session.accountId,
              }) as string
            }
            alt=""
          />
        ) : (
          <Text>-----</Text>
        )
      },
    },
    {
      key: 'variantName',
      label: 'Name',
      render: ({ variantName }: DraftVariantFragment) => variantName,
    },
    {
      key: 'description',
      label: 'Description',
      render: ({ description }: DraftVariantFragment) => (
        <UiTextTruncate
          color="gray.800"
          fontSize="md"
          maxW="200px"
        >
          {description}
        </UiTextTruncate>
      ),
    },
    {
      key: 'fulfillmentType',
      label: 'Type',
      render: ({ fulfillmentType }: DraftVariantFragment) => fulfillmentType,
    },
    {
      key: 'price',
      label: 'Price',
      render: ({ price }: DraftVariantFragment) => (
        <UiMoney
          cents={price}
          placeholder="-----"
        />
      ),
    },
    {
      key: 'sku',
      label: 'SKU',
      render: ({ sku }: DraftVariantFragment) => sku,
    },
    {
      key: 'quantity',
      label: 'Quantity',
      render: ({ quantity }: DraftVariantFragment) => (isNumber(quantity) ? quantity : '-----'),
    },
    {
      key: '',
      label: '',
      render: ({ id }: DraftVariantFragment) => {
        return (
          <Grid
            templateColumns={'1fr 1fr'}
            gap={2}
            maxW={20}
          >
            <UiIconButton
              _hover={{ backgroundColor: createVariantDisabled ? 'white' : 'gray.200' }}
              _active={{ backgroundColor: createVariantDisabled ? 'white' : 'primary.500' }}
              backgroundColor={'white'}
              aria-label="edit variant"
              icon={
                <UiIconPostalEdit
                  color="primary.400"
                  boxSize={'25px'}
                />
              }
              onClick={() => navigate(`/products/${draft?.id}/variants/${id}`)}
            />
            <UiIconButton
              _active={{ backgroundColor: createVariantDisabled ? 'white' : 'primary.500' }}
              _hover={{ backgroundColor: createVariantDisabled ? 'white' : 'gray.200' }}
              backgroundColor={'white'}
              aria-label="delete variant"
              icon={
                <UiIconDelete
                  color="primary.400"
                  boxSize={'25px'}
                />
              }
              isDisabled={createVariantDisabled}
              onClick={() => handleRemoveVariant(id)}
            />
          </Grid>
        )
      },
    },
  ]

  return (
    <>
      <UiCard
        isLoading={isLoading}
        boxShadow="none"
      >
        <UiCardHeader>
          <Flex justify="space-between">
            <Box>Variants</Box>
            <UiButton
              size="sm"
              fontSize={'lg'}
              fontWeight="semibold"
              variant="link"
              onClick={() => navigate(`/products/${draft?.id}/variants/edit`)}
              minW="150px"
              isDisabled={!draft?.id || createVariantDisabled}
            >
              Add Variant
            </UiButton>
          </Flex>
        </UiCardHeader>
        {!draft?.id && (
          <UiAlert
            status="info"
            title="Product variants can be added once the Product draft is saved."
          />
        )}
        <UiDataTable
          variant="list"
          columns={COLUMNS}
          rows={cleanedVariants}
          rowKey="id"
          mt={4}
        />
      </UiCard>

      {removeVariantDisclosure.isOpen && !!variantId && (
        <UiConfirm
          title="Remove Variant"
          isOpen={removeVariantDisclosure.isOpen}
          onConfirm={() => handleConfirmRemoveVariant(draft?.id, variantId)}
          onClose={removeVariantDisclosure.onClose}
          buttonColor="red"
          buttonText="Remove"
        >
          <Text>
            Are you sure you want to <strong>Remove</strong> this product variant?
          </Text>
        </UiConfirm>
      )}
    </>
  )
}
