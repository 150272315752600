import { UiTag, UiTagLabel } from '@postal-io/postal-ui'

interface VendorStatusTagProps {
  label: string
  type?: string
  color?: string
  colorScheme?: string
}

export const VendorStatusTag = ({ label, color, colorScheme, ...rest }: VendorStatusTagProps) => {
  return (
    <UiTag
      minH={0}
      minW={24}
      px={3}
      py={1.5}
      fontSize="sm"
      bg={colorScheme}
      {...rest}
    >
      <UiTagLabel
        color={color}
        w="100%"
        fontWeight="bold"
        textAlign="center"
      >
        {label}
      </UiTagLabel>
    </UiTag>
  )
}
