import { UiLoading, useUiAlerts } from '@postal-io/postal-ui'
import { useSession } from 'hooks'
import qs from 'query-string'
import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

interface SsoProps {
  token?: string
}

export const Sso: React.FC<SsoProps> = ({ token }) => {
  const navigate = useNavigate()
  const Alert = useUiAlerts()
  const { loginSso } = useSession()
  const { returnTo } = qs.parse(window.location.search) as { returnTo: string }
  const ref = useRef(false)

  useEffect(() => {
    if (ref.current) return
    ref.current = true
    loginSso(token)
      .then(() => navigate(returnTo || '/'))
      .catch((err: Error) => {
        Alert.error(err)
        window.location.href = `${process.env.PUBLIC_URL}/`
      })
  }, [Alert, loginSso, navigate, returnTo, token])

  return <UiLoading />
}
