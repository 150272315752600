import { Grid, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { UiCard, UiCardProps, UiDate, UiLink, UiMoney, UiTruncate } from '@postal-io/postal-ui'
import {
  AddressFragment,
  FulfillmentStatus,
  GetContactByPostalFulfillmentDocument,
  PostalFulfillmentFragment,
  ProductVariantFragment,
} from 'api'
import { generateRegionString } from 'components'
import { Row } from 'components/Common/Row'
import { VendorCopyLink } from 'components/Main'
import { getAddress } from 'lib'
import React, { useMemo, useState } from 'react'
import { OrderAddressRows } from './OrderAddressRows'
import { OrderItemCustomFields, OrderItemFormFields } from './OrderItemCustomFields'

export interface TrackingNumberProps {
  trackingNumber?: string | null
}

export const TrackingNumber: React.FC<TrackingNumberProps> = ({ trackingNumber }) => {
  if (!trackingNumber) {
    return <Text>-</Text>
  }
  if (trackingNumber.startsWith('http')) {
    return (
      <UiLink
        href={trackingNumber}
        target="_blank"
      >
        View Shipment Status
      </UiLink>
    )
  }
  return <Text>{trackingNumber}</Text>
}

interface OrderProfileProps extends Omit<UiCardProps, 'variant'> {
  postalFulfillment?: PostalFulfillmentFragment
  isLoading?: boolean
  variant?: ProductVariantFragment
}

export const OrderProfile: React.FC<OrderProfileProps> = ({ postalFulfillment, isLoading, variant, ...rest }) => {
  const {
    itemName,
    fulfillmentPartnerOrderId,
    status,
    stage,
    fulfillmentMap,
    targetDeliveryDate,
    deliveryDate,
    trackingNumber,
    physicalMessage,
    cost,
    itemCustomizationList,
    shipTo,
    shipToName,
    shipToEmail,
    shipToPhone,
    formFieldValueList,
    accountId,
    accountName,
  } = postalFulfillment || {}

  const [activeTab, setActiveTab] = useState(0)
  const handleTabChange = (idx: number) => setActiveTab(idx)

  const shippingAddress = getAddress(shipTo)
  const shippingAddressRegion = generateRegionString(shippingAddress as AddressFragment)

  const getContact = useGraphqlQuery(
    GetContactByPostalFulfillmentDocument,
    { id: postalFulfillment?.id },
    { enabled: !!postalFulfillment?.id }
  )
  const contact = getContact.data?.getContactByPostalFulfillment

  const hasFormValues = useMemo(
    () => formFieldValueList && !!Object.keys(formFieldValueList).length,
    [formFieldValueList]
  )

  const UNSHIPPED_STATUSES = [FulfillmentStatus.Placed, FulfillmentStatus.Confirmed]

  return (
    <>
      <UiCard
        isLoading={isLoading}
        {...rest}
      >
        <Tabs onChange={handleTabChange}>
          <TabList>
            <Tab>Fulfillment Details</Tab>
            <Tab isDisabled={!hasFormValues}>Custom Questions</Tab>
            <Tab isDisabled={!itemCustomizationList?.length}>Custom Fields</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              {activeTab === 0 && (
                <Grid
                  templateColumns="repeat(auto-fill, minmax(250px, 1fr))"
                  minH="250px"
                  justifyContent="stretch"
                  gap={5}
                >
                  <Row label="Item Name">
                    {(
                      <UiTruncate
                        text={itemName}
                        length={50}
                      />
                    ) || '-'}
                  </Row>
                  <Row label="Vendor Order ID">{fulfillmentPartnerOrderId || '-'}</Row>
                  <Row label="SKU">{fulfillmentMap?.itemCode || '-'}</Row>
                  <Row label="Variant Name">{variant?.variantName?.toUpperCase() || '-'}</Row>
                  <Row label="Status"> {status || '-'}</Row>
                  <Row label="Stage"> {stage || '-'}</Row>
                  {status && !UNSHIPPED_STATUSES.includes(status) && (
                    <Row label="Target Delivery Date">
                      <UiDate
                        date={targetDeliveryDate}
                        fallback="-"
                      />
                    </Row>
                  )}
                  <Row label="Delivery Date">
                    <UiDate
                      date={deliveryDate}
                      fallback="-"
                    />
                  </Row>
                  <Row label="Tracking Number">{trackingNumber || '-'}</Row>
                  <Row label="Total Price">
                    <UiMoney
                      cents={cost?.gmvExpense}
                      placeholder="-"
                    />
                  </Row>
                  <Row label="Subtotal">
                    <UiMoney
                      cents={cost?.subtotal}
                      placeholder="-"
                    />
                  </Row>
                  <Row label={cost?.taxEstimated ? 'Estimated Taxes' : 'Taxes'}>
                    <UiMoney
                      cents={cost?.tax}
                      placeholder="-"
                    />
                  </Row>
                  <Row label="Shipping Cost">
                    <UiMoney
                      cents={cost?.shipping}
                      placeholder="-"
                    />
                  </Row>
                  <Row label="Third Party Fee">
                    <UiMoney
                      cents={cost?.thirdPartyFee}
                      placeholder="-"
                    />
                  </Row>
                  <Row label="Postal Revenue Share">
                    <UiMoney
                      cents={cost?.postalRevenueShare}
                      placeholder="-"
                    />
                  </Row>

                  <Row label="Physical Message">
                    {(
                      <UiTruncate
                        text={physicalMessage || '-'}
                        length={100}
                      />
                    ) || '-'}
                    <VendorCopyLink
                      ml={2}
                      display="inline-block"
                      text={physicalMessage}
                      variant="card"
                    />
                  </Row>
                  <OrderAddressRows
                    shippingAddress={shippingAddress as AddressFragment}
                    shippingAddressRegion={shippingAddressRegion}
                  />
                  <Row label="Ship To Name">
                    <VendorCopyLink
                      text={shipToName}
                      variant="text"
                      data-private
                    >
                      {shipToName}
                    </VendorCopyLink>
                  </Row>
                  {shipToEmail && (
                    <Row label="Ship To Email">
                      <VendorCopyLink
                        text={shipToEmail}
                        variant="text"
                        data-private
                      >
                        {shipToEmail}
                      </VendorCopyLink>
                    </Row>
                  )}

                  <Row label="Contact Phone Number">
                    {shipToPhone ? (
                      <VendorCopyLink
                        text={shipToPhone}
                        variant="text"
                        data-private
                      >
                        {shipToPhone}
                      </VendorCopyLink>
                    ) : (
                      '-'
                    )}
                  </Row>

                  <Row label="Customer Name">
                    <VendorCopyLink
                      text={accountName}
                      variant="text"
                      data-private
                    >
                      {accountName}
                    </VendorCopyLink>
                  </Row>

                  {contact?.companyName && (
                    <Row label="Ship to Company">
                      <VendorCopyLink
                        text={contact?.companyName}
                        variant="text"
                        data-private
                      >
                        {contact?.companyName}
                      </VendorCopyLink>
                    </Row>
                  )}
                </Grid>
              )}
            </TabPanel>
            <TabPanel>
              {activeTab === 1 && formFieldValueList && <OrderItemFormFields formFields={formFieldValueList} />}
            </TabPanel>
            <TabPanel>
              {activeTab === 2 && itemCustomizationList && (
                <OrderItemCustomFields
                  customItemList={itemCustomizationList}
                  accountId={accountId}
                />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </UiCard>
    </>
  )
}
