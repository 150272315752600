import { Box, Flex, FlexProps, Slide } from '@chakra-ui/react'
import { UiButton, UiButtonProps, UiLink, UiLinkProps } from '@postal-io/postal-ui'

export const DropBarSecondaryButton: React.FC<UiLinkProps> = ({ children, ...rest }) => (
  <UiLink
    fontSize={'xl'}
    color={'turquoise'}
    {...rest}
  >
    {children}
  </UiLink>
)

export const DropBarPrimaryButton: React.FC<UiButtonProps> = ({ children, ...rest }) => (
  <UiButton
    size="sm"
    colorScheme="turquoise"
    color="primary.900"
    fontSize="md"
    letterSpacing="0.5px"
    px={6}
    {...rest}
  >
    {children}
  </UiButton>
)

interface DropBarStaticProps extends FlexProps {
  isStatic?: boolean
}

const DropBarStatic: React.FC<DropBarStaticProps> = ({ isStatic, ...rest }) => (
  <Box
    bgColor="header.dark"
    pos={isStatic ? 'absolute' : 'relative'}
    left={0}
    right={0}
    top={0}
  >
    <Flex
      p={4}
      alignItems="center"
      justifyContent="space-between"
      boxShadow="lg"
      {...rest}
    />
  </Box>
)
interface DropBarProps extends FlexProps {
  isDirty?: boolean
  isStatic?: boolean
}

export const DropBar: React.FC<DropBarProps> = ({ children, isDirty, isStatic, ...rest }) => {
  return isStatic ? (
    <DropBarStatic
      isStatic
      {...rest}
    >
      {children}
    </DropBarStatic>
  ) : isDirty ? (
    <Slide
      in={isDirty}
      direction="top"
      style={{ zIndex: 10 }}
    >
      <DropBarStatic {...rest}>{children}</DropBarStatic>
    </Slide>
  ) : null
}
