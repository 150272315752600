import { DeleteIcon } from '@chakra-ui/icons'
import { ButtonGroup, Flex, Heading, Text, Tooltip, useDisclosure } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  sanitize,
  UiAlert,
  UiButton,
  UiConfirm,
  UiDialog,
  UiIconButton,
  UiLink,
  UiMenu,
  UiMenuButton,
  UiMenuItemOption,
  UiMenuList,
  UiSelect,
  UiSkeleton,
  UiSubNavbar,
  UiText,
  useAlertError,
  useAlerts,
  useLocalStorage,
} from '@postal-io/postal-ui'
import {
  CreateDraftMarketplaceProductDocument,
  DeleteDraftMarketplaceProductDocument,
  DraftMarketplaceProductFragment,
  DraftVariantFragment,
  EstimatedShippingTime,
  GetAssociatedAccountsDocument,
  GetDraftMarketplaceProductDocument,
  ImageReference,
  RequestPrivateListingDocument,
  RequestPublicListingDocument,
  UpdateDraftMarketplaceProductDocument,
} from 'api'
import { CancelFormModal, DropBar } from 'components/Common'
import { useGetDraftStatus } from 'hooks/useGetDraftStatus'
import { compact, isEqual, isNull, uniq } from 'lodash'
import { FormEvent, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Updater, useImmer } from 'use-immer'
import { DraftStatus } from '../../api/index'
import { DropBarPrimaryButton, DropBarSecondaryButton } from '../Common/DropBar'
import { BackButton } from '../Common/Layout'
import { OCCASION_KEYWORDS, SEASON_KEYWORDS, TYPES_V2 } from './draftProductDataV2'
import { DraftProductPageForm } from './DraftProductPageForm'

export const keywordMapper = (list: string[]) => {
  const resultObject: {
    keywords: string[]
    occasion: string[]
    season: string[]
  } = {
    keywords: [],
    occasion: [],
    season: [],
  }
  if (!!list?.length) {
    list.forEach((item) => {
      if (SEASON_KEYWORDS.includes(item)) {
        resultObject.season.push(item)
      } else if (OCCASION_KEYWORDS.includes(item)) {
        resultObject.occasion.push(item)
      } else {
        resultObject.keywords.push(item)
      }
    })
  }
  return resultObject
}

export interface DraftFormProps {
  name: string
  description?: string
  category?: string
  tags?: string[]
  brandName?: string
  type?: string
  systemName: string
  imageUrls: ImageReference[]
  keywords: string[] | []
  keywords_season: string[] | []
  keywords_occasion: string[] | []
  variants?: DraftVariantFragment[]
  estimatedShippingTime?: EstimatedShippingTime
  overrideName: Boolean
  overrideDescription: Boolean
  overrideImageUrls: Boolean
  phoneNumberRequired: Boolean
  variantOrderFlexibility: Boolean
}

//TODO: make this generic work - should take in a type of DraftForm props and DraftVariantForm props
export interface DraftCardProps<T> {
  form: T
  setForm: Updater<T>
  isLoading?: boolean
  productId?: string
  draft?: DraftMarketplaceProductFragment
  isShopify?: boolean
  handleShopifyUpdate?: (input: string) => void
}

export const DraftProductEditV2: React.FC = () => {
  const { productId }: { productId?: string } = useParams()
  const [selectedAccountId, setSelectedAccountId] = useState<string>()

  const Alert = useAlerts()
  const navigate = useNavigate()

  const [isDirty, setIsDirty] = useState(false)

  const cancelDisclosure = useDisclosure()
  const confirmRequestPublicListing = useDisclosure()
  const confirmRequestPrivateListing = useDisclosure()
  const confirmDeleteDraft = useDisclosure()

  const requestPrivateListing = useGraphqlMutation(RequestPrivateListingDocument)
  const requestPublicListing = useGraphqlMutation(RequestPublicListingDocument)

  const createDraft = useGraphqlMutation(CreateDraftMarketplaceProductDocument)
  const updateDraft = useGraphqlMutation(UpdateDraftMarketplaceProductDocument)
  const deleteDraft = useGraphqlMutation(DeleteDraftMarketplaceProductDocument)
  const productAccessList = useGraphqlQuery(GetAssociatedAccountsDocument)

  const associatedAccounts = productAccessList?.data?.getAssociatedAccounts?.filter(
    (a) => a?.productAccess?.product === 'POSTAL_IO_APP'
  )

  const getDraft = useGraphqlQuery(GetDraftMarketplaceProductDocument, { id: productId }, { enabled: !!productId })

  const draft = getDraft?.data?.getDraftMarketplaceProduct as any

  const { externalId, externalCreatedAt, externalUpdatedAt } = draft || {}

  // show info message for shopify users to create variants inside Shopify
  const [variantCreateDisabledMessage, setVariantCreateDisabledMessage] = useLocalStorage(
    'postal-vendor:variantCreateDisabledMessage',
    true
  )

  // shopify draft variants cannot be created or edited
  const isShopify = useMemo(() => draft?.systemName === 'shopify', [draft?.systemName])

  useAlertError(getDraft.error)

  const [form, setForm] = useImmer<DraftFormProps>({} as DraftFormProps)
  const formRef = useRef({})

  // checking to see if form was modified
  useEffect(() => {
    if (!isEqual(form, formRef.current)) {
      setIsDirty(true)
    } else {
      setIsDirty(false)
    }
  }, [form])

  useEffect(() => {
    const keywordsObj = keywordMapper(draft?.keywords)
    const formData = {
      name: draft?.name || '',
      description: sanitize(draft?.description) || '',
      category: draft?.category,
      tags: draft?.tags || [],
      brandName: draft?.brandName || '',
      type: draft?.type || '',
      systemName: draft?.systemName || 'manual',
      imageUrls: draft?.imageUrls || [],
      keywords: keywordsObj?.keywords,
      keywords_season: keywordsObj?.season,
      keywords_occasion: keywordsObj?.occasion,
      estimatedShippingTime: draft?.estimatedShippingTime || EstimatedShippingTime.None,
      overrideName: draft?.overrideName,
      overrideDescription: draft?.overrideDescription,
      overrideImageUrls: isNull(draft?.overrideImageUrls) ? true : draft?.overrideImageUrls,
      phoneNumberRequired: draft?.phoneNumberRequired,
      variantOrderFlexibility: draft?.variantOrderFlexibility,
    }

    setForm(() => formData)
    formRef.current = formData
  }, [draft, productId, setForm])

  const { defaultAccountId, hasManyAccounts } = useMemo(() => {
    let defaultAccountId: string | null = null
    let hasManyAccounts: boolean = false
    const numAcccounts = associatedAccounts?.length ?? 0
    if (numAcccounts === 1) {
      defaultAccountId = associatedAccounts?.[0]?.productAccess?.accountId
    } else if (numAcccounts > 1) {
      hasManyAccounts = true
    }
    return { defaultAccountId, hasManyAccounts }
  }, [associatedAccounts])

  const StatusTag = useGetDraftStatus(draft)

  const handleBack = () => {
    if (isDirty) {
      cancelDisclosure.onOpen()
    } else {
      navigate('/products', { state: { useSavedPage: true } })
    }
  }

  const handleFieldErrors = (form: DraftFormProps) => {
    if (!form.name) {
      Alert.error('Please provide a draft product name.')
      return true
    }
    if (!form.category) {
      Alert.error('Please add a Product Category')
      return true
    }
    // category must be truthy at this point
    // require product type - only if associated types are available
    if ((TYPES_V2[form.category]?.length ?? 0) > 0 && !form.type) {
      Alert.error('Please add a Subcategory')
      return true
    }
    if (form.variants?.some((v) => !v.sku?.trim())) {
      Alert.error('Please include a SKU on all variants')
      return true
    }
    return false
  }

  const handleProductEdit = async (fieldName?: string) => {
    if (handleFieldErrors(form)) return
    //combine different keyword lists into one
    const combinedKeywords = [...new Set([...form.keywords, ...form.keywords_occasion, ...form.keywords_season])]

    const data = {
      ...form,
      // we aren't using the form to update these fields, but the backend still requires them
      externalId: externalId || null,
      externalCreatedAt: externalCreatedAt || null,
      externalUpdatedAt: externalUpdatedAt || null,
    } as any

    data.description = sanitize(form.description)
    data.tags = compact(uniq(data?.tags?.map((t: string) => t.trim())))
    data.keywords = compact(uniq(combinedKeywords.map((t) => t.trim())))
    delete data.keywords_occasion
    delete data.keywords_season

    //this ties the product fields back to shopify on the next sync
    if (isShopify && fieldName) {
      switch (fieldName) {
        case 'name':
          data.overrideName = false
          break
        case 'description':
          data.overrideDescription = false
          break
        case 'imageUrls':
          data.overrideImageUrls = false
          break
      }
    }

    if (draft?.id) {
      const response = await updateDraft.mutateAsync({
        id: draft?.id,
        data,
      })
      const { id } = response.updateDraftMarketplaceProduct
      if (id) {
        Alert.success('Product updated')
        return id
      }
    } else {
      const response = await createDraft.mutateAsync({ data })
      const { id } = response.createDraftMarketplaceProduct
      if (id) {
        Alert.success('Product created')
        return id
      }
    }
  }

  const handleRequestPublicListing = async () => {
    if (!form.brandName) {
      confirmRequestPublicListing.onClose()
      return Alert.error('Please provide a Brand Name before you request a listing.')
    }

    if (!form.category) {
      confirmRequestPublicListing.onClose()
      return Alert.error('Please provide a category before you request a listing.')
    }
    try {
      if (isShopify) {
        await requestPublicListing.mutateAsync({ id: draft?.id })
      } else {
        const id = await handleProductEdit()
        await requestPublicListing.mutateAsync({ id })
      }
      Alert.success('Request submitted')
    } catch (err) {
      Alert.error(err)
    } finally {
      confirmRequestPublicListing.onClose()
    }
  }

  const handleRequestPrivateListing = async () => {
    const accountId = !hasManyAccounts ? defaultAccountId : selectedAccountId

    if (!form.brandName) {
      confirmRequestPrivateListing.onClose()
      return Alert.error('Please provide a Brand Name before you request a listing.')
    }

    if (!form.category) {
      confirmRequestPrivateListing.onClose()
      return Alert.error('Please provide a category before you request a listing.')
    }
    if (!accountId) {
      confirmRequestPrivateListing.onClose()
      return Alert.error('You must be have a Postal.io User to make this request')
    }
    try {
      if (isShopify) {
        await requestPrivateListing.mutateAsync({ id: draft?.id, accountId })
      } else {
        const id = await handleProductEdit()
        await requestPrivateListing.mutateAsync({ id, accountId })
      }
      Alert.success('Request submitted')
    } catch (err) {
      Alert.error(err)
    } finally {
      confirmRequestPrivateListing.onClose()
    }
  }

  const handleDeleteDraft = async () => {
    try {
      await deleteDraft.mutateAsync({ id: draft?.id })
      Alert.success('Draft deleted')
      navigate(`/products`)
    } catch (err) {
      Alert.error(err)
    } finally {
      confirmDeleteDraft.onClose()
    }
  }

  const handleCancel = () => {
    if (Object.keys(formRef.current).length > 0) {
      setForm(formRef.current as DraftFormProps)
    }
    setIsDirty(false)
    cancelDisclosure.onClose()
    navigate(`/products`)
  }

  const handleOnSubmit = async (e: FormEvent) => {
    e.preventDefault()

    try {
      const id = await handleProductEdit()
      //Want to stay on the Draft Product Page
      if (id) await navigate(`/products/${id}`)
    } catch (err) {
      Alert.error(err)
    }
  }

  const isLoading = updateDraft.isLoading || deleteDraft.isLoading || getDraft.isLoading
  const hasOverrides = useMemo(() => {
    return [form.overrideDescription, form.overrideImageUrls, form.overrideName].some((i) => i === false)
  }, [form.overrideDescription, form.overrideImageUrls, form.overrideName])

  return (
    <>
      {!!draft?.id ? (
        <>
          <DropBar isDirty={isDirty}>
            <DropBarSecondaryButton onClick={cancelDisclosure.onOpen}>Cancel</DropBarSecondaryButton>
            <DropBarPrimaryButton
              type="submit"
              form="draftProductForm"
              isDisabled={isLoading}
            >
              Save Draft
            </DropBarPrimaryButton>
          </DropBar>

          {isShopify && variantCreateDisabledMessage && hasOverrides && (
            <UiAlert
              status="info"
              title="Some product fields have been connected to the Shopify instance. Additional updates to these fields need to be done in Shopify and resynced."
              onClose={() => setVariantCreateDisabledMessage(false)}
              mb={0}
            />
          )}
          <UiSubNavbar
            left={
              <Flex alignItems={'center'}>
                <BackButton onClick={handleBack} />
                <Heading
                  mr={4}
                  fontSize="2xl"
                >
                  {productId ? `${draft?.name}` : 'Add Product'}
                </Heading>
                <StatusTag />
              </Flex>
            }
            right={
              <>
                <DraftButtonGroup
                  isShopify={isShopify}
                  showDraftBtn={false}
                  onPrivateRequest={confirmRequestPrivateListing.onOpen}
                  onPublicRequest={confirmRequestPublicListing.onOpen}
                  onDeleteDraft={confirmDeleteDraft.onOpen}
                  isDraft={(draft?.status ?? DraftStatus.Draft) === DraftStatus.Draft}
                />
              </>
            }
            boxShadow="none"
            border="none"
            mb={0}
            maxW="1800px"
            gridProps={{ p: 4 }}
          />
        </>
      ) : (
        <UiSkeleton
          startColor="white"
          endColor="white"
          isLoaded={!getDraft.isLoading}
        >
          <DropBar isStatic>
            <UiLink
              fontSize={'xl'}
              color={'turquoise'}
              onClick={handleCancel}
              alignItems={'center'}
              justifyContent={'space-between'}
              w="100%"
              isDisabled={isLoading}
            >
              Cancel
            </UiLink>
            <UiButton
              size="sm"
              colorScheme="turquoise"
              color="primary.900"
              fontSize="md"
              letterSpacing="0.5px"
              px={6}
              type="submit"
              form="draftProductForm"
              isDisabled={isLoading}
            >
              Save as Draft
            </UiButton>
          </DropBar>
          <UiSubNavbar
            left={
              <Flex alignItems={'center'}>
                <Heading
                  mr={4}
                  fontSize="2xl"
                >
                  {'Add Product'}
                </Heading>
              </Flex>
            }
            maxW="1800px"
            gridProps={{ p: 4 }}
          />
        </UiSkeleton>
      )}
      <DraftProductPageForm
        onSubmit={handleOnSubmit}
        isLoading={isLoading}
        draft={draft}
        form={form}
        setForm={setForm}
        productId={productId}
        handleShopifyUpdate={handleProductEdit}
      />

      {cancelDisclosure.isOpen && (
        <CancelFormModal
          onConfirm={handleCancel}
          {...cancelDisclosure}
        />
      )}

      {confirmRequestPublicListing.isOpen && !!draft.variants?.length ? (
        <UiConfirm
          title="Request Public Listing"
          isOpen={confirmRequestPublicListing.isOpen}
          onConfirm={handleRequestPublicListing}
          onClose={confirmRequestPublicListing.onClose}
        >
          <UiText>Do you want to request a Public Listing for this product?</UiText>
        </UiConfirm>
      ) : (
        <MissingVariantsModal {...confirmRequestPublicListing} />
      )}

      {confirmRequestPrivateListing.isOpen && !!draft?.variants?.length ? (
        <UiConfirm
          title="Publish to your Account"
          isOpen={confirmRequestPrivateListing.isOpen}
          onConfirm={handleRequestPrivateListing}
          onClose={confirmRequestPrivateListing.onClose}
          isDisabled={hasManyAccounts && !selectedAccountId}
        >
          <UiText mb={0}>This will make the product available in your Postal.io account’s marketplace.</UiText>
          {hasManyAccounts && (
            <>
              <UiAlert
                mt={4}
                fontSize="15px"
                status="warning"
                hideClose
                title={`There are multiple accounts connected to this User. Please select the correct one.`}
              />
              <UiSelect
                placeholder="Select an account..."
                onChange={(e) => setSelectedAccountId(e.target.value)}
                value={selectedAccountId}
                mt={4}
              >
                {associatedAccounts?.map((account) => {
                  const isPostalAdmin = account?.productAccess?.roles?.includes('ADMIN')
                  return (
                    <option
                      value={account?.productAccess?.accountId}
                      disabled={!isPostalAdmin}
                    >
                      {account?.accountName}
                      {isPostalAdmin || ' - Admin Role Required'}
                    </option>
                  )
                })}
              </UiSelect>
              <UiText
                fontStyle="italic"
                mt={4}
                mb={0}
              >
                Please note: if this product was already published under a different account, that entry will be deleted
                when you complete this action.
              </UiText>
            </>
          )}

          <UiText
            mt={4}
            mb={0}
          >
            Are you ready to publish this product?
          </UiText>
        </UiConfirm>
      ) : (
        <MissingVariantsModal {...confirmRequestPrivateListing} />
      )}

      {confirmDeleteDraft.isOpen && (
        <UiConfirm
          size="lg"
          title="Confirm Delete Draft"
          isOpen={confirmDeleteDraft.isOpen}
          onConfirm={handleDeleteDraft}
          onClose={confirmDeleteDraft.onClose}
          buttonColor="red"
          isLoading={deleteDraft.isLoading}
          isDisabled={deleteDraft.isLoading}
        >
          <UiText>
            The draft <strong>{draft?.name}</strong> will be deleted.
            <br />
            <br />
            Please confirm product deletion. This cannot be undone.
          </UiText>
        </UiConfirm>
      )}
    </>
  )
}

export const DraftButtonGroup: React.FC<{
  onPublicRequest: () => void
  onPrivateRequest: () => void
  onDeleteDraft: () => void
  showDraftBtn?: boolean
  isShopify?: boolean
  isDraft?: boolean
}> = ({ onPublicRequest, onPrivateRequest, onDeleteDraft, isShopify, showDraftBtn = true, isDraft }) => {
  return (
    <ButtonGroup>
      {showDraftBtn && (
        <UiButton
          size="sm"
          colorScheme="turquoise"
          color="primary.900"
          fontSize="md"
          letterSpacing="0.5px"
          px={6}
          type="submit"
          form="draftProductForm"
        >
          Save as Draft
        </UiButton>
      )}

      <UiMenu closeOnSelect={false}>
        <UiMenuButton
          borderRadius={'5px'}
          border={'none'}
          size="sm"
          colorScheme="turquoise"
          color="primary.900"
          fontWeight="bold"
          fontSize="md"
          letterSpacing="0.5px"
          py={'10px'}
          px={6}
        >
          {isShopify ? 'SEND FOR APPROVAL' : 'SAVE AND SEND FOR APPROVAL'}
        </UiMenuButton>
        <UiMenuList
          borderRadius={0}
          fontSize="md"
        >
          <UiMenuItemOption onClick={onPublicRequest}>Request Public Listing</UiMenuItemOption>
          <UiMenuItemOption onClick={onPrivateRequest}>Request Private Listing</UiMenuItemOption>
        </UiMenuList>
      </UiMenu>
      <Tooltip
        label="Only products with draft status can be deleted"
        placement="top-start"
        hasArrow
        shouldWrapChildren
        isDisabled={isDraft}
        closeOnClick={false}
      >
        <UiIconButton
          aria-label="Delete"
          variant="outline"
          icon={<DeleteIcon />}
          colorScheme="gray"
          borderRadius="5px"
          size="sm"
          fontWeight="bold"
          fontSize="md"
          letterSpacing="0.5px"
          py="10px"
          px={6}
          onClick={onDeleteDraft}
          isDisabled={!isDraft}
        />
      </Tooltip>
    </ButtonGroup>
  )
}

export const MissingVariantsModal: React.FC<{ isOpen: boolean; onClose: () => void }> = ({ onClose, isOpen }) => {
  return (
    <UiDialog
      size="4xl"
      title="Missing Draft Variants"
      status="info"
      onClose={onClose}
      isOpen={isOpen}
    >
      <Text textAlign={'center'}>
        Please add at least one variant to your draft product before continuing with this request.
      </Text>
    </UiDialog>
  )
}
