import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
// @ts-ignore
import createActivityDetector from 'activity-detector'
import { useSession } from 'hooks'
import React, { useCallback, useEffect } from 'react'

const IDLE_TIMEOUT = 30 * 1000 * 60 // 30 minutes
const IDLE_WARNING = 30 * 1000 // 30 seconds

// const IDLE_TIMEOUT = 5000 // for testing
// const IDLE_WARNING = 5000 // for testing

export const Timeout = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const timerRef = React.useRef<NodeJS.Timeout>()
  const detectorRef = React.useRef<any>()
  const { logout } = useSession()

  const stopLogout = useCallback(() => {
    if (timerRef.current) clearTimeout(timerRef.current)
    onClose()
  }, [onClose])

  const startLogout = useCallback(() => {
    stopLogout()
    timerRef.current = setTimeout(() => logout(), IDLE_WARNING)
    onOpen()
  }, [logout, onOpen, stopLogout])

  const stopDetector = useCallback(() => {
    stopLogout()
    if (detectorRef.current) detectorRef?.current?.stop()
  }, [stopLogout])

  const startDetector = useCallback(() => {
    stopDetector()
    detectorRef.current = createActivityDetector({ timeToIdle: IDLE_TIMEOUT, inactivityEvents: [] })
    detectorRef?.current?.on('idle', startLogout)
    detectorRef?.current?.on('active', stopLogout)
  }, [startLogout, stopDetector, stopLogout])

  useEffect(() => {
    startDetector()
    return () => stopDetector()
  }, [startDetector, stopDetector])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Session Timeout</ModalHeader>
        <ModalBody>
          <Text>Your Session is about to expire. Click anywhere on the screen to continue.</Text>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  )
}
