import { Grid, Heading } from '@chakra-ui/react'
import { useGraphqlQuery } from '@postal-io/postal-graphql'
import { useAlertError } from '@postal-io/postal-ui'
import { Layout, SubNavbar } from 'components/Common'
import { SidebarNav } from 'components/Common/SidebarNav'
import { ProfileSidebar } from 'components/Profile'
import { useParams } from 'react-router-dom'
import { GetVendorPayableDocument } from '../../api/index'
import { VendorFulfillmentsSearchCard } from './VendorFulfillmentsSearchCard'
import { VendorPayableDetailsCard } from './VendorPayableDetailsCard'

export const VendorPayableDetailsPage = () => {
  const { vendorPayableId }: any = useParams()
  const { data, isLoading, isFetching, error } = useGraphqlQuery(
    GetVendorPayableDocument,
    { id: vendorPayableId },
    { enabled: !!vendorPayableId }
  )
  const vendorPayable = data?.getVendorPayable
  useAlertError(error)

  return (
    <Layout pt={0}>
      <SidebarNav blocks={<ProfileSidebar />}>
        <SubNavbar
          left={<Heading fontSize="2xl">{`Vendor Payable #${vendorPayable?.id}`}</Heading>}
          boxShadow="none"
          border="none"
          maxW="1800px"
          gridProps={{ py: 6, px: 0 }}
          containerProps={{ p: 0 }}
          mb={0}
        />
        <Grid gap={8}>
          <VendorPayableDetailsCard
            boxShadow={'none'}
            vendorPayable={vendorPayable}
            isLoading={isLoading}
            isFetching={isFetching}
          />
          <VendorFulfillmentsSearchCard boxShadow={'none'} />
        </Grid>
      </SidebarNav>
    </Layout>
  )
}
