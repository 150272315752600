import { Stack } from '@chakra-ui/react'
import { useGraphqlInfiniteQuery } from '@postal-io/postal-graphql'
import { UiCard, UiSSDataTable, useAlertError, useGraphqlFilter } from '@postal-io/postal-ui'
import { ApprovedPostal, SearchEventsDocument, SearchEventsQueryVariables, Status } from 'api'
import { Layout } from 'components/Common'
import { useCurrentPage } from 'hooks'
import { StorageKeys } from 'lib'
import { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDebounce } from 'use-debounce'
import { FILTER_OPTIONS, SEARCH_COLUMNS } from './data'
import { EventsFilter } from './EventsFilter'
import { EventsSubNavbar } from './EventsSubNavbar'

export const EventsPage = () => {
  const navigate = useNavigate()
  const { state: useSavedPage } = useLocation()
  const [currentPage, setCurrentPage] = useCurrentPage(StorageKeys.EventsCurrentPage, useSavedPage as boolean)

  // graphql filter hook
  const graphqlFilter = useGraphqlFilter<SearchEventsQueryVariables>(FILTER_OPTIONS)

  // debounce to accomodate typing
  const [debouncedVariables] = useDebounce(graphqlFilter.variables, 400)

  const { mergedData, isFetching, error, fetchNextPage, hasNextPage } = useGraphqlInfiniteQuery(
    SearchEventsDocument,
    debouncedVariables,
    { refetchInterval: 300000, refetchIntervalInBackground: true, keepPreviousData: true } // 5 minutes
  )
  const events = useMemo(() => mergedData?.searchEvents || [], [mergedData?.searchEvents])
  useAlertError(error)

  const getActiveVariant = useCallback((event: ApprovedPostal) => {
    const variants = event.variants?.filter((v) => v.status === Status.Active) || []
    return variants.map((v) => v.variantName).join(', ')
  }, [])

  const cleanedEvents = useMemo(() => {
    return events.map((event) => ({
      ...event,
      variant: getActiveVariant(event),
      handleRowClick: () => navigate(`/events/${event.id}`),
    }))
  }, [events, getActiveVariant, navigate])

  return (
    <>
      <EventsSubNavbar />
      <Layout pt={0}>
        <UiCard boxShadow={'none'}>
          <Stack spacing={4}>
            <EventsFilter
              filter={graphqlFilter.filter}
              setFilter={graphqlFilter.setFilter}
              isFetching={isFetching}
            />
            <UiSSDataTable
              variant="list"
              isLoading={isFetching}
              page={currentPage}
              setPage={setCurrentPage}
              columns={SEARCH_COLUMNS}
              rows={cleanedEvents}
              rowKey="id"
              filter={graphqlFilter.variables.filter}
              fetchMore={fetchNextPage}
              hasMore={hasNextPage}
              orderBy={graphqlFilter.orderBy}
              onOrderBy={graphqlFilter.setOrderBy}
              pagerOptions={{ showFirstPage: true, hotkeysEnabled: true }}
            />
          </Stack>
        </UiCard>
      </Layout>
    </>
  )
}
