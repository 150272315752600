import { Flex } from '@chakra-ui/react'
import { UiIconPostalLogo } from '@postal-io/postal-ui'
import React from 'react'
import { useMatch } from 'react-router-dom'

export const Main: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const signUp = useMatch('/sign-up')

  return (
    <Flex
      flexDir="column"
      minH="100vh"
      w="100%"
    >
      {signUp ? (
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          w="100%"
          mt={5}
        >
          {children}
        </Flex>
      ) : (
        <>
          <Flex
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            w="100%"
            maxW="600px"
            mx="auto"
            mt={12}
            px={5}
          >
            <UiIconPostalLogo
              color="primary.500"
              fontSize="200px"
              h="auto"
            />
            {children}
          </Flex>
        </>
      )}
    </Flex>
  )
}
