import { Grid } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  UiCard,
  UiCardHeader,
  UiFormControl,
  UiFormLabel,
  UiInfoTooltip,
  UiSwitch,
  useAlertError,
  useAlerts,
} from '@postal-io/postal-ui'
import { MeDocument, UpdateVendorNotificationsDocument, VendorNotificationsFragment } from 'api'
import { useAcl } from 'hooks'
import { cloneDeep } from 'lodash'
import { ChangeEvent, useCallback, useEffect, useMemo } from 'react'
import { useImmer } from 'use-immer'

export const ProfileNotifications: React.FC = () => {
  const { hasFeature } = useAcl()

  const meQuery = useGraphqlQuery(MeDocument)
  const updateVendorNotifications = useGraphqlMutation(UpdateVendorNotificationsDocument)

  const Alert = useAlerts()

  const vendorNotifications = useMemo(
    () => meQuery.data?.me?.vendorNotifications || ({} as VendorNotificationsFragment),
    [meQuery.data?.me?.vendorNotifications]
  )

  const [form, setForm] = useImmer<Record<string, any>>({})

  const resetForm = useCallback(() => {
    setForm((draft: VendorNotificationsFragment) => {
      draft.eventConfirmation = vendorNotifications.eventConfirmation
      draft.newEvent = vendorNotifications.newEvent
      draft.unconfirmedOrders = vendorNotifications.unconfirmedOrders
      draft.unshippedOrders = vendorNotifications.unshippedOrders
      draft.eventCancelled = vendorNotifications.eventCancelled
      draft.registrationClosed = vendorNotifications.registrationClosed
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    vendorNotifications.eventCancelled,
    vendorNotifications.newEvent,
    vendorNotifications.eventConfirmation,
    vendorNotifications.registrationClosed,
    vendorNotifications.unconfirmedOrders,
    vendorNotifications.unshippedOrders,
  ])

  useEffect(() => {
    resetForm()
  }, [resetForm])

  const handleVendorNotificationChange = async ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = target

    setForm((draft: Record<string, any>) => {
      draft[name].toggleOn = checked
    })

    const data = cloneDeep(form) as any
    data[name].toggleOn = checked

    try {
      await updateVendorNotifications.mutateAsync({
        id: meQuery.data?.me?.id,
        data: {
          eventConfirmation: data.eventConfirmation,
          newEvent: data.newEvent,
          unconfirmedOrders: data.unconfirmedOrders,
          unshippedOrders: data.unshippedOrders,
          registrationClosed: data.registrationClosed,
          eventCancelled: data.eventCancelled,
        },
      })
      await meQuery.refetch()
      Alert.success('Notifications updated.')
    } catch (err) {
      resetForm()
      Alert.error(err)
    }
  }

  useAlertError(meQuery.error)
  useAlertError(updateVendorNotifications.error)

  const isLoading = updateVendorNotifications.isLoading

  return (
    <UiCard
      isFetching={isLoading}
      boxShadow={'none'}
    >
      <UiCardHeader>
        Notifications
        <UiInfoTooltip
          hasArrow
          ml={2}
          placement="right"
          label="Send me an email when the following actions occur."
        />
      </UiCardHeader>

      <Grid
        gap={8}
        mt={8}
      >
        <UiFormControl
          id="eventConfirmation"
          display="flex"
          alignItems="center"
        >
          <UiSwitch
            size="lg"
            name="eventConfirmation"
            isChecked={!!form.eventConfirmation?.toggleOn}
            isDisabled={isLoading}
            onChange={handleVendorNotificationChange}
          />
          <UiFormLabel
            ml={4}
            mb={0}
            fontSize="xl"
          >
            Event Confirmation
          </UiFormLabel>
        </UiFormControl>
        <UiFormControl
          id="newEvent"
          display="flex"
          alignItems="center"
        >
          <UiSwitch
            size="lg"
            name="newEvent"
            isChecked={!!form.newEvent?.toggleOn}
            isDisabled={isLoading}
            onChange={handleVendorNotificationChange}
          />
          <UiFormLabel
            ml={4}
            mb={0}
            fontSize="xl"
          >
            New Event Request
          </UiFormLabel>
        </UiFormControl>
        {hasFeature('unconfirmedNotifications') && (
          <UiFormControl
            id="unconfirmedOrders"
            display="flex"
            alignItems="center"
          >
            <UiSwitch
              size="lg"
              name="unconfirmedOrders"
              isChecked={!!form.unconfirmedOrders?.toggleOn}
              isDisabled={isLoading}
              onChange={handleVendorNotificationChange}
            />
            <UiFormLabel
              ml={4}
              mb={0}
              fontSize="xl"
            >
              Unconfirmed Orders
            </UiFormLabel>
          </UiFormControl>
        )}
        <UiFormControl
          id="unshippedOrders"
          display="flex"
          alignItems="center"
        >
          <UiSwitch
            size="lg"
            name="unshippedOrders"
            isChecked={!!form.unshippedOrders?.toggleOn}
            isDisabled={isLoading}
            onChange={handleVendorNotificationChange}
          />
          <UiFormLabel
            ml={4}
            mb={0}
            fontSize="xl"
          >
            Unshipped Orders
          </UiFormLabel>
        </UiFormControl>
        <UiFormControl
          id="eventCancelled"
          display="flex"
          alignItems="center"
        >
          <UiSwitch
            size="lg"
            name="eventCancelled"
            isChecked={!!form.eventCancelled?.toggleOn}
            isDisabled={isLoading}
            onChange={handleVendorNotificationChange}
          />
          <UiFormLabel
            ml={4}
            mb={0}
            fontSize="xl"
          >
            Event Cancelled
          </UiFormLabel>
        </UiFormControl>
        <UiFormControl
          id="registrationClosed"
          display="flex"
          alignItems="center"
        >
          <UiSwitch
            size="lg"
            name="registrationClosed"
            isChecked={!!form.registrationClosed?.toggleOn}
            isDisabled={isLoading}
            onChange={handleVendorNotificationChange}
          />
          <UiFormLabel
            ml={4}
            mb={0}
            fontSize="xl"
          >
            Registration Closed
          </UiFormLabel>
        </UiFormControl>
      </Grid>
    </UiCard>
  )
}
