import { ButtonGroup, Heading, useDisclosure } from '@chakra-ui/react'
import { UiButton, UiSubNavbar } from '@postal-io/postal-ui'
import { PostalFulfillmentFilterInput, ReportType } from 'api'
import { useReport } from 'hooks'
import { cloneDeep } from 'lodash'
import React from 'react'
import { OrderImports } from './OrderImports'
import { OrderSavedExports } from './OrderSavedExports'
import { SELECT_FULFILLMENT_STATUSES } from './ordersData'

interface OrdersSubNavbarProps {
  exportFilter: PostalFulfillmentFilterInput
  refetch: () => void
}

export const OrdersSubNavbar: React.FC<OrdersSubNavbarProps> = ({ exportFilter, refetch }) => {
  const { createReport, isLoading: reportIsLoading } = useReport(ReportType.PostalFulfillmentPartner)

  const orderSavedExportsDisclosure = useDisclosure()
  const orderImportsDisclosure = useDisclosure()

  const handleExport = async () => {
    if (exportFilter.status === undefined) {
      exportFilter = cloneDeep(exportFilter)
      exportFilter.status = { in: SELECT_FULFILLMENT_STATUSES }
    }
    await createReport(exportFilter)
    refetch()
  }

  return (
    <>
      <UiSubNavbar
        left={<Heading fontSize="2xl">Orders</Heading>}
        right={
          <ButtonGroup spacing={2}>
            <UiButton
              variant="ghost"
              size="sm"
              fontSize="sm"
              letterSpacing="0.5px"
              px={6}
              onClick={() => orderImportsDisclosure.onOpen()}
              isDisabled={reportIsLoading}
            >
              BULK IMPORT
            </UiButton>
            <UiButton
              variant="ghost"
              size="sm"
              fontSize="sm"
              letterSpacing="0.5px"
              px={6}
              onClick={handleExport}
              isLoading={reportIsLoading}
              isDisabled={reportIsLoading}
            >
              BULK EXPORT
            </UiButton>
            <UiButton
              size="sm"
              colorScheme="turquoise"
              color="primary.900"
              fontSize="sm"
              letterSpacing="0.5px"
              px={6}
              onClick={() => orderSavedExportsDisclosure.onOpen()}
            >
              SAVED EXPORTS
            </UiButton>
          </ButtonGroup>
        }
        boxShadow="none"
        border="none"
        maxW="1800px"
        gridProps={{ p: 4 }}
        mb={0}
      />

      {orderSavedExportsDisclosure.isOpen && (
        <OrderSavedExports
          isOpen={orderSavedExportsDisclosure.isOpen}
          onClose={() => orderSavedExportsDisclosure.onClose()}
        />
      )}
      {orderImportsDisclosure.isOpen && (
        <OrderImports
          isOpen={orderImportsDisclosure.isOpen}
          onClose={() => orderImportsDisclosure.onClose()}
          onSuccess={refetch}
        />
      )}
    </>
  )
}
