import { ReactElement, useMemo } from 'react'
import { AclFilter, useAcl } from './useAcl'

export interface UseRouteProps extends AclFilter {
  path?: string | string[]
  element?: ReactElement
}

/**
 * Pass in an array of routes and this hook filters out the ones
 * that that have permission for the module/permissions/feature defined
 */
export const useRoutesAcl = (routes: UseRouteProps[]) => {
  const { aclFilter } = useAcl()
  return useMemo(() => aclFilter(routes), [aclFilter, routes])
}
