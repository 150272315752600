import { Flex, Grid, Heading, useDisclosure } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import { UiButton, UiLink, UiSkeleton, UiSubNavbar, useAlerts } from '@postal-io/postal-ui'
import {
  AddDraftMarketplaceProductVariantDocument,
  DraftMarketplaceProductFragment,
  DraftVariantFragment,
  DraftVariantInput,
  GetDraftMarketplaceProductDocument,
  ImageReference,
  RemoveDraftMarketplaceProductVariantDocument,
  ShippingOptionInput,
  UpdateDraftMarketplaceProductVariantDocument,
} from 'api'
import { CancelFormModal, DropBar } from 'components/Common'
import { BackButton, Layout } from 'components/Common/Layout'
import { isEqual, isNull, isUndefined } from 'lodash'
import { FormEvent, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useImmer } from 'use-immer'
import { DropBarPrimaryButton, DropBarSecondaryButton } from '../Common/DropBar'
import { DraftProductMediaCard } from './DraftProductMediaCard'
import { DraftProductsVariantDetailsCard } from './DraftProductVariantDetailsCard'
import { DraftVariantShippingOptionsCard } from './DraftVariantShippingOptionsCard'

export interface DraftVariantFormProps {
  variantName?: string
  sku?: string | null
  description?: string
  fulfillmentType?: string
  upcCode?: string | null
  imageUrls: ImageReference[]
  price?: number | null
  quantity?: number | null
  taxable?: boolean
  physicalMessageSupported?: boolean
  requiresShipping?: boolean
  shippingOptions?: ShippingOptionInput[]
  overrideVariantName: Boolean
  overrideImageUrls: Boolean
  containsPerishables: Boolean
}

export const DraftVariantEditV2: React.FC = () => {
  const Alert = useAlerts()
  const navigate = useNavigate()
  const cancelDisclosure = useDisclosure()
  const formRef = useRef({})

  const [form, setForm] = useImmer<DraftVariantFormProps>({} as DraftVariantFormProps)
  const [isDirty, setIsDirty] = useState(false)

  const { productId }: { productId?: string } = useParams()
  const { variantId }: { variantId?: string } = useParams()

  const create = useGraphqlMutation(AddDraftMarketplaceProductVariantDocument)
  const update = useGraphqlMutation(UpdateDraftMarketplaceProductVariantDocument)
  const remove = useGraphqlMutation(RemoveDraftMarketplaceProductVariantDocument)

  const getDraft = useGraphqlQuery(GetDraftMarketplaceProductDocument, { id: productId }, { enabled: !!productId })

  const draft = getDraft?.data?.getDraftMarketplaceProduct as DraftMarketplaceProductFragment

  // shopify draft variants cannot be created or edited
  const isShopify = useMemo(() => draft?.systemName === 'shopify', [draft?.systemName])

  const variant = useMemo(() => {
    if (variantId) {
      return draft?.variants?.find((v) => v.id === variantId)
    }
    return {} as DraftVariantFragment
  }, [draft?.variants, variantId])

  useEffect(() => {
    if (getDraft.isFetched) {
      const hasShippingOptions = (variant?.shippingOptions?.length ?? 0) > 0
      const formData = {
        variantName: variant?.variantName || '',
        description: variant?.description || '',
        fulfillmentType: variant?.fulfillmentType || '',
        sku: variant?.sku || '',
        upcCode: variant?.upcCode || '',
        price: variant?.price || 0,
        quantity: variant?.quantity,
        taxable: isUndefined(variant?.taxable) ? true : variant?.taxable,
        physicalMessageSupported: !!variant?.physicalMessageSupported || false,
        requiresShipping: variant?.requiresShipping ?? true,
        shippingOptions: hasShippingOptions ? variant?.shippingOptions : ([] as any),
        imageUrls: variant?.imageUrls || [],
        overrideVariantName: isNull(variant?.overrideVariantName) ? true : variant?.overrideVariantName,
        overrideImageUrls: isNull(variant?.overrideImageUrls) ? true : variant?.overrideImageUrls,
        containsPerishables: !!variant?.containsPerishables ?? false,
      }
      setForm(() => formData)
      formRef.current = formData
    }
  }, [getDraft.isFetched, setForm, variant])

  //checking to see if form was modified
  useEffect(() => {
    if (!isEqual(form, formRef.current)) {
      setIsDirty(true)
    } else {
      setIsDirty(false)
    }
  }, [form])

  const isLoading = create.isLoading || update.isLoading || remove.isLoading

  const handleBack = () => {
    navigate(`/products/${productId}`)
  }

  const handleCancel = () => {
    if (Object.keys(formRef.current).length > 0) {
      setForm(formRef.current as DraftVariantFormProps)
    }
    handleBack()
  }

  const handleOnSubmit = (e: FormEvent) => {
    e.preventDefault()
    handleUpdate()
  }

  const handleUpdate = async (fieldName?: string) => {
    const {
      variantName,
      taxable,
      physicalMessageSupported,
      requiresShipping,
      // otherwise this gets included in ...rest
      shippingOptions: _,
      ...rest
    } = form
    let { shippingOptions } = form

    if (shippingOptions?.[0]?.name === undefined || shippingOptions?.[0]?.price === undefined) {
      shippingOptions = undefined
    }

    if (!!requiresShipping && !form.shippingOptions?.length) {
      return Alert.error('This variant requires shipping. Please provide a shipping option below.')
    }

    const data = {
      variantName: variantName ?? '',
      taxable: taxable,
      physicalMessageSupported: physicalMessageSupported,
      requiresShipping: requiresShipping,
      shippingOptions,

      // we aren't using the form to update these fields, but the backend still requires them
      externalId: variant?.externalId,
      externalCreatedAt: variant?.externalCreatedAt,
      externalUpdatedAt: variant?.externalUpdatedAt,
      ...rest,
    } as DraftVariantInput

    //this ties the product fields back to shopify on the next sync
    if (isShopify && fieldName) {
      switch (fieldName) {
        case 'variantName':
          data.overrideVariantName = false
          break
        case 'imageUrls':
          data.overrideImageUrls = false
          break
      }
    }

    try {
      if (variant?.id) {
        await update.mutateAsync({
          id: variant?.id,
          draftProductId: productId,
          data,
        })
        Alert.success('Variant updated and draft saved.')
        handleBack()
      } else {
        await create.mutateAsync({
          draftProductId: productId,
          data,
        })
        Alert.success('Variant created and draft saved.')
        handleBack()
      }
    } catch (err) {
      Alert.error(err)
    }
  }

  return (
    <>
      {!!variantId && (
        <DropBar isDirty={isDirty}>
          <DropBarSecondaryButton onClick={cancelDisclosure.onOpen}>Cancel</DropBarSecondaryButton>
          <DropBarPrimaryButton
            type="submit"
            form="draftVariantForm"
          >
            Save Variant Draft
          </DropBarPrimaryButton>
        </DropBar>
      )}

      <UiSkeleton isLoaded={!getDraft.isLoading}>
        {!!variantId ? (
          <>
            <UiSubNavbar
              left={
                <Flex alignItems={'center'}>
                  <BackButton onClick={handleBack} />
                  <Heading
                    mr={4}
                    fontSize="2xl"
                  >
                    {variantId ? `${variant?.variantName}` : 'Add Variant'}
                  </Heading>
                </Flex>
              }
              maxW="1800px"
              gridProps={{ p: 4 }}
            />
          </>
        ) : (
          <>
            <Flex
              backgroundColor="header.dark"
              width="100%"
              p={4}
              top={0}
              position={'absolute'}
              alignItems={'center'}
            >
              <UiLink
                fontSize={'xl'}
                color={'turquoise'}
                onClick={handleCancel}
                alignItems={'center'}
                justifyContent={'space-between'}
                w="100%"
                isDisabled={isLoading}
              >
                Cancel
              </UiLink>
              <UiButton
                form="draftVariantForm"
                size="sm"
                colorScheme="turquoise"
                color="primary.900"
                fontSize="md"
                letterSpacing="0.5px"
                px={6}
                type="submit"
                isDisabled={isLoading}
              >
                Save Variant Draft
              </UiButton>
            </Flex>
            <UiSubNavbar
              left={
                <Flex alignItems={'center'}>
                  <Heading
                    mr={4}
                    fontSize="2xl"
                  >
                    {'Add Variant'}
                  </Heading>
                </Flex>
              }
              maxW="1800px"
              gridProps={{ p: 4 }}
            />
          </>
        )}
        <Layout pt={0}>
          <form
            onSubmit={handleOnSubmit}
            id="draftVariantForm"
          >
            <Grid
              templateColumns={{ base: '1fr', lg: '0.5fr 1fr' }}
              gap={8}
              mb={8}
            >
              <DraftProductMediaCard
                handleShopifyUpdate={handleUpdate}
                form={form}
                setForm={setForm}
                isShopify={isShopify}
                draft={draft}
              />
              <DraftProductsVariantDetailsCard
                handleShopifyUpdate={handleUpdate}
                form={form}
                setForm={setForm}
                isShopify={isShopify}
                draft={draft}
              />
            </Grid>
            <DraftVariantShippingOptionsCard
              form={form}
              setForm={setForm}
            />
          </form>
        </Layout>
      </UiSkeleton>
      {cancelDisclosure.isOpen && (
        <CancelFormModal
          onConfirm={handleCancel}
          {...cancelDisclosure}
        />
      )}
    </>
  )
}
