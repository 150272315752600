import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null | undefined
export type InputMaybe<T> = T | null | undefined
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Instant: any
  Object: any
  ObjectId: any
  Url: any
}

export type ActionRecord = {
  __typename?: 'ActionRecord'
  actor?: Maybe<Scalars['String']>
  dateTime?: Maybe<Scalars['Instant']>
}

export type Address = {
  __typename?: 'Address'
  address1?: Maybe<Scalars['String']>
  address2?: Maybe<Scalars['String']>
  address3?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  postalCode?: Maybe<Scalars['String']>
  preferred: Scalars['Boolean']
  source?: Maybe<AddressSource>
  state?: Maybe<Scalars['String']>
  status?: Maybe<AddressStatus>
  statusReason?: Maybe<Scalars['String']>
  systems?: Maybe<Array<Scalars['String']>>
  uspsAddress?: Maybe<UspsAddress>
}

export enum AddressSource {
  Eloqua = 'ELOQUA',
  External = 'EXTERNAL',
  Hubspot = 'HUBSPOT',
  Manual = 'MANUAL',
  Marketo = 'MARKETO',
  Outreach = 'OUTREACH',
  Refresh = 'REFRESH',
  Salesloft = 'SALESLOFT',
  Sfdc = 'SFDC',
}

export enum AddressStatus {
  Blocked = 'BLOCKED',
  Error = 'ERROR',
  Invalid = 'INVALID',
  Matched = 'MATCHED',
  MatchedOverride = 'MATCHED_OVERRIDE',
}

export type ApiStatus = {
  __typename?: 'ApiStatus'
  status: Scalars['String']
}

export type ApprovedPostal = {
  __typename?: 'ApprovedPostal'
  brandName?: Maybe<Scalars['String']>
  category: Scalars['String']
  description: Scalars['String']
  event?: Maybe<MagicEventInstance>
  id: Scalars['ObjectId']
  imageUrls?: Maybe<Array<ImageReference>>
  marketplaceProductId: Scalars['ObjectId']
  name: Scalars['String']
  status: Status
  subCategory?: Maybe<Array<Scalars['String']>>
  type?: Maybe<Scalars['String']>
  useCases?: Maybe<Array<Scalars['String']>>
  variants?: Maybe<Array<ApprovedProductVariant>>
}

export type ApprovedProductVariant = {
  __typename?: 'ApprovedProductVariant'
  containsPerishables?: Maybe<Scalars['Boolean']>
  description?: Maybe<Scalars['String']>
  displayPrice: Scalars['Int']
  displaySubscriberPrice: Scalars['Int']
  giftMessageSupported?: Maybe<Scalars['Boolean']>
  id: Scalars['ObjectId']
  imageUrls?: Maybe<Array<ImageReference>>
  price: Scalars['Int']
  status: Status
  variantName: Scalars['String']
}

export type Asset = {
  __typename?: 'Asset'
  created: ActionRecord
  id: Scalars['ObjectId']
  name: Scalars['String']
  status: Status
  type: AssetType
  updated?: Maybe<ActionRecord>
}

export type AssetInput = {
  name?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Status>
  type?: InputMaybe<AssetType>
}

export enum AssetType {
  File = 'FILE',
  Image = 'IMAGE',
  Logo = 'LOGO',
  Other = 'OTHER',
  Pdf = 'PDF',
  PostalPreview = 'POSTAL_PREVIEW',
}

export type AuthenticationMethod = {
  __typename?: 'AuthenticationMethod'
  created?: Maybe<Scalars['Instant']>
  expires?: Maybe<Scalars['Instant']>
  method: Scalars['String']
  updated?: Maybe<Scalars['Instant']>
  version?: Maybe<Scalars['String']>
}

export type BackgroundOperationError = {
  __typename?: 'BackgroundOperationError'
  code: Scalars['String']
  count: Scalars['Int']
  message?: Maybe<Scalars['String']>
}

export type BackgroundTask = {
  __typename?: 'BackgroundTask'
  errorCount: Scalars['Int']
  errors?: Maybe<Array<BackgroundOperationError>>
  id: Scalars['ObjectId']
  name: Scalars['String']
  outputs?: Maybe<Scalars['Object']>
  processedCount: Scalars['Int']
  relatedObjectId?: Maybe<Scalars['ObjectId']>
  status: BackgroundTaskStatus
  successCount: Scalars['Int']
  taskAction: Scalars['String']
  taskCompleted?: Maybe<Scalars['Instant']>
  taskErrored?: Maybe<Scalars['Instant']>
  taskStarted?: Maybe<Scalars['Instant']>
  type: BackgroundTaskType
}

export enum BackgroundTaskStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Error = 'ERROR',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
  Queued = 'QUEUED',
}

export enum BackgroundTaskType {
  Bulk = 'BULK',
  Import = 'IMPORT',
  Other = 'OTHER',
  Report = 'REPORT',
  Sync = 'SYNC',
}

export type BooleanFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']>
  in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>
}

export type BulkDraftMarketplaceProductInput = {
  category?: InputMaybe<Scalars['String']>
  orderRulesId?: InputMaybe<Scalars['ObjectId']>
  status?: InputMaybe<Scalars['String']>
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  type?: InputMaybe<Scalars['String']>
}

export type CachedAccount = {
  __typename?: 'CachedAccount'
  address?: Maybe<Address>
  brandingLogoEnabled?: Maybe<Scalars['Boolean']>
  displayName?: Maybe<Scalars['String']>
  id: Scalars['String']
  name: Scalars['String']
}

export type CanvasSize = {
  __typename?: 'CanvasSize'
  height: Scalars['Int']
  width: Scalars['Int']
}

export type CategoryTreeEntry = {
  __typename?: 'CategoryTreeEntry'
  hidden?: Maybe<Scalars['Boolean']>
  id: Scalars['ObjectId']
  name: Scalars['String']
  parentId?: Maybe<Scalars['ObjectId']>
}

export type CategoryTreeEntryFilterInput = {
  hidden?: InputMaybe<BooleanFilterInput>
  name?: InputMaybe<StringFilterInput>
  parentId?: InputMaybe<ObjectIdFilterInput>
}

export type CategoryTreeEntryInput = {
  hidden?: InputMaybe<Scalars['Boolean']>
  name: Scalars['String']
  parentId?: InputMaybe<Scalars['ObjectId']>
}

export enum CategoryTreeEntryOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ParentIdAsc = 'parentId_ASC',
  ParentIdDesc = 'parentId_DESC',
}

export type ChangePasswordInput = {
  currentPassword?: InputMaybe<Scalars['String']>
  newPassword1: Scalars['String']
  newPassword2: Scalars['String']
}

export enum ChronoUnit {
  Days = 'DAYS',
  Hours = 'HOURS',
  Micros = 'MICROS',
  Millis = 'MILLIS',
  Minutes = 'MINUTES',
  Nano = 'NANO',
  Seconds = 'SECONDS',
}

export type Collection = {
  __typename?: 'Collection'
  cursor?: Maybe<Scalars['String']>
  legacyResourceId?: Maybe<Scalars['String']>
  productsCount?: Maybe<Scalars['Int']>
  title?: Maybe<Scalars['String']>
}

export type Contact = {
  __typename?: 'Contact'
  addresses?: Maybe<Array<Maybe<Address>>>
  companyName?: Maybe<Scalars['String']>
  firstName: Scalars['String']
  id: Scalars['ObjectId']
  lastName?: Maybe<Scalars['String']>
  phones?: Maybe<Array<Maybe<PhoneMap>>>
  title?: Maybe<Scalars['String']>
}

export type CreateStoreInstanceInput = {
  hostName: Scalars['String']
  linkColor: Scalars['String']
  navBackground: Scalars['String']
  navForeground: Scalars['String']
  primaryColor: Scalars['String']
  secondaryColor: Scalars['String']
  tertiaryColor: Scalars['String']
  title: Scalars['String']
}

export type CrmUser = {
  __typename?: 'CrmUser'
  crmIdentifier: Scalars['String']
  system: Scalars['String']
}

export enum Currency {
  Aud = 'AUD',
  Cad = 'CAD',
  Eur = 'EUR',
  Gbp = 'GBP',
  Mxn = 'MXN',
  Sgd = 'SGD',
  Usd = 'USD',
}

export enum DataObjectType {
  Account = 'ACCOUNT',
  Activity = 'ACTIVITY',
  BillingAccount = 'BILLING_ACCOUNT',
  Campaign = 'CAMPAIGN',
  Contact = 'CONTACT',
  Event = 'EVENT',
  Lead = 'LEAD',
  MagicLink = 'MAGIC_LINK',
  Opportunity = 'OPPORTUNITY',
  Other = 'OTHER',
  Playbook = 'PLAYBOOK',
  Postal = 'POSTAL',
  Product = 'PRODUCT',
  Trigger = 'TRIGGER',
  User = 'USER',
}

export type DesignTemplate = {
  __typename?: 'DesignTemplate'
  back?: Maybe<Array<ElementConfig>>
  dpi: Scalars['Int']
  front?: Maybe<Array<ElementConfig>>
  outputSize: CanvasSize
}

export type DraftMarketplaceProduct = {
  __typename?: 'DraftMarketplaceProduct'
  brandName?: Maybe<Scalars['String']>
  category?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  estimatedShippingTime?: Maybe<EstimatedShippingTime>
  externalCreatedAt?: Maybe<Scalars['Instant']>
  externalId?: Maybe<Scalars['String']>
  externalUpdatedAt?: Maybe<Scalars['Instant']>
  id: Scalars['ObjectId']
  imageUrls?: Maybe<Array<ImageReference>>
  keywords?: Maybe<Array<Scalars['String']>>
  marketplaceProductId?: Maybe<Scalars['ObjectId']>
  marketplaceStatus?: Maybe<Status>
  name: Scalars['String']
  orderRulesId?: Maybe<Scalars['ObjectId']>
  overrideDescription?: Maybe<Scalars['Boolean']>
  overrideImageUrls?: Maybe<Scalars['Boolean']>
  overrideName?: Maybe<Scalars['Boolean']>
  phoneNumberRequired?: Maybe<Scalars['Boolean']>
  publishedAt?: Maybe<Scalars['Instant']>
  status: DraftStatus
  statusReason?: Maybe<Scalars['String']>
  systemName: Scalars['String']
  tags?: Maybe<Array<Maybe<Scalars['String']>>>
  type?: Maybe<Scalars['String']>
  variantOrderFlexibility?: Maybe<Scalars['Boolean']>
  variants?: Maybe<Array<DraftVariant>>
}

export type DraftMarketplaceProductFilterInput = {
  category?: InputMaybe<StringFilterInput>
  externalCreatedAt?: InputMaybe<InstantFilterInput>
  externalId?: InputMaybe<StringFilterInput>
  externalUpdatedAt?: InputMaybe<InstantFilterInput>
  id?: InputMaybe<ObjectIdFilterInput>
  marketplaceStatus?: InputMaybe<StringFilterInput>
  name?: InputMaybe<StringFilterInput>
  status?: InputMaybe<StringFilterInput>
  systemName?: InputMaybe<StringFilterInput>
  variants_category?: InputMaybe<StringFilterInput>
  variants_externalCreatedAt?: InputMaybe<InstantFilterInput>
  variants_externalId?: InputMaybe<StringFilterInput>
  variants_externalUpdatedAt?: InputMaybe<InstantFilterInput>
  variants_sku?: InputMaybe<StringFilterInput>
  variants_status?: InputMaybe<StringFilterInput>
  variants_variantName?: InputMaybe<StringFilterInput>
}

export type DraftMarketplaceProductInput = {
  brandName?: InputMaybe<Scalars['String']>
  category?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  estimatedShippingTime?: InputMaybe<EstimatedShippingTime>
  externalCreatedAt?: InputMaybe<Scalars['Instant']>
  externalId?: InputMaybe<Scalars['String']>
  externalUpdatedAt?: InputMaybe<Scalars['Instant']>
  imageUrls?: InputMaybe<Array<ImageReferenceInput>>
  keywords?: InputMaybe<Array<Scalars['String']>>
  name: Scalars['String']
  orderRulesId?: InputMaybe<Scalars['ObjectId']>
  overrideDescription?: InputMaybe<Scalars['Boolean']>
  overrideImageUrls?: InputMaybe<Scalars['Boolean']>
  overrideName?: InputMaybe<Scalars['Boolean']>
  phoneNumberRequired?: InputMaybe<Scalars['Boolean']>
  systemName: Scalars['String']
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  type?: InputMaybe<Scalars['String']>
  variantOrderFlexibility?: InputMaybe<Scalars['Boolean']>
}

export enum DraftMarketplaceProductOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type DraftMarketplaceProductValidationResult = {
  __typename?: 'DraftMarketplaceProductValidationResult'
  status: Result
  validationErrors?: Maybe<Array<ValidationCheck>>
}

export enum DraftStatus {
  Active = 'ACTIVE',
  Draft = 'DRAFT',
  ListingRejected = 'LISTING_REJECTED',
  NeedsData = 'NEEDS_DATA',
  RequestListing = 'REQUEST_LISTING',
  Synced = 'SYNCED',
  WithdrawListing = 'WITHDRAW_LISTING',
}

export type DraftVariant = {
  __typename?: 'DraftVariant'
  containsPerishables?: Maybe<Scalars['Boolean']>
  description?: Maybe<Scalars['String']>
  externalCreatedAt?: Maybe<Scalars['Instant']>
  externalId?: Maybe<Scalars['String']>
  externalUpdatedAt?: Maybe<Scalars['Instant']>
  fulfillmentType?: Maybe<Scalars['String']>
  id: Scalars['ObjectId']
  imageUrls?: Maybe<Array<ImageReference>>
  overrideImageUrls?: Maybe<Scalars['Boolean']>
  overrideVariantName?: Maybe<Scalars['Boolean']>
  physicalMessageSupported?: Maybe<Scalars['Boolean']>
  price?: Maybe<Scalars['Int']>
  quantity?: Maybe<Scalars['Int']>
  requiresShipping?: Maybe<Scalars['Boolean']>
  shippingOptions?: Maybe<Array<Maybe<ShippingOption>>>
  sku?: Maybe<Scalars['String']>
  taxable?: Maybe<Scalars['Boolean']>
  upcCode?: Maybe<Scalars['String']>
  variantName: Scalars['String']
}

export type DraftVariantInput = {
  containsPerishables?: InputMaybe<Scalars['Boolean']>
  description?: InputMaybe<Scalars['String']>
  externalCreatedAt?: InputMaybe<Scalars['Instant']>
  externalId?: InputMaybe<Scalars['String']>
  externalUpdatedAt?: InputMaybe<Scalars['Instant']>
  fulfillmentType?: InputMaybe<Scalars['String']>
  imageUrls?: InputMaybe<Array<ImageReferenceInput>>
  overrideImageUrls?: InputMaybe<Scalars['Boolean']>
  overrideVariantName?: InputMaybe<Scalars['Boolean']>
  physicalMessageSupported?: InputMaybe<Scalars['Boolean']>
  price?: InputMaybe<Scalars['Int']>
  quantity?: InputMaybe<Scalars['Int']>
  requiresShipping?: InputMaybe<Scalars['Boolean']>
  shippingOptions?: InputMaybe<Array<InputMaybe<ShippingOptionInput>>>
  sku?: InputMaybe<Scalars['String']>
  taxable?: InputMaybe<Scalars['Boolean']>
  upcCode?: InputMaybe<Scalars['String']>
  variantName: Scalars['String']
}

export type ElementBoundary = {
  __typename?: 'ElementBoundary'
  maxX: Scalars['Int']
  maxY: Scalars['Int']
  minX: Scalars['Int']
  minY: Scalars['Int']
}

export type ElementConfig = {
  __typename?: 'ElementConfig'
  boundary?: Maybe<ElementBoundary>
  customizable?: Maybe<Scalars['Boolean']>
  description?: Maybe<Scalars['String']>
  hidden?: Maybe<Scalars['Boolean']>
  location: ElementLocation
  locked?: Maybe<Scalars['Boolean']>
  name: Scalars['String']
  noPdfOutput?: Maybe<Scalars['Boolean']>
  resize?: Maybe<ElementResize>
  settings?: Maybe<Scalars['Object']>
}

export type ElementLocation = {
  __typename?: 'ElementLocation'
  height: Scalars['Int']
  width: Scalars['Int']
  x: Scalars['Int']
  y: Scalars['Int']
}

export type ElementResize = {
  __typename?: 'ElementResize'
  maxHeight: Scalars['Int']
  maxWidth: Scalars['Int']
  minHeight: Scalars['Int']
  minWidth: Scalars['Int']
}

export enum EstimatedShippingTime {
  None = 'NONE',
  SevenDays = 'SEVEN_DAYS',
  TenDays = 'TEN_DAYS',
  TwoDays = 'TWO_DAYS',
}

export type EventFilterInput = {
  event_eventDateTime?: InputMaybe<InstantFilterInput>
  event_status?: InputMaybe<StringFilterInput>
  name?: InputMaybe<StringFilterInput>
}

export enum EventOrderByInput {
  CreatedAsc = 'created_ASC',
  CreatedDesc = 'created_DESC',
  EventEventDateTimeAsc = 'event_eventDateTime_ASC',
  EventEventDateTimeDesc = 'event_eventDateTime_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type FulfillmentEvents = {
  __typename?: 'FulfillmentEvents'
  placed?: Maybe<Scalars['Instant']>
}

export type FulfillmentHistory = {
  __typename?: 'FulfillmentHistory'
  created: Scalars['Instant']
  currentLocation?: Maybe<Scalars['String']>
  notes?: Maybe<Scalars['String']>
  status: FulfillmentStatus
}

export type FulfillmentMap = {
  __typename?: 'FulfillmentMap'
  displayPrice?: Maybe<Scalars['Int']>
  displaySubscriberPrice?: Maybe<Scalars['Int']>
  fulfillmentPartnerId: Scalars['ObjectId']
  fulfillmentPartnerName: Scalars['String']
  fulfillmentType: FulfillmentType
  inventory?: Maybe<Inventory>
  itemCode: Scalars['String']
  price: Scalars['Int']
  revShareFee?: Maybe<Scalars['Int']>
  revSharePercentage?: Maybe<Scalars['Float']>
  shippingOptions?: Maybe<Array<ShippingOption>>
  subscriberPrice?: Maybe<Scalars['Int']>
  supplierPartAuxiliaryId?: Maybe<Scalars['String']>
  supplierPartId?: Maybe<Scalars['String']>
  thirdPartyExpense?: Maybe<Scalars['Int']>
  thirdPartyFee?: Maybe<Scalars['Int']>
  transactionFee?: Maybe<Scalars['Int']>
}

export type FulfillmentPartner = {
  __typename?: 'FulfillmentPartner'
  description?: Maybe<Scalars['String']>
  featuredImage?: Maybe<ImageReference>
  headerText?: Maybe<Scalars['String']>
  highlightText?: Maybe<Scalars['String']>
  id: Scalars['ObjectId']
  keywords?: Maybe<Array<Maybe<Scalars['String']>>>
  location?: Maybe<Location>
  logo?: Maybe<ImageReference>
  name: Scalars['String']
}

export type FulfillmentPartnerInput = {
  description?: InputMaybe<Scalars['String']>
  featuredImage?: InputMaybe<ImageReferenceInput>
  headerText?: InputMaybe<Scalars['String']>
  highlightText?: InputMaybe<Scalars['String']>
  keywords?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  location?: InputMaybe<LocationInput>
  logo?: InputMaybe<ImageReferenceInput>
  name: Scalars['String']
}

export enum FulfillmentStatus {
  Cancelled = 'CANCELLED',
  Confirmed = 'CONFIRMED',
  Delivered = 'DELIVERED',
  DeliveredAssumed = 'DELIVERED_ASSUMED',
  DeliveryError = 'DELIVERY_ERROR',
  None = 'NONE',
  OrderApproved = 'ORDER_APPROVED',
  OrderDenied = 'ORDER_DENIED',
  OutForDelivery = 'OUT_FOR_DELIVERY',
  Pending = 'PENDING',
  PendingDelivery = 'PENDING_DELIVERY',
  PendingUserApproval = 'PENDING_USER_APPROVAL',
  Placed = 'PLACED',
  PostalAddressRefresh = 'POSTAL_ADDRESS_REFRESH',
  PostalDeliveryDonated = 'POSTAL_DELIVERY_DONATED',
  PostalDeliveryEmail = 'POSTAL_DELIVERY_EMAIL',
  PostalDeliveryEmailError = 'POSTAL_DELIVERY_EMAIL_ERROR',
  PostalDeliveryExpired = 'POSTAL_DELIVERY_EXPIRED',
  PostalDeliveryRejected = 'POSTAL_DELIVERY_REJECTED',
  Processing = 'PROCESSING',
  ProcessingError = 'PROCESSING_ERROR',
  ProcessingInternal = 'PROCESSING_INTERNAL',
  ServerErrorBackoff = 'SERVER_ERROR_BACKOFF',
  Shipped = 'SHIPPED',
  Waitlist = 'WAITLIST',
}

export enum FulfillmentType {
  Donation = 'DONATION',
  Electronic = 'ELECTRONIC',
  EventFee = 'EVENT_FEE',
  Physical = 'PHYSICAL',
}

export type ImageReference = {
  __typename?: 'ImageReference'
  description?: Maybe<Scalars['String']>
  url: Scalars['String']
}

export type ImageReferenceInput = {
  description?: InputMaybe<Scalars['String']>
  url: Scalars['String']
}

export type InstantFilterInput = {
  between?: InputMaybe<Array<InputMaybe<Scalars['Instant']>>>
  eq?: InputMaybe<Scalars['Instant']>
  ge?: InputMaybe<Scalars['Instant']>
  gt?: InputMaybe<Scalars['Instant']>
  le?: InputMaybe<Scalars['Instant']>
  lt?: InputMaybe<Scalars['Instant']>
}

export type IntFilterInput = {
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  eq?: InputMaybe<Scalars['Int']>
  ge?: InputMaybe<Scalars['Int']>
  gt?: InputMaybe<Scalars['Int']>
  le?: InputMaybe<Scalars['Int']>
  lt?: InputMaybe<Scalars['Int']>
}

export type IntegrationSync = {
  __typename?: 'IntegrationSync'
  id: Scalars['ObjectId']
  lastRun?: Maybe<Scalars['Instant']>
  nextRun?: Maybe<Scalars['Instant']>
  objectType: DataObjectType
  pollingTimeMins?: Maybe<Scalars['Int']>
  status: Status
  system: Scalars['String']
  workStatus?: Maybe<BackgroundTaskStatus>
}

export type IntegrationSyncFilterInput = {
  objectType?: InputMaybe<StringFilterInput>
  status?: InputMaybe<StringFilterInput>
  system?: InputMaybe<StringFilterInput>
}

export type Inventory = {
  __typename?: 'Inventory'
  available?: Maybe<Scalars['Int']>
  ordered?: Maybe<Scalars['Int']>
}

export type ItemCustomization = {
  __typename?: 'ItemCustomization'
  characterLimit?: Maybe<Scalars['Int']>
  customizableByRecipient?: Maybe<Scalars['Boolean']>
  customizableBySender?: Maybe<Scalars['Boolean']>
  displayName?: Maybe<Scalars['String']>
  fileTypesAccepted?: Maybe<Array<Scalars['String']>>
  heightRecommendation?: Maybe<Scalars['Int']>
  id: Scalars['String']
  required?: Maybe<Scalars['Boolean']>
  type?: Maybe<ItemCustomizationType>
  value?: Maybe<Scalars['Object']>
  variantId?: Maybe<Scalars['ObjectId']>
  widthRecommendation?: Maybe<Scalars['Int']>
}

export enum ItemCustomizationType {
  File = 'FILE',
  Image = 'IMAGE',
  Location = 'LOCATION',
  Text = 'TEXT',
}

export enum ItemStatus {
  Cancelled = 'CANCELLED',
  Confirmed = 'CONFIRMED',
  Delivered = 'DELIVERED',
  DeliveredAssumed = 'DELIVERED_ASSUMED',
  DeliveryFailed = 'DELIVERY_FAILED',
  Failed = 'FAILED',
  OutForDelivery = 'OUT_FOR_DELIVERY',
  Pending = 'PENDING',
  Placed = 'PLACED',
  Shipped = 'SHIPPED',
}

export type Location = {
  __typename?: 'Location'
  city?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
}

export type LocationInput = {
  city?: InputMaybe<Scalars['String']>
  country?: InputMaybe<Scalars['String']>
  state?: InputMaybe<Scalars['String']>
}

export type MagicEvent = {
  __typename?: 'MagicEvent'
  eventLengthMinutes: Scalars['Int']
  maximumAttendees?: Maybe<Scalars['Int']>
  minimumAttendees?: Maybe<Scalars['Int']>
  requirements?: Maybe<Array<Requirements>>
  talentCompany?: Maybe<Scalars['String']>
  talentEmailAddress: Scalars['String']
  talentName: Scalars['String']
  talentPhoneNumber: Scalars['String']
}

export type MagicEventInstance = {
  __typename?: 'MagicEventInstance'
  accountName?: Maybe<Scalars['String']>
  eventDateTime?: Maybe<Scalars['Instant']>
  lastRegistrationDateTime?: Maybe<Scalars['Instant']>
  meetingLink?: Maybe<Scalars['String']>
  requestedAttendeeCount: Scalars['Int']
  requestedByEmail: Scalars['String']
  requestedByMessage: Scalars['String']
  requestedByName: Scalars['String']
  requestedByPhone: Scalars['String']
  requestedDates?: Maybe<Array<Scalars['Instant']>>
  status: MagicEventStatus
}

export enum MagicEventStatus {
  AcceptingInvites = 'ACCEPTING_INVITES',
  Cancelled = 'CANCELLED',
  Complete = 'COMPLETE',
  ConfirmedBillingIssue = 'CONFIRMED_BILLING_ISSUE',
  ConfirmedNeedsData = 'CONFIRMED_NEEDS_DATA',
  PendingConfirmation = 'PENDING_CONFIRMATION',
  RegistrationClosed = 'REGISTRATION_CLOSED',
}

export type MarketplaceProduct = {
  __typename?: 'MarketplaceProduct'
  allowedPlans?: Maybe<Array<Scalars['String']>>
  brandName?: Maybe<Scalars['String']>
  category: Scalars['String']
  description: Scalars['String']
  designTemplate?: Maybe<DesignTemplate>
  estimatedShippingTime?: Maybe<EstimatedShippingTime>
  eventAvailabilityDates?: Maybe<Array<Scalars['String']>>
  eventDetails?: Maybe<MagicEvent>
  id: Scalars['ObjectId']
  imageUrls?: Maybe<Array<ImageReference>>
  itemPreview?: Maybe<Scalars['Boolean']>
  name: Scalars['String']
  phoneNumberRequired?: Maybe<Scalars['Boolean']>
  starterTemplate?: Maybe<Scalars['Boolean']>
  status: Status
  subCategory?: Maybe<Array<Scalars['String']>>
  type?: Maybe<Scalars['String']>
  useCases?: Maybe<Array<Scalars['String']>>
  variantOrderFlexibility?: Maybe<Scalars['Boolean']>
  variants?: Maybe<Array<ProductVariant>>
}

export type Module = {
  __typename?: 'Module'
  meta?: Maybe<Scalars['Object']>
  name: Scalars['String']
  permissions?: Maybe<Scalars['Object']>
}

export type Mutation = {
  __typename?: 'Mutation'
  addDraftMarketplaceProductVariant: DraftMarketplaceProduct
  bulkDeleteDraftMarketplaceProducts: Scalars['Int']
  bulkUpdateDraftMarketplaceProducts: BackgroundTask
  bulkUpdatePostalFulfillmentStatus: BackgroundTask
  changePassword: Scalars['Boolean']
  connectShopify: ShopifyCollection
  createDraftMarketplaceProduct: DraftMarketplaceProduct
  createStoreInstance: StoreInstance
  deleteDraftMarketplaceProduct: DraftMarketplaceProduct
  deleteStoreInstance: StoreInstance
  deleteUserAction: Scalars['Boolean']
  generateOrderReport: BackgroundTask
  generateVendorFulfillmentsReport: BackgroundTask
  markOrderCancelled: PostalFulfillment
  markOrderConfirmed: PostalFulfillment
  markOrderDelivered: PostalFulfillment
  markOrderPlaced: PostalFulfillment
  markOrderShipped: PostalFulfillment
  recordUserAction: Scalars['Boolean']
  removeDraftMarketplaceProductVariant: DraftMarketplaceProduct
  requestPrivateListing: DraftMarketplaceProduct
  requestPublicListing: DraftMarketplaceProduct
  saveAsset: Asset
  selectShopifyCollection: BackgroundTask
  syncShopify: BackgroundTask
  updateAsset: Asset
  updateDraftMarketplaceProduct: DraftMarketplaceProduct
  updateDraftMarketplaceProductVariant: DraftMarketplaceProduct
  updateFulfillmentPartner: FulfillmentPartner
  updatePostalFulfillmentTrackingNumber: PostalFulfillment
  updateSelf: User
  updateStoreInstance: StoreInstance
  updateVendorNotifications: User
}

export type MutationAddDraftMarketplaceProductVariantArgs = {
  data: DraftVariantInput
  draftProductId: Scalars['ObjectId']
}

export type MutationBulkDeleteDraftMarketplaceProductsArgs = {
  filter?: InputMaybe<DraftMarketplaceProductFilterInput>
}

export type MutationBulkUpdateDraftMarketplaceProductsArgs = {
  data: BulkDraftMarketplaceProductInput
  ids?: InputMaybe<Array<Scalars['ObjectId']>>
}

export type MutationBulkUpdatePostalFulfillmentStatusArgs = {
  filter?: InputMaybe<PostalFulfillmentFilterInput>
  ids?: InputMaybe<Array<Scalars['ObjectId']>>
  status: FulfillmentStatus
}

export type MutationChangePasswordArgs = {
  data: ChangePasswordInput
}

export type MutationConnectShopifyArgs = {
  accessToken: Scalars['String']
  shopifyUrl: Scalars['String']
}

export type MutationCreateDraftMarketplaceProductArgs = {
  data: DraftMarketplaceProductInput
}

export type MutationCreateStoreInstanceArgs = {
  data: CreateStoreInstanceInput
}

export type MutationDeleteDraftMarketplaceProductArgs = {
  id: Scalars['ObjectId']
}

export type MutationDeleteStoreInstanceArgs = {
  id: Scalars['ObjectId']
}

export type MutationDeleteUserActionArgs = {
  name: Scalars['String']
}

export type MutationGenerateOrderReportArgs = {
  filter: PostalFulfillmentFilterInput
  name?: InputMaybe<Scalars['String']>
}

export type MutationGenerateVendorFulfillmentsReportArgs = {
  filter: PostalFulfillmentFilterInput
  name?: InputMaybe<Scalars['String']>
}

export type MutationMarkOrderCancelledArgs = {
  data: OrderCancelledInput
  id: Scalars['ObjectId']
}

export type MutationMarkOrderConfirmedArgs = {
  data: OrderConfirmedInput
  id: Scalars['ObjectId']
}

export type MutationMarkOrderDeliveredArgs = {
  id: Scalars['ObjectId']
}

export type MutationMarkOrderPlacedArgs = {
  data: OrderPlacedInput
  id: Scalars['ObjectId']
}

export type MutationMarkOrderShippedArgs = {
  id: Scalars['ObjectId']
}

export type MutationRecordUserActionArgs = {
  name: Scalars['String']
}

export type MutationRemoveDraftMarketplaceProductVariantArgs = {
  draftProductId: Scalars['ObjectId']
  id: Scalars['ObjectId']
}

export type MutationRequestPrivateListingArgs = {
  accountId: Scalars['ObjectId']
  id: Scalars['ObjectId']
}

export type MutationRequestPublicListingArgs = {
  id: Scalars['ObjectId']
}

export type MutationSaveAssetArgs = {
  assetType?: InputMaybe<AssetType>
  name: Scalars['String']
  requestId: Scalars['String']
}

export type MutationSelectShopifyCollectionArgs = {
  shopifyCollectionId?: InputMaybe<Scalars['String']>
}

export type MutationUpdateAssetArgs = {
  data: AssetInput
  id: Scalars['ObjectId']
}

export type MutationUpdateDraftMarketplaceProductArgs = {
  data: DraftMarketplaceProductInput
  id: Scalars['ObjectId']
}

export type MutationUpdateDraftMarketplaceProductVariantArgs = {
  data: DraftVariantInput
  draftProductId: Scalars['ObjectId']
  id: Scalars['ObjectId']
}

export type MutationUpdateFulfillmentPartnerArgs = {
  data: FulfillmentPartnerInput
}

export type MutationUpdatePostalFulfillmentTrackingNumberArgs = {
  id: Scalars['ObjectId']
  trackingNumber: Scalars['String']
}

export type MutationUpdateSelfArgs = {
  data: UpdateSelfInput
}

export type MutationUpdateStoreInstanceArgs = {
  data: StoreInstanceInput
  id: Scalars['ObjectId']
}

export type MutationUpdateVendorNotificationsArgs = {
  data: VendorNotificationsInput
  id: Scalars['ObjectId']
}

export type NamedProductAccess = {
  __typename?: 'NamedProductAccess'
  accountName?: Maybe<Scalars['String']>
  productAccess: ProductAccess
  teamName?: Maybe<Scalars['String']>
}

export type NotificationInfo = {
  __typename?: 'NotificationInfo'
  channels?: Maybe<Array<UserNotificationChannel>>
  lastSent?: Maybe<Scalars['Instant']>
  toggleOn?: Maybe<Scalars['Boolean']>
}

export type NotificationInfoInput = {
  channels?: InputMaybe<Array<Scalars['String']>>
  lastSent?: InputMaybe<Scalars['Instant']>
  toggleOn?: InputMaybe<Scalars['Boolean']>
}

export type ObjectIdFilterInput = {
  eq?: InputMaybe<Scalars['ObjectId']>
  in?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>
  ne?: InputMaybe<Scalars['ObjectId']>
  nin?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>
}

export enum ObjectType {
  Event = 'EVENT',
  Gift = 'GIFT',
}

export type OrderCancelledInput = {
  notes: Scalars['String']
}

export type OrderConfirmedInput = {
  fulfillmentPartnerOrderId?: InputMaybe<Scalars['String']>
}

export type OrderCost = {
  __typename?: 'OrderCost'
  gmvExpense?: Maybe<Scalars['Int']>
  postalRevenueShare?: Maybe<Scalars['Int']>
  shipping?: Maybe<Scalars['Int']>
  subtotal?: Maybe<Scalars['Int']>
  tax?: Maybe<Scalars['Int']>
  taxEstimated?: Maybe<Scalars['Boolean']>
  thirdPartyFee?: Maybe<Scalars['Int']>
  total?: Maybe<Scalars['Int']>
}

export type OrderPlacedInput = {
  fulfillmentPartnerOrderId?: InputMaybe<Scalars['String']>
}

export type PageInfo = {
  __typename?: 'PageInfo'
  hasNextPage: Scalars['Boolean']
  hasPreviousPage: Scalars['Boolean']
}

export type PhoneMap = {
  __typename?: 'PhoneMap'
  created: ActionRecord
  phoneNumber: Scalars['String']
  type: PhoneType
}

export enum PhoneType {
  Cell = 'CELL',
  Home = 'HOME',
  Other = 'OTHER',
  Work = 'WORK',
}

export type PostalFulfillment = {
  __typename?: 'PostalFulfillment'
  accountId: Scalars['ObjectId']
  accountName?: Maybe<Scalars['String']>
  addressRefresh?: Maybe<Scalars['Boolean']>
  approvedPostalId?: Maybe<Scalars['ObjectId']>
  attributes?: Maybe<Scalars['Object']>
  campaignId?: Maybe<Scalars['ObjectId']>
  contactId: Scalars['ObjectId']
  cost?: Maybe<OrderCost>
  created?: Maybe<ActionRecord>
  deliveryDate?: Maybe<Scalars['Instant']>
  deliveryEmail?: Maybe<Scalars['Boolean']>
  deliveryEmailClicked?: Maybe<Scalars['Instant']>
  deliveryEmailCompleted?: Maybe<Scalars['Instant']>
  deliveryEmailExpires?: Maybe<Scalars['Instant']>
  deliveryEmailSent?: Maybe<Scalars['Instant']>
  deliveryPartner?: Maybe<Scalars['String']>
  formFieldValueList?: Maybe<Scalars['Object']>
  fulfillmentEvents?: Maybe<FulfillmentEvents>
  fulfillmentMap?: Maybe<FulfillmentMap>
  fulfillmentPartnerId: Scalars['ObjectId']
  fulfillmentPartnerOrderId?: Maybe<Scalars['String']>
  giftMessage?: Maybe<Scalars['String']>
  history?: Maybe<Array<FulfillmentHistory>>
  id: Scalars['ObjectId']
  itemCustomizationList?: Maybe<Array<ItemCustomization>>
  itemName: Scalars['String']
  magicLinkId?: Maybe<Scalars['ObjectId']>
  magicLinkName?: Maybe<Scalars['String']>
  marketplaceProductId: Scalars['ObjectId']
  physicalMessage?: Maybe<Scalars['String']>
  playbookId?: Maybe<Scalars['ObjectId']>
  previewImage?: Maybe<Scalars['String']>
  shipTo?: Maybe<Address>
  shipToEmail?: Maybe<Scalars['String']>
  shipToName?: Maybe<Scalars['String']>
  shipToPhone?: Maybe<Scalars['String']>
  stage?: Maybe<Scalars['String']>
  status: FulfillmentStatus
  targetDeliveryDate?: Maybe<Scalars['Instant']>
  trackingNumber?: Maybe<Scalars['String']>
  type?: Maybe<ObjectType>
  userId: Scalars['ObjectId']
  userLink?: Maybe<UserLink>
  variantId?: Maybe<Scalars['ObjectId']>
}

export type PostalFulfillmentFilterInput = {
  accountId?: InputMaybe<ObjectIdFilterInput>
  approvedPostalId?: InputMaybe<ObjectIdFilterInput>
  created?: InputMaybe<InstantFilterInput>
  fulfillmentEvents_placed?: InputMaybe<InstantFilterInput>
  fulfillmentPartnerOrderId?: InputMaybe<StringFilterInput>
  fulfillments_vendorPayment_vendorPayableId?: InputMaybe<ObjectIdFilterInput>
  id?: InputMaybe<ObjectIdFilterInput>
  itemName?: InputMaybe<StringFilterInput>
  stage?: InputMaybe<StringFilterInput>
  status?: InputMaybe<StringFilterInput>
}

export enum PostalFulfillmentOrderByInput {
  CreatedAsc = 'created_ASC',
  CreatedDesc = 'created_DESC',
  FulfillmentEventsPlacedAsc = 'fulfillmentEvents_placed_ASC',
  FulfillmentEventsPlacedDesc = 'fulfillmentEvents_placed_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export enum Product {
  PostalIoAdmin = 'POSTAL_IO_ADMIN',
  PostalIoApp = 'POSTAL_IO_APP',
  PostalIoStore = 'POSTAL_IO_STORE',
  PostalIoVendor = 'POSTAL_IO_VENDOR',
}

export type ProductAccess = {
  __typename?: 'ProductAccess'
  accountId?: Maybe<Scalars['ObjectId']>
  created: ActionRecord
  id: Scalars['ObjectId']
  partnerId?: Maybe<Scalars['ObjectId']>
  product: Product
  roles?: Maybe<Array<Scalars['String']>>
  teamId?: Maybe<Scalars['ObjectId']>
  type?: Maybe<ProductAccessType>
}

export enum ProductAccessType {
  Account = 'ACCOUNT',
  Partner = 'PARTNER',
  Store = 'STORE',
  Team = 'TEAM',
}

export type ProductVariant = {
  __typename?: 'ProductVariant'
  attributes?: Maybe<Scalars['Object']>
  containsPerishables?: Maybe<Scalars['Boolean']>
  description?: Maybe<Scalars['String']>
  displayPrice?: Maybe<Scalars['Int']>
  flatFees?: Maybe<Scalars['Int']>
  fulfillmentPartnerList?: Maybe<Array<FulfillmentMap>>
  giftMessageSupported?: Maybe<Scalars['Boolean']>
  id: Scalars['ObjectId']
  imageUrls?: Maybe<Array<ImageReference>>
  manufacturerPartId?: Maybe<Scalars['String']>
  minimumTransactions?: Maybe<Scalars['Int']>
  physicalMessageSupported?: Maybe<Scalars['Boolean']>
  size?: Maybe<Scalars['String']>
  status: Status
  upcCode?: Maybe<Scalars['String']>
  variantName: Scalars['String']
}

export type Query = {
  __typename?: 'Query'
  accessToken?: Maybe<TokenResponse>
  downloadReport?: Maybe<Scalars['String']>
  getAccountByPostalFulfillment: CachedAccount
  getAssociatedAccounts?: Maybe<Array<Maybe<NamedProductAccess>>>
  getBackgroundTaskById?: Maybe<BackgroundTask>
  getContactByPostalFulfillment: Contact
  getDraftMarketplaceProduct: DraftMarketplaceProduct
  getEvent: ApprovedPostal
  getFulfillmentPartner: FulfillmentPartner
  getPostalFulfillment: PostalFulfillment
  getProductByEvent: MarketplaceProduct
  getProductByPostalFulfillment: MarketplaceProduct
  getStoreInstance: StoreInstance
  getVendorPayable: VendorPayable
  me: UserAccount
  modules?: Maybe<Array<Maybe<Module>>>
  namedProductAccessList?: Maybe<Array<Maybe<NamedProductAccess>>>
  ping: Scalars['Boolean']
  productAccessList?: Maybe<Array<Maybe<ProductAccess>>>
  searchCategoryTreeEntries?: Maybe<Array<CategoryTreeEntry>>
  searchDraftMarketplaceProduct?: Maybe<Array<DraftMarketplaceProduct>>
  searchEvents?: Maybe<Array<ApprovedPostal>>
  searchIntegrationSync?: Maybe<Array<IntegrationSync>>
  searchPostalFulfillments?: Maybe<Array<PostalFulfillment>>
  searchReportOutputInstances?: Maybe<Array<ReportOutputInstance>>
  searchShopifyCollections: ShopifyCollection
  searchStoreInstance?: Maybe<Array<StoreInstance>>
  searchVendorFulfillments?: Maybe<Array<VendorFulfillment>>
  searchVendorPayables?: Maybe<Array<VendorPayable>>
  validateDraftMarketplaceProduct?: Maybe<DraftMarketplaceProductValidationResult>
}

export type QueryAccessTokenArgs = {
  id?: InputMaybe<Scalars['ObjectId']>
}

export type QueryDownloadReportArgs = {
  reportOutputInstanceId: Scalars['ObjectId']
}

export type QueryGetAccountByPostalFulfillmentArgs = {
  id: Scalars['ObjectId']
}

export type QueryGetBackgroundTaskByIdArgs = {
  id: Scalars['ObjectId']
}

export type QueryGetContactByPostalFulfillmentArgs = {
  id: Scalars['ObjectId']
}

export type QueryGetDraftMarketplaceProductArgs = {
  id: Scalars['ObjectId']
}

export type QueryGetEventArgs = {
  id: Scalars['ObjectId']
}

export type QueryGetPostalFulfillmentArgs = {
  id: Scalars['ObjectId']
}

export type QueryGetProductByEventArgs = {
  id: Scalars['ObjectId']
}

export type QueryGetProductByPostalFulfillmentArgs = {
  id: Scalars['ObjectId']
}

export type QueryGetStoreInstanceArgs = {
  id: Scalars['ObjectId']
}

export type QueryGetVendorPayableArgs = {
  id: Scalars['ObjectId']
}

export type QuerySearchCategoryTreeEntriesArgs = {
  filter?: InputMaybe<CategoryTreeEntryFilterInput>
  limit?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<CategoryTreeEntryOrderByInput>
  skip?: InputMaybe<Scalars['Int']>
}

export type QuerySearchDraftMarketplaceProductArgs = {
  filter?: InputMaybe<DraftMarketplaceProductFilterInput>
  limit?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<DraftMarketplaceProductOrderByInput>
  skip?: InputMaybe<Scalars['Int']>
}

export type QuerySearchEventsArgs = {
  filter?: InputMaybe<EventFilterInput>
  limit?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<EventOrderByInput>
  skip?: InputMaybe<Scalars['Int']>
}

export type QuerySearchIntegrationSyncArgs = {
  filter?: InputMaybe<IntegrationSyncFilterInput>
}

export type QuerySearchPostalFulfillmentsArgs = {
  filter?: InputMaybe<PostalFulfillmentFilterInput>
  limit?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<PostalFulfillmentOrderByInput>
  skip?: InputMaybe<Scalars['Int']>
}

export type QuerySearchReportOutputInstancesArgs = {
  filter?: InputMaybe<ReportOutputFilterInput>
  limit?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<ReportOutputOrderByInput>
  skip?: InputMaybe<Scalars['Int']>
}

export type QuerySearchShopifyCollectionsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
}

export type QuerySearchStoreInstanceArgs = {
  filter?: InputMaybe<StoreInstanceFilterInput>
  limit?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<StoreInstanceOrderByInput>
  skip?: InputMaybe<Scalars['Int']>
}

export type QuerySearchVendorFulfillmentsArgs = {
  filter?: InputMaybe<PostalFulfillmentFilterInput>
  limit?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<PostalFulfillmentOrderByInput>
  skip?: InputMaybe<Scalars['Int']>
}

export type QuerySearchVendorPayablesArgs = {
  filter?: InputMaybe<VendorPayableFilterInput>
  limit?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<VendorPayableOrderByInput>
  skip?: InputMaybe<Scalars['Int']>
}

export type QueryValidateDraftMarketplaceProductArgs = {
  id: Scalars['ObjectId']
}

export type ReportOutputFilterInput = {
  type: ReportType
}

export type ReportOutputInstance = {
  __typename?: 'ReportOutputInstance'
  fileName: Scalars['String']
  id: Scalars['ObjectId']
  name: Scalars['String']
  type: DataObjectType
}

export enum ReportOutputOrderByInput {
  CreatedAsc = 'created_ASC',
  CreatedDesc = 'created_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export enum ReportType {
  PostalFulfillmentPartner = 'POSTAL_FULFILLMENT_PARTNER',
  VendorFulfillments = 'VENDOR_FULFILLMENTS',
}

export enum Requirements {
  Computer = 'COMPUTER',
  InPerson = 'IN_PERSON',
  Phone = 'PHONE',
  Tablet = 'TABLET',
}

export enum Result {
  Error = 'ERROR',
  Success = 'SUCCESS',
}

export enum Role {
  AccessSelection = 'ACCESS_SELECTION',
  Admin = 'ADMIN',
  Manager = 'MANAGER',
  None = 'NONE',
  Signup = 'SIGNUP',
  TeamAdmin = 'TEAM_ADMIN',
  User = 'USER',
}

export type ShippingOption = {
  __typename?: 'ShippingOption'
  name: Scalars['String']
  price: Scalars['Int']
}

export type ShippingOptionInput = {
  name: Scalars['String']
  price: Scalars['Int']
}

export type ShopifyCollection = {
  __typename?: 'ShopifyCollection'
  collectionList?: Maybe<Array<Collection>>
  crntCollectionId?: Maybe<Scalars['String']>
  pageInfo?: Maybe<PageInfo>
}

export enum Status {
  Active = 'ACTIVE',
  Delete = 'DELETE',
  Disabled = 'DISABLED',
  OutOfStock = 'OUT_OF_STOCK',
  Pending = 'PENDING',
  VendorDisabled = 'VENDOR_DISABLED',
}

export type StoreInstance = {
  __typename?: 'StoreInstance'
  hostName: Scalars['String']
  id: Scalars['ObjectId']
  linkColor: Scalars['String']
  navBackground: Scalars['String']
  navForeground: Scalars['String']
  primaryColor: Scalars['String']
  secondaryColor: Scalars['String']
  status: Status
  tertiaryColor: Scalars['String']
  title: Scalars['String']
}

export type StoreInstanceFilterInput = {
  hostName?: InputMaybe<StringFilterInput>
  id?: InputMaybe<ObjectIdFilterInput>
  status?: InputMaybe<StringFilterInput>
  title?: InputMaybe<StringFilterInput>
}

export type StoreInstanceInput = {
  hostName?: InputMaybe<Scalars['String']>
  linkColor?: InputMaybe<Scalars['String']>
  navBackground?: InputMaybe<Scalars['String']>
  navForeground?: InputMaybe<Scalars['String']>
  primaryColor?: InputMaybe<Scalars['String']>
  secondaryColor?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['String']>
  tertiaryColor?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
}

export enum StoreInstanceOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type StringFilterInput = {
  all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  beginsWith?: InputMaybe<Scalars['String']>
  between?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contains?: InputMaybe<Scalars['String']>
  eq?: InputMaybe<Scalars['String']>
  ge?: InputMaybe<Scalars['String']>
  gt?: InputMaybe<Scalars['String']>
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  le?: InputMaybe<Scalars['String']>
  lt?: InputMaybe<Scalars['String']>
  ne?: InputMaybe<Scalars['String']>
  notContains?: InputMaybe<Scalars['String']>
}

export type TokenResponse = {
  __typename?: 'TokenResponse'
  refreshToken?: Maybe<Scalars['String']>
  token: Scalars['String']
}

export type UpdateSelfInput = {
  emailAddress?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  handwritingName?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  meetingLink?: InputMaybe<Scalars['String']>
  phoneNumber?: InputMaybe<Scalars['String']>
  profilePicture?: InputMaybe<Scalars['Url']>
  title?: InputMaybe<Scalars['String']>
}

export type User = {
  __typename?: 'User'
  accountLockedUntil?: Maybe<Scalars['Instant']>
  auth?: Maybe<Array<Maybe<AuthenticationMethod>>>
  authenticationFailures?: Maybe<Scalars['Int']>
  created?: Maybe<ActionRecord>
  crmUserMap?: Maybe<Array<CrmUser>>
  emailAddress: Scalars['String']
  firstName?: Maybe<Scalars['String']>
  handwritingName?: Maybe<Scalars['String']>
  id: Scalars['ObjectId']
  lastName?: Maybe<Scalars['String']>
  meetingLink?: Maybe<Scalars['String']>
  notifications?: Maybe<UserNotifications>
  phoneNumber?: Maybe<Scalars['String']>
  productAccess?: Maybe<Array<ProductAccess>>
  profilePicture?: Maybe<Scalars['Url']>
  status: Status
  title?: Maybe<Scalars['String']>
  userName: Scalars['String']
  vendorNotifications?: Maybe<VendorNotifications>
}

export type UserAccount = {
  __typename?: 'UserAccount'
  accountName?: Maybe<Scalars['String']>
  authTypes?: Maybe<Array<Maybe<Scalars['String']>>>
  completedActions?: Maybe<Scalars['Object']>
  created: ActionRecord
  emailAddress: Scalars['String']
  firstName: Scalars['String']
  handwritingName?: Maybe<Scalars['String']>
  id: Scalars['ObjectId']
  lastName: Scalars['String']
  meetingLink?: Maybe<Scalars['String']>
  notifications?: Maybe<UserNotifications>
  phoneNumber?: Maybe<Scalars['String']>
  productAccess?: Maybe<Array<ProductAccess>>
  profilePicture?: Maybe<Scalars['Url']>
  title?: Maybe<Scalars['String']>
  updated?: Maybe<ActionRecord>
  vendorNotifications?: Maybe<VendorNotifications>
}

export type UserLink = {
  __typename?: 'UserLink'
  fullName: Scalars['String']
  userName: Scalars['String']
}

export enum UserNotificationChannel {
  Email = 'EMAIL',
  Slack = 'SLACK',
  Sms = 'SMS',
}

export type UserNotifications = {
  __typename?: 'UserNotifications'
  balanceLowChannels?: Maybe<Array<Maybe<UserNotificationChannel>>>
  balanceLowNotificationsOn?: Maybe<Scalars['Boolean']>
  budgetLowChannels?: Maybe<Array<Maybe<UserNotificationChannel>>>
  budgetLowNotificationsOn?: Maybe<Scalars['Boolean']>
  giftEmailAcceptedChannels?: Maybe<Array<Maybe<UserNotificationChannel>>>
  giftEmailAcceptedNotificationsOn?: Maybe<Scalars['Boolean']>
  lastBalanceLowNotificationSent?: Maybe<Scalars['Instant']>
  lastBudgetLowNotificationSent?: Maybe<Scalars['Instant']>
  magicLinkAcceptedChannels?: Maybe<Array<Maybe<UserNotificationChannel>>>
  magicLinkAcceptedNotificationsOn?: Maybe<Scalars['Boolean']>
  notificationsOn?: Maybe<Scalars['Boolean']>
}

export type UspsAddress = {
  __typename?: 'UspsAddress'
  address1?: Maybe<Scalars['String']>
  address2?: Maybe<Scalars['String']>
  address3?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  deliveryPoint?: Maybe<Scalars['String']>
  plus4?: Maybe<Scalars['String']>
  postalCode?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
}

export type ValidationCheck = {
  __typename?: 'ValidationCheck'
  itemName: Scalars['String']
  message: Scalars['String']
  status: Result
}

export type VendorFulfillment = {
  __typename?: 'VendorFulfillment'
  accountId: Scalars['ObjectId']
  approvedPostalId?: Maybe<Scalars['ObjectId']>
  contactId?: Maybe<Scalars['ObjectId']>
  cost?: Maybe<OrderCost>
  deliveryDate?: Maybe<Scalars['Instant']>
  fulfillmentId?: Maybe<Scalars['String']>
  fulfillmentPartnerId?: Maybe<Scalars['ObjectId']>
  fulfillmentPartnerName?: Maybe<Scalars['String']>
  fulfillmentPartnerOrderId?: Maybe<Scalars['String']>
  fulfillmentType?: Maybe<FulfillmentType>
  history?: Maybe<Array<FulfillmentHistory>>
  internalHistory?: Maybe<Array<FulfillmentHistory>>
  itemCode?: Maybe<Scalars['String']>
  marketplaceProductId?: Maybe<Scalars['ObjectId']>
  postalFulfillmentId: Scalars['ObjectId']
  retryCount?: Maybe<Scalars['Instant']>
  shipTo?: Maybe<Address>
  shipToCompanyName?: Maybe<Scalars['String']>
  shipToEmail?: Maybe<Scalars['String']>
  shipToFirstName?: Maybe<Scalars['String']>
  shipToLastName?: Maybe<Scalars['String']>
  shipToName?: Maybe<Scalars['String']>
  shipToPhone?: Maybe<Scalars['String']>
  shipToTitle?: Maybe<Scalars['String']>
  status: ItemStatus
  targetDeliveryDate?: Maybe<Scalars['Instant']>
  teamId?: Maybe<Scalars['ObjectId']>
  trackingCompany?: Maybe<Scalars['String']>
  trackingNumber?: Maybe<Scalars['String']>
  trackingUrl?: Maybe<Scalars['String']>
  type?: Maybe<ObjectType>
  userId?: Maybe<Scalars['ObjectId']>
  variantId?: Maybe<Scalars['ObjectId']>
  vendorPayment?: Maybe<VendorPayment>
}

export type VendorNotifications = {
  __typename?: 'VendorNotifications'
  eventCancelled: NotificationInfo
  eventConfirmation: NotificationInfo
  eventDateTimeChanged: NotificationInfo
  eventMeetingLinkChanged: NotificationInfo
  newEvent: NotificationInfo
  registrationClosed: NotificationInfo
  unconfirmedOrders: NotificationInfo
  unshippedOrders: NotificationInfo
}

export type VendorNotificationsInput = {
  eventCancelled?: InputMaybe<NotificationInfoInput>
  eventConfirmation?: InputMaybe<NotificationInfoInput>
  eventDateTimeChanged?: InputMaybe<NotificationInfoInput>
  eventMeetingLinkChanged?: InputMaybe<NotificationInfoInput>
  newEvent?: InputMaybe<NotificationInfoInput>
  registrationClosed?: InputMaybe<NotificationInfoInput>
  unconfirmedOrders?: InputMaybe<NotificationInfoInput>
  unshippedOrders?: InputMaybe<NotificationInfoInput>
}

export type VendorPayable = {
  __typename?: 'VendorPayable'
  created: ActionRecord
  currency?: Maybe<Currency>
  externalHistory?: Maybe<Array<VendorPayableExternalHistory>>
  externalId?: Maybe<Scalars['String']>
  externalStatus: VendorPayableExternalStatus
  fulfillmentPartnerId: Scalars['ObjectId']
  id: Scalars['ObjectId']
  owedAtEnd: Scalars['Instant']
  owedAtStart: Scalars['Instant']
  totalAmount?: Maybe<Scalars['Int']>
  totalFulfillments?: Maybe<Scalars['Int']>
  updated?: Maybe<ActionRecord>
}

export type VendorPayableExternalHistory = {
  __typename?: 'VendorPayableExternalHistory'
  created: ActionRecord
  notes?: Maybe<Scalars['String']>
  status: VendorPayableExternalStatus
}

export enum VendorPayableExternalStatus {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Created = 'CREATED',
  Processing = 'PROCESSING',
}

export type VendorPayableFilterInput = {
  created?: InputMaybe<InstantFilterInput>
  externalId?: InputMaybe<StringFilterInput>
  externalStatus?: InputMaybe<StringFilterInput>
  fulfillmentPartnerId?: InputMaybe<ObjectIdFilterInput>
  id?: InputMaybe<ObjectIdFilterInput>
  owedAtEnd?: InputMaybe<InstantFilterInput>
  owedAtStart?: InputMaybe<InstantFilterInput>
  updated?: InputMaybe<InstantFilterInput>
}

export enum VendorPayableOrderByInput {
  CreatedAsc = 'created_ASC',
  CreatedDesc = 'created_DESC',
  ExternalStatusAsc = 'externalStatus_ASC',
  ExternalStatusDesc = 'externalStatus_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  OwedAtEndAsc = 'owedAtEnd_ASC',
  OwedAtEndDesc = 'owedAtEnd_DESC',
  OwedAtStartAsc = 'owedAtStart_ASC',
  OwedAtStartDesc = 'owedAtStart_DESC',
  UpdatedAsc = 'updated_ASC',
  UpdatedDesc = 'updated_DESC',
}

export type VendorPayment = {
  __typename?: 'VendorPayment'
  owedAt?: Maybe<Scalars['Instant']>
  paidAt?: Maybe<Scalars['Instant']>
  vendorPayableId?: Maybe<Scalars['ObjectId']>
}

export type CachedAccountFragment = {
  __typename?: 'CachedAccount'
  id: string
  name: string
  brandingLogoEnabled?: boolean | null | undefined
  displayName?: string | null | undefined
  address?:
    | {
        __typename?: 'Address'
        address1?: string | null | undefined
        address2?: string | null | undefined
        address3?: string | null | undefined
        city?: string | null | undefined
        state?: string | null | undefined
        postalCode?: string | null | undefined
        country?: string | null | undefined
        preferred: boolean
        source?: AddressSource | null | undefined
        status?: AddressStatus | null | undefined
        statusReason?: string | null | undefined
        systems?: Array<string> | null | undefined
        uspsAddress?:
          | {
              __typename?: 'UspsAddress'
              address1?: string | null | undefined
              address2?: string | null | undefined
              address3?: string | null | undefined
              city?: string | null | undefined
              state?: string | null | undefined
              postalCode?: string | null | undefined
              country?: string | null | undefined
              plus4?: string | null | undefined
              deliveryPoint?: string | null | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type ProductAccessFragment = {
  __typename?: 'ProductAccess'
  id: any
  accountId?: any | null | undefined
  teamId?: any | null | undefined
  partnerId?: any | null | undefined
  product: Product
  roles?: Array<string> | null | undefined
  type?: ProductAccessType | null | undefined
  created: { __typename?: 'ActionRecord'; actor?: string | null | undefined; dateTime?: any | null | undefined }
}

export type GetAccountByPostalFulfillmentQueryVariables = Exact<{
  id: Scalars['ObjectId']
}>

export type GetAccountByPostalFulfillmentQuery = {
  __typename?: 'Query'
  getAccountByPostalFulfillment: {
    __typename?: 'CachedAccount'
    id: string
    name: string
    brandingLogoEnabled?: boolean | null | undefined
    displayName?: string | null | undefined
    address?:
      | {
          __typename?: 'Address'
          address1?: string | null | undefined
          address2?: string | null | undefined
          address3?: string | null | undefined
          city?: string | null | undefined
          state?: string | null | undefined
          postalCode?: string | null | undefined
          country?: string | null | undefined
          preferred: boolean
          source?: AddressSource | null | undefined
          status?: AddressStatus | null | undefined
          statusReason?: string | null | undefined
          systems?: Array<string> | null | undefined
          uspsAddress?:
            | {
                __typename?: 'UspsAddress'
                address1?: string | null | undefined
                address2?: string | null | undefined
                address3?: string | null | undefined
                city?: string | null | undefined
                state?: string | null | undefined
                postalCode?: string | null | undefined
                country?: string | null | undefined
                plus4?: string | null | undefined
                deliveryPoint?: string | null | undefined
              }
            | null
            | undefined
        }
      | null
      | undefined
  }
}

export type NamedProductAccessFragment = {
  __typename?: 'NamedProductAccess'
  accountName?: string | null | undefined
  teamName?: string | null | undefined
  productAccess: {
    __typename?: 'ProductAccess'
    id: any
    accountId?: any | null | undefined
    teamId?: any | null | undefined
    partnerId?: any | null | undefined
    product: Product
    roles?: Array<string> | null | undefined
    type?: ProductAccessType | null | undefined
    created: { __typename?: 'ActionRecord'; actor?: string | null | undefined; dateTime?: any | null | undefined }
  }
}

export type NamedProductAccessListQueryVariables = Exact<{ [key: string]: never }>

export type NamedProductAccessListQuery = {
  __typename?: 'Query'
  namedProductAccessList?:
    | Array<
        | {
            __typename?: 'NamedProductAccess'
            accountName?: string | null | undefined
            teamName?: string | null | undefined
            productAccess: {
              __typename?: 'ProductAccess'
              id: any
              accountId?: any | null | undefined
              teamId?: any | null | undefined
              partnerId?: any | null | undefined
              product: Product
              roles?: Array<string> | null | undefined
              type?: ProductAccessType | null | undefined
              created: {
                __typename?: 'ActionRecord'
                actor?: string | null | undefined
                dateTime?: any | null | undefined
              }
            }
          }
        | null
        | undefined
      >
    | null
    | undefined
}

export type ProductAccessListQueryVariables = Exact<{ [key: string]: never }>

export type ProductAccessListQuery = {
  __typename?: 'Query'
  productAccessList?:
    | Array<
        | {
            __typename?: 'ProductAccess'
            id: any
            accountId?: any | null | undefined
            teamId?: any | null | undefined
            partnerId?: any | null | undefined
            product: Product
            roles?: Array<string> | null | undefined
            type?: ProductAccessType | null | undefined
            created: {
              __typename?: 'ActionRecord'
              actor?: string | null | undefined
              dateTime?: any | null | undefined
            }
          }
        | null
        | undefined
      >
    | null
    | undefined
}

export type GetAssociatedAccountsQueryVariables = Exact<{ [key: string]: never }>

export type GetAssociatedAccountsQuery = {
  __typename?: 'Query'
  getAssociatedAccounts?:
    | Array<
        | {
            __typename?: 'NamedProductAccess'
            accountName?: string | null | undefined
            teamName?: string | null | undefined
            productAccess: {
              __typename?: 'ProductAccess'
              id: any
              accountId?: any | null | undefined
              teamId?: any | null | undefined
              partnerId?: any | null | undefined
              product: Product
              roles?: Array<string> | null | undefined
              type?: ProductAccessType | null | undefined
              created: {
                __typename?: 'ActionRecord'
                actor?: string | null | undefined
                dateTime?: any | null | undefined
              }
            }
          }
        | null
        | undefined
      >
    | null
    | undefined
}

export type AssetFragment = {
  __typename?: 'Asset'
  id: any
  type: AssetType
  name: string
  status: Status
  created: { __typename?: 'ActionRecord'; actor?: string | null | undefined; dateTime?: any | null | undefined }
  updated?:
    | { __typename?: 'ActionRecord'; actor?: string | null | undefined; dateTime?: any | null | undefined }
    | null
    | undefined
}

export type SaveAssetMutationVariables = Exact<{
  assetType?: InputMaybe<AssetType>
  name: Scalars['String']
  requestId: Scalars['String']
}>

export type SaveAssetMutation = {
  __typename?: 'Mutation'
  saveAsset: {
    __typename?: 'Asset'
    id: any
    type: AssetType
    name: string
    status: Status
    created: { __typename?: 'ActionRecord'; actor?: string | null | undefined; dateTime?: any | null | undefined }
    updated?:
      | { __typename?: 'ActionRecord'; actor?: string | null | undefined; dateTime?: any | null | undefined }
      | null
      | undefined
  }
}

export type UpdateAssetMutationVariables = Exact<{
  id: Scalars['ObjectId']
  data: AssetInput
}>

export type UpdateAssetMutation = {
  __typename?: 'Mutation'
  updateAsset: {
    __typename?: 'Asset'
    id: any
    type: AssetType
    name: string
    status: Status
    created: { __typename?: 'ActionRecord'; actor?: string | null | undefined; dateTime?: any | null | undefined }
    updated?:
      | { __typename?: 'ActionRecord'; actor?: string | null | undefined; dateTime?: any | null | undefined }
      | null
      | undefined
  }
}

export type BackgroundOperationErrorFragment = {
  __typename?: 'BackgroundOperationError'
  code: string
  count: number
  message?: string | null | undefined
}

export type BackgroundTaskFragment = {
  __typename?: 'BackgroundTask'
  id: any
  relatedObjectId?: any | null | undefined
  name: string
  taskAction: string
  type: BackgroundTaskType
  status: BackgroundTaskStatus
  processedCount: number
  successCount: number
  errorCount: number
  taskStarted?: any | null | undefined
  taskCompleted?: any | null | undefined
  taskErrored?: any | null | undefined
  outputs?: any | null | undefined
  errors?:
    | Array<{
        __typename?: 'BackgroundOperationError'
        code: string
        count: number
        message?: string | null | undefined
      }>
    | null
    | undefined
}

export type GetBackgroundTaskByIdQueryVariables = Exact<{
  id: Scalars['ObjectId']
}>

export type GetBackgroundTaskByIdQuery = {
  __typename?: 'Query'
  getBackgroundTaskById?:
    | {
        __typename?: 'BackgroundTask'
        id: any
        relatedObjectId?: any | null | undefined
        name: string
        taskAction: string
        type: BackgroundTaskType
        status: BackgroundTaskStatus
        processedCount: number
        successCount: number
        errorCount: number
        taskStarted?: any | null | undefined
        taskCompleted?: any | null | undefined
        taskErrored?: any | null | undefined
        outputs?: any | null | undefined
        errors?:
          | Array<{
              __typename?: 'BackgroundOperationError'
              code: string
              count: number
              message?: string | null | undefined
            }>
          | null
          | undefined
      }
    | null
    | undefined
}

export type UspsAddressFragment = {
  __typename?: 'UspsAddress'
  address1?: string | null | undefined
  address2?: string | null | undefined
  address3?: string | null | undefined
  city?: string | null | undefined
  state?: string | null | undefined
  postalCode?: string | null | undefined
  country?: string | null | undefined
  plus4?: string | null | undefined
  deliveryPoint?: string | null | undefined
}

export type AddressFragment = {
  __typename?: 'Address'
  address1?: string | null | undefined
  address2?: string | null | undefined
  address3?: string | null | undefined
  city?: string | null | undefined
  state?: string | null | undefined
  postalCode?: string | null | undefined
  country?: string | null | undefined
  preferred: boolean
  source?: AddressSource | null | undefined
  status?: AddressStatus | null | undefined
  statusReason?: string | null | undefined
  systems?: Array<string> | null | undefined
  uspsAddress?:
    | {
        __typename?: 'UspsAddress'
        address1?: string | null | undefined
        address2?: string | null | undefined
        address3?: string | null | undefined
        city?: string | null | undefined
        state?: string | null | undefined
        postalCode?: string | null | undefined
        country?: string | null | undefined
        plus4?: string | null | undefined
        deliveryPoint?: string | null | undefined
      }
    | null
    | undefined
}

export type PhoneMapFragment = {
  __typename?: 'PhoneMap'
  phoneNumber: string
  type: PhoneType
  created: { __typename?: 'ActionRecord'; actor?: string | null | undefined; dateTime?: any | null | undefined }
}

export type ContactFragment = {
  __typename?: 'Contact'
  id: any
  firstName: string
  lastName?: string | null | undefined
  title?: string | null | undefined
  companyName?: string | null | undefined
  phones?:
    | Array<
        | {
            __typename?: 'PhoneMap'
            phoneNumber: string
            type: PhoneType
            created: {
              __typename?: 'ActionRecord'
              actor?: string | null | undefined
              dateTime?: any | null | undefined
            }
          }
        | null
        | undefined
      >
    | null
    | undefined
  addresses?:
    | Array<
        | {
            __typename?: 'Address'
            address1?: string | null | undefined
            address2?: string | null | undefined
            address3?: string | null | undefined
            city?: string | null | undefined
            state?: string | null | undefined
            postalCode?: string | null | undefined
            country?: string | null | undefined
            preferred: boolean
            source?: AddressSource | null | undefined
            status?: AddressStatus | null | undefined
            statusReason?: string | null | undefined
            systems?: Array<string> | null | undefined
            uspsAddress?:
              | {
                  __typename?: 'UspsAddress'
                  address1?: string | null | undefined
                  address2?: string | null | undefined
                  address3?: string | null | undefined
                  city?: string | null | undefined
                  state?: string | null | undefined
                  postalCode?: string | null | undefined
                  country?: string | null | undefined
                  plus4?: string | null | undefined
                  deliveryPoint?: string | null | undefined
                }
              | null
              | undefined
          }
        | null
        | undefined
      >
    | null
    | undefined
}

export type GetContactByPostalFulfillmentQueryVariables = Exact<{
  id: Scalars['ObjectId']
}>

export type GetContactByPostalFulfillmentQuery = {
  __typename?: 'Query'
  getContactByPostalFulfillment: {
    __typename?: 'Contact'
    id: any
    firstName: string
    lastName?: string | null | undefined
    title?: string | null | undefined
    companyName?: string | null | undefined
    phones?:
      | Array<
          | {
              __typename?: 'PhoneMap'
              phoneNumber: string
              type: PhoneType
              created: {
                __typename?: 'ActionRecord'
                actor?: string | null | undefined
                dateTime?: any | null | undefined
              }
            }
          | null
          | undefined
        >
      | null
      | undefined
    addresses?:
      | Array<
          | {
              __typename?: 'Address'
              address1?: string | null | undefined
              address2?: string | null | undefined
              address3?: string | null | undefined
              city?: string | null | undefined
              state?: string | null | undefined
              postalCode?: string | null | undefined
              country?: string | null | undefined
              preferred: boolean
              source?: AddressSource | null | undefined
              status?: AddressStatus | null | undefined
              statusReason?: string | null | undefined
              systems?: Array<string> | null | undefined
              uspsAddress?:
                | {
                    __typename?: 'UspsAddress'
                    address1?: string | null | undefined
                    address2?: string | null | undefined
                    address3?: string | null | undefined
                    city?: string | null | undefined
                    state?: string | null | undefined
                    postalCode?: string | null | undefined
                    country?: string | null | undefined
                    plus4?: string | null | undefined
                    deliveryPoint?: string | null | undefined
                  }
                | null
                | undefined
            }
          | null
          | undefined
        >
      | null
      | undefined
  }
}

export type ValidationCheckFragment = {
  __typename?: 'ValidationCheck'
  itemName: string
  message: string
  status: Result
}

export type DraftMarketplaceProductValidationResultFragment = {
  __typename?: 'DraftMarketplaceProductValidationResult'
  status: Result
  validationErrors?:
    | Array<{ __typename?: 'ValidationCheck'; itemName: string; message: string; status: Result }>
    | null
    | undefined
}

export type DraftVariantFragment = {
  __typename?: 'DraftVariant'
  id: any
  externalId?: string | null | undefined
  variantName: string
  description?: string | null | undefined
  sku?: string | null | undefined
  upcCode?: string | null | undefined
  price?: number | null | undefined
  quantity?: number | null | undefined
  taxable?: boolean | null | undefined
  requiresShipping?: boolean | null | undefined
  externalCreatedAt?: any | null | undefined
  externalUpdatedAt?: any | null | undefined
  physicalMessageSupported?: boolean | null | undefined
  overrideVariantName?: boolean | null | undefined
  overrideImageUrls?: boolean | null | undefined
  containsPerishables?: boolean | null | undefined
  fulfillmentType?: string | null | undefined
  imageUrls?:
    | Array<{ __typename?: 'ImageReference'; url: string; description?: string | null | undefined }>
    | null
    | undefined
  shippingOptions?:
    | Array<{ __typename?: 'ShippingOption'; name: string; price: number } | null | undefined>
    | null
    | undefined
}

export type DraftMarketplaceProductFragment = {
  __typename?: 'DraftMarketplaceProduct'
  id: any
  systemName: string
  description?: string | null | undefined
  externalId?: string | null | undefined
  category?: string | null | undefined
  name: string
  tags?: Array<string | null | undefined> | null | undefined
  brandName?: string | null | undefined
  externalCreatedAt?: any | null | undefined
  externalUpdatedAt?: any | null | undefined
  marketplaceStatus?: Status | null | undefined
  orderRulesId?: any | null | undefined
  keywords?: Array<string> | null | undefined
  type?: string | null | undefined
  status: DraftStatus
  statusReason?: string | null | undefined
  marketplaceProductId?: any | null | undefined
  publishedAt?: any | null | undefined
  estimatedShippingTime?: EstimatedShippingTime | null | undefined
  overrideName?: boolean | null | undefined
  overrideDescription?: boolean | null | undefined
  overrideImageUrls?: boolean | null | undefined
  phoneNumberRequired?: boolean | null | undefined
  variantOrderFlexibility?: boolean | null | undefined
  imageUrls?:
    | Array<{ __typename?: 'ImageReference'; url: string; description?: string | null | undefined }>
    | null
    | undefined
  variants?:
    | Array<{
        __typename?: 'DraftVariant'
        id: any
        externalId?: string | null | undefined
        variantName: string
        description?: string | null | undefined
        sku?: string | null | undefined
        upcCode?: string | null | undefined
        price?: number | null | undefined
        quantity?: number | null | undefined
        taxable?: boolean | null | undefined
        requiresShipping?: boolean | null | undefined
        externalCreatedAt?: any | null | undefined
        externalUpdatedAt?: any | null | undefined
        physicalMessageSupported?: boolean | null | undefined
        overrideVariantName?: boolean | null | undefined
        overrideImageUrls?: boolean | null | undefined
        containsPerishables?: boolean | null | undefined
        fulfillmentType?: string | null | undefined
        imageUrls?:
          | Array<{ __typename?: 'ImageReference'; url: string; description?: string | null | undefined }>
          | null
          | undefined
        shippingOptions?:
          | Array<{ __typename?: 'ShippingOption'; name: string; price: number } | null | undefined>
          | null
          | undefined
      }>
    | null
    | undefined
}

export type CategoryTreeEntryFragment = {
  __typename?: 'CategoryTreeEntry'
  id: any
  parentId?: any | null | undefined
  name: string
  hidden?: boolean | null | undefined
}

export type SearchDraftMarketplaceProductQueryVariables = Exact<{
  filter?: InputMaybe<DraftMarketplaceProductFilterInput>
  orderBy?: InputMaybe<DraftMarketplaceProductOrderByInput>
  skip?: InputMaybe<Scalars['Int']>
  limit?: InputMaybe<Scalars['Int']>
}>

export type SearchDraftMarketplaceProductQuery = {
  __typename?: 'Query'
  searchDraftMarketplaceProduct?:
    | Array<{
        __typename?: 'DraftMarketplaceProduct'
        id: any
        systemName: string
        description?: string | null | undefined
        externalId?: string | null | undefined
        category?: string | null | undefined
        name: string
        tags?: Array<string | null | undefined> | null | undefined
        brandName?: string | null | undefined
        externalCreatedAt?: any | null | undefined
        externalUpdatedAt?: any | null | undefined
        marketplaceStatus?: Status | null | undefined
        orderRulesId?: any | null | undefined
        keywords?: Array<string> | null | undefined
        type?: string | null | undefined
        status: DraftStatus
        statusReason?: string | null | undefined
        marketplaceProductId?: any | null | undefined
        publishedAt?: any | null | undefined
        estimatedShippingTime?: EstimatedShippingTime | null | undefined
        overrideName?: boolean | null | undefined
        overrideDescription?: boolean | null | undefined
        overrideImageUrls?: boolean | null | undefined
        phoneNumberRequired?: boolean | null | undefined
        variantOrderFlexibility?: boolean | null | undefined
        imageUrls?:
          | Array<{ __typename?: 'ImageReference'; url: string; description?: string | null | undefined }>
          | null
          | undefined
        variants?:
          | Array<{
              __typename?: 'DraftVariant'
              id: any
              externalId?: string | null | undefined
              variantName: string
              description?: string | null | undefined
              sku?: string | null | undefined
              upcCode?: string | null | undefined
              price?: number | null | undefined
              quantity?: number | null | undefined
              taxable?: boolean | null | undefined
              requiresShipping?: boolean | null | undefined
              externalCreatedAt?: any | null | undefined
              externalUpdatedAt?: any | null | undefined
              physicalMessageSupported?: boolean | null | undefined
              overrideVariantName?: boolean | null | undefined
              overrideImageUrls?: boolean | null | undefined
              containsPerishables?: boolean | null | undefined
              fulfillmentType?: string | null | undefined
              imageUrls?:
                | Array<{ __typename?: 'ImageReference'; url: string; description?: string | null | undefined }>
                | null
                | undefined
              shippingOptions?:
                | Array<{ __typename?: 'ShippingOption'; name: string; price: number } | null | undefined>
                | null
                | undefined
            }>
          | null
          | undefined
      }>
    | null
    | undefined
}

export type GetDraftMarketplaceProductQueryVariables = Exact<{
  id: Scalars['ObjectId']
}>

export type GetDraftMarketplaceProductQuery = {
  __typename?: 'Query'
  getDraftMarketplaceProduct: {
    __typename?: 'DraftMarketplaceProduct'
    id: any
    systemName: string
    description?: string | null | undefined
    externalId?: string | null | undefined
    category?: string | null | undefined
    name: string
    tags?: Array<string | null | undefined> | null | undefined
    brandName?: string | null | undefined
    externalCreatedAt?: any | null | undefined
    externalUpdatedAt?: any | null | undefined
    marketplaceStatus?: Status | null | undefined
    orderRulesId?: any | null | undefined
    keywords?: Array<string> | null | undefined
    type?: string | null | undefined
    status: DraftStatus
    statusReason?: string | null | undefined
    marketplaceProductId?: any | null | undefined
    publishedAt?: any | null | undefined
    estimatedShippingTime?: EstimatedShippingTime | null | undefined
    overrideName?: boolean | null | undefined
    overrideDescription?: boolean | null | undefined
    overrideImageUrls?: boolean | null | undefined
    phoneNumberRequired?: boolean | null | undefined
    variantOrderFlexibility?: boolean | null | undefined
    imageUrls?:
      | Array<{ __typename?: 'ImageReference'; url: string; description?: string | null | undefined }>
      | null
      | undefined
    variants?:
      | Array<{
          __typename?: 'DraftVariant'
          id: any
          externalId?: string | null | undefined
          variantName: string
          description?: string | null | undefined
          sku?: string | null | undefined
          upcCode?: string | null | undefined
          price?: number | null | undefined
          quantity?: number | null | undefined
          taxable?: boolean | null | undefined
          requiresShipping?: boolean | null | undefined
          externalCreatedAt?: any | null | undefined
          externalUpdatedAt?: any | null | undefined
          physicalMessageSupported?: boolean | null | undefined
          overrideVariantName?: boolean | null | undefined
          overrideImageUrls?: boolean | null | undefined
          containsPerishables?: boolean | null | undefined
          fulfillmentType?: string | null | undefined
          imageUrls?:
            | Array<{ __typename?: 'ImageReference'; url: string; description?: string | null | undefined }>
            | null
            | undefined
          shippingOptions?:
            | Array<{ __typename?: 'ShippingOption'; name: string; price: number } | null | undefined>
            | null
            | undefined
        }>
      | null
      | undefined
  }
}

export type ValidateDraftMarketplaceProductQueryVariables = Exact<{
  id: Scalars['ObjectId']
}>

export type ValidateDraftMarketplaceProductQuery = {
  __typename?: 'Query'
  validateDraftMarketplaceProduct?:
    | {
        __typename?: 'DraftMarketplaceProductValidationResult'
        status: Result
        validationErrors?:
          | Array<{ __typename?: 'ValidationCheck'; itemName: string; message: string; status: Result }>
          | null
          | undefined
      }
    | null
    | undefined
}

export type CreateDraftMarketplaceProductMutationVariables = Exact<{
  data: DraftMarketplaceProductInput
}>

export type CreateDraftMarketplaceProductMutation = {
  __typename?: 'Mutation'
  createDraftMarketplaceProduct: {
    __typename?: 'DraftMarketplaceProduct'
    id: any
    systemName: string
    description?: string | null | undefined
    externalId?: string | null | undefined
    category?: string | null | undefined
    name: string
    tags?: Array<string | null | undefined> | null | undefined
    brandName?: string | null | undefined
    externalCreatedAt?: any | null | undefined
    externalUpdatedAt?: any | null | undefined
    marketplaceStatus?: Status | null | undefined
    orderRulesId?: any | null | undefined
    keywords?: Array<string> | null | undefined
    type?: string | null | undefined
    status: DraftStatus
    statusReason?: string | null | undefined
    marketplaceProductId?: any | null | undefined
    publishedAt?: any | null | undefined
    estimatedShippingTime?: EstimatedShippingTime | null | undefined
    overrideName?: boolean | null | undefined
    overrideDescription?: boolean | null | undefined
    overrideImageUrls?: boolean | null | undefined
    phoneNumberRequired?: boolean | null | undefined
    variantOrderFlexibility?: boolean | null | undefined
    imageUrls?:
      | Array<{ __typename?: 'ImageReference'; url: string; description?: string | null | undefined }>
      | null
      | undefined
    variants?:
      | Array<{
          __typename?: 'DraftVariant'
          id: any
          externalId?: string | null | undefined
          variantName: string
          description?: string | null | undefined
          sku?: string | null | undefined
          upcCode?: string | null | undefined
          price?: number | null | undefined
          quantity?: number | null | undefined
          taxable?: boolean | null | undefined
          requiresShipping?: boolean | null | undefined
          externalCreatedAt?: any | null | undefined
          externalUpdatedAt?: any | null | undefined
          physicalMessageSupported?: boolean | null | undefined
          overrideVariantName?: boolean | null | undefined
          overrideImageUrls?: boolean | null | undefined
          containsPerishables?: boolean | null | undefined
          fulfillmentType?: string | null | undefined
          imageUrls?:
            | Array<{ __typename?: 'ImageReference'; url: string; description?: string | null | undefined }>
            | null
            | undefined
          shippingOptions?:
            | Array<{ __typename?: 'ShippingOption'; name: string; price: number } | null | undefined>
            | null
            | undefined
        }>
      | null
      | undefined
  }
}

export type UpdateDraftMarketplaceProductMutationVariables = Exact<{
  id: Scalars['ObjectId']
  data: DraftMarketplaceProductInput
}>

export type UpdateDraftMarketplaceProductMutation = {
  __typename?: 'Mutation'
  updateDraftMarketplaceProduct: {
    __typename?: 'DraftMarketplaceProduct'
    id: any
    systemName: string
    description?: string | null | undefined
    externalId?: string | null | undefined
    category?: string | null | undefined
    name: string
    tags?: Array<string | null | undefined> | null | undefined
    brandName?: string | null | undefined
    externalCreatedAt?: any | null | undefined
    externalUpdatedAt?: any | null | undefined
    marketplaceStatus?: Status | null | undefined
    orderRulesId?: any | null | undefined
    keywords?: Array<string> | null | undefined
    type?: string | null | undefined
    status: DraftStatus
    statusReason?: string | null | undefined
    marketplaceProductId?: any | null | undefined
    publishedAt?: any | null | undefined
    estimatedShippingTime?: EstimatedShippingTime | null | undefined
    overrideName?: boolean | null | undefined
    overrideDescription?: boolean | null | undefined
    overrideImageUrls?: boolean | null | undefined
    phoneNumberRequired?: boolean | null | undefined
    variantOrderFlexibility?: boolean | null | undefined
    imageUrls?:
      | Array<{ __typename?: 'ImageReference'; url: string; description?: string | null | undefined }>
      | null
      | undefined
    variants?:
      | Array<{
          __typename?: 'DraftVariant'
          id: any
          externalId?: string | null | undefined
          variantName: string
          description?: string | null | undefined
          sku?: string | null | undefined
          upcCode?: string | null | undefined
          price?: number | null | undefined
          quantity?: number | null | undefined
          taxable?: boolean | null | undefined
          requiresShipping?: boolean | null | undefined
          externalCreatedAt?: any | null | undefined
          externalUpdatedAt?: any | null | undefined
          physicalMessageSupported?: boolean | null | undefined
          overrideVariantName?: boolean | null | undefined
          overrideImageUrls?: boolean | null | undefined
          containsPerishables?: boolean | null | undefined
          fulfillmentType?: string | null | undefined
          imageUrls?:
            | Array<{ __typename?: 'ImageReference'; url: string; description?: string | null | undefined }>
            | null
            | undefined
          shippingOptions?:
            | Array<{ __typename?: 'ShippingOption'; name: string; price: number } | null | undefined>
            | null
            | undefined
        }>
      | null
      | undefined
  }
}

export type DeleteDraftMarketplaceProductMutationVariables = Exact<{
  id: Scalars['ObjectId']
}>

export type DeleteDraftMarketplaceProductMutation = {
  __typename?: 'Mutation'
  deleteDraftMarketplaceProduct: {
    __typename?: 'DraftMarketplaceProduct'
    id: any
    systemName: string
    description?: string | null | undefined
    externalId?: string | null | undefined
    category?: string | null | undefined
    name: string
    tags?: Array<string | null | undefined> | null | undefined
    brandName?: string | null | undefined
    externalCreatedAt?: any | null | undefined
    externalUpdatedAt?: any | null | undefined
    marketplaceStatus?: Status | null | undefined
    orderRulesId?: any | null | undefined
    keywords?: Array<string> | null | undefined
    type?: string | null | undefined
    status: DraftStatus
    statusReason?: string | null | undefined
    marketplaceProductId?: any | null | undefined
    publishedAt?: any | null | undefined
    estimatedShippingTime?: EstimatedShippingTime | null | undefined
    overrideName?: boolean | null | undefined
    overrideDescription?: boolean | null | undefined
    overrideImageUrls?: boolean | null | undefined
    phoneNumberRequired?: boolean | null | undefined
    variantOrderFlexibility?: boolean | null | undefined
    imageUrls?:
      | Array<{ __typename?: 'ImageReference'; url: string; description?: string | null | undefined }>
      | null
      | undefined
    variants?:
      | Array<{
          __typename?: 'DraftVariant'
          id: any
          externalId?: string | null | undefined
          variantName: string
          description?: string | null | undefined
          sku?: string | null | undefined
          upcCode?: string | null | undefined
          price?: number | null | undefined
          quantity?: number | null | undefined
          taxable?: boolean | null | undefined
          requiresShipping?: boolean | null | undefined
          externalCreatedAt?: any | null | undefined
          externalUpdatedAt?: any | null | undefined
          physicalMessageSupported?: boolean | null | undefined
          overrideVariantName?: boolean | null | undefined
          overrideImageUrls?: boolean | null | undefined
          containsPerishables?: boolean | null | undefined
          fulfillmentType?: string | null | undefined
          imageUrls?:
            | Array<{ __typename?: 'ImageReference'; url: string; description?: string | null | undefined }>
            | null
            | undefined
          shippingOptions?:
            | Array<{ __typename?: 'ShippingOption'; name: string; price: number } | null | undefined>
            | null
            | undefined
        }>
      | null
      | undefined
  }
}

export type BulkUpdateDraftMarketplaceProductsMutationVariables = Exact<{
  data: BulkDraftMarketplaceProductInput
  ids?: InputMaybe<Array<Scalars['ObjectId']> | Scalars['ObjectId']>
}>

export type BulkUpdateDraftMarketplaceProductsMutation = {
  __typename?: 'Mutation'
  bulkUpdateDraftMarketplaceProducts: {
    __typename?: 'BackgroundTask'
    id: any
    relatedObjectId?: any | null | undefined
    name: string
    taskAction: string
    type: BackgroundTaskType
    status: BackgroundTaskStatus
    processedCount: number
    successCount: number
    errorCount: number
    taskStarted?: any | null | undefined
    taskCompleted?: any | null | undefined
    taskErrored?: any | null | undefined
    outputs?: any | null | undefined
    errors?:
      | Array<{
          __typename?: 'BackgroundOperationError'
          code: string
          count: number
          message?: string | null | undefined
        }>
      | null
      | undefined
  }
}

export type BulkDeleteDraftMarketplaceProductsMutationVariables = Exact<{
  filter?: InputMaybe<DraftMarketplaceProductFilterInput>
}>

export type BulkDeleteDraftMarketplaceProductsMutation = {
  __typename?: 'Mutation'
  bulkDeleteDraftMarketplaceProducts: number
}

export type SearchCategoryTreeEntriesQueryVariables = Exact<{
  filter?: InputMaybe<CategoryTreeEntryFilterInput>
  orderBy?: InputMaybe<CategoryTreeEntryOrderByInput>
  skip?: InputMaybe<Scalars['Int']>
  limit?: InputMaybe<Scalars['Int']>
}>

export type SearchCategoryTreeEntriesQuery = {
  __typename?: 'Query'
  searchCategoryTreeEntries?:
    | Array<{
        __typename?: 'CategoryTreeEntry'
        id: any
        parentId?: any | null | undefined
        name: string
        hidden?: boolean | null | undefined
      }>
    | null
    | undefined
}

export type RequestPrivateListingMutationVariables = Exact<{
  id: Scalars['ObjectId']
  accountId: Scalars['ObjectId']
}>

export type RequestPrivateListingMutation = {
  __typename?: 'Mutation'
  requestPrivateListing: {
    __typename?: 'DraftMarketplaceProduct'
    id: any
    systemName: string
    description?: string | null | undefined
    externalId?: string | null | undefined
    category?: string | null | undefined
    name: string
    tags?: Array<string | null | undefined> | null | undefined
    brandName?: string | null | undefined
    externalCreatedAt?: any | null | undefined
    externalUpdatedAt?: any | null | undefined
    marketplaceStatus?: Status | null | undefined
    orderRulesId?: any | null | undefined
    keywords?: Array<string> | null | undefined
    type?: string | null | undefined
    status: DraftStatus
    statusReason?: string | null | undefined
    marketplaceProductId?: any | null | undefined
    publishedAt?: any | null | undefined
    estimatedShippingTime?: EstimatedShippingTime | null | undefined
    overrideName?: boolean | null | undefined
    overrideDescription?: boolean | null | undefined
    overrideImageUrls?: boolean | null | undefined
    phoneNumberRequired?: boolean | null | undefined
    variantOrderFlexibility?: boolean | null | undefined
    imageUrls?:
      | Array<{ __typename?: 'ImageReference'; url: string; description?: string | null | undefined }>
      | null
      | undefined
    variants?:
      | Array<{
          __typename?: 'DraftVariant'
          id: any
          externalId?: string | null | undefined
          variantName: string
          description?: string | null | undefined
          sku?: string | null | undefined
          upcCode?: string | null | undefined
          price?: number | null | undefined
          quantity?: number | null | undefined
          taxable?: boolean | null | undefined
          requiresShipping?: boolean | null | undefined
          externalCreatedAt?: any | null | undefined
          externalUpdatedAt?: any | null | undefined
          physicalMessageSupported?: boolean | null | undefined
          overrideVariantName?: boolean | null | undefined
          overrideImageUrls?: boolean | null | undefined
          containsPerishables?: boolean | null | undefined
          fulfillmentType?: string | null | undefined
          imageUrls?:
            | Array<{ __typename?: 'ImageReference'; url: string; description?: string | null | undefined }>
            | null
            | undefined
          shippingOptions?:
            | Array<{ __typename?: 'ShippingOption'; name: string; price: number } | null | undefined>
            | null
            | undefined
        }>
      | null
      | undefined
  }
}

export type RequestPublicListingMutationVariables = Exact<{
  id: Scalars['ObjectId']
}>

export type RequestPublicListingMutation = {
  __typename?: 'Mutation'
  requestPublicListing: {
    __typename?: 'DraftMarketplaceProduct'
    id: any
    systemName: string
    description?: string | null | undefined
    externalId?: string | null | undefined
    category?: string | null | undefined
    name: string
    tags?: Array<string | null | undefined> | null | undefined
    brandName?: string | null | undefined
    externalCreatedAt?: any | null | undefined
    externalUpdatedAt?: any | null | undefined
    marketplaceStatus?: Status | null | undefined
    orderRulesId?: any | null | undefined
    keywords?: Array<string> | null | undefined
    type?: string | null | undefined
    status: DraftStatus
    statusReason?: string | null | undefined
    marketplaceProductId?: any | null | undefined
    publishedAt?: any | null | undefined
    estimatedShippingTime?: EstimatedShippingTime | null | undefined
    overrideName?: boolean | null | undefined
    overrideDescription?: boolean | null | undefined
    overrideImageUrls?: boolean | null | undefined
    phoneNumberRequired?: boolean | null | undefined
    variantOrderFlexibility?: boolean | null | undefined
    imageUrls?:
      | Array<{ __typename?: 'ImageReference'; url: string; description?: string | null | undefined }>
      | null
      | undefined
    variants?:
      | Array<{
          __typename?: 'DraftVariant'
          id: any
          externalId?: string | null | undefined
          variantName: string
          description?: string | null | undefined
          sku?: string | null | undefined
          upcCode?: string | null | undefined
          price?: number | null | undefined
          quantity?: number | null | undefined
          taxable?: boolean | null | undefined
          requiresShipping?: boolean | null | undefined
          externalCreatedAt?: any | null | undefined
          externalUpdatedAt?: any | null | undefined
          physicalMessageSupported?: boolean | null | undefined
          overrideVariantName?: boolean | null | undefined
          overrideImageUrls?: boolean | null | undefined
          containsPerishables?: boolean | null | undefined
          fulfillmentType?: string | null | undefined
          imageUrls?:
            | Array<{ __typename?: 'ImageReference'; url: string; description?: string | null | undefined }>
            | null
            | undefined
          shippingOptions?:
            | Array<{ __typename?: 'ShippingOption'; name: string; price: number } | null | undefined>
            | null
            | undefined
        }>
      | null
      | undefined
  }
}

export type AddDraftMarketplaceProductVariantMutationVariables = Exact<{
  draftProductId: Scalars['ObjectId']
  data: DraftVariantInput
}>

export type AddDraftMarketplaceProductVariantMutation = {
  __typename?: 'Mutation'
  addDraftMarketplaceProductVariant: {
    __typename?: 'DraftMarketplaceProduct'
    id: any
    systemName: string
    description?: string | null | undefined
    externalId?: string | null | undefined
    category?: string | null | undefined
    name: string
    tags?: Array<string | null | undefined> | null | undefined
    brandName?: string | null | undefined
    externalCreatedAt?: any | null | undefined
    externalUpdatedAt?: any | null | undefined
    marketplaceStatus?: Status | null | undefined
    orderRulesId?: any | null | undefined
    keywords?: Array<string> | null | undefined
    type?: string | null | undefined
    status: DraftStatus
    statusReason?: string | null | undefined
    marketplaceProductId?: any | null | undefined
    publishedAt?: any | null | undefined
    estimatedShippingTime?: EstimatedShippingTime | null | undefined
    overrideName?: boolean | null | undefined
    overrideDescription?: boolean | null | undefined
    overrideImageUrls?: boolean | null | undefined
    phoneNumberRequired?: boolean | null | undefined
    variantOrderFlexibility?: boolean | null | undefined
    imageUrls?:
      | Array<{ __typename?: 'ImageReference'; url: string; description?: string | null | undefined }>
      | null
      | undefined
    variants?:
      | Array<{
          __typename?: 'DraftVariant'
          id: any
          externalId?: string | null | undefined
          variantName: string
          description?: string | null | undefined
          sku?: string | null | undefined
          upcCode?: string | null | undefined
          price?: number | null | undefined
          quantity?: number | null | undefined
          taxable?: boolean | null | undefined
          requiresShipping?: boolean | null | undefined
          externalCreatedAt?: any | null | undefined
          externalUpdatedAt?: any | null | undefined
          physicalMessageSupported?: boolean | null | undefined
          overrideVariantName?: boolean | null | undefined
          overrideImageUrls?: boolean | null | undefined
          containsPerishables?: boolean | null | undefined
          fulfillmentType?: string | null | undefined
          imageUrls?:
            | Array<{ __typename?: 'ImageReference'; url: string; description?: string | null | undefined }>
            | null
            | undefined
          shippingOptions?:
            | Array<{ __typename?: 'ShippingOption'; name: string; price: number } | null | undefined>
            | null
            | undefined
        }>
      | null
      | undefined
  }
}

export type UpdateDraftMarketplaceProductVariantMutationVariables = Exact<{
  draftProductId: Scalars['ObjectId']
  id: Scalars['ObjectId']
  data: DraftVariantInput
}>

export type UpdateDraftMarketplaceProductVariantMutation = {
  __typename?: 'Mutation'
  updateDraftMarketplaceProductVariant: {
    __typename?: 'DraftMarketplaceProduct'
    id: any
    systemName: string
    description?: string | null | undefined
    externalId?: string | null | undefined
    category?: string | null | undefined
    name: string
    tags?: Array<string | null | undefined> | null | undefined
    brandName?: string | null | undefined
    externalCreatedAt?: any | null | undefined
    externalUpdatedAt?: any | null | undefined
    marketplaceStatus?: Status | null | undefined
    orderRulesId?: any | null | undefined
    keywords?: Array<string> | null | undefined
    type?: string | null | undefined
    status: DraftStatus
    statusReason?: string | null | undefined
    marketplaceProductId?: any | null | undefined
    publishedAt?: any | null | undefined
    estimatedShippingTime?: EstimatedShippingTime | null | undefined
    overrideName?: boolean | null | undefined
    overrideDescription?: boolean | null | undefined
    overrideImageUrls?: boolean | null | undefined
    phoneNumberRequired?: boolean | null | undefined
    variantOrderFlexibility?: boolean | null | undefined
    imageUrls?:
      | Array<{ __typename?: 'ImageReference'; url: string; description?: string | null | undefined }>
      | null
      | undefined
    variants?:
      | Array<{
          __typename?: 'DraftVariant'
          id: any
          externalId?: string | null | undefined
          variantName: string
          description?: string | null | undefined
          sku?: string | null | undefined
          upcCode?: string | null | undefined
          price?: number | null | undefined
          quantity?: number | null | undefined
          taxable?: boolean | null | undefined
          requiresShipping?: boolean | null | undefined
          externalCreatedAt?: any | null | undefined
          externalUpdatedAt?: any | null | undefined
          physicalMessageSupported?: boolean | null | undefined
          overrideVariantName?: boolean | null | undefined
          overrideImageUrls?: boolean | null | undefined
          containsPerishables?: boolean | null | undefined
          fulfillmentType?: string | null | undefined
          imageUrls?:
            | Array<{ __typename?: 'ImageReference'; url: string; description?: string | null | undefined }>
            | null
            | undefined
          shippingOptions?:
            | Array<{ __typename?: 'ShippingOption'; name: string; price: number } | null | undefined>
            | null
            | undefined
        }>
      | null
      | undefined
  }
}

export type RemoveDraftMarketplaceProductVariantMutationVariables = Exact<{
  draftProductId: Scalars['ObjectId']
  id: Scalars['ObjectId']
}>

export type RemoveDraftMarketplaceProductVariantMutation = {
  __typename?: 'Mutation'
  removeDraftMarketplaceProductVariant: {
    __typename?: 'DraftMarketplaceProduct'
    id: any
    systemName: string
    description?: string | null | undefined
    externalId?: string | null | undefined
    category?: string | null | undefined
    name: string
    tags?: Array<string | null | undefined> | null | undefined
    brandName?: string | null | undefined
    externalCreatedAt?: any | null | undefined
    externalUpdatedAt?: any | null | undefined
    marketplaceStatus?: Status | null | undefined
    orderRulesId?: any | null | undefined
    keywords?: Array<string> | null | undefined
    type?: string | null | undefined
    status: DraftStatus
    statusReason?: string | null | undefined
    marketplaceProductId?: any | null | undefined
    publishedAt?: any | null | undefined
    estimatedShippingTime?: EstimatedShippingTime | null | undefined
    overrideName?: boolean | null | undefined
    overrideDescription?: boolean | null | undefined
    overrideImageUrls?: boolean | null | undefined
    phoneNumberRequired?: boolean | null | undefined
    variantOrderFlexibility?: boolean | null | undefined
    imageUrls?:
      | Array<{ __typename?: 'ImageReference'; url: string; description?: string | null | undefined }>
      | null
      | undefined
    variants?:
      | Array<{
          __typename?: 'DraftVariant'
          id: any
          externalId?: string | null | undefined
          variantName: string
          description?: string | null | undefined
          sku?: string | null | undefined
          upcCode?: string | null | undefined
          price?: number | null | undefined
          quantity?: number | null | undefined
          taxable?: boolean | null | undefined
          requiresShipping?: boolean | null | undefined
          externalCreatedAt?: any | null | undefined
          externalUpdatedAt?: any | null | undefined
          physicalMessageSupported?: boolean | null | undefined
          overrideVariantName?: boolean | null | undefined
          overrideImageUrls?: boolean | null | undefined
          containsPerishables?: boolean | null | undefined
          fulfillmentType?: string | null | undefined
          imageUrls?:
            | Array<{ __typename?: 'ImageReference'; url: string; description?: string | null | undefined }>
            | null
            | undefined
          shippingOptions?:
            | Array<{ __typename?: 'ShippingOption'; name: string; price: number } | null | undefined>
            | null
            | undefined
        }>
      | null
      | undefined
  }
}

export type ImageReferenceFragment = {
  __typename?: 'ImageReference'
  url: string
  description?: string | null | undefined
}

export type MagicEventInstanceFragment = {
  __typename?: 'MagicEventInstance'
  accountName?: string | null | undefined
  requestedByName: string
  requestedByEmail: string
  requestedByPhone: string
  requestedByMessage: string
  requestedAttendeeCount: number
  requestedDates?: Array<any> | null | undefined
  eventDateTime?: any | null | undefined
  status: MagicEventStatus
  lastRegistrationDateTime?: any | null | undefined
  meetingLink?: string | null | undefined
}

export type ApprovedProductVariantFragment = {
  __typename?: 'ApprovedProductVariant'
  id: any
  variantName: string
  description?: string | null | undefined
  status: Status
  price: number
  displayPrice: number
  displaySubscriberPrice: number
  giftMessageSupported?: boolean | null | undefined
  imageUrls?:
    | Array<{ __typename?: 'ImageReference'; url: string; description?: string | null | undefined }>
    | null
    | undefined
}

export type ApprovedPostalFragment = {
  __typename?: 'ApprovedPostal'
  id: any
  name: string
  description: string
  brandName?: string | null | undefined
  status: Status
  category: string
  type?: string | null | undefined
  subCategory?: Array<string> | null | undefined
  useCases?: Array<string> | null | undefined
  marketplaceProductId: any
  imageUrls?:
    | Array<{ __typename?: 'ImageReference'; url: string; description?: string | null | undefined }>
    | null
    | undefined
  variants?:
    | Array<{
        __typename?: 'ApprovedProductVariant'
        id: any
        variantName: string
        description?: string | null | undefined
        status: Status
        price: number
        displayPrice: number
        displaySubscriberPrice: number
        giftMessageSupported?: boolean | null | undefined
        imageUrls?:
          | Array<{ __typename?: 'ImageReference'; url: string; description?: string | null | undefined }>
          | null
          | undefined
      }>
    | null
    | undefined
  event?:
    | {
        __typename?: 'MagicEventInstance'
        accountName?: string | null | undefined
        requestedByName: string
        requestedByEmail: string
        requestedByPhone: string
        requestedByMessage: string
        requestedAttendeeCount: number
        requestedDates?: Array<any> | null | undefined
        eventDateTime?: any | null | undefined
        status: MagicEventStatus
        lastRegistrationDateTime?: any | null | undefined
        meetingLink?: string | null | undefined
      }
    | null
    | undefined
}

export type GetEventQueryVariables = Exact<{
  id: Scalars['ObjectId']
}>

export type GetEventQuery = {
  __typename?: 'Query'
  getEvent: {
    __typename?: 'ApprovedPostal'
    id: any
    name: string
    description: string
    brandName?: string | null | undefined
    status: Status
    category: string
    type?: string | null | undefined
    subCategory?: Array<string> | null | undefined
    useCases?: Array<string> | null | undefined
    marketplaceProductId: any
    imageUrls?:
      | Array<{ __typename?: 'ImageReference'; url: string; description?: string | null | undefined }>
      | null
      | undefined
    variants?:
      | Array<{
          __typename?: 'ApprovedProductVariant'
          id: any
          variantName: string
          description?: string | null | undefined
          status: Status
          price: number
          displayPrice: number
          displaySubscriberPrice: number
          giftMessageSupported?: boolean | null | undefined
          imageUrls?:
            | Array<{ __typename?: 'ImageReference'; url: string; description?: string | null | undefined }>
            | null
            | undefined
        }>
      | null
      | undefined
    event?:
      | {
          __typename?: 'MagicEventInstance'
          accountName?: string | null | undefined
          requestedByName: string
          requestedByEmail: string
          requestedByPhone: string
          requestedByMessage: string
          requestedAttendeeCount: number
          requestedDates?: Array<any> | null | undefined
          eventDateTime?: any | null | undefined
          status: MagicEventStatus
          lastRegistrationDateTime?: any | null | undefined
          meetingLink?: string | null | undefined
        }
      | null
      | undefined
  }
}

export type SearchEventsQueryVariables = Exact<{
  filter?: InputMaybe<EventFilterInput>
  orderBy?: InputMaybe<EventOrderByInput>
  skip?: InputMaybe<Scalars['Int']>
  limit?: InputMaybe<Scalars['Int']>
}>

export type SearchEventsQuery = {
  __typename?: 'Query'
  searchEvents?:
    | Array<{
        __typename?: 'ApprovedPostal'
        id: any
        name: string
        description: string
        brandName?: string | null | undefined
        status: Status
        category: string
        type?: string | null | undefined
        subCategory?: Array<string> | null | undefined
        useCases?: Array<string> | null | undefined
        marketplaceProductId: any
        imageUrls?:
          | Array<{ __typename?: 'ImageReference'; url: string; description?: string | null | undefined }>
          | null
          | undefined
        variants?:
          | Array<{
              __typename?: 'ApprovedProductVariant'
              id: any
              variantName: string
              description?: string | null | undefined
              status: Status
              price: number
              displayPrice: number
              displaySubscriberPrice: number
              giftMessageSupported?: boolean | null | undefined
              imageUrls?:
                | Array<{ __typename?: 'ImageReference'; url: string; description?: string | null | undefined }>
                | null
                | undefined
            }>
          | null
          | undefined
        event?:
          | {
              __typename?: 'MagicEventInstance'
              accountName?: string | null | undefined
              requestedByName: string
              requestedByEmail: string
              requestedByPhone: string
              requestedByMessage: string
              requestedAttendeeCount: number
              requestedDates?: Array<any> | null | undefined
              eventDateTime?: any | null | undefined
              status: MagicEventStatus
              lastRegistrationDateTime?: any | null | undefined
              meetingLink?: string | null | undefined
            }
          | null
          | undefined
      }>
    | null
    | undefined
}

export type IntegrationSyncFragment = {
  __typename?: 'IntegrationSync'
  id: any
  system: string
  status: Status
  lastRun?: any | null | undefined
  nextRun?: any | null | undefined
  pollingTimeMins?: number | null | undefined
  workStatus?: BackgroundTaskStatus | null | undefined
  objectType: DataObjectType
}

export type ConnectShopifyMutationVariables = Exact<{
  shopifyUrl: Scalars['String']
  accessToken: Scalars['String']
}>

export type ConnectShopifyMutation = {
  __typename?: 'Mutation'
  connectShopify: {
    __typename?: 'ShopifyCollection'
    crntCollectionId?: string | null | undefined
    collectionList?:
      | Array<{
          __typename?: 'Collection'
          cursor?: string | null | undefined
          title?: string | null | undefined
          legacyResourceId?: string | null | undefined
          productsCount?: number | null | undefined
        }>
      | null
      | undefined
    pageInfo?: { __typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean } | null | undefined
  }
}

export type SyncShopifyMutationVariables = Exact<{ [key: string]: never }>

export type SyncShopifyMutation = {
  __typename?: 'Mutation'
  syncShopify: {
    __typename?: 'BackgroundTask'
    id: any
    relatedObjectId?: any | null | undefined
    name: string
    taskAction: string
    type: BackgroundTaskType
    status: BackgroundTaskStatus
    processedCount: number
    successCount: number
    errorCount: number
    taskStarted?: any | null | undefined
    taskCompleted?: any | null | undefined
    taskErrored?: any | null | undefined
    outputs?: any | null | undefined
    errors?:
      | Array<{
          __typename?: 'BackgroundOperationError'
          code: string
          count: number
          message?: string | null | undefined
        }>
      | null
      | undefined
  }
}

export type SearchIntegrationSyncQueryVariables = Exact<{
  filter?: InputMaybe<IntegrationSyncFilterInput>
}>

export type SearchIntegrationSyncQuery = {
  __typename?: 'Query'
  searchIntegrationSync?:
    | Array<{
        __typename?: 'IntegrationSync'
        id: any
        system: string
        status: Status
        lastRun?: any | null | undefined
        nextRun?: any | null | undefined
        pollingTimeMins?: number | null | undefined
        workStatus?: BackgroundTaskStatus | null | undefined
        objectType: DataObjectType
      }>
    | null
    | undefined
}

export type ModuleFragment = {
  __typename?: 'Module'
  name: string
  permissions?: any | null | undefined
  meta?: any | null | undefined
}

export type ModulesQueryVariables = Exact<{ [key: string]: never }>

export type ModulesQuery = {
  __typename?: 'Query'
  modules?:
    | Array<
        | { __typename?: 'Module'; name: string; permissions?: any | null | undefined; meta?: any | null | undefined }
        | null
        | undefined
      >
    | null
    | undefined
}

export type UpdateVendorNotificationsMutationVariables = Exact<{
  id: Scalars['ObjectId']
  data: VendorNotificationsInput
}>

export type UpdateVendorNotificationsMutation = {
  __typename?: 'Mutation'
  updateVendorNotifications: {
    __typename?: 'User'
    id: any
    firstName?: string | null | undefined
    lastName?: string | null | undefined
    userName: string
    emailAddress: string
    profilePicture?: any | null | undefined
    phoneNumber?: string | null | undefined
    title?: string | null | undefined
    meetingLink?: string | null | undefined
    status: Status
    accountLockedUntil?: any | null | undefined
    authenticationFailures?: number | null | undefined
    handwritingName?: string | null | undefined
    auth?:
      | Array<
          | {
              __typename?: 'AuthenticationMethod'
              method: string
              version?: string | null | undefined
              expires?: any | null | undefined
              updated?: any | null | undefined
              created?: any | null | undefined
            }
          | null
          | undefined
        >
      | null
      | undefined
    created?:
      | { __typename?: 'ActionRecord'; actor?: string | null | undefined; dateTime?: any | null | undefined }
      | null
      | undefined
    productAccess?:
      | Array<{
          __typename?: 'ProductAccess'
          id: any
          accountId?: any | null | undefined
          teamId?: any | null | undefined
          partnerId?: any | null | undefined
          product: Product
          roles?: Array<string> | null | undefined
          type?: ProductAccessType | null | undefined
          created: { __typename?: 'ActionRecord'; actor?: string | null | undefined; dateTime?: any | null | undefined }
        }>
      | null
      | undefined
    crmUserMap?: Array<{ __typename?: 'CrmUser'; system: string; crmIdentifier: string }> | null | undefined
    notifications?:
      | {
          __typename?: 'UserNotifications'
          notificationsOn?: boolean | null | undefined
          balanceLowNotificationsOn?: boolean | null | undefined
          balanceLowChannels?: Array<UserNotificationChannel | null | undefined> | null | undefined
          lastBalanceLowNotificationSent?: any | null | undefined
          budgetLowNotificationsOn?: boolean | null | undefined
          budgetLowChannels?: Array<UserNotificationChannel | null | undefined> | null | undefined
          lastBudgetLowNotificationSent?: any | null | undefined
          giftEmailAcceptedNotificationsOn?: boolean | null | undefined
          giftEmailAcceptedChannels?: Array<UserNotificationChannel | null | undefined> | null | undefined
          magicLinkAcceptedNotificationsOn?: boolean | null | undefined
          magicLinkAcceptedChannels?: Array<UserNotificationChannel | null | undefined> | null | undefined
        }
      | null
      | undefined
    vendorNotifications?:
      | {
          __typename?: 'VendorNotifications'
          eventConfirmation: {
            __typename?: 'NotificationInfo'
            toggleOn?: boolean | null | undefined
            channels?: Array<UserNotificationChannel> | null | undefined
            lastSent?: any | null | undefined
          }
          eventMeetingLinkChanged: {
            __typename?: 'NotificationInfo'
            toggleOn?: boolean | null | undefined
            channels?: Array<UserNotificationChannel> | null | undefined
            lastSent?: any | null | undefined
          }
          eventDateTimeChanged: {
            __typename?: 'NotificationInfo'
            toggleOn?: boolean | null | undefined
            channels?: Array<UserNotificationChannel> | null | undefined
            lastSent?: any | null | undefined
          }
          unconfirmedOrders: {
            __typename?: 'NotificationInfo'
            toggleOn?: boolean | null | undefined
            channels?: Array<UserNotificationChannel> | null | undefined
            lastSent?: any | null | undefined
          }
          unshippedOrders: {
            __typename?: 'NotificationInfo'
            toggleOn?: boolean | null | undefined
            channels?: Array<UserNotificationChannel> | null | undefined
            lastSent?: any | null | undefined
          }
          registrationClosed: {
            __typename?: 'NotificationInfo'
            toggleOn?: boolean | null | undefined
            channels?: Array<UserNotificationChannel> | null | undefined
            lastSent?: any | null | undefined
          }
          eventCancelled: {
            __typename?: 'NotificationInfo'
            toggleOn?: boolean | null | undefined
            channels?: Array<UserNotificationChannel> | null | undefined
            lastSent?: any | null | undefined
          }
          newEvent: {
            __typename?: 'NotificationInfo'
            toggleOn?: boolean | null | undefined
            channels?: Array<UserNotificationChannel> | null | undefined
            lastSent?: any | null | undefined
          }
        }
      | null
      | undefined
  }
}

export type ElementLocationFragment = {
  __typename?: 'ElementLocation'
  x: number
  y: number
  width: number
  height: number
}

export type ElementResizeFragment = {
  __typename?: 'ElementResize'
  minWidth: number
  minHeight: number
  maxWidth: number
  maxHeight: number
}

export type ElementBoundaryFragment = {
  __typename?: 'ElementBoundary'
  minX: number
  minY: number
  maxX: number
  maxY: number
}

export type ElementConfigFragment = {
  __typename?: 'ElementConfig'
  name: string
  description?: string | null | undefined
  hidden?: boolean | null | undefined
  locked?: boolean | null | undefined
  noPdfOutput?: boolean | null | undefined
  settings?: any | null | undefined
  customizable?: boolean | null | undefined
  location: { __typename?: 'ElementLocation'; x: number; y: number; width: number; height: number }
  resize?:
    | { __typename?: 'ElementResize'; minWidth: number; minHeight: number; maxWidth: number; maxHeight: number }
    | null
    | undefined
  boundary?:
    | { __typename?: 'ElementBoundary'; minX: number; minY: number; maxX: number; maxY: number }
    | null
    | undefined
}

export type CanvasSizeFragment = { __typename?: 'CanvasSize'; width: number; height: number }

export type DesignTemplateFragment = {
  __typename?: 'DesignTemplate'
  dpi: number
  front?:
    | Array<{
        __typename?: 'ElementConfig'
        name: string
        description?: string | null | undefined
        hidden?: boolean | null | undefined
        locked?: boolean | null | undefined
        noPdfOutput?: boolean | null | undefined
        settings?: any | null | undefined
        customizable?: boolean | null | undefined
        location: { __typename?: 'ElementLocation'; x: number; y: number; width: number; height: number }
        resize?:
          | { __typename?: 'ElementResize'; minWidth: number; minHeight: number; maxWidth: number; maxHeight: number }
          | null
          | undefined
        boundary?:
          | { __typename?: 'ElementBoundary'; minX: number; minY: number; maxX: number; maxY: number }
          | null
          | undefined
      }>
    | null
    | undefined
  back?:
    | Array<{
        __typename?: 'ElementConfig'
        name: string
        description?: string | null | undefined
        hidden?: boolean | null | undefined
        locked?: boolean | null | undefined
        noPdfOutput?: boolean | null | undefined
        settings?: any | null | undefined
        customizable?: boolean | null | undefined
        location: { __typename?: 'ElementLocation'; x: number; y: number; width: number; height: number }
        resize?:
          | { __typename?: 'ElementResize'; minWidth: number; minHeight: number; maxWidth: number; maxHeight: number }
          | null
          | undefined
        boundary?:
          | { __typename?: 'ElementBoundary'; minX: number; minY: number; maxX: number; maxY: number }
          | null
          | undefined
      }>
    | null
    | undefined
  outputSize: { __typename?: 'CanvasSize'; width: number; height: number }
}

export type ProductVariantFragment = {
  __typename?: 'ProductVariant'
  id: any
  variantName: string
  description?: string | null | undefined
  upcCode?: string | null | undefined
  manufacturerPartId?: string | null | undefined
  status: Status
  size?: string | null | undefined
  giftMessageSupported?: boolean | null | undefined
  physicalMessageSupported?: boolean | null | undefined
  attributes?: any | null | undefined
  minimumTransactions?: number | null | undefined
  flatFees?: number | null | undefined
  displayPrice?: number | null | undefined
  imageUrls?:
    | Array<{ __typename?: 'ImageReference'; url: string; description?: string | null | undefined }>
    | null
    | undefined
  fulfillmentPartnerList?:
    | Array<{
        __typename?: 'FulfillmentMap'
        fulfillmentType: FulfillmentType
        fulfillmentPartnerId: any
        fulfillmentPartnerName: string
        itemCode: string
        displayPrice?: number | null | undefined
        displaySubscriberPrice?: number | null | undefined
        price: number
        subscriberPrice?: number | null | undefined
        transactionFee?: number | null | undefined
        thirdPartyFee?: number | null | undefined
        thirdPartyExpense?: number | null | undefined
        revSharePercentage?: number | null | undefined
        revShareFee?: number | null | undefined
        supplierPartId?: string | null | undefined
        supplierPartAuxiliaryId?: string | null | undefined
        shippingOptions?: Array<{ __typename?: 'ShippingOption'; name: string; price: number }> | null | undefined
        inventory?:
          | { __typename?: 'Inventory'; ordered?: number | null | undefined; available?: number | null | undefined }
          | null
          | undefined
      }>
    | null
    | undefined
}

export type MagicEventFragment = {
  __typename?: 'MagicEvent'
  eventLengthMinutes: number
  minimumAttendees?: number | null | undefined
  maximumAttendees?: number | null | undefined
  requirements?: Array<Requirements> | null | undefined
  talentEmailAddress: string
  talentName: string
  talentPhoneNumber: string
  talentCompany?: string | null | undefined
}

export type ItemCustomizationFragment = {
  __typename?: 'ItemCustomization'
  id: string
  displayName?: string | null | undefined
  type?: ItemCustomizationType | null | undefined
  customizableBySender?: boolean | null | undefined
  customizableByRecipient?: boolean | null | undefined
  required?: boolean | null | undefined
  value?: any | null | undefined
  characterLimit?: number | null | undefined
  widthRecommendation?: number | null | undefined
  heightRecommendation?: number | null | undefined
  fileTypesAccepted?: Array<string> | null | undefined
  variantId?: any | null | undefined
}

export type MarketplaceProductFragment = {
  __typename?: 'MarketplaceProduct'
  id: any
  name: string
  description: string
  brandName?: string | null | undefined
  category: string
  type?: string | null | undefined
  subCategory?: Array<string> | null | undefined
  useCases?: Array<string> | null | undefined
  starterTemplate?: boolean | null | undefined
  itemPreview?: boolean | null | undefined
  allowedPlans?: Array<string> | null | undefined
  status: Status
  variantOrderFlexibility?: boolean | null | undefined
  phoneNumberRequired?: boolean | null | undefined
  eventAvailabilityDates?: Array<string> | null | undefined
  variants?:
    | Array<{
        __typename?: 'ProductVariant'
        id: any
        variantName: string
        description?: string | null | undefined
        upcCode?: string | null | undefined
        manufacturerPartId?: string | null | undefined
        status: Status
        size?: string | null | undefined
        giftMessageSupported?: boolean | null | undefined
        physicalMessageSupported?: boolean | null | undefined
        attributes?: any | null | undefined
        minimumTransactions?: number | null | undefined
        flatFees?: number | null | undefined
        displayPrice?: number | null | undefined
        imageUrls?:
          | Array<{ __typename?: 'ImageReference'; url: string; description?: string | null | undefined }>
          | null
          | undefined
        fulfillmentPartnerList?:
          | Array<{
              __typename?: 'FulfillmentMap'
              fulfillmentType: FulfillmentType
              fulfillmentPartnerId: any
              fulfillmentPartnerName: string
              itemCode: string
              displayPrice?: number | null | undefined
              displaySubscriberPrice?: number | null | undefined
              price: number
              subscriberPrice?: number | null | undefined
              transactionFee?: number | null | undefined
              thirdPartyFee?: number | null | undefined
              thirdPartyExpense?: number | null | undefined
              revSharePercentage?: number | null | undefined
              revShareFee?: number | null | undefined
              supplierPartId?: string | null | undefined
              supplierPartAuxiliaryId?: string | null | undefined
              shippingOptions?: Array<{ __typename?: 'ShippingOption'; name: string; price: number }> | null | undefined
              inventory?:
                | {
                    __typename?: 'Inventory'
                    ordered?: number | null | undefined
                    available?: number | null | undefined
                  }
                | null
                | undefined
            }>
          | null
          | undefined
      }>
    | null
    | undefined
  imageUrls?:
    | Array<{ __typename?: 'ImageReference'; url: string; description?: string | null | undefined }>
    | null
    | undefined
  designTemplate?:
    | {
        __typename?: 'DesignTemplate'
        dpi: number
        front?:
          | Array<{
              __typename?: 'ElementConfig'
              name: string
              description?: string | null | undefined
              hidden?: boolean | null | undefined
              locked?: boolean | null | undefined
              noPdfOutput?: boolean | null | undefined
              settings?: any | null | undefined
              customizable?: boolean | null | undefined
              location: { __typename?: 'ElementLocation'; x: number; y: number; width: number; height: number }
              resize?:
                | {
                    __typename?: 'ElementResize'
                    minWidth: number
                    minHeight: number
                    maxWidth: number
                    maxHeight: number
                  }
                | null
                | undefined
              boundary?:
                | { __typename?: 'ElementBoundary'; minX: number; minY: number; maxX: number; maxY: number }
                | null
                | undefined
            }>
          | null
          | undefined
        back?:
          | Array<{
              __typename?: 'ElementConfig'
              name: string
              description?: string | null | undefined
              hidden?: boolean | null | undefined
              locked?: boolean | null | undefined
              noPdfOutput?: boolean | null | undefined
              settings?: any | null | undefined
              customizable?: boolean | null | undefined
              location: { __typename?: 'ElementLocation'; x: number; y: number; width: number; height: number }
              resize?:
                | {
                    __typename?: 'ElementResize'
                    minWidth: number
                    minHeight: number
                    maxWidth: number
                    maxHeight: number
                  }
                | null
                | undefined
              boundary?:
                | { __typename?: 'ElementBoundary'; minX: number; minY: number; maxX: number; maxY: number }
                | null
                | undefined
            }>
          | null
          | undefined
        outputSize: { __typename?: 'CanvasSize'; width: number; height: number }
      }
    | null
    | undefined
  eventDetails?:
    | {
        __typename?: 'MagicEvent'
        eventLengthMinutes: number
        minimumAttendees?: number | null | undefined
        maximumAttendees?: number | null | undefined
        requirements?: Array<Requirements> | null | undefined
        talentEmailAddress: string
        talentName: string
        talentPhoneNumber: string
        talentCompany?: string | null | undefined
      }
    | null
    | undefined
}

export type InventoryFragment = {
  __typename?: 'Inventory'
  ordered?: number | null | undefined
  available?: number | null | undefined
}

export type FulfillmentMapFragment = {
  __typename?: 'FulfillmentMap'
  fulfillmentType: FulfillmentType
  fulfillmentPartnerId: any
  fulfillmentPartnerName: string
  itemCode: string
  displayPrice?: number | null | undefined
  displaySubscriberPrice?: number | null | undefined
  price: number
  subscriberPrice?: number | null | undefined
  transactionFee?: number | null | undefined
  thirdPartyFee?: number | null | undefined
  thirdPartyExpense?: number | null | undefined
  revSharePercentage?: number | null | undefined
  revShareFee?: number | null | undefined
  supplierPartId?: string | null | undefined
  supplierPartAuxiliaryId?: string | null | undefined
  shippingOptions?: Array<{ __typename?: 'ShippingOption'; name: string; price: number }> | null | undefined
  inventory?:
    | { __typename?: 'Inventory'; ordered?: number | null | undefined; available?: number | null | undefined }
    | null
    | undefined
}

export type OrderCostFragment = {
  __typename?: 'OrderCost'
  subtotal?: number | null | undefined
  shipping?: number | null | undefined
  tax?: number | null | undefined
  thirdPartyFee?: number | null | undefined
  taxEstimated?: boolean | null | undefined
  total?: number | null | undefined
  gmvExpense?: number | null | undefined
  postalRevenueShare?: number | null | undefined
}

export type FulfillmentHistoryFragment = {
  __typename?: 'FulfillmentHistory'
  status: FulfillmentStatus
  notes?: string | null | undefined
  created: any
  currentLocation?: string | null | undefined
}

export type ShippingOptionFragment = { __typename?: 'ShippingOption'; name: string; price: number }

export type FulfillmentEventsFragment = { __typename?: 'FulfillmentEvents'; placed?: any | null | undefined }

export type PostalFulfillmentFragment = {
  __typename?: 'PostalFulfillment'
  id: any
  accountId: any
  accountName?: string | null | undefined
  userId: any
  contactId: any
  shipToName?: string | null | undefined
  shipToEmail?: string | null | undefined
  shipToPhone?: string | null | undefined
  addressRefresh?: boolean | null | undefined
  deliveryEmail?: boolean | null | undefined
  deliveryEmailExpires?: any | null | undefined
  deliveryEmailSent?: any | null | undefined
  deliveryEmailClicked?: any | null | undefined
  deliveryEmailCompleted?: any | null | undefined
  campaignId?: any | null | undefined
  playbookId?: any | null | undefined
  itemName: string
  physicalMessage?: string | null | undefined
  variantId?: any | null | undefined
  previewImage?: string | null | undefined
  giftMessage?: string | null | undefined
  marketplaceProductId: any
  approvedPostalId?: any | null | undefined
  fulfillmentPartnerId: any
  fulfillmentPartnerOrderId?: string | null | undefined
  status: FulfillmentStatus
  stage?: string | null | undefined
  targetDeliveryDate?: any | null | undefined
  deliveryDate?: any | null | undefined
  deliveryPartner?: string | null | undefined
  trackingNumber?: string | null | undefined
  attributes?: any | null | undefined
  formFieldValueList?: any | null | undefined
  type?: ObjectType | null | undefined
  userLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null | undefined
  shipTo?:
    | {
        __typename?: 'Address'
        address1?: string | null | undefined
        address2?: string | null | undefined
        address3?: string | null | undefined
        city?: string | null | undefined
        state?: string | null | undefined
        postalCode?: string | null | undefined
        country?: string | null | undefined
        preferred: boolean
        source?: AddressSource | null | undefined
        status?: AddressStatus | null | undefined
        statusReason?: string | null | undefined
        systems?: Array<string> | null | undefined
        uspsAddress?:
          | {
              __typename?: 'UspsAddress'
              address1?: string | null | undefined
              address2?: string | null | undefined
              address3?: string | null | undefined
              city?: string | null | undefined
              state?: string | null | undefined
              postalCode?: string | null | undefined
              country?: string | null | undefined
              plus4?: string | null | undefined
              deliveryPoint?: string | null | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
  fulfillmentMap?:
    | {
        __typename?: 'FulfillmentMap'
        fulfillmentType: FulfillmentType
        fulfillmentPartnerId: any
        fulfillmentPartnerName: string
        itemCode: string
        displayPrice?: number | null | undefined
        displaySubscriberPrice?: number | null | undefined
        price: number
        subscriberPrice?: number | null | undefined
        transactionFee?: number | null | undefined
        thirdPartyFee?: number | null | undefined
        thirdPartyExpense?: number | null | undefined
        revSharePercentage?: number | null | undefined
        revShareFee?: number | null | undefined
        supplierPartId?: string | null | undefined
        supplierPartAuxiliaryId?: string | null | undefined
        shippingOptions?: Array<{ __typename?: 'ShippingOption'; name: string; price: number }> | null | undefined
        inventory?:
          | { __typename?: 'Inventory'; ordered?: number | null | undefined; available?: number | null | undefined }
          | null
          | undefined
      }
    | null
    | undefined
  cost?:
    | {
        __typename?: 'OrderCost'
        subtotal?: number | null | undefined
        shipping?: number | null | undefined
        tax?: number | null | undefined
        thirdPartyFee?: number | null | undefined
        taxEstimated?: boolean | null | undefined
        total?: number | null | undefined
        gmvExpense?: number | null | undefined
        postalRevenueShare?: number | null | undefined
      }
    | null
    | undefined
  history?:
    | Array<{
        __typename?: 'FulfillmentHistory'
        status: FulfillmentStatus
        notes?: string | null | undefined
        created: any
        currentLocation?: string | null | undefined
      }>
    | null
    | undefined
  created?:
    | { __typename?: 'ActionRecord'; actor?: string | null | undefined; dateTime?: any | null | undefined }
    | null
    | undefined
  itemCustomizationList?:
    | Array<{
        __typename?: 'ItemCustomization'
        id: string
        displayName?: string | null | undefined
        type?: ItemCustomizationType | null | undefined
        customizableBySender?: boolean | null | undefined
        customizableByRecipient?: boolean | null | undefined
        required?: boolean | null | undefined
        value?: any | null | undefined
        characterLimit?: number | null | undefined
        widthRecommendation?: number | null | undefined
        heightRecommendation?: number | null | undefined
        fileTypesAccepted?: Array<string> | null | undefined
        variantId?: any | null | undefined
      }>
    | null
    | undefined
  fulfillmentEvents?: { __typename?: 'FulfillmentEvents'; placed?: any | null | undefined } | null | undefined
}

export type SearchPostalFulfillmentsQueryVariables = Exact<{
  filter?: InputMaybe<PostalFulfillmentFilterInput>
  orderBy?: InputMaybe<PostalFulfillmentOrderByInput>
  skip?: InputMaybe<Scalars['Int']>
  limit?: InputMaybe<Scalars['Int']>
}>

export type SearchPostalFulfillmentsQuery = {
  __typename?: 'Query'
  searchPostalFulfillments?:
    | Array<{
        __typename?: 'PostalFulfillment'
        id: any
        accountId: any
        accountName?: string | null | undefined
        userId: any
        contactId: any
        shipToName?: string | null | undefined
        shipToEmail?: string | null | undefined
        shipToPhone?: string | null | undefined
        addressRefresh?: boolean | null | undefined
        deliveryEmail?: boolean | null | undefined
        deliveryEmailExpires?: any | null | undefined
        deliveryEmailSent?: any | null | undefined
        deliveryEmailClicked?: any | null | undefined
        deliveryEmailCompleted?: any | null | undefined
        campaignId?: any | null | undefined
        playbookId?: any | null | undefined
        itemName: string
        physicalMessage?: string | null | undefined
        variantId?: any | null | undefined
        previewImage?: string | null | undefined
        giftMessage?: string | null | undefined
        marketplaceProductId: any
        approvedPostalId?: any | null | undefined
        fulfillmentPartnerId: any
        fulfillmentPartnerOrderId?: string | null | undefined
        status: FulfillmentStatus
        stage?: string | null | undefined
        targetDeliveryDate?: any | null | undefined
        deliveryDate?: any | null | undefined
        deliveryPartner?: string | null | undefined
        trackingNumber?: string | null | undefined
        attributes?: any | null | undefined
        formFieldValueList?: any | null | undefined
        type?: ObjectType | null | undefined
        userLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null | undefined
        shipTo?:
          | {
              __typename?: 'Address'
              address1?: string | null | undefined
              address2?: string | null | undefined
              address3?: string | null | undefined
              city?: string | null | undefined
              state?: string | null | undefined
              postalCode?: string | null | undefined
              country?: string | null | undefined
              preferred: boolean
              source?: AddressSource | null | undefined
              status?: AddressStatus | null | undefined
              statusReason?: string | null | undefined
              systems?: Array<string> | null | undefined
              uspsAddress?:
                | {
                    __typename?: 'UspsAddress'
                    address1?: string | null | undefined
                    address2?: string | null | undefined
                    address3?: string | null | undefined
                    city?: string | null | undefined
                    state?: string | null | undefined
                    postalCode?: string | null | undefined
                    country?: string | null | undefined
                    plus4?: string | null | undefined
                    deliveryPoint?: string | null | undefined
                  }
                | null
                | undefined
            }
          | null
          | undefined
        fulfillmentMap?:
          | {
              __typename?: 'FulfillmentMap'
              fulfillmentType: FulfillmentType
              fulfillmentPartnerId: any
              fulfillmentPartnerName: string
              itemCode: string
              displayPrice?: number | null | undefined
              displaySubscriberPrice?: number | null | undefined
              price: number
              subscriberPrice?: number | null | undefined
              transactionFee?: number | null | undefined
              thirdPartyFee?: number | null | undefined
              thirdPartyExpense?: number | null | undefined
              revSharePercentage?: number | null | undefined
              revShareFee?: number | null | undefined
              supplierPartId?: string | null | undefined
              supplierPartAuxiliaryId?: string | null | undefined
              shippingOptions?: Array<{ __typename?: 'ShippingOption'; name: string; price: number }> | null | undefined
              inventory?:
                | {
                    __typename?: 'Inventory'
                    ordered?: number | null | undefined
                    available?: number | null | undefined
                  }
                | null
                | undefined
            }
          | null
          | undefined
        cost?:
          | {
              __typename?: 'OrderCost'
              subtotal?: number | null | undefined
              shipping?: number | null | undefined
              tax?: number | null | undefined
              thirdPartyFee?: number | null | undefined
              taxEstimated?: boolean | null | undefined
              total?: number | null | undefined
              gmvExpense?: number | null | undefined
              postalRevenueShare?: number | null | undefined
            }
          | null
          | undefined
        history?:
          | Array<{
              __typename?: 'FulfillmentHistory'
              status: FulfillmentStatus
              notes?: string | null | undefined
              created: any
              currentLocation?: string | null | undefined
            }>
          | null
          | undefined
        created?:
          | { __typename?: 'ActionRecord'; actor?: string | null | undefined; dateTime?: any | null | undefined }
          | null
          | undefined
        itemCustomizationList?:
          | Array<{
              __typename?: 'ItemCustomization'
              id: string
              displayName?: string | null | undefined
              type?: ItemCustomizationType | null | undefined
              customizableBySender?: boolean | null | undefined
              customizableByRecipient?: boolean | null | undefined
              required?: boolean | null | undefined
              value?: any | null | undefined
              characterLimit?: number | null | undefined
              widthRecommendation?: number | null | undefined
              heightRecommendation?: number | null | undefined
              fileTypesAccepted?: Array<string> | null | undefined
              variantId?: any | null | undefined
            }>
          | null
          | undefined
        fulfillmentEvents?: { __typename?: 'FulfillmentEvents'; placed?: any | null | undefined } | null | undefined
      }>
    | null
    | undefined
}

export type GetProductByPostalFulfillmentQueryVariables = Exact<{
  id: Scalars['ObjectId']
}>

export type GetProductByPostalFulfillmentQuery = {
  __typename?: 'Query'
  getProductByPostalFulfillment: {
    __typename?: 'MarketplaceProduct'
    id: any
    name: string
    description: string
    brandName?: string | null | undefined
    category: string
    type?: string | null | undefined
    subCategory?: Array<string> | null | undefined
    useCases?: Array<string> | null | undefined
    starterTemplate?: boolean | null | undefined
    itemPreview?: boolean | null | undefined
    allowedPlans?: Array<string> | null | undefined
    status: Status
    variantOrderFlexibility?: boolean | null | undefined
    phoneNumberRequired?: boolean | null | undefined
    eventAvailabilityDates?: Array<string> | null | undefined
    variants?:
      | Array<{
          __typename?: 'ProductVariant'
          id: any
          variantName: string
          description?: string | null | undefined
          upcCode?: string | null | undefined
          manufacturerPartId?: string | null | undefined
          status: Status
          size?: string | null | undefined
          giftMessageSupported?: boolean | null | undefined
          physicalMessageSupported?: boolean | null | undefined
          attributes?: any | null | undefined
          minimumTransactions?: number | null | undefined
          flatFees?: number | null | undefined
          displayPrice?: number | null | undefined
          imageUrls?:
            | Array<{ __typename?: 'ImageReference'; url: string; description?: string | null | undefined }>
            | null
            | undefined
          fulfillmentPartnerList?:
            | Array<{
                __typename?: 'FulfillmentMap'
                fulfillmentType: FulfillmentType
                fulfillmentPartnerId: any
                fulfillmentPartnerName: string
                itemCode: string
                displayPrice?: number | null | undefined
                displaySubscriberPrice?: number | null | undefined
                price: number
                subscriberPrice?: number | null | undefined
                transactionFee?: number | null | undefined
                thirdPartyFee?: number | null | undefined
                thirdPartyExpense?: number | null | undefined
                revSharePercentage?: number | null | undefined
                revShareFee?: number | null | undefined
                supplierPartId?: string | null | undefined
                supplierPartAuxiliaryId?: string | null | undefined
                shippingOptions?:
                  | Array<{ __typename?: 'ShippingOption'; name: string; price: number }>
                  | null
                  | undefined
                inventory?:
                  | {
                      __typename?: 'Inventory'
                      ordered?: number | null | undefined
                      available?: number | null | undefined
                    }
                  | null
                  | undefined
              }>
            | null
            | undefined
        }>
      | null
      | undefined
    imageUrls?:
      | Array<{ __typename?: 'ImageReference'; url: string; description?: string | null | undefined }>
      | null
      | undefined
    designTemplate?:
      | {
          __typename?: 'DesignTemplate'
          dpi: number
          front?:
            | Array<{
                __typename?: 'ElementConfig'
                name: string
                description?: string | null | undefined
                hidden?: boolean | null | undefined
                locked?: boolean | null | undefined
                noPdfOutput?: boolean | null | undefined
                settings?: any | null | undefined
                customizable?: boolean | null | undefined
                location: { __typename?: 'ElementLocation'; x: number; y: number; width: number; height: number }
                resize?:
                  | {
                      __typename?: 'ElementResize'
                      minWidth: number
                      minHeight: number
                      maxWidth: number
                      maxHeight: number
                    }
                  | null
                  | undefined
                boundary?:
                  | { __typename?: 'ElementBoundary'; minX: number; minY: number; maxX: number; maxY: number }
                  | null
                  | undefined
              }>
            | null
            | undefined
          back?:
            | Array<{
                __typename?: 'ElementConfig'
                name: string
                description?: string | null | undefined
                hidden?: boolean | null | undefined
                locked?: boolean | null | undefined
                noPdfOutput?: boolean | null | undefined
                settings?: any | null | undefined
                customizable?: boolean | null | undefined
                location: { __typename?: 'ElementLocation'; x: number; y: number; width: number; height: number }
                resize?:
                  | {
                      __typename?: 'ElementResize'
                      minWidth: number
                      minHeight: number
                      maxWidth: number
                      maxHeight: number
                    }
                  | null
                  | undefined
                boundary?:
                  | { __typename?: 'ElementBoundary'; minX: number; minY: number; maxX: number; maxY: number }
                  | null
                  | undefined
              }>
            | null
            | undefined
          outputSize: { __typename?: 'CanvasSize'; width: number; height: number }
        }
      | null
      | undefined
    eventDetails?:
      | {
          __typename?: 'MagicEvent'
          eventLengthMinutes: number
          minimumAttendees?: number | null | undefined
          maximumAttendees?: number | null | undefined
          requirements?: Array<Requirements> | null | undefined
          talentEmailAddress: string
          talentName: string
          talentPhoneNumber: string
          talentCompany?: string | null | undefined
        }
      | null
      | undefined
  }
}

export type GetPostalFulfillmentQueryVariables = Exact<{
  id: Scalars['ObjectId']
}>

export type GetPostalFulfillmentQuery = {
  __typename?: 'Query'
  getPostalFulfillment: {
    __typename?: 'PostalFulfillment'
    id: any
    accountId: any
    accountName?: string | null | undefined
    userId: any
    contactId: any
    shipToName?: string | null | undefined
    shipToEmail?: string | null | undefined
    shipToPhone?: string | null | undefined
    addressRefresh?: boolean | null | undefined
    deliveryEmail?: boolean | null | undefined
    deliveryEmailExpires?: any | null | undefined
    deliveryEmailSent?: any | null | undefined
    deliveryEmailClicked?: any | null | undefined
    deliveryEmailCompleted?: any | null | undefined
    campaignId?: any | null | undefined
    playbookId?: any | null | undefined
    itemName: string
    physicalMessage?: string | null | undefined
    variantId?: any | null | undefined
    previewImage?: string | null | undefined
    giftMessage?: string | null | undefined
    marketplaceProductId: any
    approvedPostalId?: any | null | undefined
    fulfillmentPartnerId: any
    fulfillmentPartnerOrderId?: string | null | undefined
    status: FulfillmentStatus
    stage?: string | null | undefined
    targetDeliveryDate?: any | null | undefined
    deliveryDate?: any | null | undefined
    deliveryPartner?: string | null | undefined
    trackingNumber?: string | null | undefined
    attributes?: any | null | undefined
    formFieldValueList?: any | null | undefined
    type?: ObjectType | null | undefined
    userLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null | undefined
    shipTo?:
      | {
          __typename?: 'Address'
          address1?: string | null | undefined
          address2?: string | null | undefined
          address3?: string | null | undefined
          city?: string | null | undefined
          state?: string | null | undefined
          postalCode?: string | null | undefined
          country?: string | null | undefined
          preferred: boolean
          source?: AddressSource | null | undefined
          status?: AddressStatus | null | undefined
          statusReason?: string | null | undefined
          systems?: Array<string> | null | undefined
          uspsAddress?:
            | {
                __typename?: 'UspsAddress'
                address1?: string | null | undefined
                address2?: string | null | undefined
                address3?: string | null | undefined
                city?: string | null | undefined
                state?: string | null | undefined
                postalCode?: string | null | undefined
                country?: string | null | undefined
                plus4?: string | null | undefined
                deliveryPoint?: string | null | undefined
              }
            | null
            | undefined
        }
      | null
      | undefined
    fulfillmentMap?:
      | {
          __typename?: 'FulfillmentMap'
          fulfillmentType: FulfillmentType
          fulfillmentPartnerId: any
          fulfillmentPartnerName: string
          itemCode: string
          displayPrice?: number | null | undefined
          displaySubscriberPrice?: number | null | undefined
          price: number
          subscriberPrice?: number | null | undefined
          transactionFee?: number | null | undefined
          thirdPartyFee?: number | null | undefined
          thirdPartyExpense?: number | null | undefined
          revSharePercentage?: number | null | undefined
          revShareFee?: number | null | undefined
          supplierPartId?: string | null | undefined
          supplierPartAuxiliaryId?: string | null | undefined
          shippingOptions?: Array<{ __typename?: 'ShippingOption'; name: string; price: number }> | null | undefined
          inventory?:
            | { __typename?: 'Inventory'; ordered?: number | null | undefined; available?: number | null | undefined }
            | null
            | undefined
        }
      | null
      | undefined
    cost?:
      | {
          __typename?: 'OrderCost'
          subtotal?: number | null | undefined
          shipping?: number | null | undefined
          tax?: number | null | undefined
          thirdPartyFee?: number | null | undefined
          taxEstimated?: boolean | null | undefined
          total?: number | null | undefined
          gmvExpense?: number | null | undefined
          postalRevenueShare?: number | null | undefined
        }
      | null
      | undefined
    history?:
      | Array<{
          __typename?: 'FulfillmentHistory'
          status: FulfillmentStatus
          notes?: string | null | undefined
          created: any
          currentLocation?: string | null | undefined
        }>
      | null
      | undefined
    created?:
      | { __typename?: 'ActionRecord'; actor?: string | null | undefined; dateTime?: any | null | undefined }
      | null
      | undefined
    itemCustomizationList?:
      | Array<{
          __typename?: 'ItemCustomization'
          id: string
          displayName?: string | null | undefined
          type?: ItemCustomizationType | null | undefined
          customizableBySender?: boolean | null | undefined
          customizableByRecipient?: boolean | null | undefined
          required?: boolean | null | undefined
          value?: any | null | undefined
          characterLimit?: number | null | undefined
          widthRecommendation?: number | null | undefined
          heightRecommendation?: number | null | undefined
          fileTypesAccepted?: Array<string> | null | undefined
          variantId?: any | null | undefined
        }>
      | null
      | undefined
    fulfillmentEvents?: { __typename?: 'FulfillmentEvents'; placed?: any | null | undefined } | null | undefined
  }
}

export type UpdatePostalFulfillmentTrackingNumberMutationVariables = Exact<{
  id: Scalars['ObjectId']
  trackingNumber: Scalars['String']
}>

export type UpdatePostalFulfillmentTrackingNumberMutation = {
  __typename?: 'Mutation'
  updatePostalFulfillmentTrackingNumber: {
    __typename?: 'PostalFulfillment'
    id: any
    accountId: any
    accountName?: string | null | undefined
    userId: any
    contactId: any
    shipToName?: string | null | undefined
    shipToEmail?: string | null | undefined
    shipToPhone?: string | null | undefined
    addressRefresh?: boolean | null | undefined
    deliveryEmail?: boolean | null | undefined
    deliveryEmailExpires?: any | null | undefined
    deliveryEmailSent?: any | null | undefined
    deliveryEmailClicked?: any | null | undefined
    deliveryEmailCompleted?: any | null | undefined
    campaignId?: any | null | undefined
    playbookId?: any | null | undefined
    itemName: string
    physicalMessage?: string | null | undefined
    variantId?: any | null | undefined
    previewImage?: string | null | undefined
    giftMessage?: string | null | undefined
    marketplaceProductId: any
    approvedPostalId?: any | null | undefined
    fulfillmentPartnerId: any
    fulfillmentPartnerOrderId?: string | null | undefined
    status: FulfillmentStatus
    stage?: string | null | undefined
    targetDeliveryDate?: any | null | undefined
    deliveryDate?: any | null | undefined
    deliveryPartner?: string | null | undefined
    trackingNumber?: string | null | undefined
    attributes?: any | null | undefined
    formFieldValueList?: any | null | undefined
    type?: ObjectType | null | undefined
    userLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null | undefined
    shipTo?:
      | {
          __typename?: 'Address'
          address1?: string | null | undefined
          address2?: string | null | undefined
          address3?: string | null | undefined
          city?: string | null | undefined
          state?: string | null | undefined
          postalCode?: string | null | undefined
          country?: string | null | undefined
          preferred: boolean
          source?: AddressSource | null | undefined
          status?: AddressStatus | null | undefined
          statusReason?: string | null | undefined
          systems?: Array<string> | null | undefined
          uspsAddress?:
            | {
                __typename?: 'UspsAddress'
                address1?: string | null | undefined
                address2?: string | null | undefined
                address3?: string | null | undefined
                city?: string | null | undefined
                state?: string | null | undefined
                postalCode?: string | null | undefined
                country?: string | null | undefined
                plus4?: string | null | undefined
                deliveryPoint?: string | null | undefined
              }
            | null
            | undefined
        }
      | null
      | undefined
    fulfillmentMap?:
      | {
          __typename?: 'FulfillmentMap'
          fulfillmentType: FulfillmentType
          fulfillmentPartnerId: any
          fulfillmentPartnerName: string
          itemCode: string
          displayPrice?: number | null | undefined
          displaySubscriberPrice?: number | null | undefined
          price: number
          subscriberPrice?: number | null | undefined
          transactionFee?: number | null | undefined
          thirdPartyFee?: number | null | undefined
          thirdPartyExpense?: number | null | undefined
          revSharePercentage?: number | null | undefined
          revShareFee?: number | null | undefined
          supplierPartId?: string | null | undefined
          supplierPartAuxiliaryId?: string | null | undefined
          shippingOptions?: Array<{ __typename?: 'ShippingOption'; name: string; price: number }> | null | undefined
          inventory?:
            | { __typename?: 'Inventory'; ordered?: number | null | undefined; available?: number | null | undefined }
            | null
            | undefined
        }
      | null
      | undefined
    cost?:
      | {
          __typename?: 'OrderCost'
          subtotal?: number | null | undefined
          shipping?: number | null | undefined
          tax?: number | null | undefined
          thirdPartyFee?: number | null | undefined
          taxEstimated?: boolean | null | undefined
          total?: number | null | undefined
          gmvExpense?: number | null | undefined
          postalRevenueShare?: number | null | undefined
        }
      | null
      | undefined
    history?:
      | Array<{
          __typename?: 'FulfillmentHistory'
          status: FulfillmentStatus
          notes?: string | null | undefined
          created: any
          currentLocation?: string | null | undefined
        }>
      | null
      | undefined
    created?:
      | { __typename?: 'ActionRecord'; actor?: string | null | undefined; dateTime?: any | null | undefined }
      | null
      | undefined
    itemCustomizationList?:
      | Array<{
          __typename?: 'ItemCustomization'
          id: string
          displayName?: string | null | undefined
          type?: ItemCustomizationType | null | undefined
          customizableBySender?: boolean | null | undefined
          customizableByRecipient?: boolean | null | undefined
          required?: boolean | null | undefined
          value?: any | null | undefined
          characterLimit?: number | null | undefined
          widthRecommendation?: number | null | undefined
          heightRecommendation?: number | null | undefined
          fileTypesAccepted?: Array<string> | null | undefined
          variantId?: any | null | undefined
        }>
      | null
      | undefined
    fulfillmentEvents?: { __typename?: 'FulfillmentEvents'; placed?: any | null | undefined } | null | undefined
  }
}

export type BulkUpdatePostalFulfillmentStatusMutationVariables = Exact<{
  status: FulfillmentStatus
  filter?: InputMaybe<PostalFulfillmentFilterInput>
  ids?: InputMaybe<Array<Scalars['ObjectId']> | Scalars['ObjectId']>
}>

export type BulkUpdatePostalFulfillmentStatusMutation = {
  __typename?: 'Mutation'
  bulkUpdatePostalFulfillmentStatus: {
    __typename?: 'BackgroundTask'
    id: any
    relatedObjectId?: any | null | undefined
    name: string
    taskAction: string
    type: BackgroundTaskType
    status: BackgroundTaskStatus
    processedCount: number
    successCount: number
    errorCount: number
    taskStarted?: any | null | undefined
    taskCompleted?: any | null | undefined
    taskErrored?: any | null | undefined
    outputs?: any | null | undefined
    errors?:
      | Array<{
          __typename?: 'BackgroundOperationError'
          code: string
          count: number
          message?: string | null | undefined
        }>
      | null
      | undefined
  }
}

export type MarkOrderShippedMutationVariables = Exact<{
  id: Scalars['ObjectId']
}>

export type MarkOrderShippedMutation = {
  __typename?: 'Mutation'
  markOrderShipped: {
    __typename?: 'PostalFulfillment'
    id: any
    accountId: any
    accountName?: string | null | undefined
    userId: any
    contactId: any
    shipToName?: string | null | undefined
    shipToEmail?: string | null | undefined
    shipToPhone?: string | null | undefined
    addressRefresh?: boolean | null | undefined
    deliveryEmail?: boolean | null | undefined
    deliveryEmailExpires?: any | null | undefined
    deliveryEmailSent?: any | null | undefined
    deliveryEmailClicked?: any | null | undefined
    deliveryEmailCompleted?: any | null | undefined
    campaignId?: any | null | undefined
    playbookId?: any | null | undefined
    itemName: string
    physicalMessage?: string | null | undefined
    variantId?: any | null | undefined
    previewImage?: string | null | undefined
    giftMessage?: string | null | undefined
    marketplaceProductId: any
    approvedPostalId?: any | null | undefined
    fulfillmentPartnerId: any
    fulfillmentPartnerOrderId?: string | null | undefined
    status: FulfillmentStatus
    stage?: string | null | undefined
    targetDeliveryDate?: any | null | undefined
    deliveryDate?: any | null | undefined
    deliveryPartner?: string | null | undefined
    trackingNumber?: string | null | undefined
    attributes?: any | null | undefined
    formFieldValueList?: any | null | undefined
    type?: ObjectType | null | undefined
    userLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null | undefined
    shipTo?:
      | {
          __typename?: 'Address'
          address1?: string | null | undefined
          address2?: string | null | undefined
          address3?: string | null | undefined
          city?: string | null | undefined
          state?: string | null | undefined
          postalCode?: string | null | undefined
          country?: string | null | undefined
          preferred: boolean
          source?: AddressSource | null | undefined
          status?: AddressStatus | null | undefined
          statusReason?: string | null | undefined
          systems?: Array<string> | null | undefined
          uspsAddress?:
            | {
                __typename?: 'UspsAddress'
                address1?: string | null | undefined
                address2?: string | null | undefined
                address3?: string | null | undefined
                city?: string | null | undefined
                state?: string | null | undefined
                postalCode?: string | null | undefined
                country?: string | null | undefined
                plus4?: string | null | undefined
                deliveryPoint?: string | null | undefined
              }
            | null
            | undefined
        }
      | null
      | undefined
    fulfillmentMap?:
      | {
          __typename?: 'FulfillmentMap'
          fulfillmentType: FulfillmentType
          fulfillmentPartnerId: any
          fulfillmentPartnerName: string
          itemCode: string
          displayPrice?: number | null | undefined
          displaySubscriberPrice?: number | null | undefined
          price: number
          subscriberPrice?: number | null | undefined
          transactionFee?: number | null | undefined
          thirdPartyFee?: number | null | undefined
          thirdPartyExpense?: number | null | undefined
          revSharePercentage?: number | null | undefined
          revShareFee?: number | null | undefined
          supplierPartId?: string | null | undefined
          supplierPartAuxiliaryId?: string | null | undefined
          shippingOptions?: Array<{ __typename?: 'ShippingOption'; name: string; price: number }> | null | undefined
          inventory?:
            | { __typename?: 'Inventory'; ordered?: number | null | undefined; available?: number | null | undefined }
            | null
            | undefined
        }
      | null
      | undefined
    cost?:
      | {
          __typename?: 'OrderCost'
          subtotal?: number | null | undefined
          shipping?: number | null | undefined
          tax?: number | null | undefined
          thirdPartyFee?: number | null | undefined
          taxEstimated?: boolean | null | undefined
          total?: number | null | undefined
          gmvExpense?: number | null | undefined
          postalRevenueShare?: number | null | undefined
        }
      | null
      | undefined
    history?:
      | Array<{
          __typename?: 'FulfillmentHistory'
          status: FulfillmentStatus
          notes?: string | null | undefined
          created: any
          currentLocation?: string | null | undefined
        }>
      | null
      | undefined
    created?:
      | { __typename?: 'ActionRecord'; actor?: string | null | undefined; dateTime?: any | null | undefined }
      | null
      | undefined
    itemCustomizationList?:
      | Array<{
          __typename?: 'ItemCustomization'
          id: string
          displayName?: string | null | undefined
          type?: ItemCustomizationType | null | undefined
          customizableBySender?: boolean | null | undefined
          customizableByRecipient?: boolean | null | undefined
          required?: boolean | null | undefined
          value?: any | null | undefined
          characterLimit?: number | null | undefined
          widthRecommendation?: number | null | undefined
          heightRecommendation?: number | null | undefined
          fileTypesAccepted?: Array<string> | null | undefined
          variantId?: any | null | undefined
        }>
      | null
      | undefined
    fulfillmentEvents?: { __typename?: 'FulfillmentEvents'; placed?: any | null | undefined } | null | undefined
  }
}

export type MarkOrderDeliveredMutationVariables = Exact<{
  id: Scalars['ObjectId']
}>

export type MarkOrderDeliveredMutation = {
  __typename?: 'Mutation'
  markOrderDelivered: {
    __typename?: 'PostalFulfillment'
    id: any
    accountId: any
    accountName?: string | null | undefined
    userId: any
    contactId: any
    shipToName?: string | null | undefined
    shipToEmail?: string | null | undefined
    shipToPhone?: string | null | undefined
    addressRefresh?: boolean | null | undefined
    deliveryEmail?: boolean | null | undefined
    deliveryEmailExpires?: any | null | undefined
    deliveryEmailSent?: any | null | undefined
    deliveryEmailClicked?: any | null | undefined
    deliveryEmailCompleted?: any | null | undefined
    campaignId?: any | null | undefined
    playbookId?: any | null | undefined
    itemName: string
    physicalMessage?: string | null | undefined
    variantId?: any | null | undefined
    previewImage?: string | null | undefined
    giftMessage?: string | null | undefined
    marketplaceProductId: any
    approvedPostalId?: any | null | undefined
    fulfillmentPartnerId: any
    fulfillmentPartnerOrderId?: string | null | undefined
    status: FulfillmentStatus
    stage?: string | null | undefined
    targetDeliveryDate?: any | null | undefined
    deliveryDate?: any | null | undefined
    deliveryPartner?: string | null | undefined
    trackingNumber?: string | null | undefined
    attributes?: any | null | undefined
    formFieldValueList?: any | null | undefined
    type?: ObjectType | null | undefined
    userLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null | undefined
    shipTo?:
      | {
          __typename?: 'Address'
          address1?: string | null | undefined
          address2?: string | null | undefined
          address3?: string | null | undefined
          city?: string | null | undefined
          state?: string | null | undefined
          postalCode?: string | null | undefined
          country?: string | null | undefined
          preferred: boolean
          source?: AddressSource | null | undefined
          status?: AddressStatus | null | undefined
          statusReason?: string | null | undefined
          systems?: Array<string> | null | undefined
          uspsAddress?:
            | {
                __typename?: 'UspsAddress'
                address1?: string | null | undefined
                address2?: string | null | undefined
                address3?: string | null | undefined
                city?: string | null | undefined
                state?: string | null | undefined
                postalCode?: string | null | undefined
                country?: string | null | undefined
                plus4?: string | null | undefined
                deliveryPoint?: string | null | undefined
              }
            | null
            | undefined
        }
      | null
      | undefined
    fulfillmentMap?:
      | {
          __typename?: 'FulfillmentMap'
          fulfillmentType: FulfillmentType
          fulfillmentPartnerId: any
          fulfillmentPartnerName: string
          itemCode: string
          displayPrice?: number | null | undefined
          displaySubscriberPrice?: number | null | undefined
          price: number
          subscriberPrice?: number | null | undefined
          transactionFee?: number | null | undefined
          thirdPartyFee?: number | null | undefined
          thirdPartyExpense?: number | null | undefined
          revSharePercentage?: number | null | undefined
          revShareFee?: number | null | undefined
          supplierPartId?: string | null | undefined
          supplierPartAuxiliaryId?: string | null | undefined
          shippingOptions?: Array<{ __typename?: 'ShippingOption'; name: string; price: number }> | null | undefined
          inventory?:
            | { __typename?: 'Inventory'; ordered?: number | null | undefined; available?: number | null | undefined }
            | null
            | undefined
        }
      | null
      | undefined
    cost?:
      | {
          __typename?: 'OrderCost'
          subtotal?: number | null | undefined
          shipping?: number | null | undefined
          tax?: number | null | undefined
          thirdPartyFee?: number | null | undefined
          taxEstimated?: boolean | null | undefined
          total?: number | null | undefined
          gmvExpense?: number | null | undefined
          postalRevenueShare?: number | null | undefined
        }
      | null
      | undefined
    history?:
      | Array<{
          __typename?: 'FulfillmentHistory'
          status: FulfillmentStatus
          notes?: string | null | undefined
          created: any
          currentLocation?: string | null | undefined
        }>
      | null
      | undefined
    created?:
      | { __typename?: 'ActionRecord'; actor?: string | null | undefined; dateTime?: any | null | undefined }
      | null
      | undefined
    itemCustomizationList?:
      | Array<{
          __typename?: 'ItemCustomization'
          id: string
          displayName?: string | null | undefined
          type?: ItemCustomizationType | null | undefined
          customizableBySender?: boolean | null | undefined
          customizableByRecipient?: boolean | null | undefined
          required?: boolean | null | undefined
          value?: any | null | undefined
          characterLimit?: number | null | undefined
          widthRecommendation?: number | null | undefined
          heightRecommendation?: number | null | undefined
          fileTypesAccepted?: Array<string> | null | undefined
          variantId?: any | null | undefined
        }>
      | null
      | undefined
    fulfillmentEvents?: { __typename?: 'FulfillmentEvents'; placed?: any | null | undefined } | null | undefined
  }
}

export type MarkOrderConfirmedMutationVariables = Exact<{
  id: Scalars['ObjectId']
  data: OrderConfirmedInput
}>

export type MarkOrderConfirmedMutation = {
  __typename?: 'Mutation'
  markOrderConfirmed: {
    __typename?: 'PostalFulfillment'
    id: any
    accountId: any
    accountName?: string | null | undefined
    userId: any
    contactId: any
    shipToName?: string | null | undefined
    shipToEmail?: string | null | undefined
    shipToPhone?: string | null | undefined
    addressRefresh?: boolean | null | undefined
    deliveryEmail?: boolean | null | undefined
    deliveryEmailExpires?: any | null | undefined
    deliveryEmailSent?: any | null | undefined
    deliveryEmailClicked?: any | null | undefined
    deliveryEmailCompleted?: any | null | undefined
    campaignId?: any | null | undefined
    playbookId?: any | null | undefined
    itemName: string
    physicalMessage?: string | null | undefined
    variantId?: any | null | undefined
    previewImage?: string | null | undefined
    giftMessage?: string | null | undefined
    marketplaceProductId: any
    approvedPostalId?: any | null | undefined
    fulfillmentPartnerId: any
    fulfillmentPartnerOrderId?: string | null | undefined
    status: FulfillmentStatus
    stage?: string | null | undefined
    targetDeliveryDate?: any | null | undefined
    deliveryDate?: any | null | undefined
    deliveryPartner?: string | null | undefined
    trackingNumber?: string | null | undefined
    attributes?: any | null | undefined
    formFieldValueList?: any | null | undefined
    type?: ObjectType | null | undefined
    userLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null | undefined
    shipTo?:
      | {
          __typename?: 'Address'
          address1?: string | null | undefined
          address2?: string | null | undefined
          address3?: string | null | undefined
          city?: string | null | undefined
          state?: string | null | undefined
          postalCode?: string | null | undefined
          country?: string | null | undefined
          preferred: boolean
          source?: AddressSource | null | undefined
          status?: AddressStatus | null | undefined
          statusReason?: string | null | undefined
          systems?: Array<string> | null | undefined
          uspsAddress?:
            | {
                __typename?: 'UspsAddress'
                address1?: string | null | undefined
                address2?: string | null | undefined
                address3?: string | null | undefined
                city?: string | null | undefined
                state?: string | null | undefined
                postalCode?: string | null | undefined
                country?: string | null | undefined
                plus4?: string | null | undefined
                deliveryPoint?: string | null | undefined
              }
            | null
            | undefined
        }
      | null
      | undefined
    fulfillmentMap?:
      | {
          __typename?: 'FulfillmentMap'
          fulfillmentType: FulfillmentType
          fulfillmentPartnerId: any
          fulfillmentPartnerName: string
          itemCode: string
          displayPrice?: number | null | undefined
          displaySubscriberPrice?: number | null | undefined
          price: number
          subscriberPrice?: number | null | undefined
          transactionFee?: number | null | undefined
          thirdPartyFee?: number | null | undefined
          thirdPartyExpense?: number | null | undefined
          revSharePercentage?: number | null | undefined
          revShareFee?: number | null | undefined
          supplierPartId?: string | null | undefined
          supplierPartAuxiliaryId?: string | null | undefined
          shippingOptions?: Array<{ __typename?: 'ShippingOption'; name: string; price: number }> | null | undefined
          inventory?:
            | { __typename?: 'Inventory'; ordered?: number | null | undefined; available?: number | null | undefined }
            | null
            | undefined
        }
      | null
      | undefined
    cost?:
      | {
          __typename?: 'OrderCost'
          subtotal?: number | null | undefined
          shipping?: number | null | undefined
          tax?: number | null | undefined
          thirdPartyFee?: number | null | undefined
          taxEstimated?: boolean | null | undefined
          total?: number | null | undefined
          gmvExpense?: number | null | undefined
          postalRevenueShare?: number | null | undefined
        }
      | null
      | undefined
    history?:
      | Array<{
          __typename?: 'FulfillmentHistory'
          status: FulfillmentStatus
          notes?: string | null | undefined
          created: any
          currentLocation?: string | null | undefined
        }>
      | null
      | undefined
    created?:
      | { __typename?: 'ActionRecord'; actor?: string | null | undefined; dateTime?: any | null | undefined }
      | null
      | undefined
    itemCustomizationList?:
      | Array<{
          __typename?: 'ItemCustomization'
          id: string
          displayName?: string | null | undefined
          type?: ItemCustomizationType | null | undefined
          customizableBySender?: boolean | null | undefined
          customizableByRecipient?: boolean | null | undefined
          required?: boolean | null | undefined
          value?: any | null | undefined
          characterLimit?: number | null | undefined
          widthRecommendation?: number | null | undefined
          heightRecommendation?: number | null | undefined
          fileTypesAccepted?: Array<string> | null | undefined
          variantId?: any | null | undefined
        }>
      | null
      | undefined
    fulfillmentEvents?: { __typename?: 'FulfillmentEvents'; placed?: any | null | undefined } | null | undefined
  }
}

export type MarkOrderPlacedMutationVariables = Exact<{
  id: Scalars['ObjectId']
  data: OrderPlacedInput
}>

export type MarkOrderPlacedMutation = {
  __typename?: 'Mutation'
  markOrderPlaced: {
    __typename?: 'PostalFulfillment'
    id: any
    accountId: any
    accountName?: string | null | undefined
    userId: any
    contactId: any
    shipToName?: string | null | undefined
    shipToEmail?: string | null | undefined
    shipToPhone?: string | null | undefined
    addressRefresh?: boolean | null | undefined
    deliveryEmail?: boolean | null | undefined
    deliveryEmailExpires?: any | null | undefined
    deliveryEmailSent?: any | null | undefined
    deliveryEmailClicked?: any | null | undefined
    deliveryEmailCompleted?: any | null | undefined
    campaignId?: any | null | undefined
    playbookId?: any | null | undefined
    itemName: string
    physicalMessage?: string | null | undefined
    variantId?: any | null | undefined
    previewImage?: string | null | undefined
    giftMessage?: string | null | undefined
    marketplaceProductId: any
    approvedPostalId?: any | null | undefined
    fulfillmentPartnerId: any
    fulfillmentPartnerOrderId?: string | null | undefined
    status: FulfillmentStatus
    stage?: string | null | undefined
    targetDeliveryDate?: any | null | undefined
    deliveryDate?: any | null | undefined
    deliveryPartner?: string | null | undefined
    trackingNumber?: string | null | undefined
    attributes?: any | null | undefined
    formFieldValueList?: any | null | undefined
    type?: ObjectType | null | undefined
    userLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null | undefined
    shipTo?:
      | {
          __typename?: 'Address'
          address1?: string | null | undefined
          address2?: string | null | undefined
          address3?: string | null | undefined
          city?: string | null | undefined
          state?: string | null | undefined
          postalCode?: string | null | undefined
          country?: string | null | undefined
          preferred: boolean
          source?: AddressSource | null | undefined
          status?: AddressStatus | null | undefined
          statusReason?: string | null | undefined
          systems?: Array<string> | null | undefined
          uspsAddress?:
            | {
                __typename?: 'UspsAddress'
                address1?: string | null | undefined
                address2?: string | null | undefined
                address3?: string | null | undefined
                city?: string | null | undefined
                state?: string | null | undefined
                postalCode?: string | null | undefined
                country?: string | null | undefined
                plus4?: string | null | undefined
                deliveryPoint?: string | null | undefined
              }
            | null
            | undefined
        }
      | null
      | undefined
    fulfillmentMap?:
      | {
          __typename?: 'FulfillmentMap'
          fulfillmentType: FulfillmentType
          fulfillmentPartnerId: any
          fulfillmentPartnerName: string
          itemCode: string
          displayPrice?: number | null | undefined
          displaySubscriberPrice?: number | null | undefined
          price: number
          subscriberPrice?: number | null | undefined
          transactionFee?: number | null | undefined
          thirdPartyFee?: number | null | undefined
          thirdPartyExpense?: number | null | undefined
          revSharePercentage?: number | null | undefined
          revShareFee?: number | null | undefined
          supplierPartId?: string | null | undefined
          supplierPartAuxiliaryId?: string | null | undefined
          shippingOptions?: Array<{ __typename?: 'ShippingOption'; name: string; price: number }> | null | undefined
          inventory?:
            | { __typename?: 'Inventory'; ordered?: number | null | undefined; available?: number | null | undefined }
            | null
            | undefined
        }
      | null
      | undefined
    cost?:
      | {
          __typename?: 'OrderCost'
          subtotal?: number | null | undefined
          shipping?: number | null | undefined
          tax?: number | null | undefined
          thirdPartyFee?: number | null | undefined
          taxEstimated?: boolean | null | undefined
          total?: number | null | undefined
          gmvExpense?: number | null | undefined
          postalRevenueShare?: number | null | undefined
        }
      | null
      | undefined
    history?:
      | Array<{
          __typename?: 'FulfillmentHistory'
          status: FulfillmentStatus
          notes?: string | null | undefined
          created: any
          currentLocation?: string | null | undefined
        }>
      | null
      | undefined
    created?:
      | { __typename?: 'ActionRecord'; actor?: string | null | undefined; dateTime?: any | null | undefined }
      | null
      | undefined
    itemCustomizationList?:
      | Array<{
          __typename?: 'ItemCustomization'
          id: string
          displayName?: string | null | undefined
          type?: ItemCustomizationType | null | undefined
          customizableBySender?: boolean | null | undefined
          customizableByRecipient?: boolean | null | undefined
          required?: boolean | null | undefined
          value?: any | null | undefined
          characterLimit?: number | null | undefined
          widthRecommendation?: number | null | undefined
          heightRecommendation?: number | null | undefined
          fileTypesAccepted?: Array<string> | null | undefined
          variantId?: any | null | undefined
        }>
      | null
      | undefined
    fulfillmentEvents?: { __typename?: 'FulfillmentEvents'; placed?: any | null | undefined } | null | undefined
  }
}

export type MarkOrderCancelledMutationVariables = Exact<{
  id: Scalars['ObjectId']
  data: OrderCancelledInput
}>

export type MarkOrderCancelledMutation = {
  __typename?: 'Mutation'
  markOrderCancelled: {
    __typename?: 'PostalFulfillment'
    id: any
    accountId: any
    accountName?: string | null | undefined
    userId: any
    contactId: any
    shipToName?: string | null | undefined
    shipToEmail?: string | null | undefined
    shipToPhone?: string | null | undefined
    addressRefresh?: boolean | null | undefined
    deliveryEmail?: boolean | null | undefined
    deliveryEmailExpires?: any | null | undefined
    deliveryEmailSent?: any | null | undefined
    deliveryEmailClicked?: any | null | undefined
    deliveryEmailCompleted?: any | null | undefined
    campaignId?: any | null | undefined
    playbookId?: any | null | undefined
    itemName: string
    physicalMessage?: string | null | undefined
    variantId?: any | null | undefined
    previewImage?: string | null | undefined
    giftMessage?: string | null | undefined
    marketplaceProductId: any
    approvedPostalId?: any | null | undefined
    fulfillmentPartnerId: any
    fulfillmentPartnerOrderId?: string | null | undefined
    status: FulfillmentStatus
    stage?: string | null | undefined
    targetDeliveryDate?: any | null | undefined
    deliveryDate?: any | null | undefined
    deliveryPartner?: string | null | undefined
    trackingNumber?: string | null | undefined
    attributes?: any | null | undefined
    formFieldValueList?: any | null | undefined
    type?: ObjectType | null | undefined
    userLink?: { __typename?: 'UserLink'; userName: string; fullName: string } | null | undefined
    shipTo?:
      | {
          __typename?: 'Address'
          address1?: string | null | undefined
          address2?: string | null | undefined
          address3?: string | null | undefined
          city?: string | null | undefined
          state?: string | null | undefined
          postalCode?: string | null | undefined
          country?: string | null | undefined
          preferred: boolean
          source?: AddressSource | null | undefined
          status?: AddressStatus | null | undefined
          statusReason?: string | null | undefined
          systems?: Array<string> | null | undefined
          uspsAddress?:
            | {
                __typename?: 'UspsAddress'
                address1?: string | null | undefined
                address2?: string | null | undefined
                address3?: string | null | undefined
                city?: string | null | undefined
                state?: string | null | undefined
                postalCode?: string | null | undefined
                country?: string | null | undefined
                plus4?: string | null | undefined
                deliveryPoint?: string | null | undefined
              }
            | null
            | undefined
        }
      | null
      | undefined
    fulfillmentMap?:
      | {
          __typename?: 'FulfillmentMap'
          fulfillmentType: FulfillmentType
          fulfillmentPartnerId: any
          fulfillmentPartnerName: string
          itemCode: string
          displayPrice?: number | null | undefined
          displaySubscriberPrice?: number | null | undefined
          price: number
          subscriberPrice?: number | null | undefined
          transactionFee?: number | null | undefined
          thirdPartyFee?: number | null | undefined
          thirdPartyExpense?: number | null | undefined
          revSharePercentage?: number | null | undefined
          revShareFee?: number | null | undefined
          supplierPartId?: string | null | undefined
          supplierPartAuxiliaryId?: string | null | undefined
          shippingOptions?: Array<{ __typename?: 'ShippingOption'; name: string; price: number }> | null | undefined
          inventory?:
            | { __typename?: 'Inventory'; ordered?: number | null | undefined; available?: number | null | undefined }
            | null
            | undefined
        }
      | null
      | undefined
    cost?:
      | {
          __typename?: 'OrderCost'
          subtotal?: number | null | undefined
          shipping?: number | null | undefined
          tax?: number | null | undefined
          thirdPartyFee?: number | null | undefined
          taxEstimated?: boolean | null | undefined
          total?: number | null | undefined
          gmvExpense?: number | null | undefined
          postalRevenueShare?: number | null | undefined
        }
      | null
      | undefined
    history?:
      | Array<{
          __typename?: 'FulfillmentHistory'
          status: FulfillmentStatus
          notes?: string | null | undefined
          created: any
          currentLocation?: string | null | undefined
        }>
      | null
      | undefined
    created?:
      | { __typename?: 'ActionRecord'; actor?: string | null | undefined; dateTime?: any | null | undefined }
      | null
      | undefined
    itemCustomizationList?:
      | Array<{
          __typename?: 'ItemCustomization'
          id: string
          displayName?: string | null | undefined
          type?: ItemCustomizationType | null | undefined
          customizableBySender?: boolean | null | undefined
          customizableByRecipient?: boolean | null | undefined
          required?: boolean | null | undefined
          value?: any | null | undefined
          characterLimit?: number | null | undefined
          widthRecommendation?: number | null | undefined
          heightRecommendation?: number | null | undefined
          fileTypesAccepted?: Array<string> | null | undefined
          variantId?: any | null | undefined
        }>
      | null
      | undefined
    fulfillmentEvents?: { __typename?: 'FulfillmentEvents'; placed?: any | null | undefined } | null | undefined
  }
}

export type VendorPayableExternalHistoryFragment = {
  __typename?: 'VendorPayableExternalHistory'
  status: VendorPayableExternalStatus
  notes?: string | null | undefined
  created: { __typename?: 'ActionRecord'; actor?: string | null | undefined; dateTime?: any | null | undefined }
}

export type VendorPayableFragment = {
  __typename?: 'VendorPayable'
  id: any
  externalId?: string | null | undefined
  fulfillmentPartnerId: any
  externalStatus: VendorPayableExternalStatus
  owedAtStart: any
  owedAtEnd: any
  currency?: Currency | null | undefined
  totalAmount?: number | null | undefined
  totalFulfillments?: number | null | undefined
  externalHistory?:
    | Array<{
        __typename?: 'VendorPayableExternalHistory'
        status: VendorPayableExternalStatus
        notes?: string | null | undefined
        created: { __typename?: 'ActionRecord'; actor?: string | null | undefined; dateTime?: any | null | undefined }
      }>
    | null
    | undefined
  created: { __typename?: 'ActionRecord'; actor?: string | null | undefined; dateTime?: any | null | undefined }
  updated?:
    | { __typename?: 'ActionRecord'; actor?: string | null | undefined; dateTime?: any | null | undefined }
    | null
    | undefined
}

export type VendorPaymentFragment = {
  __typename?: 'VendorPayment'
  owedAt?: any | null | undefined
  paidAt?: any | null | undefined
  vendorPayableId?: any | null | undefined
}

export type VendorFulfillmentFragment = {
  __typename?: 'VendorFulfillment'
  postalFulfillmentId: any
  accountId: any
  teamId?: any | null | undefined
  userId?: any | null | undefined
  contactId?: any | null | undefined
  shipToEmail?: string | null | undefined
  shipToName?: string | null | undefined
  shipToFirstName?: string | null | undefined
  shipToLastName?: string | null | undefined
  shipToPhone?: string | null | undefined
  shipToTitle?: string | null | undefined
  shipToCompanyName?: string | null | undefined
  type?: ObjectType | null | undefined
  fulfillmentPartnerOrderId?: string | null | undefined
  fulfillmentId?: string | null | undefined
  marketplaceProductId?: any | null | undefined
  approvedPostalId?: any | null | undefined
  variantId?: any | null | undefined
  fulfillmentType?: FulfillmentType | null | undefined
  fulfillmentPartnerId?: any | null | undefined
  fulfillmentPartnerName?: string | null | undefined
  itemCode?: string | null | undefined
  trackingNumber?: string | null | undefined
  trackingCompany?: string | null | undefined
  trackingUrl?: string | null | undefined
  status: ItemStatus
  targetDeliveryDate?: any | null | undefined
  deliveryDate?: any | null | undefined
  retryCount?: any | null | undefined
  shipTo?:
    | {
        __typename?: 'Address'
        address1?: string | null | undefined
        address2?: string | null | undefined
        address3?: string | null | undefined
        city?: string | null | undefined
        state?: string | null | undefined
        postalCode?: string | null | undefined
        country?: string | null | undefined
        preferred: boolean
        source?: AddressSource | null | undefined
        status?: AddressStatus | null | undefined
        statusReason?: string | null | undefined
        systems?: Array<string> | null | undefined
        uspsAddress?:
          | {
              __typename?: 'UspsAddress'
              address1?: string | null | undefined
              address2?: string | null | undefined
              address3?: string | null | undefined
              city?: string | null | undefined
              state?: string | null | undefined
              postalCode?: string | null | undefined
              country?: string | null | undefined
              plus4?: string | null | undefined
              deliveryPoint?: string | null | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
  cost?:
    | {
        __typename?: 'OrderCost'
        subtotal?: number | null | undefined
        shipping?: number | null | undefined
        tax?: number | null | undefined
        thirdPartyFee?: number | null | undefined
        taxEstimated?: boolean | null | undefined
        total?: number | null | undefined
        gmvExpense?: number | null | undefined
        postalRevenueShare?: number | null | undefined
      }
    | null
    | undefined
  history?:
    | Array<{
        __typename?: 'FulfillmentHistory'
        status: FulfillmentStatus
        notes?: string | null | undefined
        created: any
        currentLocation?: string | null | undefined
      }>
    | null
    | undefined
  internalHistory?:
    | Array<{
        __typename?: 'FulfillmentHistory'
        status: FulfillmentStatus
        notes?: string | null | undefined
        created: any
        currentLocation?: string | null | undefined
      }>
    | null
    | undefined
  vendorPayment?:
    | {
        __typename?: 'VendorPayment'
        owedAt?: any | null | undefined
        paidAt?: any | null | undefined
        vendorPayableId?: any | null | undefined
      }
    | null
    | undefined
}

export type GetVendorPayableQueryVariables = Exact<{
  id: Scalars['ObjectId']
}>

export type GetVendorPayableQuery = {
  __typename?: 'Query'
  getVendorPayable: {
    __typename?: 'VendorPayable'
    id: any
    externalId?: string | null | undefined
    fulfillmentPartnerId: any
    externalStatus: VendorPayableExternalStatus
    owedAtStart: any
    owedAtEnd: any
    currency?: Currency | null | undefined
    totalAmount?: number | null | undefined
    totalFulfillments?: number | null | undefined
    externalHistory?:
      | Array<{
          __typename?: 'VendorPayableExternalHistory'
          status: VendorPayableExternalStatus
          notes?: string | null | undefined
          created: { __typename?: 'ActionRecord'; actor?: string | null | undefined; dateTime?: any | null | undefined }
        }>
      | null
      | undefined
    created: { __typename?: 'ActionRecord'; actor?: string | null | undefined; dateTime?: any | null | undefined }
    updated?:
      | { __typename?: 'ActionRecord'; actor?: string | null | undefined; dateTime?: any | null | undefined }
      | null
      | undefined
  }
}

export type SearchVendorPayablesQueryVariables = Exact<{
  filter?: InputMaybe<VendorPayableFilterInput>
  orderBy?: InputMaybe<VendorPayableOrderByInput>
  skip?: InputMaybe<Scalars['Int']>
  limit?: InputMaybe<Scalars['Int']>
}>

export type SearchVendorPayablesQuery = {
  __typename?: 'Query'
  searchVendorPayables?:
    | Array<{
        __typename?: 'VendorPayable'
        id: any
        externalId?: string | null | undefined
        fulfillmentPartnerId: any
        externalStatus: VendorPayableExternalStatus
        owedAtStart: any
        owedAtEnd: any
        currency?: Currency | null | undefined
        totalAmount?: number | null | undefined
        totalFulfillments?: number | null | undefined
        externalHistory?:
          | Array<{
              __typename?: 'VendorPayableExternalHistory'
              status: VendorPayableExternalStatus
              notes?: string | null | undefined
              created: {
                __typename?: 'ActionRecord'
                actor?: string | null | undefined
                dateTime?: any | null | undefined
              }
            }>
          | null
          | undefined
        created: { __typename?: 'ActionRecord'; actor?: string | null | undefined; dateTime?: any | null | undefined }
        updated?:
          | { __typename?: 'ActionRecord'; actor?: string | null | undefined; dateTime?: any | null | undefined }
          | null
          | undefined
      }>
    | null
    | undefined
}

export type SearchVendorFulfillmentsQueryVariables = Exact<{
  filter?: InputMaybe<PostalFulfillmentFilterInput>
  orderBy?: InputMaybe<PostalFulfillmentOrderByInput>
  skip?: InputMaybe<Scalars['Int']>
  limit?: InputMaybe<Scalars['Int']>
}>

export type SearchVendorFulfillmentsQuery = {
  __typename?: 'Query'
  searchVendorFulfillments?:
    | Array<{
        __typename?: 'VendorFulfillment'
        postalFulfillmentId: any
        accountId: any
        teamId?: any | null | undefined
        userId?: any | null | undefined
        contactId?: any | null | undefined
        shipToEmail?: string | null | undefined
        shipToName?: string | null | undefined
        shipToFirstName?: string | null | undefined
        shipToLastName?: string | null | undefined
        shipToPhone?: string | null | undefined
        shipToTitle?: string | null | undefined
        shipToCompanyName?: string | null | undefined
        type?: ObjectType | null | undefined
        fulfillmentPartnerOrderId?: string | null | undefined
        fulfillmentId?: string | null | undefined
        marketplaceProductId?: any | null | undefined
        approvedPostalId?: any | null | undefined
        variantId?: any | null | undefined
        fulfillmentType?: FulfillmentType | null | undefined
        fulfillmentPartnerId?: any | null | undefined
        fulfillmentPartnerName?: string | null | undefined
        itemCode?: string | null | undefined
        trackingNumber?: string | null | undefined
        trackingCompany?: string | null | undefined
        trackingUrl?: string | null | undefined
        status: ItemStatus
        targetDeliveryDate?: any | null | undefined
        deliveryDate?: any | null | undefined
        retryCount?: any | null | undefined
        shipTo?:
          | {
              __typename?: 'Address'
              address1?: string | null | undefined
              address2?: string | null | undefined
              address3?: string | null | undefined
              city?: string | null | undefined
              state?: string | null | undefined
              postalCode?: string | null | undefined
              country?: string | null | undefined
              preferred: boolean
              source?: AddressSource | null | undefined
              status?: AddressStatus | null | undefined
              statusReason?: string | null | undefined
              systems?: Array<string> | null | undefined
              uspsAddress?:
                | {
                    __typename?: 'UspsAddress'
                    address1?: string | null | undefined
                    address2?: string | null | undefined
                    address3?: string | null | undefined
                    city?: string | null | undefined
                    state?: string | null | undefined
                    postalCode?: string | null | undefined
                    country?: string | null | undefined
                    plus4?: string | null | undefined
                    deliveryPoint?: string | null | undefined
                  }
                | null
                | undefined
            }
          | null
          | undefined
        cost?:
          | {
              __typename?: 'OrderCost'
              subtotal?: number | null | undefined
              shipping?: number | null | undefined
              tax?: number | null | undefined
              thirdPartyFee?: number | null | undefined
              taxEstimated?: boolean | null | undefined
              total?: number | null | undefined
              gmvExpense?: number | null | undefined
              postalRevenueShare?: number | null | undefined
            }
          | null
          | undefined
        history?:
          | Array<{
              __typename?: 'FulfillmentHistory'
              status: FulfillmentStatus
              notes?: string | null | undefined
              created: any
              currentLocation?: string | null | undefined
            }>
          | null
          | undefined
        internalHistory?:
          | Array<{
              __typename?: 'FulfillmentHistory'
              status: FulfillmentStatus
              notes?: string | null | undefined
              created: any
              currentLocation?: string | null | undefined
            }>
          | null
          | undefined
        vendorPayment?:
          | {
              __typename?: 'VendorPayment'
              owedAt?: any | null | undefined
              paidAt?: any | null | undefined
              vendorPayableId?: any | null | undefined
            }
          | null
          | undefined
      }>
    | null
    | undefined
}

export type ReportOutputInstanceFragment = {
  __typename?: 'ReportOutputInstance'
  id: any
  name: string
  type: DataObjectType
  fileName: string
}

export type SearchReportOutputInstancesQueryVariables = Exact<{
  filter?: InputMaybe<ReportOutputFilterInput>
  orderBy?: InputMaybe<ReportOutputOrderByInput>
  skip?: InputMaybe<Scalars['Int']>
  limit?: InputMaybe<Scalars['Int']>
}>

export type SearchReportOutputInstancesQuery = {
  __typename?: 'Query'
  searchReportOutputInstances?:
    | Array<{ __typename?: 'ReportOutputInstance'; id: any; name: string; type: DataObjectType; fileName: string }>
    | null
    | undefined
}

export type DownloadReportQueryVariables = Exact<{
  reportOutputInstanceId: Scalars['ObjectId']
}>

export type DownloadReportQuery = { __typename?: 'Query'; downloadReport?: string | null | undefined }

export type GenerateOrderReportMutationVariables = Exact<{
  filter: PostalFulfillmentFilterInput
  name?: InputMaybe<Scalars['String']>
}>

export type GenerateOrderReportMutation = {
  __typename?: 'Mutation'
  generateOrderReport: {
    __typename?: 'BackgroundTask'
    id: any
    relatedObjectId?: any | null | undefined
    name: string
    taskAction: string
    type: BackgroundTaskType
    status: BackgroundTaskStatus
    processedCount: number
    successCount: number
    errorCount: number
    taskStarted?: any | null | undefined
    taskCompleted?: any | null | undefined
    taskErrored?: any | null | undefined
    outputs?: any | null | undefined
    errors?:
      | Array<{
          __typename?: 'BackgroundOperationError'
          code: string
          count: number
          message?: string | null | undefined
        }>
      | null
      | undefined
  }
}

export type GenerateVendorFulfillmentsReportMutationVariables = Exact<{
  filter: PostalFulfillmentFilterInput
  name?: InputMaybe<Scalars['String']>
}>

export type GenerateVendorFulfillmentsReportMutation = {
  __typename?: 'Mutation'
  generateVendorFulfillmentsReport: {
    __typename?: 'BackgroundTask'
    id: any
    relatedObjectId?: any | null | undefined
    name: string
    taskAction: string
    type: BackgroundTaskType
    status: BackgroundTaskStatus
    processedCount: number
    successCount: number
    errorCount: number
    taskStarted?: any | null | undefined
    taskCompleted?: any | null | undefined
    taskErrored?: any | null | undefined
    outputs?: any | null | undefined
    errors?:
      | Array<{
          __typename?: 'BackgroundOperationError'
          code: string
          count: number
          message?: string | null | undefined
        }>
      | null
      | undefined
  }
}

export type CollectionFragment = {
  __typename?: 'Collection'
  cursor?: string | null | undefined
  title?: string | null | undefined
  legacyResourceId?: string | null | undefined
  productsCount?: number | null | undefined
}

export type PageInfoFragment = { __typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean }

export type ShopifyCollectionFragment = {
  __typename?: 'ShopifyCollection'
  crntCollectionId?: string | null | undefined
  collectionList?:
    | Array<{
        __typename?: 'Collection'
        cursor?: string | null | undefined
        title?: string | null | undefined
        legacyResourceId?: string | null | undefined
        productsCount?: number | null | undefined
      }>
    | null
    | undefined
  pageInfo?: { __typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean } | null | undefined
}

export type SearchShopifyCollectionsQueryVariables = Exact<{
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
}>

export type SearchShopifyCollectionsQuery = {
  __typename?: 'Query'
  searchShopifyCollections: {
    __typename?: 'ShopifyCollection'
    crntCollectionId?: string | null | undefined
    collectionList?:
      | Array<{
          __typename?: 'Collection'
          cursor?: string | null | undefined
          title?: string | null | undefined
          legacyResourceId?: string | null | undefined
          productsCount?: number | null | undefined
        }>
      | null
      | undefined
    pageInfo?: { __typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean } | null | undefined
  }
}

export type SelectShopifyCollectionMutationVariables = Exact<{
  shopifyCollectionId?: InputMaybe<Scalars['String']>
}>

export type SelectShopifyCollectionMutation = {
  __typename?: 'Mutation'
  selectShopifyCollection: {
    __typename?: 'BackgroundTask'
    id: any
    relatedObjectId?: any | null | undefined
    name: string
    taskAction: string
    type: BackgroundTaskType
    status: BackgroundTaskStatus
    processedCount: number
    successCount: number
    errorCount: number
    taskStarted?: any | null | undefined
    taskCompleted?: any | null | undefined
    taskErrored?: any | null | undefined
    outputs?: any | null | undefined
    errors?:
      | Array<{
          __typename?: 'BackgroundOperationError'
          code: string
          count: number
          message?: string | null | undefined
        }>
      | null
      | undefined
  }
}

export type StoreInstanceFragment = {
  __typename?: 'StoreInstance'
  id: any
  status: Status
  hostName: string
  title: string
  primaryColor: string
  secondaryColor: string
  tertiaryColor: string
  navForeground: string
  navBackground: string
  linkColor: string
}

export type SearchStoreInstanceQueryVariables = Exact<{
  filter?: InputMaybe<StoreInstanceFilterInput>
  orderBy?: InputMaybe<StoreInstanceOrderByInput>
  skip?: InputMaybe<Scalars['Int']>
  limit?: InputMaybe<Scalars['Int']>
}>

export type SearchStoreInstanceQuery = {
  __typename?: 'Query'
  searchStoreInstance?:
    | Array<{
        __typename?: 'StoreInstance'
        id: any
        status: Status
        hostName: string
        title: string
        primaryColor: string
        secondaryColor: string
        tertiaryColor: string
        navForeground: string
        navBackground: string
        linkColor: string
      }>
    | null
    | undefined
}

export type GetStoreInstanceQueryVariables = Exact<{
  id: Scalars['ObjectId']
}>

export type GetStoreInstanceQuery = {
  __typename?: 'Query'
  getStoreInstance: {
    __typename?: 'StoreInstance'
    id: any
    status: Status
    hostName: string
    title: string
    primaryColor: string
    secondaryColor: string
    tertiaryColor: string
    navForeground: string
    navBackground: string
    linkColor: string
  }
}

export type CreateStoreInstanceMutationVariables = Exact<{
  data: CreateStoreInstanceInput
}>

export type CreateStoreInstanceMutation = {
  __typename?: 'Mutation'
  createStoreInstance: {
    __typename?: 'StoreInstance'
    id: any
    status: Status
    hostName: string
    title: string
    primaryColor: string
    secondaryColor: string
    tertiaryColor: string
    navForeground: string
    navBackground: string
    linkColor: string
  }
}

export type UpdateStoreInstanceMutationVariables = Exact<{
  id: Scalars['ObjectId']
  data: StoreInstanceInput
}>

export type UpdateStoreInstanceMutation = {
  __typename?: 'Mutation'
  updateStoreInstance: {
    __typename?: 'StoreInstance'
    id: any
    status: Status
    hostName: string
    title: string
    primaryColor: string
    secondaryColor: string
    tertiaryColor: string
    navForeground: string
    navBackground: string
    linkColor: string
  }
}

export type DeleteStoreInstanceMutationVariables = Exact<{
  id: Scalars['ObjectId']
}>

export type DeleteStoreInstanceMutation = {
  __typename?: 'Mutation'
  deleteStoreInstance: {
    __typename?: 'StoreInstance'
    id: any
    status: Status
    hostName: string
    title: string
    primaryColor: string
    secondaryColor: string
    tertiaryColor: string
    navForeground: string
    navBackground: string
    linkColor: string
  }
}

export type UserLinkFragment = { __typename?: 'UserLink'; userName: string; fullName: string }

export type AuthenticationMethodFragment = {
  __typename?: 'AuthenticationMethod'
  method: string
  version?: string | null | undefined
  expires?: any | null | undefined
  updated?: any | null | undefined
  created?: any | null | undefined
}

export type ActionRecordFragment = {
  __typename?: 'ActionRecord'
  actor?: string | null | undefined
  dateTime?: any | null | undefined
}

export type CrmUserFragment = { __typename?: 'CrmUser'; system: string; crmIdentifier: string }

export type UserNotificationsFragment = {
  __typename?: 'UserNotifications'
  notificationsOn?: boolean | null | undefined
  balanceLowNotificationsOn?: boolean | null | undefined
  balanceLowChannels?: Array<UserNotificationChannel | null | undefined> | null | undefined
  lastBalanceLowNotificationSent?: any | null | undefined
  budgetLowNotificationsOn?: boolean | null | undefined
  budgetLowChannels?: Array<UserNotificationChannel | null | undefined> | null | undefined
  lastBudgetLowNotificationSent?: any | null | undefined
  giftEmailAcceptedNotificationsOn?: boolean | null | undefined
  giftEmailAcceptedChannels?: Array<UserNotificationChannel | null | undefined> | null | undefined
  magicLinkAcceptedNotificationsOn?: boolean | null | undefined
  magicLinkAcceptedChannels?: Array<UserNotificationChannel | null | undefined> | null | undefined
}

export type NotificationInfoFragment = {
  __typename?: 'NotificationInfo'
  toggleOn?: boolean | null | undefined
  channels?: Array<UserNotificationChannel> | null | undefined
  lastSent?: any | null | undefined
}

export type VendorNotificationsFragment = {
  __typename?: 'VendorNotifications'
  eventConfirmation: {
    __typename?: 'NotificationInfo'
    toggleOn?: boolean | null | undefined
    channels?: Array<UserNotificationChannel> | null | undefined
    lastSent?: any | null | undefined
  }
  eventMeetingLinkChanged: {
    __typename?: 'NotificationInfo'
    toggleOn?: boolean | null | undefined
    channels?: Array<UserNotificationChannel> | null | undefined
    lastSent?: any | null | undefined
  }
  eventDateTimeChanged: {
    __typename?: 'NotificationInfo'
    toggleOn?: boolean | null | undefined
    channels?: Array<UserNotificationChannel> | null | undefined
    lastSent?: any | null | undefined
  }
  unconfirmedOrders: {
    __typename?: 'NotificationInfo'
    toggleOn?: boolean | null | undefined
    channels?: Array<UserNotificationChannel> | null | undefined
    lastSent?: any | null | undefined
  }
  unshippedOrders: {
    __typename?: 'NotificationInfo'
    toggleOn?: boolean | null | undefined
    channels?: Array<UserNotificationChannel> | null | undefined
    lastSent?: any | null | undefined
  }
  registrationClosed: {
    __typename?: 'NotificationInfo'
    toggleOn?: boolean | null | undefined
    channels?: Array<UserNotificationChannel> | null | undefined
    lastSent?: any | null | undefined
  }
  eventCancelled: {
    __typename?: 'NotificationInfo'
    toggleOn?: boolean | null | undefined
    channels?: Array<UserNotificationChannel> | null | undefined
    lastSent?: any | null | undefined
  }
  newEvent: {
    __typename?: 'NotificationInfo'
    toggleOn?: boolean | null | undefined
    channels?: Array<UserNotificationChannel> | null | undefined
    lastSent?: any | null | undefined
  }
}

export type UserFragment = {
  __typename?: 'User'
  id: any
  firstName?: string | null | undefined
  lastName?: string | null | undefined
  userName: string
  emailAddress: string
  profilePicture?: any | null | undefined
  phoneNumber?: string | null | undefined
  title?: string | null | undefined
  meetingLink?: string | null | undefined
  status: Status
  accountLockedUntil?: any | null | undefined
  authenticationFailures?: number | null | undefined
  handwritingName?: string | null | undefined
  auth?:
    | Array<
        | {
            __typename?: 'AuthenticationMethod'
            method: string
            version?: string | null | undefined
            expires?: any | null | undefined
            updated?: any | null | undefined
            created?: any | null | undefined
          }
        | null
        | undefined
      >
    | null
    | undefined
  created?:
    | { __typename?: 'ActionRecord'; actor?: string | null | undefined; dateTime?: any | null | undefined }
    | null
    | undefined
  productAccess?:
    | Array<{
        __typename?: 'ProductAccess'
        id: any
        accountId?: any | null | undefined
        teamId?: any | null | undefined
        partnerId?: any | null | undefined
        product: Product
        roles?: Array<string> | null | undefined
        type?: ProductAccessType | null | undefined
        created: { __typename?: 'ActionRecord'; actor?: string | null | undefined; dateTime?: any | null | undefined }
      }>
    | null
    | undefined
  crmUserMap?: Array<{ __typename?: 'CrmUser'; system: string; crmIdentifier: string }> | null | undefined
  notifications?:
    | {
        __typename?: 'UserNotifications'
        notificationsOn?: boolean | null | undefined
        balanceLowNotificationsOn?: boolean | null | undefined
        balanceLowChannels?: Array<UserNotificationChannel | null | undefined> | null | undefined
        lastBalanceLowNotificationSent?: any | null | undefined
        budgetLowNotificationsOn?: boolean | null | undefined
        budgetLowChannels?: Array<UserNotificationChannel | null | undefined> | null | undefined
        lastBudgetLowNotificationSent?: any | null | undefined
        giftEmailAcceptedNotificationsOn?: boolean | null | undefined
        giftEmailAcceptedChannels?: Array<UserNotificationChannel | null | undefined> | null | undefined
        magicLinkAcceptedNotificationsOn?: boolean | null | undefined
        magicLinkAcceptedChannels?: Array<UserNotificationChannel | null | undefined> | null | undefined
      }
    | null
    | undefined
  vendorNotifications?:
    | {
        __typename?: 'VendorNotifications'
        eventConfirmation: {
          __typename?: 'NotificationInfo'
          toggleOn?: boolean | null | undefined
          channels?: Array<UserNotificationChannel> | null | undefined
          lastSent?: any | null | undefined
        }
        eventMeetingLinkChanged: {
          __typename?: 'NotificationInfo'
          toggleOn?: boolean | null | undefined
          channels?: Array<UserNotificationChannel> | null | undefined
          lastSent?: any | null | undefined
        }
        eventDateTimeChanged: {
          __typename?: 'NotificationInfo'
          toggleOn?: boolean | null | undefined
          channels?: Array<UserNotificationChannel> | null | undefined
          lastSent?: any | null | undefined
        }
        unconfirmedOrders: {
          __typename?: 'NotificationInfo'
          toggleOn?: boolean | null | undefined
          channels?: Array<UserNotificationChannel> | null | undefined
          lastSent?: any | null | undefined
        }
        unshippedOrders: {
          __typename?: 'NotificationInfo'
          toggleOn?: boolean | null | undefined
          channels?: Array<UserNotificationChannel> | null | undefined
          lastSent?: any | null | undefined
        }
        registrationClosed: {
          __typename?: 'NotificationInfo'
          toggleOn?: boolean | null | undefined
          channels?: Array<UserNotificationChannel> | null | undefined
          lastSent?: any | null | undefined
        }
        eventCancelled: {
          __typename?: 'NotificationInfo'
          toggleOn?: boolean | null | undefined
          channels?: Array<UserNotificationChannel> | null | undefined
          lastSent?: any | null | undefined
        }
        newEvent: {
          __typename?: 'NotificationInfo'
          toggleOn?: boolean | null | undefined
          channels?: Array<UserNotificationChannel> | null | undefined
          lastSent?: any | null | undefined
        }
      }
    | null
    | undefined
}

export type UserAccountFragment = {
  __typename?: 'UserAccount'
  id: any
  firstName: string
  lastName: string
  emailAddress: string
  profilePicture?: any | null | undefined
  phoneNumber?: string | null | undefined
  title?: string | null | undefined
  meetingLink?: string | null | undefined
  accountName?: string | null | undefined
  authTypes?: Array<string | null | undefined> | null | undefined
  handwritingName?: string | null | undefined
  completedActions?: any | null | undefined
  productAccess?:
    | Array<{
        __typename?: 'ProductAccess'
        id: any
        accountId?: any | null | undefined
        teamId?: any | null | undefined
        partnerId?: any | null | undefined
        product: Product
        roles?: Array<string> | null | undefined
        type?: ProductAccessType | null | undefined
        created: { __typename?: 'ActionRecord'; actor?: string | null | undefined; dateTime?: any | null | undefined }
      }>
    | null
    | undefined
  created: { __typename?: 'ActionRecord'; actor?: string | null | undefined; dateTime?: any | null | undefined }
  updated?:
    | { __typename?: 'ActionRecord'; actor?: string | null | undefined; dateTime?: any | null | undefined }
    | null
    | undefined
  notifications?:
    | {
        __typename?: 'UserNotifications'
        notificationsOn?: boolean | null | undefined
        balanceLowNotificationsOn?: boolean | null | undefined
        balanceLowChannels?: Array<UserNotificationChannel | null | undefined> | null | undefined
        lastBalanceLowNotificationSent?: any | null | undefined
        budgetLowNotificationsOn?: boolean | null | undefined
        budgetLowChannels?: Array<UserNotificationChannel | null | undefined> | null | undefined
        lastBudgetLowNotificationSent?: any | null | undefined
        giftEmailAcceptedNotificationsOn?: boolean | null | undefined
        giftEmailAcceptedChannels?: Array<UserNotificationChannel | null | undefined> | null | undefined
        magicLinkAcceptedNotificationsOn?: boolean | null | undefined
        magicLinkAcceptedChannels?: Array<UserNotificationChannel | null | undefined> | null | undefined
      }
    | null
    | undefined
  vendorNotifications?:
    | {
        __typename?: 'VendorNotifications'
        eventConfirmation: {
          __typename?: 'NotificationInfo'
          toggleOn?: boolean | null | undefined
          channels?: Array<UserNotificationChannel> | null | undefined
          lastSent?: any | null | undefined
        }
        eventMeetingLinkChanged: {
          __typename?: 'NotificationInfo'
          toggleOn?: boolean | null | undefined
          channels?: Array<UserNotificationChannel> | null | undefined
          lastSent?: any | null | undefined
        }
        eventDateTimeChanged: {
          __typename?: 'NotificationInfo'
          toggleOn?: boolean | null | undefined
          channels?: Array<UserNotificationChannel> | null | undefined
          lastSent?: any | null | undefined
        }
        unconfirmedOrders: {
          __typename?: 'NotificationInfo'
          toggleOn?: boolean | null | undefined
          channels?: Array<UserNotificationChannel> | null | undefined
          lastSent?: any | null | undefined
        }
        unshippedOrders: {
          __typename?: 'NotificationInfo'
          toggleOn?: boolean | null | undefined
          channels?: Array<UserNotificationChannel> | null | undefined
          lastSent?: any | null | undefined
        }
        registrationClosed: {
          __typename?: 'NotificationInfo'
          toggleOn?: boolean | null | undefined
          channels?: Array<UserNotificationChannel> | null | undefined
          lastSent?: any | null | undefined
        }
        eventCancelled: {
          __typename?: 'NotificationInfo'
          toggleOn?: boolean | null | undefined
          channels?: Array<UserNotificationChannel> | null | undefined
          lastSent?: any | null | undefined
        }
        newEvent: {
          __typename?: 'NotificationInfo'
          toggleOn?: boolean | null | undefined
          channels?: Array<UserNotificationChannel> | null | undefined
          lastSent?: any | null | undefined
        }
      }
    | null
    | undefined
}

export type FulfillmentPartnerFragment = {
  __typename?: 'FulfillmentPartner'
  id: any
  name: string
  keywords?: Array<string | null | undefined> | null | undefined
  description?: string | null | undefined
}

export type PingQueryVariables = Exact<{ [key: string]: never }>

export type PingQuery = { __typename?: 'Query'; ping: boolean }

export type MeQueryVariables = Exact<{ [key: string]: never }>

export type MeQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'UserAccount'
    id: any
    firstName: string
    lastName: string
    emailAddress: string
    profilePicture?: any | null | undefined
    phoneNumber?: string | null | undefined
    title?: string | null | undefined
    meetingLink?: string | null | undefined
    accountName?: string | null | undefined
    authTypes?: Array<string | null | undefined> | null | undefined
    handwritingName?: string | null | undefined
    completedActions?: any | null | undefined
    productAccess?:
      | Array<{
          __typename?: 'ProductAccess'
          id: any
          accountId?: any | null | undefined
          teamId?: any | null | undefined
          partnerId?: any | null | undefined
          product: Product
          roles?: Array<string> | null | undefined
          type?: ProductAccessType | null | undefined
          created: { __typename?: 'ActionRecord'; actor?: string | null | undefined; dateTime?: any | null | undefined }
        }>
      | null
      | undefined
    created: { __typename?: 'ActionRecord'; actor?: string | null | undefined; dateTime?: any | null | undefined }
    updated?:
      | { __typename?: 'ActionRecord'; actor?: string | null | undefined; dateTime?: any | null | undefined }
      | null
      | undefined
    notifications?:
      | {
          __typename?: 'UserNotifications'
          notificationsOn?: boolean | null | undefined
          balanceLowNotificationsOn?: boolean | null | undefined
          balanceLowChannels?: Array<UserNotificationChannel | null | undefined> | null | undefined
          lastBalanceLowNotificationSent?: any | null | undefined
          budgetLowNotificationsOn?: boolean | null | undefined
          budgetLowChannels?: Array<UserNotificationChannel | null | undefined> | null | undefined
          lastBudgetLowNotificationSent?: any | null | undefined
          giftEmailAcceptedNotificationsOn?: boolean | null | undefined
          giftEmailAcceptedChannels?: Array<UserNotificationChannel | null | undefined> | null | undefined
          magicLinkAcceptedNotificationsOn?: boolean | null | undefined
          magicLinkAcceptedChannels?: Array<UserNotificationChannel | null | undefined> | null | undefined
        }
      | null
      | undefined
    vendorNotifications?:
      | {
          __typename?: 'VendorNotifications'
          eventConfirmation: {
            __typename?: 'NotificationInfo'
            toggleOn?: boolean | null | undefined
            channels?: Array<UserNotificationChannel> | null | undefined
            lastSent?: any | null | undefined
          }
          eventMeetingLinkChanged: {
            __typename?: 'NotificationInfo'
            toggleOn?: boolean | null | undefined
            channels?: Array<UserNotificationChannel> | null | undefined
            lastSent?: any | null | undefined
          }
          eventDateTimeChanged: {
            __typename?: 'NotificationInfo'
            toggleOn?: boolean | null | undefined
            channels?: Array<UserNotificationChannel> | null | undefined
            lastSent?: any | null | undefined
          }
          unconfirmedOrders: {
            __typename?: 'NotificationInfo'
            toggleOn?: boolean | null | undefined
            channels?: Array<UserNotificationChannel> | null | undefined
            lastSent?: any | null | undefined
          }
          unshippedOrders: {
            __typename?: 'NotificationInfo'
            toggleOn?: boolean | null | undefined
            channels?: Array<UserNotificationChannel> | null | undefined
            lastSent?: any | null | undefined
          }
          registrationClosed: {
            __typename?: 'NotificationInfo'
            toggleOn?: boolean | null | undefined
            channels?: Array<UserNotificationChannel> | null | undefined
            lastSent?: any | null | undefined
          }
          eventCancelled: {
            __typename?: 'NotificationInfo'
            toggleOn?: boolean | null | undefined
            channels?: Array<UserNotificationChannel> | null | undefined
            lastSent?: any | null | undefined
          }
          newEvent: {
            __typename?: 'NotificationInfo'
            toggleOn?: boolean | null | undefined
            channels?: Array<UserNotificationChannel> | null | undefined
            lastSent?: any | null | undefined
          }
        }
      | null
      | undefined
  }
}

export type ChangePasswordMutationVariables = Exact<{
  data: ChangePasswordInput
}>

export type ChangePasswordMutation = { __typename?: 'Mutation'; changePassword: boolean }

export type UpdateSelfMutationVariables = Exact<{
  data: UpdateSelfInput
}>

export type UpdateSelfMutation = {
  __typename?: 'Mutation'
  updateSelf: {
    __typename?: 'User'
    id: any
    firstName?: string | null | undefined
    lastName?: string | null | undefined
    userName: string
    emailAddress: string
    profilePicture?: any | null | undefined
    phoneNumber?: string | null | undefined
    title?: string | null | undefined
    meetingLink?: string | null | undefined
    status: Status
    accountLockedUntil?: any | null | undefined
    authenticationFailures?: number | null | undefined
    handwritingName?: string | null | undefined
    auth?:
      | Array<
          | {
              __typename?: 'AuthenticationMethod'
              method: string
              version?: string | null | undefined
              expires?: any | null | undefined
              updated?: any | null | undefined
              created?: any | null | undefined
            }
          | null
          | undefined
        >
      | null
      | undefined
    created?:
      | { __typename?: 'ActionRecord'; actor?: string | null | undefined; dateTime?: any | null | undefined }
      | null
      | undefined
    productAccess?:
      | Array<{
          __typename?: 'ProductAccess'
          id: any
          accountId?: any | null | undefined
          teamId?: any | null | undefined
          partnerId?: any | null | undefined
          product: Product
          roles?: Array<string> | null | undefined
          type?: ProductAccessType | null | undefined
          created: { __typename?: 'ActionRecord'; actor?: string | null | undefined; dateTime?: any | null | undefined }
        }>
      | null
      | undefined
    crmUserMap?: Array<{ __typename?: 'CrmUser'; system: string; crmIdentifier: string }> | null | undefined
    notifications?:
      | {
          __typename?: 'UserNotifications'
          notificationsOn?: boolean | null | undefined
          balanceLowNotificationsOn?: boolean | null | undefined
          balanceLowChannels?: Array<UserNotificationChannel | null | undefined> | null | undefined
          lastBalanceLowNotificationSent?: any | null | undefined
          budgetLowNotificationsOn?: boolean | null | undefined
          budgetLowChannels?: Array<UserNotificationChannel | null | undefined> | null | undefined
          lastBudgetLowNotificationSent?: any | null | undefined
          giftEmailAcceptedNotificationsOn?: boolean | null | undefined
          giftEmailAcceptedChannels?: Array<UserNotificationChannel | null | undefined> | null | undefined
          magicLinkAcceptedNotificationsOn?: boolean | null | undefined
          magicLinkAcceptedChannels?: Array<UserNotificationChannel | null | undefined> | null | undefined
        }
      | null
      | undefined
    vendorNotifications?:
      | {
          __typename?: 'VendorNotifications'
          eventConfirmation: {
            __typename?: 'NotificationInfo'
            toggleOn?: boolean | null | undefined
            channels?: Array<UserNotificationChannel> | null | undefined
            lastSent?: any | null | undefined
          }
          eventMeetingLinkChanged: {
            __typename?: 'NotificationInfo'
            toggleOn?: boolean | null | undefined
            channels?: Array<UserNotificationChannel> | null | undefined
            lastSent?: any | null | undefined
          }
          eventDateTimeChanged: {
            __typename?: 'NotificationInfo'
            toggleOn?: boolean | null | undefined
            channels?: Array<UserNotificationChannel> | null | undefined
            lastSent?: any | null | undefined
          }
          unconfirmedOrders: {
            __typename?: 'NotificationInfo'
            toggleOn?: boolean | null | undefined
            channels?: Array<UserNotificationChannel> | null | undefined
            lastSent?: any | null | undefined
          }
          unshippedOrders: {
            __typename?: 'NotificationInfo'
            toggleOn?: boolean | null | undefined
            channels?: Array<UserNotificationChannel> | null | undefined
            lastSent?: any | null | undefined
          }
          registrationClosed: {
            __typename?: 'NotificationInfo'
            toggleOn?: boolean | null | undefined
            channels?: Array<UserNotificationChannel> | null | undefined
            lastSent?: any | null | undefined
          }
          eventCancelled: {
            __typename?: 'NotificationInfo'
            toggleOn?: boolean | null | undefined
            channels?: Array<UserNotificationChannel> | null | undefined
            lastSent?: any | null | undefined
          }
          newEvent: {
            __typename?: 'NotificationInfo'
            toggleOn?: boolean | null | undefined
            channels?: Array<UserNotificationChannel> | null | undefined
            lastSent?: any | null | undefined
          }
        }
      | null
      | undefined
  }
}

export type GetFulfillmentPartnerQueryVariables = Exact<{ [key: string]: never }>

export type GetFulfillmentPartnerQuery = {
  __typename?: 'Query'
  getFulfillmentPartner: {
    __typename?: 'FulfillmentPartner'
    id: any
    name: string
    keywords?: Array<string | null | undefined> | null | undefined
    description?: string | null | undefined
  }
}

export type UpdateFulfillmentPartnerMutationVariables = Exact<{
  data: FulfillmentPartnerInput
}>

export type UpdateFulfillmentPartnerMutation = {
  __typename?: 'Mutation'
  updateFulfillmentPartner: {
    __typename?: 'FulfillmentPartner'
    id: any
    name: string
    keywords?: Array<string | null | undefined> | null | undefined
    description?: string | null | undefined
  }
}

export const UspsAddressFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UspsAddress' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UspsAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plus4' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryPoint' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UspsAddressFragment, unknown>
export const AddressFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Address' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address3' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uspsAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UspsAddress' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'systems' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddressFragment, unknown>
export const CachedAccountFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CachedAccount' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CachedAccount' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brandingLogoEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Address' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CachedAccountFragment, unknown>
export const ActionRecordFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActionRecord' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ActionRecord' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'actor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateTime' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ActionRecordFragment, unknown>
export const ProductAccessFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductAccess' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductAccess' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'partnerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'product' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'created' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActionRecord' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductAccessFragment, unknown>
export const NamedProductAccessFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NamedProductAccess' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NamedProductAccess' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'accountName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productAccess' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductAccess' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NamedProductAccessFragment, unknown>
export const AssetFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Asset' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Asset' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'created' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActionRecord' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updated' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActionRecord' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AssetFragment, unknown>
export const BackgroundOperationErrorFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BackgroundOperationError' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BackgroundOperationError' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BackgroundOperationErrorFragment, unknown>
export const BackgroundTaskFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BackgroundTask' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BackgroundTask' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'relatedObjectId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taskAction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'processedCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'successCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'errorCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'errors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BackgroundOperationError' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'taskStarted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taskCompleted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taskErrored' } },
          { kind: 'Field', name: { kind: 'Name', value: 'outputs' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BackgroundTaskFragment, unknown>
export const PhoneMapFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PhoneMap' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PhoneMap' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'created' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActionRecord' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PhoneMapFragment, unknown>
export const ContactFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Contact' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Contact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phones' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PhoneMap' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Address' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContactFragment, unknown>
export const ValidationCheckFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ValidationCheck' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ValidationCheck' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'itemName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ValidationCheckFragment, unknown>
export const DraftMarketplaceProductValidationResultFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DraftMarketplaceProductValidationResult' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DraftMarketplaceProductValidationResult' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'validationErrors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ValidationCheck' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DraftMarketplaceProductValidationResultFragment, unknown>
export const ImageReferenceFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImageReference' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ImageReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ImageReferenceFragment, unknown>
export const ShippingOptionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShippingOption' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ShippingOption' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShippingOptionFragment, unknown>
export const DraftVariantFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DraftVariant' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DraftVariant' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variantName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sku' } },
          { kind: 'Field', name: { kind: 'Name', value: 'upcCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'imageUrls' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageReference' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taxable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requiresShipping' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalCreatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalUpdatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'physicalMessageSupported' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overrideVariantName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overrideImageUrls' } },
          { kind: 'Field', name: { kind: 'Name', value: 'containsPerishables' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shippingOptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShippingOption' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DraftVariantFragment, unknown>
export const DraftMarketplaceProductFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DraftMarketplaceProduct' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DraftMarketplaceProduct' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'systemName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'imageUrls' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageReference' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brandName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalCreatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalUpdatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DraftVariant' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'marketplaceStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderRulesId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'keywords' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'marketplaceProductId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'estimatedShippingTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overrideName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overrideDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'overrideImageUrls' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumberRequired' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variantOrderFlexibility' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DraftMarketplaceProductFragment, unknown>
export const CategoryTreeEntryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CategoryTreeEntry' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CategoryTreeEntry' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CategoryTreeEntryFragment, unknown>
export const ApprovedProductVariantFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ApprovedProductVariant' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ApprovedProductVariant' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variantName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'imageUrls' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageReference' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displaySubscriberPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftMessageSupported' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApprovedProductVariantFragment, unknown>
export const MagicEventInstanceFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MagicEventInstance' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MagicEventInstance' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'accountName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requestedByName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requestedByEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requestedByPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requestedByMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requestedAttendeeCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requestedDates' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eventDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastRegistrationDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'meetingLink' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MagicEventInstanceFragment, unknown>
export const ApprovedPostalFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ApprovedPostal' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ApprovedPostal' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brandName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useCases' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'imageUrls' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageReference' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'marketplaceProductId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ApprovedProductVariant' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'event' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'MagicEventInstance' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApprovedPostalFragment, unknown>
export const IntegrationSyncFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IntegrationSync' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'IntegrationSync' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'system' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastRun' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nextRun' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pollingTimeMins' } },
          { kind: 'Field', name: { kind: 'Name', value: 'workStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'objectType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<IntegrationSyncFragment, unknown>
export const ModuleFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Module' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Module' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'permissions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'meta' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ModuleFragment, unknown>
export const InventoryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Inventory' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Inventory' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'ordered' } },
          { kind: 'Field', name: { kind: 'Name', value: 'available' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InventoryFragment, unknown>
export const FulfillmentMapFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FulfillmentMap' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FulfillmentMap' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentPartnerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentPartnerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'itemCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displaySubscriberPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subscriberPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transactionFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thirdPartyFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thirdPartyExpense' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shippingOptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShippingOption' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'revSharePercentage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'revShareFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'supplierPartId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'supplierPartAuxiliaryId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'inventory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Inventory' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FulfillmentMapFragment, unknown>
export const ProductVariantFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProductVariant' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductVariant' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variantName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'upcCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'manufacturerPartId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'imageUrls' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageReference' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'size' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftMessageSupported' } },
          { kind: 'Field', name: { kind: 'Name', value: 'physicalMessageSupported' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fulfillmentPartnerList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FulfillmentMap' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'attributes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minimumTransactions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flatFees' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayPrice' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductVariantFragment, unknown>
export const ElementLocationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementLocation' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ElementLocation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'x' } },
          { kind: 'Field', name: { kind: 'Name', value: 'y' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ElementLocationFragment, unknown>
export const ElementResizeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementResize' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ElementResize' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'minWidth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minHeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxWidth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxHeight' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ElementResizeFragment, unknown>
export const ElementBoundaryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementBoundary' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ElementBoundary' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'minX' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minY' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxX' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxY' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ElementBoundaryFragment, unknown>
export const ElementConfigFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ElementConfig' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ElementConfig' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'locked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'noPdfOutput' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ElementLocation' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resize' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ElementResize' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'boundary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ElementBoundary' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'settings' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customizable' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ElementConfigFragment, unknown>
export const CanvasSizeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CanvasSize' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CanvasSize' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CanvasSizeFragment, unknown>
export const DesignTemplateFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DesignTemplate' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DesignTemplate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'front' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ElementConfig' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'back' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ElementConfig' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'dpi' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'outputSize' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CanvasSize' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DesignTemplateFragment, unknown>
export const MagicEventFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MagicEvent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MagicEvent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'eventLengthMinutes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minimumAttendees' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maximumAttendees' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requirements' } },
          { kind: 'Field', name: { kind: 'Name', value: 'talentEmailAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'talentName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'talentPhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'talentCompany' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MagicEventFragment, unknown>
export const MarketplaceProductFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MarketplaceProduct' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MarketplaceProduct' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brandName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useCases' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductVariant' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'imageUrls' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageReference' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'starterTemplate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'itemPreview' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowedPlans' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'designTemplate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DesignTemplate' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'variantOrderFlexibility' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumberRequired' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eventDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'MagicEvent' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'eventAvailabilityDates' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MarketplaceProductFragment, unknown>
export const UserLinkFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserLink' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserLink' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserLinkFragment, unknown>
export const OrderCostFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderCost' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'OrderCost' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'subtotal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipping' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thirdPartyFee' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taxEstimated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'total' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gmvExpense' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalRevenueShare' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OrderCostFragment, unknown>
export const FulfillmentHistoryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FulfillmentHistory' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FulfillmentHistory' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentLocation' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FulfillmentHistoryFragment, unknown>
export const ItemCustomizationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ItemCustomization' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ItemCustomization' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customizableBySender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customizableByRecipient' } },
          { kind: 'Field', name: { kind: 'Name', value: 'required' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'characterLimit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'widthRecommendation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'heightRecommendation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileTypesAccepted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ItemCustomizationFragment, unknown>
export const FulfillmentEventsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FulfillmentEvents' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FulfillmentEvents' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'placed' } }] },
    },
  ],
} as unknown as DocumentNode<FulfillmentEventsFragment, unknown>
export const PostalFulfillmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PostalFulfillment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PostalFulfillment' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userLink' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserLink' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shipTo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Address' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'shipToName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipToEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipToPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'addressRefresh' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailExpires' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailSent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailClicked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryEmailCompleted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'campaignId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'playbookId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'itemName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'physicalMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'previewImage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'marketplaceProductId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentPartnerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentPartnerOrderId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fulfillmentMap' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FulfillmentMap' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrderCost' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'history' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FulfillmentHistory' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'targetDeliveryDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryPartner' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trackingNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'attributes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'created' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActionRecord' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'itemCustomizationList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ItemCustomization' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fulfillmentEvents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FulfillmentEvents' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'formFieldValueList' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PostalFulfillmentFragment, unknown>
export const VendorPayableExternalHistoryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VendorPayableExternalHistory' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'VendorPayableExternalHistory' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'created' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActionRecord' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VendorPayableExternalHistoryFragment, unknown>
export const VendorPayableFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VendorPayable' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'VendorPayable' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentPartnerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'externalHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'VendorPayableExternalHistory' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'owedAtStart' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owedAtEnd' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalFulfillments' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'created' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActionRecord' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updated' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActionRecord' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VendorPayableFragment, unknown>
export const VendorPaymentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VendorPayment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'VendorPayment' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'owedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paidAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vendorPayableId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VendorPaymentFragment, unknown>
export const VendorFulfillmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VendorFulfillment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'VendorFulfillment' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'postalFulfillmentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shipTo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Address' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'shipToEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipToName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipToFirstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipToLastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipToPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipToTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shipToCompanyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentPartnerOrderId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'marketplaceProductId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvedPostalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variantId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentPartnerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fulfillmentPartnerName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'itemCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cost' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'OrderCost' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'trackingNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trackingCompany' } },
          { kind: 'Field', name: { kind: 'Name', value: 'trackingUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'targetDeliveryDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'retryCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'history' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FulfillmentHistory' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internalHistory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FulfillmentHistory' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vendorPayment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'VendorPayment' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VendorFulfillmentFragment, unknown>
export const ReportOutputInstanceFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReportOutputInstance' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ReportOutputInstance' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReportOutputInstanceFragment, unknown>
export const CollectionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Collection' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Collection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legacyResourceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productsCount' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CollectionFragment, unknown>
export const PageInfoFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PageInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PageInfo' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PageInfoFragment, unknown>
export const ShopifyCollectionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopifyCollection' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ShopifyCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'crntCollectionId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collectionList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Collection' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pageInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PageInfo' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShopifyCollectionFragment, unknown>
export const StoreInstanceFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreInstance' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StoreInstance' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hostName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondaryColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tertiaryColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'navForeground' } },
          { kind: 'Field', name: { kind: 'Name', value: 'navBackground' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkColor' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreInstanceFragment, unknown>
export const AuthenticationMethodFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuthenticationMethod' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AuthenticationMethod' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'method' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expires' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuthenticationMethodFragment, unknown>
export const CrmUserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CrmUser' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CrmUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'system' } },
          { kind: 'Field', name: { kind: 'Name', value: 'crmIdentifier' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CrmUserFragment, unknown>
export const UserNotificationsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserNotifications' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserNotifications' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'notificationsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'balanceLowNotificationsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'balanceLowChannels' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastBalanceLowNotificationSent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'budgetLowNotificationsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'budgetLowChannels' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastBudgetLowNotificationSent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftEmailAcceptedNotificationsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giftEmailAcceptedChannels' } },
          { kind: 'Field', name: { kind: 'Name', value: 'magicLinkAcceptedNotificationsOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'magicLinkAcceptedChannels' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserNotificationsFragment, unknown>
export const NotificationInfoFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NotificationInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NotificationInfo' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'toggleOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'channels' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastSent' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NotificationInfoFragment, unknown>
export const VendorNotificationsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VendorNotifications' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'VendorNotifications' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eventConfirmation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NotificationInfo' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eventMeetingLinkChanged' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NotificationInfo' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eventDateTimeChanged' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NotificationInfo' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unconfirmedOrders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NotificationInfo' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unshippedOrders' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NotificationInfo' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registrationClosed' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NotificationInfo' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eventCancelled' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NotificationInfo' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newEvent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NotificationInfo' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VendorNotificationsFragment, unknown>
export const UserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePicture' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'meetingLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'accountLockedUntil' } },
          { kind: 'Field', name: { kind: 'Name', value: 'authenticationFailures' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auth' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AuthenticationMethod' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'created' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActionRecord' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productAccess' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductAccess' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'handwritingName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'crmUserMap' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CrmUser' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notifications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserNotifications' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vendorNotifications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'VendorNotifications' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserFragment, unknown>
export const UserAccountFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserAccount' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserAccount' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePicture' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'meetingLink' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productAccess' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductAccess' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'accountName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'authTypes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'created' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActionRecord' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updated' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActionRecord' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'handwritingName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedActions' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notifications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserNotifications' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vendorNotifications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'VendorNotifications' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserAccountFragment, unknown>
export const FulfillmentPartnerFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FulfillmentPartner' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FulfillmentPartner' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'keywords' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FulfillmentPartnerFragment, unknown>
export const GetAccountByPostalFulfillmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAccountByPostalFulfillment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAccountByPostalFulfillment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CachedAccount' } }],
            },
          },
        ],
      },
    },
    ...CachedAccountFragmentDoc.definitions,
    ...AddressFragmentDoc.definitions,
    ...UspsAddressFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetAccountByPostalFulfillmentQuery, GetAccountByPostalFulfillmentQueryVariables>
export const NamedProductAccessListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'namedProductAccessList' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'namedProductAccessList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NamedProductAccess' } }],
            },
          },
        ],
      },
    },
    ...NamedProductAccessFragmentDoc.definitions,
    ...ProductAccessFragmentDoc.definitions,
    ...ActionRecordFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<NamedProductAccessListQuery, NamedProductAccessListQueryVariables>
export const ProductAccessListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'productAccessList' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productAccessList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProductAccess' } }],
            },
          },
        ],
      },
    },
    ...ProductAccessFragmentDoc.definitions,
    ...ActionRecordFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ProductAccessListQuery, ProductAccessListQueryVariables>
export const GetAssociatedAccountsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAssociatedAccounts' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAssociatedAccounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NamedProductAccess' } }],
            },
          },
        ],
      },
    },
    ...NamedProductAccessFragmentDoc.definitions,
    ...ProductAccessFragmentDoc.definitions,
    ...ActionRecordFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetAssociatedAccountsQuery, GetAssociatedAccountsQueryVariables>
export const SaveAssetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'saveAsset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'assetType' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'AssetType' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'requestId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'saveAsset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'assetType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'assetType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'requestId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'requestId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Asset' } }],
            },
          },
        ],
      },
    },
    ...AssetFragmentDoc.definitions,
    ...ActionRecordFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<SaveAssetMutation, SaveAssetMutationVariables>
export const UpdateAssetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateAsset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'AssetInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAsset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Asset' } }],
            },
          },
        ],
      },
    },
    ...AssetFragmentDoc.definitions,
    ...ActionRecordFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpdateAssetMutation, UpdateAssetMutationVariables>
export const GetBackgroundTaskByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getBackgroundTaskById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getBackgroundTaskById' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BackgroundTask' } }],
            },
          },
        ],
      },
    },
    ...BackgroundTaskFragmentDoc.definitions,
    ...BackgroundOperationErrorFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetBackgroundTaskByIdQuery, GetBackgroundTaskByIdQueryVariables>
export const GetContactByPostalFulfillmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getContactByPostalFulfillment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getContactByPostalFulfillment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Contact' } }],
            },
          },
        ],
      },
    },
    ...ContactFragmentDoc.definitions,
    ...PhoneMapFragmentDoc.definitions,
    ...ActionRecordFragmentDoc.definitions,
    ...AddressFragmentDoc.definitions,
    ...UspsAddressFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetContactByPostalFulfillmentQuery, GetContactByPostalFulfillmentQueryVariables>
export const SearchDraftMarketplaceProductDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchDraftMarketplaceProduct' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DraftMarketplaceProductFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DraftMarketplaceProductOrderByInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchDraftMarketplaceProduct' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DraftMarketplaceProduct' } }],
            },
          },
        ],
      },
    },
    ...DraftMarketplaceProductFragmentDoc.definitions,
    ...ImageReferenceFragmentDoc.definitions,
    ...DraftVariantFragmentDoc.definitions,
    ...ShippingOptionFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<SearchDraftMarketplaceProductQuery, SearchDraftMarketplaceProductQueryVariables>
export const GetDraftMarketplaceProductDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getDraftMarketplaceProduct' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDraftMarketplaceProduct' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DraftMarketplaceProduct' } }],
            },
          },
        ],
      },
    },
    ...DraftMarketplaceProductFragmentDoc.definitions,
    ...ImageReferenceFragmentDoc.definitions,
    ...DraftVariantFragmentDoc.definitions,
    ...ShippingOptionFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetDraftMarketplaceProductQuery, GetDraftMarketplaceProductQueryVariables>
export const ValidateDraftMarketplaceProductDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'validateDraftMarketplaceProduct' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'validateDraftMarketplaceProduct' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DraftMarketplaceProductValidationResult' } },
              ],
            },
          },
        ],
      },
    },
    ...DraftMarketplaceProductValidationResultFragmentDoc.definitions,
    ...ValidationCheckFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ValidateDraftMarketplaceProductQuery, ValidateDraftMarketplaceProductQueryVariables>
export const CreateDraftMarketplaceProductDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createDraftMarketplaceProduct' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DraftMarketplaceProductInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createDraftMarketplaceProduct' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DraftMarketplaceProduct' } }],
            },
          },
        ],
      },
    },
    ...DraftMarketplaceProductFragmentDoc.definitions,
    ...ImageReferenceFragmentDoc.definitions,
    ...DraftVariantFragmentDoc.definitions,
    ...ShippingOptionFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<CreateDraftMarketplaceProductMutation, CreateDraftMarketplaceProductMutationVariables>
export const UpdateDraftMarketplaceProductDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateDraftMarketplaceProduct' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DraftMarketplaceProductInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDraftMarketplaceProduct' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DraftMarketplaceProduct' } }],
            },
          },
        ],
      },
    },
    ...DraftMarketplaceProductFragmentDoc.definitions,
    ...ImageReferenceFragmentDoc.definitions,
    ...DraftVariantFragmentDoc.definitions,
    ...ShippingOptionFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpdateDraftMarketplaceProductMutation, UpdateDraftMarketplaceProductMutationVariables>
export const DeleteDraftMarketplaceProductDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteDraftMarketplaceProduct' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteDraftMarketplaceProduct' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DraftMarketplaceProduct' } }],
            },
          },
        ],
      },
    },
    ...DraftMarketplaceProductFragmentDoc.definitions,
    ...ImageReferenceFragmentDoc.definitions,
    ...DraftVariantFragmentDoc.definitions,
    ...ShippingOptionFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<DeleteDraftMarketplaceProductMutation, DeleteDraftMarketplaceProductMutationVariables>
export const BulkUpdateDraftMarketplaceProductsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'bulkUpdateDraftMarketplaceProducts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BulkDraftMarketplaceProductInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkUpdateDraftMarketplaceProducts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BackgroundTask' } }],
            },
          },
        ],
      },
    },
    ...BackgroundTaskFragmentDoc.definitions,
    ...BackgroundOperationErrorFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  BulkUpdateDraftMarketplaceProductsMutation,
  BulkUpdateDraftMarketplaceProductsMutationVariables
>
export const BulkDeleteDraftMarketplaceProductsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'bulkDeleteDraftMarketplaceProducts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DraftMarketplaceProductFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkDeleteDraftMarketplaceProducts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BulkDeleteDraftMarketplaceProductsMutation,
  BulkDeleteDraftMarketplaceProductsMutationVariables
>
export const SearchCategoryTreeEntriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchCategoryTreeEntries' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'CategoryTreeEntryFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'CategoryTreeEntryOrderByInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchCategoryTreeEntries' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CategoryTreeEntry' } }],
            },
          },
        ],
      },
    },
    ...CategoryTreeEntryFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<SearchCategoryTreeEntriesQuery, SearchCategoryTreeEntriesQueryVariables>
export const RequestPrivateListingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'requestPrivateListing' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestPrivateListing' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DraftMarketplaceProduct' } }],
            },
          },
        ],
      },
    },
    ...DraftMarketplaceProductFragmentDoc.definitions,
    ...ImageReferenceFragmentDoc.definitions,
    ...DraftVariantFragmentDoc.definitions,
    ...ShippingOptionFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<RequestPrivateListingMutation, RequestPrivateListingMutationVariables>
export const RequestPublicListingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'requestPublicListing' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestPublicListing' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DraftMarketplaceProduct' } }],
            },
          },
        ],
      },
    },
    ...DraftMarketplaceProductFragmentDoc.definitions,
    ...ImageReferenceFragmentDoc.definitions,
    ...DraftVariantFragmentDoc.definitions,
    ...ShippingOptionFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<RequestPublicListingMutation, RequestPublicListingMutationVariables>
export const AddDraftMarketplaceProductVariantDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addDraftMarketplaceProductVariant' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'draftProductId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DraftVariantInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addDraftMarketplaceProductVariant' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'draftProductId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'draftProductId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DraftMarketplaceProduct' } }],
            },
          },
        ],
      },
    },
    ...DraftMarketplaceProductFragmentDoc.definitions,
    ...ImageReferenceFragmentDoc.definitions,
    ...DraftVariantFragmentDoc.definitions,
    ...ShippingOptionFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  AddDraftMarketplaceProductVariantMutation,
  AddDraftMarketplaceProductVariantMutationVariables
>
export const UpdateDraftMarketplaceProductVariantDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateDraftMarketplaceProductVariant' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'draftProductId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DraftVariantInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDraftMarketplaceProductVariant' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'draftProductId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'draftProductId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DraftMarketplaceProduct' } }],
            },
          },
        ],
      },
    },
    ...DraftMarketplaceProductFragmentDoc.definitions,
    ...ImageReferenceFragmentDoc.definitions,
    ...DraftVariantFragmentDoc.definitions,
    ...ShippingOptionFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  UpdateDraftMarketplaceProductVariantMutation,
  UpdateDraftMarketplaceProductVariantMutationVariables
>
export const RemoveDraftMarketplaceProductVariantDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'removeDraftMarketplaceProductVariant' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'draftProductId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeDraftMarketplaceProductVariant' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'draftProductId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'draftProductId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DraftMarketplaceProduct' } }],
            },
          },
        ],
      },
    },
    ...DraftMarketplaceProductFragmentDoc.definitions,
    ...ImageReferenceFragmentDoc.definitions,
    ...DraftVariantFragmentDoc.definitions,
    ...ShippingOptionFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  RemoveDraftMarketplaceProductVariantMutation,
  RemoveDraftMarketplaceProductVariantMutationVariables
>
export const GetEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ApprovedPostal' } }],
            },
          },
        ],
      },
    },
    ...ApprovedPostalFragmentDoc.definitions,
    ...ImageReferenceFragmentDoc.definitions,
    ...ApprovedProductVariantFragmentDoc.definitions,
    ...MagicEventInstanceFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetEventQuery, GetEventQueryVariables>
export const SearchEventsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchEvents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'EventFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'EventOrderByInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchEvents' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ApprovedPostal' } }],
            },
          },
        ],
      },
    },
    ...ApprovedPostalFragmentDoc.definitions,
    ...ImageReferenceFragmentDoc.definitions,
    ...ApprovedProductVariantFragmentDoc.definitions,
    ...MagicEventInstanceFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<SearchEventsQuery, SearchEventsQueryVariables>
export const ConnectShopifyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'connectShopify' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shopifyUrl' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accessToken' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'connectShopify' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shopifyUrl' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'shopifyUrl' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accessToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accessToken' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopifyCollection' } }],
            },
          },
        ],
      },
    },
    ...ShopifyCollectionFragmentDoc.definitions,
    ...CollectionFragmentDoc.definitions,
    ...PageInfoFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ConnectShopifyMutation, ConnectShopifyMutationVariables>
export const SyncShopifyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'syncShopify' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'syncShopify' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BackgroundTask' } }],
            },
          },
        ],
      },
    },
    ...BackgroundTaskFragmentDoc.definitions,
    ...BackgroundOperationErrorFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<SyncShopifyMutation, SyncShopifyMutationVariables>
export const SearchIntegrationSyncDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchIntegrationSync' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'IntegrationSyncFilterInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchIntegrationSync' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'IntegrationSync' } }],
            },
          },
        ],
      },
    },
    ...IntegrationSyncFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<SearchIntegrationSyncQuery, SearchIntegrationSyncQueryVariables>
export const ModulesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'modules' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'modules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Module' } }],
            },
          },
        ],
      },
    },
    ...ModuleFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ModulesQuery, ModulesQueryVariables>
export const UpdateVendorNotificationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateVendorNotifications' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'VendorNotificationsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateVendorNotifications' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'User' } }],
            },
          },
        ],
      },
    },
    ...UserFragmentDoc.definitions,
    ...AuthenticationMethodFragmentDoc.definitions,
    ...ActionRecordFragmentDoc.definitions,
    ...ProductAccessFragmentDoc.definitions,
    ...CrmUserFragmentDoc.definitions,
    ...UserNotificationsFragmentDoc.definitions,
    ...VendorNotificationsFragmentDoc.definitions,
    ...NotificationInfoFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpdateVendorNotificationsMutation, UpdateVendorNotificationsMutationVariables>
export const SearchPostalFulfillmentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchPostalFulfillments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PostalFulfillmentFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PostalFulfillmentOrderByInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchPostalFulfillments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PostalFulfillment' } }],
            },
          },
        ],
      },
    },
    ...PostalFulfillmentFragmentDoc.definitions,
    ...UserLinkFragmentDoc.definitions,
    ...AddressFragmentDoc.definitions,
    ...UspsAddressFragmentDoc.definitions,
    ...FulfillmentMapFragmentDoc.definitions,
    ...ShippingOptionFragmentDoc.definitions,
    ...InventoryFragmentDoc.definitions,
    ...OrderCostFragmentDoc.definitions,
    ...FulfillmentHistoryFragmentDoc.definitions,
    ...ActionRecordFragmentDoc.definitions,
    ...ItemCustomizationFragmentDoc.definitions,
    ...FulfillmentEventsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<SearchPostalFulfillmentsQuery, SearchPostalFulfillmentsQueryVariables>
export const GetProductByPostalFulfillmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getProductByPostalFulfillment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getProductByPostalFulfillment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'MarketplaceProduct' } }],
            },
          },
        ],
      },
    },
    ...MarketplaceProductFragmentDoc.definitions,
    ...ProductVariantFragmentDoc.definitions,
    ...ImageReferenceFragmentDoc.definitions,
    ...FulfillmentMapFragmentDoc.definitions,
    ...ShippingOptionFragmentDoc.definitions,
    ...InventoryFragmentDoc.definitions,
    ...DesignTemplateFragmentDoc.definitions,
    ...ElementConfigFragmentDoc.definitions,
    ...ElementLocationFragmentDoc.definitions,
    ...ElementResizeFragmentDoc.definitions,
    ...ElementBoundaryFragmentDoc.definitions,
    ...CanvasSizeFragmentDoc.definitions,
    ...MagicEventFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetProductByPostalFulfillmentQuery, GetProductByPostalFulfillmentQueryVariables>
export const GetPostalFulfillmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPostalFulfillment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPostalFulfillment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PostalFulfillment' } }],
            },
          },
        ],
      },
    },
    ...PostalFulfillmentFragmentDoc.definitions,
    ...UserLinkFragmentDoc.definitions,
    ...AddressFragmentDoc.definitions,
    ...UspsAddressFragmentDoc.definitions,
    ...FulfillmentMapFragmentDoc.definitions,
    ...ShippingOptionFragmentDoc.definitions,
    ...InventoryFragmentDoc.definitions,
    ...OrderCostFragmentDoc.definitions,
    ...FulfillmentHistoryFragmentDoc.definitions,
    ...ActionRecordFragmentDoc.definitions,
    ...ItemCustomizationFragmentDoc.definitions,
    ...FulfillmentEventsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetPostalFulfillmentQuery, GetPostalFulfillmentQueryVariables>
export const UpdatePostalFulfillmentTrackingNumberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updatePostalFulfillmentTrackingNumber' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'trackingNumber' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePostalFulfillmentTrackingNumber' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'trackingNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'trackingNumber' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PostalFulfillment' } }],
            },
          },
        ],
      },
    },
    ...PostalFulfillmentFragmentDoc.definitions,
    ...UserLinkFragmentDoc.definitions,
    ...AddressFragmentDoc.definitions,
    ...UspsAddressFragmentDoc.definitions,
    ...FulfillmentMapFragmentDoc.definitions,
    ...ShippingOptionFragmentDoc.definitions,
    ...InventoryFragmentDoc.definitions,
    ...OrderCostFragmentDoc.definitions,
    ...FulfillmentHistoryFragmentDoc.definitions,
    ...ActionRecordFragmentDoc.definitions,
    ...ItemCustomizationFragmentDoc.definitions,
    ...FulfillmentEventsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  UpdatePostalFulfillmentTrackingNumberMutation,
  UpdatePostalFulfillmentTrackingNumberMutationVariables
>
export const BulkUpdatePostalFulfillmentStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'bulkUpdatePostalFulfillmentStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'FulfillmentStatus' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PostalFulfillmentFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkUpdatePostalFulfillmentStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'status' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BackgroundTask' } }],
            },
          },
        ],
      },
    },
    ...BackgroundTaskFragmentDoc.definitions,
    ...BackgroundOperationErrorFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  BulkUpdatePostalFulfillmentStatusMutation,
  BulkUpdatePostalFulfillmentStatusMutationVariables
>
export const MarkOrderShippedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'markOrderShipped' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markOrderShipped' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PostalFulfillment' } }],
            },
          },
        ],
      },
    },
    ...PostalFulfillmentFragmentDoc.definitions,
    ...UserLinkFragmentDoc.definitions,
    ...AddressFragmentDoc.definitions,
    ...UspsAddressFragmentDoc.definitions,
    ...FulfillmentMapFragmentDoc.definitions,
    ...ShippingOptionFragmentDoc.definitions,
    ...InventoryFragmentDoc.definitions,
    ...OrderCostFragmentDoc.definitions,
    ...FulfillmentHistoryFragmentDoc.definitions,
    ...ActionRecordFragmentDoc.definitions,
    ...ItemCustomizationFragmentDoc.definitions,
    ...FulfillmentEventsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<MarkOrderShippedMutation, MarkOrderShippedMutationVariables>
export const MarkOrderDeliveredDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'markOrderDelivered' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markOrderDelivered' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PostalFulfillment' } }],
            },
          },
        ],
      },
    },
    ...PostalFulfillmentFragmentDoc.definitions,
    ...UserLinkFragmentDoc.definitions,
    ...AddressFragmentDoc.definitions,
    ...UspsAddressFragmentDoc.definitions,
    ...FulfillmentMapFragmentDoc.definitions,
    ...ShippingOptionFragmentDoc.definitions,
    ...InventoryFragmentDoc.definitions,
    ...OrderCostFragmentDoc.definitions,
    ...FulfillmentHistoryFragmentDoc.definitions,
    ...ActionRecordFragmentDoc.definitions,
    ...ItemCustomizationFragmentDoc.definitions,
    ...FulfillmentEventsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<MarkOrderDeliveredMutation, MarkOrderDeliveredMutationVariables>
export const MarkOrderConfirmedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'markOrderConfirmed' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'OrderConfirmedInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markOrderConfirmed' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PostalFulfillment' } }],
            },
          },
        ],
      },
    },
    ...PostalFulfillmentFragmentDoc.definitions,
    ...UserLinkFragmentDoc.definitions,
    ...AddressFragmentDoc.definitions,
    ...UspsAddressFragmentDoc.definitions,
    ...FulfillmentMapFragmentDoc.definitions,
    ...ShippingOptionFragmentDoc.definitions,
    ...InventoryFragmentDoc.definitions,
    ...OrderCostFragmentDoc.definitions,
    ...FulfillmentHistoryFragmentDoc.definitions,
    ...ActionRecordFragmentDoc.definitions,
    ...ItemCustomizationFragmentDoc.definitions,
    ...FulfillmentEventsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<MarkOrderConfirmedMutation, MarkOrderConfirmedMutationVariables>
export const MarkOrderPlacedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'markOrderPlaced' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'OrderPlacedInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markOrderPlaced' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PostalFulfillment' } }],
            },
          },
        ],
      },
    },
    ...PostalFulfillmentFragmentDoc.definitions,
    ...UserLinkFragmentDoc.definitions,
    ...AddressFragmentDoc.definitions,
    ...UspsAddressFragmentDoc.definitions,
    ...FulfillmentMapFragmentDoc.definitions,
    ...ShippingOptionFragmentDoc.definitions,
    ...InventoryFragmentDoc.definitions,
    ...OrderCostFragmentDoc.definitions,
    ...FulfillmentHistoryFragmentDoc.definitions,
    ...ActionRecordFragmentDoc.definitions,
    ...ItemCustomizationFragmentDoc.definitions,
    ...FulfillmentEventsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<MarkOrderPlacedMutation, MarkOrderPlacedMutationVariables>
export const MarkOrderCancelledDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'markOrderCancelled' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'OrderCancelledInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markOrderCancelled' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PostalFulfillment' } }],
            },
          },
        ],
      },
    },
    ...PostalFulfillmentFragmentDoc.definitions,
    ...UserLinkFragmentDoc.definitions,
    ...AddressFragmentDoc.definitions,
    ...UspsAddressFragmentDoc.definitions,
    ...FulfillmentMapFragmentDoc.definitions,
    ...ShippingOptionFragmentDoc.definitions,
    ...InventoryFragmentDoc.definitions,
    ...OrderCostFragmentDoc.definitions,
    ...FulfillmentHistoryFragmentDoc.definitions,
    ...ActionRecordFragmentDoc.definitions,
    ...ItemCustomizationFragmentDoc.definitions,
    ...FulfillmentEventsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<MarkOrderCancelledMutation, MarkOrderCancelledMutationVariables>
export const GetVendorPayableDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getVendorPayable' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getVendorPayable' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'VendorPayable' } }],
            },
          },
        ],
      },
    },
    ...VendorPayableFragmentDoc.definitions,
    ...VendorPayableExternalHistoryFragmentDoc.definitions,
    ...ActionRecordFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetVendorPayableQuery, GetVendorPayableQueryVariables>
export const SearchVendorPayablesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchVendorPayables' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'VendorPayableFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'VendorPayableOrderByInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchVendorPayables' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'VendorPayable' } }],
            },
          },
        ],
      },
    },
    ...VendorPayableFragmentDoc.definitions,
    ...VendorPayableExternalHistoryFragmentDoc.definitions,
    ...ActionRecordFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<SearchVendorPayablesQuery, SearchVendorPayablesQueryVariables>
export const SearchVendorFulfillmentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchVendorFulfillments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PostalFulfillmentFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PostalFulfillmentOrderByInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchVendorFulfillments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'VendorFulfillment' } }],
            },
          },
        ],
      },
    },
    ...VendorFulfillmentFragmentDoc.definitions,
    ...AddressFragmentDoc.definitions,
    ...UspsAddressFragmentDoc.definitions,
    ...OrderCostFragmentDoc.definitions,
    ...FulfillmentHistoryFragmentDoc.definitions,
    ...VendorPaymentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<SearchVendorFulfillmentsQuery, SearchVendorFulfillmentsQueryVariables>
export const SearchReportOutputInstancesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchReportOutputInstances' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ReportOutputFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ReportOutputOrderByInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchReportOutputInstances' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ReportOutputInstance' } }],
            },
          },
        ],
      },
    },
    ...ReportOutputInstanceFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<SearchReportOutputInstancesQuery, SearchReportOutputInstancesQueryVariables>
export const DownloadReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'downloadReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reportOutputInstanceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'downloadReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reportOutputInstanceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'reportOutputInstanceId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DownloadReportQuery, DownloadReportQueryVariables>
export const GenerateOrderReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'generateOrderReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PostalFulfillmentFilterInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generateOrderReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BackgroundTask' } }],
            },
          },
        ],
      },
    },
    ...BackgroundTaskFragmentDoc.definitions,
    ...BackgroundOperationErrorFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GenerateOrderReportMutation, GenerateOrderReportMutationVariables>
export const GenerateVendorFulfillmentsReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'generateVendorFulfillmentsReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PostalFulfillmentFilterInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generateVendorFulfillmentsReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BackgroundTask' } }],
            },
          },
        ],
      },
    },
    ...BackgroundTaskFragmentDoc.definitions,
    ...BackgroundOperationErrorFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  GenerateVendorFulfillmentsReportMutation,
  GenerateVendorFulfillmentsReportMutationVariables
>
export const SearchShopifyCollectionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchShopifyCollections' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'before' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchShopifyCollections' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'before' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'before' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopifyCollection' } }],
            },
          },
        ],
      },
    },
    ...ShopifyCollectionFragmentDoc.definitions,
    ...CollectionFragmentDoc.definitions,
    ...PageInfoFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<SearchShopifyCollectionsQuery, SearchShopifyCollectionsQueryVariables>
export const SelectShopifyCollectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'selectShopifyCollection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shopifyCollectionId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'selectShopifyCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shopifyCollectionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'shopifyCollectionId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BackgroundTask' } }],
            },
          },
        ],
      },
    },
    ...BackgroundTaskFragmentDoc.definitions,
    ...BackgroundOperationErrorFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<SelectShopifyCollectionMutation, SelectShopifyCollectionMutationVariables>
export const SearchStoreInstanceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchStoreInstance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'StoreInstanceFilterInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'StoreInstanceOrderByInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchStoreInstance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreInstance' } }],
            },
          },
        ],
      },
    },
    ...StoreInstanceFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<SearchStoreInstanceQuery, SearchStoreInstanceQueryVariables>
export const GetStoreInstanceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getStoreInstance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getStoreInstance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreInstance' } }],
            },
          },
        ],
      },
    },
    ...StoreInstanceFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetStoreInstanceQuery, GetStoreInstanceQueryVariables>
export const CreateStoreInstanceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createStoreInstance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateStoreInstanceInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createStoreInstance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreInstance' } }],
            },
          },
        ],
      },
    },
    ...StoreInstanceFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<CreateStoreInstanceMutation, CreateStoreInstanceMutationVariables>
export const UpdateStoreInstanceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateStoreInstance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'StoreInstanceInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateStoreInstance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreInstance' } }],
            },
          },
        ],
      },
    },
    ...StoreInstanceFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpdateStoreInstanceMutation, UpdateStoreInstanceMutationVariables>
export const DeleteStoreInstanceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteStoreInstance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ObjectId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteStoreInstance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreInstance' } }],
            },
          },
        ],
      },
    },
    ...StoreInstanceFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<DeleteStoreInstanceMutation, DeleteStoreInstanceMutationVariables>
export const PingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ping' },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'ping' } }] },
    },
  ],
} as unknown as DocumentNode<PingQuery, PingQueryVariables>
export const MeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'me' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserAccount' } }],
            },
          },
        ],
      },
    },
    ...UserAccountFragmentDoc.definitions,
    ...ProductAccessFragmentDoc.definitions,
    ...ActionRecordFragmentDoc.definitions,
    ...UserNotificationsFragmentDoc.definitions,
    ...VendorNotificationsFragmentDoc.definitions,
    ...NotificationInfoFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<MeQuery, MeQueryVariables>
export const ChangePasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'changePassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ChangePasswordInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'changePassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChangePasswordMutation, ChangePasswordMutationVariables>
export const UpdateSelfDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateSelf' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateSelfInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSelf' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'User' } }],
            },
          },
        ],
      },
    },
    ...UserFragmentDoc.definitions,
    ...AuthenticationMethodFragmentDoc.definitions,
    ...ActionRecordFragmentDoc.definitions,
    ...ProductAccessFragmentDoc.definitions,
    ...CrmUserFragmentDoc.definitions,
    ...UserNotificationsFragmentDoc.definitions,
    ...VendorNotificationsFragmentDoc.definitions,
    ...NotificationInfoFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpdateSelfMutation, UpdateSelfMutationVariables>
export const GetFulfillmentPartnerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getFulfillmentPartner' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getFulfillmentPartner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FulfillmentPartner' } }],
            },
          },
        ],
      },
    },
    ...FulfillmentPartnerFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetFulfillmentPartnerQuery, GetFulfillmentPartnerQueryVariables>
export const UpdateFulfillmentPartnerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateFulfillmentPartner' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'FulfillmentPartnerInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateFulfillmentPartner' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FulfillmentPartner' } }],
            },
          },
        ],
      },
    },
    ...FulfillmentPartnerFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpdateFulfillmentPartnerMutation, UpdateFulfillmentPartnerMutationVariables>
