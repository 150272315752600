import { localStorage, sessionStorage } from '@postal-io/postal-ui'
import api, { LoginPasswordRequest, SignUpPasswordRequest } from 'api/rest'
import jwtDecode from 'jwt-decode'
import { StorageKeys } from 'lib'
import React, { createContext, useContext, useState } from 'react'
import { useQueryClient } from 'react-query'

export const getToken = () => {
  return sessionStorage.getItem(StorageKeys.AppToken) || ''
}

export const getRefreshToken = () => {
  return localStorage.getItem(StorageKeys.AppRefreshToken) || ''
}

export const clearSession = () => {
  sessionStorage.clear()
  localStorage.removeItem(StorageKeys.AppRefreshToken)
}

export const getProductAccessId = () => {
  return localStorage.getItem(StorageKeys.AppProductAccessId) || ''
}

export const clearProductAccessId = () => {
  return localStorage.removeItem(StorageKeys.AppProductAccessId)
}

export const switchAccount = (productAccessId?: string) => {
  return api.accessToken({ productAccessId })
}

export const saveSession = ({ token, refreshToken }: { token?: string; refreshToken?: string }) => {
  sessionStorage.setItem(StorageKeys.AppToken, token as string)
  localStorage.setItem(StorageKeys.AppRefreshToken, refreshToken as string)

  if (token) {
    try {
      const { productAccessId } = jwtDecode(token) as any
      if (productAccessId) localStorage.setItem(StorageKeys.AppProductAccessId, productAccessId)
    } catch (err) {
      console.error(err)
    }
  }
  return { token, refreshToken }
}

// track a single promise
let reloadingPromise: any

export const reloadSession = () => {
  if (reloadingPromise) return reloadingPromise
  reloadingPromise = api.accessToken().catch(() => {})
  return reloadingPromise
}

type SessionContextProps = {
  loadSession?: any
  loginPassword?: any
  loginSso?: any
  logout?: any
  removeSession?: any
  session?: any
  signUpPassword?: any
  switchSession?: any
  getInviteInfo?: any
  inviteComplete?: any
  signUpVerify?: any
  resetPassword?: any
  forgotPassword?: any
  signUp?: any
}

const SessionContext = createContext<SessionContextProps>({})

export const SessionProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const client = useQueryClient()
  const [session, setSession] = useState<any>()

  const startSession = async () => {
    try {
      const data: any = jwtDecode(getToken() as string)
      await client.clear()
      setSession(data)
    } catch (e) {
      removeSession()
    }
  }

  const removeSession = async () => {
    clearSession()
    await client.clear()
    setSession(false)
  }

  const switchSession = async (productAccessId: string) => {
    await switchAccount(productAccessId)
    window.location.href = `${process.env.PUBLIC_URL}/`
    return new Promise((res) => setTimeout(() => res(true), 500))
  }

  const logout = () => {
    return api.logout().then(removeSession)
  }

  const loginPassword = async (args: LoginPasswordRequest) => {
    saveSession(await api.loginPassword(args))
    await startSession()
  }

  const loginSso = async (args: string) => {
    saveSession(await api.loginSso(args))
    await startSession()
  }

  const signUpPassword = async (args: SignUpPasswordRequest) => {
    saveSession(await api.signUpPassword(args))
    await startSession()
  }

  const loadSession = async () => {
    await api.accessToken()
    await startSession()
  }

  const signUpVerify = async (args: any) => {
    const { refreshToken } = await api.signUpVerify(args)
    await api.accessToken({ refreshToken })
    await startSession()
  }

  const value = {
    loadSession,
    loginPassword,
    loginSso,
    logout,
    removeSession,
    session,
    signUpPassword,
    signUpVerify,
    switchSession,
    forgotPassword: api.forgotPassword,
    getInviteInfo: api.getInviteInfo,
    inviteComplete: api.inviteComplete,
    resetPassword: api.resetPassword,
    signUp: api.signUp,
  }
  return <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
}

export const useSession = () => useContext(SessionContext)
