import { Grid } from '@chakra-ui/react'
import {
  UiCard,
  UiCardHeader,
  UiChangeEvent,
  UiFormControl,
  UiFormLabel,
  UiInputMoney,
  UiSelect,
  UiSwitch,
} from '@postal-io/postal-ui'
import { ChangeEvent, useState } from 'react'
import { DraftCardProps, DraftVariantFormProps } from '.'
import { SHIPPING_NAMES } from './draftProductDataV2'

export const DraftVariantShippingOptionsCard: React.FC<DraftCardProps<DraftVariantFormProps>> = ({ form, setForm }) => {
  const [freeShipping, setFreeShipping] = useState(false)

  const handleChangeShippingOption = (key: string, value?: string | number) => {
    if (value === 0) {
      setFreeShipping(true)
    }

    setForm((draft: any) => {
      if (draft.shippingOptions?.length === 0) {
        draft.shippingOptions = [{}]
      }
      draft.shippingOptions[0][key] = value
    })
  }

  const handleFreeShippingSwitch = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { checked } = target

    setFreeShipping(checked)

    setForm((draft: any) => {
      if (draft.shippingOptions?.length === 0) {
        draft.shippingOptions = [{}]
      }

      if (checked) {
        draft.shippingOptions[0]['price'] = 0
      } else if (form.shippingOptions?.[0].price === 0) {
        draft.shippingOptions[0]['price'] = ''
      }
    })
  }

  return (
    <UiCard boxShadow={'none'}>
      <UiCardHeader>Shipping Options</UiCardHeader>
      <Grid
        templateColumns="1fr 1fr"
        alignItems="center"
        gap={4}
      >
        <UiSelect
          name="name"
          value={form.shippingOptions?.[0]?.name || ''}
          onChange={({ target }) => handleChangeShippingOption(target.name, target.value)}
          isRequired={!!form.requiresShipping}
        >
          <option value="">-- Shipping Provider --</option>
          {SHIPPING_NAMES.map((name) => {
            return (
              <option
                key={name}
                value={name}
              >
                {name}
              </option>
            )
          })}
        </UiSelect>
        <UiInputMoney
          id="price"
          name="price"
          value={form.shippingOptions?.[0]?.price}
          placeholder="Price (in dollars)"
          onChange={({ key, value }: UiChangeEvent<number>) => handleChangeShippingOption(key, value)}
          mr={2}
          isRequired={!!form.requiresShipping}
          isDisabled={!!freeShipping}
        />
        <UiFormControl></UiFormControl>
        <UiFormControl id="freeShipping">
          <UiFormLabel>Free Shipping</UiFormLabel>
          <UiSwitch
            size="lg"
            isChecked={!!freeShipping || form.shippingOptions?.[0]?.price === 0}
            onChange={handleFreeShippingSwitch}
          />
        </UiFormControl>
      </Grid>
    </UiCard>
  )
}
