import { LockIcon } from '@chakra-ui/icons'
import { Flex, Image, useDisclosure } from '@chakra-ui/react'
import { UiCard, UiCardHeader } from '@postal-io/postal-ui'
import { systemImageMap } from 'components/Integrations'
import { useMemo, useState } from 'react'
import { DraftImagesCard } from './DraftImagesCard'
import { DraftCardProps } from './DraftProductEditV2'
import { DraftResyncFieldModal } from './DraftResyncFieldModal'

//TODO: to create a generic for this interface.
export const DraftProductMediaCard: React.FC<DraftCardProps<any>> = ({
  form,
  setForm,
  isLoading,
  draft,
  handleShopifyUpdate,
}) => {
  const resyncMedia = useDisclosure()
  const resyncModalDisclosure = useDisclosure()
  const [syncField, setSyncField] = useState('')

  const handleShopifyResync = (field: string) => {
    setSyncField(field)
    resyncModalDisclosure.onOpen()
  }

  const systemName = useMemo(() => {
    return !!draft ? draft.systemName : form.systemName
  }, [draft, form.systemName])

  return (
    <>
      <UiCard
        isLoading={isLoading}
        boxShadow="none"
        onMouseEnter={resyncMedia.onOpen}
        onMouseLeave={resyncMedia.onClose}
      >
        <Flex>
          <UiCardHeader>Media</UiCardHeader>
          {systemName === 'shopify' && resyncMedia.isOpen && !!form.overrideImageUrls ? (
            <Image
              ml={2}
              src={systemImageMap[systemName]}
              display="block"
              boxSize={'20px'}
              onClick={() => handleShopifyResync('imageUrls')}
              _hover={{ cursor: 'pointer' }}
            />
          ) : systemName === 'shopify' && !form.overrideImageUrls ? (
            <LockIcon
              ml={2}
              color={'gray.300'}
              boxSize={'18px'}
            />
          ) : null}
        </Flex>

        <DraftImagesCard
          form={form}
          setForm={setForm}
          isShopify={systemName === 'shopify'}
        />
      </UiCard>
      {resyncModalDisclosure.isOpen && (
        <DraftResyncFieldModal
          handleShopifyUpdate={handleShopifyUpdate}
          syncField={syncField}
          {...resyncModalDisclosure}
        />
      )}
    </>
  )
}
