import { Fade, Grid, useDisclosure } from '@chakra-ui/react'
import { UiActionMenu, UiButton, UiCard, UiCardProps, UiText, useAlerts } from '@postal-io/postal-ui'
import { DraftMarketplaceProductFragment, DraftStatus } from 'api/index'
import { useEffect, useMemo, useState } from 'react'
import { DraftProductsBulkDeleteModal } from './DraftProductsBulkDeleteModal'
import { BulkUpdateType, DraftProductsBulkUpdateModal } from './DraftProductsBulkUpdateModal'

interface DraftProductsBulkActionsCardProps extends UiCardProps {
  selectedDrafts: DraftMarketplaceProductFragment[]
  drafts: DraftMarketplaceProductFragment[]
  onSuccess(): void
  onClose(): void
}

export const DraftProductsBulkActionsCard: React.FC<DraftProductsBulkActionsCardProps> = ({
  selectedDrafts,
  drafts,
  onSuccess,
  onClose,
  ...rest
}) => {
  const Alert = useAlerts()
  const [idsFilter, setIdsFilter] = useState<'in' | 'nin'>('in')

  const isSelectedAll = useMemo(() => selectedDrafts.length === drafts.length, [drafts.length, selectedDrafts.length])
  const isSelectedNone = useMemo(() => selectedDrafts.length === 0, [selectedDrafts.length])

  useEffect(() => {
    if (isSelectedAll) setIdsFilter('nin')
    if (isSelectedNone) setIdsFilter('in')
  }, [isSelectedAll, isSelectedNone])

  const filteredProducts = useMemo(() => {
    return selectedDrafts.filter((product) => !product.category || !product.type)
  }, [selectedDrafts])

  const missingDataDisclosure = useDisclosure()
  const bulkDeleteDisclosure = useDisclosure()

  const onDeleteDrafts = () => {
    const isOnlyDrafts = selectedDrafts.every((draft) =>
      [DraftStatus.Draft, DraftStatus.NeedsData].includes(draft.status)
    )
    if (!isOnlyDrafts) {
      Alert.warning('Only drafts with draft status can be deleted. Please deselect other drafts and try again.')
      return
    }
    const isOnlyMarketplace = selectedDrafts.every((draft) => draft.marketplaceStatus !== null)
    if (isOnlyMarketplace) {
      Alert.info(
        'These drafts were already submitted to the marketplace. Please contact your Postal.io representative for deletion.'
      )
      return
    }
    bulkDeleteDisclosure.onOpen()
  }

  const actionItems = [{ title: 'Delete Drafts', onClick: onDeleteDrafts }]

  return !selectedDrafts.length ? null : (
    <>
      <Fade
        in={!!selectedDrafts.length}
        delay={0.1}
      >
        <UiCard
          boxShadow="none"
          border="none"
          p={0}
          px={6}
          pt={2}
          pb={4}
          minH="0px"
          pos="relative"
          h={!!selectedDrafts.length ? 'revert' : 0}
          transition="100ms height linear"
          {...rest}
        >
          <Grid
            templateColumns="repeat(3, max-content)"
            gap={6}
            alignItems="center"
          >
            <UiText
              fontWeight="bold"
              fontSize="sm"
            >
              {selectedDrafts.length} Selected
            </UiText>
            {filteredProducts.length === 0 ? null : (
              <UiButton
                colorScheme="whiteAlpha"
                color="shades.900"
                bg="rgba(42, 228, 169, 0.06)"
                _hover={{ bg: 'turquoise.100' }}
                _active={{ bg: 'turquoise.200' }}
                borderWidth="1px"
                borderColor="turquoise.500"
                size="sm"
                fontSize="sm"
                w="min-content"
                px={4}
                borderRadius="2px"
                textTransform="none"
                onClick={missingDataDisclosure.onOpen}
              >
                Set Missing Data
              </UiButton>
            )}
            <UiActionMenu
              actionItems={actionItems}
              fontWeight="bold"
              color="shades.800"
              title="Actions"
            />
          </Grid>
        </UiCard>
      </Fade>

      {missingDataDisclosure.isOpen && (
        <DraftProductsBulkUpdateModal
          type={BulkUpdateType.SetMissingData}
          selectedDrafts={selectedDrafts}
          onSuccess={onSuccess}
          {...missingDataDisclosure}
        />
      )}

      {bulkDeleteDisclosure.isOpen && (
        <DraftProductsBulkDeleteModal
          drafts={drafts}
          selectedDrafts={selectedDrafts}
          idsFilter={idsFilter}
          onSuccess={onSuccess}
          {...bulkDeleteDisclosure}
        />
      )}
    </>
  )
}
