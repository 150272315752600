export enum StorageKeys {
  AppToken = 'postal-vendor:token',
  AppRefreshToken = 'postal-vendor:refreshToken',
  AppRedirectPath = 'postal-vendor:redirectPath',
  AppProductAccessId = 'postal-vendor:productAccessId',
  DraftMarketplaceProductFilter = 'postal-vendor:draftMarketplaceProduct:filter',
  DraftMarketplaceProductCurrentPage = 'postal-venor:draftMarketplaceProduct:currentPage',
  EventsFilter = 'postal-vendor:events:filter',
  EventsCurrentPage = 'postal-vendor:events:currentPage',
  PostalFulfillmentsFilter = 'postal-vendor:postalFulfillments:filter',
  VendorFulfillmentsFilter = 'postal-vendor:vendorFulfillments:filter',
  VendorFulfillmentsCurrentPage = 'postal-venor:vendorFulfillments:currentPage',
  VendorPayablesFilter = 'postal-vendor:vendorPayables:filter',
}
