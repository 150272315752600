import { InfoIcon } from '@chakra-ui/icons'
import { Grid } from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import {
  UiButton,
  UiCard,
  UiCardProps,
  UiFormControl,
  UiFormLabel,
  UiInput,
  UiSelect,
  UiTooltip,
  useUiAlerts,
} from '@postal-io/postal-ui'
import { UpdatePostalFulfillmentTrackingNumberDocument } from 'api'
import React, { useMemo } from 'react'
import { useImmer } from 'use-immer'
import { CARRIERS } from '../ordersData'

interface TrackingNumberEditProps extends UiCardProps {
  postalFulfillmentId?: string
  trackingNumber?: string
  deliveryPartner?: string | null
}

export const TrackingNumberEdit: React.FC<TrackingNumberEditProps> = ({
  postalFulfillmentId,
  trackingNumber,
  deliveryPartner,
  ...rest
}) => {
  const Alert = useUiAlerts()

  const normalizedCarrier = useMemo(() => {
    return CARRIERS.find((carrier) => carrier.value.toLowerCase() === deliveryPartner?.toLowerCase())
  }, [deliveryPartner])

  const [form, setForm] = useImmer<Record<string, any>>({
    trackingNumber: trackingNumber ?? '',
    carrier: normalizedCarrier?.value ?? '',
    customCarrier: '',
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target

    setForm((draft: any) => {
      // reset carrier menu if inputing custom carrier, to avoid confusion
      if (name === 'customCarrier' && !!value) {
        draft.carrier = ''
      }
      draft[name] = value
      return draft
    })
  }

  const updateTracking = useGraphqlMutation(UpdatePostalFulfillmentTrackingNumberDocument)

  const handleSubmit = async (e: React.FormEvent<any>) => {
    e.preventDefault()

    let carrier = (form.customCarrier || form.carrier)?.trim()?.toUpperCase()
    carrier = carrier ? `${carrier}!` : ''

    try {
      await updateTracking.mutateAsync({
        id: postalFulfillmentId,
        trackingNumber: `${carrier}${form.trackingNumber}`,
      })
      Alert.success('Tracking number updated')
    } catch (err) {
      Alert.error(err)
    }
  }

  return (
    <UiCard
      boxShadow="none"
      borderRadius="0px"
      {...rest}
    >
      <form
        id="tracking-number-form"
        onSubmit={handleSubmit}
      >
        <Grid
          templateColumns={{ base: '1fr', xl: '1fr 1fr 1fr 200px' }}
          gap={4}
        >
          <UiFormControl
            id="trackingNumber"
            mb={0}
          >
            <UiFormLabel>
              Tracking Number
              <UiTooltip
                aria-label="Tracking Number"
                label="Tracking numbers can be updated at any time"
                placement="right"
                hasArrow
              >
                <InfoIcon
                  color="shades.100"
                  ml={1}
                  pos="relative"
                  top={-1}
                />
              </UiTooltip>
            </UiFormLabel>
            <UiInput
              id="trackingNumber"
              name="trackingNumber"
              value={form.trackingNumber}
              onChange={handleChange}
              w="100%"
              isRequired
            />
          </UiFormControl>
          <UiFormControl
            id="carrier"
            mb={0}
          >
            <UiFormLabel>Carrier</UiFormLabel>
            <UiSelect
              h="40px"
              name="carrier"
              onChange={handleChange}
              value={form.carrier}
            >
              <option value="">-- Select --</option>
              {CARRIERS.map((carrier, idx) => (
                <option
                  key={`${carrier}-${idx}`}
                  value={carrier.value}
                >
                  {carrier.label || carrier.value}
                </option>
              ))}
            </UiSelect>
          </UiFormControl>

          <UiFormControl id="customCarrier">
            <UiFormLabel> Or Use a Custom Carrier</UiFormLabel>
            <UiInput
              name="customCarrier"
              value={form.customCarrier}
              onChange={handleChange}
            />
          </UiFormControl>

          <UiButton
            py={0}
            w="100%"
            type="submit"
            h="40px"
            isDisabled={updateTracking.isLoading}
            isLoading={updateTracking.isLoading}
            alignSelf="flex-end"
          >
            Update Tracking
          </UiButton>
        </Grid>
      </form>
    </UiCard>
  )
}
