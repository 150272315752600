import { isNumber } from '@chakra-ui/utils'
import { GraphqlFilterState, GraphqlFilterTransform, UiDateTime, UiLink, UiMoney } from '@postal-io/postal-ui'
import { Currency, ItemStatus, VendorPayableFragment } from 'api'
import { VendorStatusTag } from 'components/Main'
import { StorageKeys, vendorPayableExternalStatusMapper } from 'lib'
import { Link as RouterLink } from 'react-router-dom'
import { VendorFulfillmentFragment } from '../../api/index'

export const VENDOR_PAYABLE_INITIAL_STATE = {
  filter: {},
  orderBy: { key: 'created', direction: 'desc' },
} as Partial<GraphqlFilterState>

export const VENDOR_PAYABLE_STATIC_VARIABLES = {
  limit: 100,
}

export const VENDOR_PAYABLE_TRANSFORMS = {
  id: GraphqlFilterTransform.Equal,
  externalId: GraphqlFilterTransform.Contains,
  created: GraphqlFilterTransform.Between,
  owedAtStart: GraphqlFilterTransform.GreaterThan,
  owedAtEnd: GraphqlFilterTransform.LessThanOrEqual,
}

export const VENDOR_PAYABLE_FILTER_OPTIONS = {
  transforms: VENDOR_PAYABLE_TRANSFORMS,
  initialState: VENDOR_PAYABLE_INITIAL_STATE,
  staticVariables: VENDOR_PAYABLE_STATIC_VARIABLES,
  persistKey: StorageKeys.VendorPayablesFilter,
}

export const VENDOR_PAYABLE_COLUMNS = [
  {
    key: 'owedAtStart',
    label: 'Owed At',
    render: ({ owedAtStart, owedAtEnd }: VendorPayableFragment) => (
      <>
        <UiDateTime
          date={owedAtStart as string}
          format={{ dateStyle: 'medium' }}
          fallback="-"
        />{' '}
        -{' '}
        <UiDateTime
          date={owedAtEnd as string}
          format={{ dateStyle: 'medium' }}
          fallback="-"
        />
      </>
    ),
  },
  {
    key: 'totalFulfillments',
    label: 'Fulfillments',
    render: ({ totalFulfillments }: VendorPayableFragment) => totalFulfillments ?? '-',
  },
  {
    key: 'totalAmount',
    label: 'Amount',
    render: ({ totalAmount, currency }: VendorPayableFragment) => (
      <UiMoney
        cents={totalAmount}
        currency={currency}
        placeholder="-"
      />
    ),
  },
  {
    key: 'id',
    label: 'Payable ID',
    render: ({ id }: VendorPayableFragment) => id ?? '-',
  },
  {
    key: 'externalId',
    label: 'External ID',
    render: ({ externalId }: VendorPayableFragment) => externalId ?? '-',
  },
  {
    key: 'externalStatus',
    label: 'Status',
    render: ({ externalStatus }: VendorPayableFragment) => (
      <VendorStatusTag {...vendorPayableExternalStatusMapper(externalStatus)} />
    ),
  },
]

export interface VendorPayableFulfillmentRow extends VendorFulfillmentFragment {
  currency: Currency
}

export const VENDOR_FULFILLMENT_STATUSES = Object.values(ItemStatus)

export const VENDOR_FULFILLMENT_STATIC_VARIABLES = { limit: 100 }

export const VENDOR_FULFILLMENT_TRANSFORMS = {
  id: GraphqlFilterTransform.Equal,
  itemName: GraphqlFilterTransform.Contains,
  status: GraphqlFilterTransform.In,
  created: GraphqlFilterTransform.Between,
  fulfillments_vendorPayment_vendorPayableId: GraphqlFilterTransform.Equal,
}

export const VENDOR_FULFILLMENT_COLUMNS = [
  {
    key: 'postalFulfillmentId',
    label: 'Postal Order ID',
    render: ({ postalFulfillmentId }: VendorPayableFulfillmentRow) => (
      <UiLink
        as={RouterLink}
        to={`/orders/${postalFulfillmentId}`}
      >
        {postalFulfillmentId}
      </UiLink>
    ),
  },
  {
    key: 'fulfillmentPartnerOrderID',
    label: 'Vendor Order ID',
    render: ({ fulfillmentPartnerOrderId, postalFulfillmentId }: VendorPayableFulfillmentRow) => (
      <UiLink
        as={RouterLink}
        to={`/orders/${postalFulfillmentId}`}
      >
        {fulfillmentPartnerOrderId}
      </UiLink>
    ),
  },
  {
    key: 'itemCode',
    label: 'SKU',
    render: ({ itemCode }: VendorPayableFulfillmentRow) => itemCode ?? '-',
  },
  {
    key: 'cost',
    label: 'Subtotal',
    render: ({ cost, currency }: VendorPayableFulfillmentRow) => (
      <UiMoney
        cents={cost?.subtotal}
        currency={currency}
        placeholder="-"
      />
    ),
  },
  {
    key: 'tax',
    label: 'Tax',
    render: ({ cost, currency }: VendorPayableFulfillmentRow) => (
      <UiMoney
        cents={cost?.tax}
        currency={currency}
        placeholder="-"
      />
    ),
  },
  {
    key: 'shipping',
    label: 'Shipping',
    render: ({ cost, currency }: VendorPayableFulfillmentRow) =>
      isNumber(cost?.shipping) ? (
        <UiMoney
          cents={cost?.shipping}
          currency={currency}
          placeholder="-"
        />
      ) : (
        '-'
      ),
  },
  {
    key: 'postalRevenueShare',
    label: 'Postal Revenue Share',
    render: ({ cost, currency }: VendorPayableFulfillmentRow) => (
      <UiMoney
        cents={cost?.postalRevenueShare}
        currency={currency}
        placeholder="-"
      />
    ),
  },
  {
    key: 'thirdPartyFee',
    label: 'Third Party Fee',
    render: ({ cost, currency }: VendorPayableFulfillmentRow) => (
      <UiMoney
        cents={cost?.thirdPartyFee}
        currency={currency}
        placeholder="-"
      />
    ),
  },
  {
    key: 'gmvExpense',
    label: 'Vendor Owed',
    render: ({ cost, currency }: VendorPayableFulfillmentRow) => (
      <UiMoney
        cents={cost?.gmvExpense}
        currency={currency}
        placeholder="-"
      />
    ),
  },
  {
    key: 'owedAt',
    label: 'Order Date',
    render: ({ vendorPayment }: VendorPayableFulfillmentRow) => <UiDateTime date={vendorPayment?.owedAt} />,
  },
]
