import { Box, BoxProps, Flex, Heading, Text, VStack } from '@chakra-ui/react'
import {
  UiButton,
  UiDivider,
  UiFormControl,
  UiFormLabel,
  UiGoogleCaptchaPolicies,
  UiHeading3,
  UiInput,
  UiInputPassword,
  UiLink,
  useUiAlerts,
} from '@postal-io/postal-ui'
import { useRecaptcha, useSession } from 'hooks'
import React, { FormEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useImmer } from 'use-immer'
import { ConfirmEmail } from './ConfirmEmail'
import { SsoOption } from './SsoOption'

const BodyText: React.FC<BoxProps> = ({ children, ...rest }) => (
  <Text
    fontSize="md"
    fontWeight={400}
    {...rest}
  >
    {children}
  </Text>
)

interface ForgotPasswordProps {
  toggle: () => void
}

const ForgotPassword: React.FC<ForgotPasswordProps> = ({ toggle }) => {
  const [resetSent, setResetSent] = useState('')
  const [loading, setLoading] = useState(false)
  const Alert = useUiAlerts()
  const { forgotPassword } = useSession()
  const { executeRecaptcha } = useRecaptcha()
  const navigate = useNavigate()

  const [form, setForm] = useImmer({ userName: '' })

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()
    setLoading(true)
    try {
      let siteVerify: string | undefined

      if (executeRecaptcha) {
        siteVerify = await executeRecaptcha('forgotPassword')
      }

      if (form.userName && siteVerify) {
        await forgotPassword({ userName: form.userName, siteVerify })
        Alert.success('Password reset is on the way')
        setResetSent(form.userName)
        if (process.env.NODE_ENV !== 'production' && process.env.REACT_APP_SITE_VERIFY) {
          setTimeout(() => navigate(`/forgotpw/${siteVerify}`), 2000)
        }
      } else {
        Alert.error('An error has occured')
      }
    } catch (err) {
      Alert.warning(err)
    } finally {
      setLoading(false)
    }
  }

  if (resetSent)
    return (
      <ConfirmEmail
        emailAddress={resetSent}
        isReset={!!resetSent}
      />
    )

  return (
    <Box
      w="100%"
      maxW="500px"
    >
      <Heading
        fontWeight={600}
        as="h1"
        size="md"
        pt={4}
        textAlign="center"
        mt={4}
      >
        Forgot Password
      </Heading>
      <BodyText my={8}>
        Please enter your registered email address below and we will send you a password reset link to your email.
      </BodyText>
      <Box w="100%">
        <form onSubmit={onSubmit}>
          <UiFormControl
            id="userName"
            isRequired
          >
            <UiFormLabel>Email Address</UiFormLabel>
            <UiInput
              name="userName"
              type="email"
              placeholder="Email Address"
              autoCapitalize="off"
              onChange={({ target }) => setForm((draft) => void (draft.userName = target.value))}
              value={form.userName}
            />
          </UiFormControl>
          <UiLink onClick={toggle}>I remember my password</UiLink>
          <UiButton
            type="submit"
            mt={6}
            w="100%"
            colorScheme="tertiary"
            size="md"
            isDisabled={loading}
            isLoading={loading}
          >
            Reset Password
          </UiButton>
          <UiGoogleCaptchaPolicies />
        </form>
      </Box>
    </Box>
  )
}

const Login: React.FC<ForgotPasswordProps> = ({ toggle }) => {
  const { loginPassword } = useSession()
  const [loading, setLoading] = useState(false)
  const Alert = useUiAlerts()
  const navigate = useNavigate()

  const [form, setForm] = useImmer({ userName: '', password: '' })

  const login = async (e: FormEvent) => {
    e.preventDefault()
    try {
      setLoading(true)
      await loginPassword(form)
      navigate('/')
    } catch (err) {
      Alert.warning(err)
      setLoading(false)
    }
  }

  return (
    <Box
      w="100%"
      maxW="500px"
    >
      <UiHeading3
        textAlign="center"
        color="secondary.500"
        mb={0}
      >
        POSTAL.IO VENDORS
      </UiHeading3>
      <UiDivider
        text="SSO"
        my={2}
      />
      <SsoOption />
      <UiDivider
        text="Or"
        mt={2}
      />
      <Flex
        direction="column"
        w="100%"
      >
        <form onSubmit={login}>
          <VStack spacing={4}>
            <UiFormControl
              id="userName"
              isRequired
            >
              <UiFormLabel>Email Address</UiFormLabel>
              <UiInput
                name="userName"
                type="email"
                placeholder="Email Address"
                autoCapitalize="off"
                onChange={({ target }) => setForm((draft) => void (draft.userName = target.value))}
                value={form.userName}
                data-private
              />
            </UiFormControl>
            <UiFormControl
              id="password"
              isRequired
            >
              <UiFormLabel>Password</UiFormLabel>
              <UiInputPassword
                id="password"
                name="password"
                placeholder="Password"
                iconProps={{ h: '40px' }}
                minLength={6}
                onChange={({ target }) => setForm((draft) => void (draft.password = target.value))}
                value={form.password}
                data-private
              />
            </UiFormControl>
            <UiLink
              float="left"
              onClick={toggle}
              alignSelf="flex-start"
            >
              I forgot my password
            </UiLink>
          </VStack>
          <UiButton
            type="submit"
            mt={6}
            w="100%"
            colorScheme="tertiary"
            size="md"
            isDisabled={loading}
            isLoading={loading}
          >
            Log in
          </UiButton>
        </form>
        <UiGoogleCaptchaPolicies />
      </Flex>
    </Box>
  )
}

export const SignIn = () => {
  const [forgot, setForgot] = useState(false)
  const toggle = () => setForgot(!forgot)

  return (
    <Flex
      justify="center"
      align="center"
      w="100vw"
      h="100vh"
    >
      {forgot ? <ForgotPassword toggle={toggle} /> : <Login toggle={toggle} />}
    </Flex>
  )
}
