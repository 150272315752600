import { CheckIcon } from '@chakra-ui/icons'
import { Flex, Grid, Stack } from '@chakra-ui/react'
import { useGraphqlMutation } from '@postal-io/postal-graphql'
import {
  UiButton,
  UiCard,
  UiFormControl,
  UiFormLabel,
  UiGenericChangeEvent,
  UiInfoTooltip,
  UiInput,
  UiRadioButton,
  UiRadioButtonGroup,
  UiTextarea,
  useUiAlerts,
} from '@postal-io/postal-ui'
import {
  FulfillmentStatus,
  MarkOrderCancelledDocument,
  MarkOrderConfirmedDocument,
  MarkOrderDeliveredDocument,
  MarkOrderPlacedDocument,
  MarkOrderShippedDocument,
  PostalFulfillmentFragment,
} from 'api'
import React, { FormEvent, useEffect, useMemo, useRef } from 'react'
import { useImmer } from 'use-immer'

interface StatusEditProps {
  postalFulfillment?: PostalFulfillmentFragment
  isLoading?: boolean
}

export const StatusEdit: React.FC<StatusEditProps> = ({ postalFulfillment, isLoading: postalLoading, ...rest }) => {
  const Alert = useUiAlerts()
  // update status and notes (tracking handled separately)
  const cancelOrder = useGraphqlMutation(MarkOrderCancelledDocument)
  const shipOrder = useGraphqlMutation(MarkOrderShippedDocument)
  const deliverOrder = useGraphqlMutation(MarkOrderDeliveredDocument)
  const confirmOrder = useGraphqlMutation(MarkOrderConfirmedDocument)
  const placeOrder = useGraphqlMutation(MarkOrderPlacedDocument)

  const lastNotes = useMemo(
    () => postalFulfillment?.history && postalFulfillment?.history[postalFulfillment?.history?.length - 1]?.notes,
    [postalFulfillment?.history]
  )

  const statusRef = useRef(postalFulfillment?.status)

  const [form, setForm] = useImmer<{
    notes: string
    status?: FulfillmentStatus
    fulfillmentPartnerOrderId?: string
  }>({
    notes: '',
    status: postalFulfillment?.status,
    fulfillmentPartnerOrderId: '',
  })

  useEffect(() => {
    setForm((draft) => void (draft.status = postalFulfillment?.status as any))
    statusRef.current = postalFulfillment?.status
  }, [postalFulfillment?.status, setForm])

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()

    if (form.status === FulfillmentStatus.Cancelled && form.notes?.length === 0) {
      return Alert.warning('Notes are required for cancellations.')
    }

    try {
      switch (form.status) {
        case FulfillmentStatus.Cancelled:
          await cancelOrder.mutateAsync({
            id: postalFulfillment?.id,
            data: { notes: form.notes },
          })
          break

        case FulfillmentStatus.Placed:
          await placeOrder.mutateAsync({
            id: postalFulfillment?.id,
            data: { fulfillmentPartnerOrderId: form.fulfillmentPartnerOrderId ?? null },
          })
          break

        case FulfillmentStatus.Confirmed:
          await confirmOrder.mutateAsync({
            id: postalFulfillment?.id,
            data: { fulfillmentPartnerOrderId: form.fulfillmentPartnerOrderId ?? null },
          })
          break

        case FulfillmentStatus.PendingDelivery:
          await shipOrder.mutateAsync({ id: postalFulfillment?.id })
          break

        case FulfillmentStatus.Delivered:
          await deliverOrder.mutateAsync({ id: postalFulfillment?.id })
          break
      }

      Alert.success('Status updated')
    } catch (err) {
      Alert.error(err)
    }
  }

  const handleChangeStatus = ({ target }: UiGenericChangeEvent) => {
    if (target.value === FulfillmentStatus.Cancelled) {
      setForm((draft) => void (draft.notes = ''))
    }
    setForm((draft) => void (draft.status = target.value as FulfillmentStatus))
  }

  const fulFillmentOrderStatus = postalFulfillment?.status

  const canCancel = useMemo(() => {
    return (
      fulFillmentOrderStatus &&
      ![
        FulfillmentStatus.Delivered,
        FulfillmentStatus.DeliveredAssumed,
        FulfillmentStatus.OutForDelivery,
        FulfillmentStatus.PendingDelivery,
      ].includes(fulFillmentOrderStatus)
    )
  }, [fulFillmentOrderStatus])

  const canSetToDelivered = useMemo(() => {
    return (
      fulFillmentOrderStatus &&
      [
        FulfillmentStatus.Confirmed,
        FulfillmentStatus.OutForDelivery,
        FulfillmentStatus.PendingDelivery,
        FulfillmentStatus.Shipped,
      ].includes(fulFillmentOrderStatus)
    )
  }, [fulFillmentOrderStatus])

  const isLoading =
    postalLoading ||
    cancelOrder.isLoading ||
    shipOrder.isLoading ||
    deliverOrder.isLoading ||
    placeOrder.isLoading ||
    confirmOrder.isLoading

  const handleInput = ({ target }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = target
    setForm((draft: any) => void (draft[name] = value))
  }

  return (
    <UiCard
      boxShadow="none"
      borderRadius="0px"
      {...rest}
    >
      <form onSubmit={onSubmit}>
        <Stack spacing={4}>
          <Grid
            alignItems="center"
            templateColumns={{ base: '1fr', xl: '1fr 1fr' }}
            gap={4}
          >
            <UiFormControl id="status">
              <Flex
                alignItems="center"
                mb={2}
              >
                <UiFormLabel
                  fontSize="lg"
                  mb={0}
                >
                  Status
                </UiFormLabel>
                <UiInfoTooltip
                  aria-label="Status"
                  label="Orders can only be confirmed, cancelled, shipped, or delivered."
                  placement="right"
                  hasArrow
                />
              </Flex>

              <UiRadioButtonGroup
                name="status"
                value={form.status}
                onChange={handleChangeStatus}
                icon={<CheckIcon />}
                colorScheme="secondary"
              >
                <UiRadioButton
                  minW="150px"
                  value={FulfillmentStatus.Placed}
                  isDisabled={fulFillmentOrderStatus !== FulfillmentStatus.Placed}
                >
                  Placed
                </UiRadioButton>

                <UiRadioButton
                  minW="150px"
                  value={FulfillmentStatus.Confirmed}
                  isDisabled={fulFillmentOrderStatus !== FulfillmentStatus.Placed}
                >
                  Confirmed
                </UiRadioButton>

                <UiRadioButton
                  minW="150px"
                  value={FulfillmentStatus.Cancelled}
                  isDisabled={!canCancel}
                >
                  Cancelled
                </UiRadioButton>

                <UiRadioButton
                  minW="150px"
                  value={FulfillmentStatus.PendingDelivery}
                  isDisabled={fulFillmentOrderStatus !== FulfillmentStatus.Confirmed}
                >
                  Shipped
                </UiRadioButton>
                <UiRadioButton
                  minW="150px"
                  value={FulfillmentStatus.Delivered}
                  isDisabled={!canSetToDelivered}
                >
                  Delivered
                </UiRadioButton>
              </UiRadioButtonGroup>
            </UiFormControl>

            <UiFormControl id="fulfillmentPartnerOrderId">
              <UiFormLabel
                fontSize="lg"
                mb={2}
              >
                Vendor Order Id
              </UiFormLabel>

              <UiInput
                name="fulfillmentPartnerOrderId"
                onChange={handleInput}
                value={form.fulfillmentPartnerOrderId}
                isDisabled={
                  !form.status ||
                  (form.status !== FulfillmentStatus.Placed && form.status !== FulfillmentStatus.Confirmed)
                }
              />
            </UiFormControl>
          </Grid>

          <UiFormControl
            id="notes"
            isRequired={form.status === FulfillmentStatus.Cancelled}
            mt={4}
          >
            <Flex
              alignItems="center"
              mb={2}
            >
              <UiFormLabel
                fontSize="lg"
                mb={0}
              >
                Notes
              </UiFormLabel>
              <UiInfoTooltip
                aria-label="Notes"
                label="Notes are required for cancellations."
                placement="right"
                hasArrow
              />
            </Flex>

            <UiTextarea
              id="notes"
              name="notes"
              isDisabled={form.status !== FulfillmentStatus.Cancelled}
              onChange={handleInput}
              value={form.status !== FulfillmentStatus.Cancelled ? (lastNotes as string) : form.notes}
            />
          </UiFormControl>

          <UiButton
            mt={8}
            w="100%"
            type="submit"
            isLoading={isLoading}
            maxW="200px"
            isDisabled={statusRef.current === form.status}
          >
            Submit
          </UiButton>
        </Stack>
      </form>
    </UiCard>
  )
}
