import { DownloadIcon } from '@chakra-ui/icons'
import { Stack } from '@chakra-ui/react'
import { useGraphqlInfiniteQuery } from '@postal-io/postal-graphql'
import {
  UiButton,
  UiCard,
  UiCardHeader,
  UiCardProps,
  UiSSDataTable,
  useAlertError,
  useGraphqlFilter,
} from '@postal-io/postal-ui'
import { useReport } from 'hooks'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useDebounce } from 'use-debounce'
import { ReportType, SearchVendorFulfillmentsDocument, SearchVendorFulfillmentsQueryVariables } from '../../api/index'
import { VENDOR_FULFILLMENT_COLUMNS, VENDOR_FULFILLMENT_STATIC_VARIABLES, VENDOR_FULFILLMENT_TRANSFORMS } from './data'
import { VendorFulfillmentsFilter } from './VendorFulfillmentsFilter'

export const VendorFulfillmentsSearchCard: React.FC<UiCardProps> = (props) => {
  const { vendorPayableId }: { vendorPayableId?: string } = useParams()

  const { createReport, isLoading: reportIsLoading } = useReport(ReportType.VendorFulfillments)

  const graphqlFilter = useGraphqlFilter<SearchVendorFulfillmentsQueryVariables>({
    initialState: {
      filter: { fulfillments_vendorPayment_vendorPayableId: vendorPayableId },
    },
    staticVariables: VENDOR_FULFILLMENT_STATIC_VARIABLES,
    transforms: VENDOR_FULFILLMENT_TRANSFORMS,
  })

  // to accommodate typing
  const [debouncedVariables] = useDebounce(graphqlFilter.variables, 400)

  const { mergedData, isFetching, hasNextPage, error, fetchNextPage } = useGraphqlInfiniteQuery(
    SearchVendorFulfillmentsDocument,
    debouncedVariables,
    {
      keepPreviousData: true,
      enabled: !!vendorPayableId,
    }
  )
  useAlertError(error)

  const vendorFulfillments = useMemo(() => {
    return (
      mergedData?.searchVendorFulfillments?.map((fulfillment) => ({
        ...fulfillment,
        currency: 'USD' as any,
      })) ?? []
    )
  }, [mergedData?.searchVendorFulfillments])

  const handleExportVendorFulfillments = async () => createReport(debouncedVariables.filter, vendorPayableId)

  return (
    <>
      <UiCard
        isFetching={isFetching}
        {...props}
      >
        <UiCardHeader
          display="flex"
          justifyContent="space-between"
        >
          Fulfillment Search
          <UiButton
            size="sm"
            leftIcon={<DownloadIcon />}
            borderRadius="4px"
            border="none"
            colorScheme="sky"
            fontWeight="bold"
            letterSpacing="0.5px"
            onClick={handleExportVendorFulfillments}
            isLoading={reportIsLoading}
            isDisabled={reportIsLoading}
          >
            Export
          </UiButton>
        </UiCardHeader>
        <Stack spacing={4}>
          <VendorFulfillmentsFilter
            filter={graphqlFilter.filter}
            setFilter={graphqlFilter.setFilter}
          />
          <UiSSDataTable
            rowKey="fulfillmentId"
            variant="list"
            columns={VENDOR_FULFILLMENT_COLUMNS}
            rows={vendorFulfillments}
            fetchMore={fetchNextPage}
            hasMore={hasNextPage}
            filter={graphqlFilter.variables.filter}
            orderBy={graphqlFilter.orderBy}
            onOrderBy={graphqlFilter.setOrderBy}
          />
        </Stack>
      </UiCard>
    </>
  )
}
