import { Grid, Image, Text } from '@chakra-ui/react'
import { UiCard, UiCardHeader, UiCardProps, UiDateTime, UiFormControl, UiFormLabel } from '@postal-io/postal-ui'
import { DraftMarketplaceProductFragment } from 'api'
import { systemImageMap } from 'components/Integrations/IntegrationCard'

interface DraftProductOtherCardProps extends UiCardProps {
  draft?: DraftMarketplaceProductFragment
}

export const DraftProductOtherCard: React.FC<DraftProductOtherCardProps> = ({ draft, isLoading }) => {
  return (
    <UiCard
      isLoading={isLoading}
      boxShadow="none"
    >
      <UiCardHeader>Other</UiCardHeader>
      <Grid
        templateColumns="repeat(auto-fit, minmax(200px, 1fr))"
        gridGap={8}
        w="100%"
      >
        <UiFormControl>
          <UiFormLabel
            fontSize={'lg'}
            fontWeight="bold"
          >
            External ID
          </UiFormLabel>
          <Text
            fontSize={'md'}
            fontWeight="semibold"
          >
            {draft?.externalId || '-----'}
          </Text>
        </UiFormControl>
        <UiFormControl>
          <UiFormLabel
            fontSize={'lg'}
            fontWeight="bold"
          >
            System
          </UiFormLabel>
          {draft?.systemName && systemImageMap.hasOwnProperty(draft.systemName) ? (
            <Image
              mb={4}
              src={systemImageMap[draft?.systemName]}
              display="block"
              boxSize={'50px'}
            />
          ) : (
            <Text
              fontSize={'md'}
              fontWeight="semibold"
            >
              {draft?.systemName.toUpperCase() || '-----'}
            </Text>
          )}
        </UiFormControl>
        <UiFormControl>
          <UiFormLabel
            fontSize={'lg'}
            fontWeight="bold"
          >
            Type
          </UiFormLabel>
          <Text
            fontSize={'md'}
            fontWeight="semibold"
          >
            {draft?.type || '-----'}
          </Text>
        </UiFormControl>
        <UiFormControl>
          <UiFormLabel
            fontSize={'lg'}
            fontWeight="bold"
          >
            Published
          </UiFormLabel>
          {draft?.publishedAt ? (
            <UiDateTime date={draft?.publishedAt} />
          ) : (
            <Text
              fontSize={'md'}
              fontWeight="semibold"
            >
              {'-----'}
            </Text>
          )}
        </UiFormControl>
      </Grid>
    </UiCard>
  )
}
