export enum CustomColors {
  Sky = '#4782C8',
  Lavender = '#9DA2F9',
  Turquoise = '#2AE4A9',
}

export interface ColorPalette {
  5: string
  10: string
  50: string
  100: string
  200: string
  300: string
  400: string
  500: string
  600: string
  700: string
  800: string
  900: string
  [key: string]: string
}

export const AtomicColors = {
  atomicBlue: {
    '900': '#004066',
    '800': '#125D8C',
    '700': '#0070B3',
    '600': '#0088D9',
    '500': '#00A0FF',
    '400': '#29AFFF',
    '300': '#52BEFF',
    '200': '#7ACEFF',
    '100': '#A3DDFF',
    '50': '#CCECFF',
    '10': '#E4F6FF',
    '5': '#F2FAFF',
  },
  atomicGray: {
    '900': '#2F3337',
    '800': '#41474C',
    '700': '#535A62',
    '600': '#656E77',
    '500': '#78828C',
    '400': '#8D969E',
    '300': '#A3AAB1',
    '200': '#B8BEC3',
    '100': '#CED2D5',
    '50': '#E3E6E8',
    '10': '#F1F2F3',
    '5': '#F4F6F6',
  },
  vendorGreen: {
    '900': '#2FB383',
    '800': '#35BE8C',
    '700': '#36CA95',
    '600': '#38D89E',
    '500': '#37DDA1',
    '400': '#48E0A9',
    '300': '#5AE3B1',
    '200': '#6BE6BA',
    '100': '#7DE9C2',
    '50': '#8EECCA',
    '10': '#C2F5E2',
    '5': '#E6FFF6',
  },
  atomicGreen: {
    '900': '#1D4932',
    '800': '#286244',
    '700': '#327B55',
    '600': '#3C9467',
    '500': '#46AD78',
    '400': '#60BF8E',
    '300': '#7ECBA4',
    '200': '#9DD7B9',
    '100': '#BCE4CF',
    '50': '#DBF0E5',
    '10': '#EDF8F2',
    '5': '#F6FBF8',
  },
  atomicPurple: {
    '900': '#322659',
    '800': '#44337A',
    '700': '#553C9A',
    '600': '#6B46C1',
    '500': '#805AD5',
    '400': '#9F7AEA',
    '300': '#B794F4',
    '200': '#D6BCFA',
    '100': '#E9D8FD',
    '50': '#F2E5FF',
    '10': '#F9F2FF',
    '5': '#F9F7FD',
  },
  atomicYellow: {
    '900': '#664100',
    '800': '#925D00',
    '700': '#BF7900',
    '600': '#EB9500',
    '500': '#FFAB18',
    '400': '#FFB83C',
    '300': '#FFC560',
    '200': '#FFD284',
    '100': '#FFDFA8',
    '50': '#FFECCC',
    '5': '#F6E7D5',
  },
}
