import { Box, BoxProps, Flex } from '@chakra-ui/react'
import { SsoProvider, UiButtonSso } from '@postal-io/postal-ui'
import { getSsoEndpoint } from 'lib'
import React from 'react'

export interface SsoOptionProps extends BoxProps {
  params?: Record<string, any>
  isDisabled?: boolean
  onClick?: (e: any) => void
}

export const SsoOption: React.FC<SsoOptionProps> = ({ onClick, isDisabled, params, ...props }) => {
  const handleClick = ({ endpoint }: SsoProvider) => {
    const url = getSsoEndpoint({ endpoint, params: { ...params, product: process.env.REACT_APP_PRODUCT_ID ?? '' } })
    window.location.href = url
    onClick && onClick(endpoint)
  }
  return (
    <Box
      w="100%"
      {...props}
    >
      <Flex
        direction="column"
        mx="auto"
      >
        <UiButtonSso
          isDisabled={isDisabled}
          mb={4}
          name="google"
          w="100%"
          onClick={handleClick}
        />
        <UiButtonSso
          isDisabled={isDisabled}
          mb={4}
          name="salesforce"
          w="100%"
          onClick={handleClick}
        />
        <UiButtonSso
          isDisabled={isDisabled}
          mb={4}
          name="microsoft"
          w="100%"
          onClick={handleClick}
        />
      </Flex>
    </Box>
  )
}
