import { Flex, Grid, Heading, Text } from '@chakra-ui/react'
import {
  UiButton,
  UiDropzone,
  UiLink,
  UiModal,
  UiModalBody,
  UiModalCloseButton,
  UiModalContent,
  UiModalFooter,
  UiModalFooterButtons,
  UiModalHeader,
  UiModalOverlay,
  useAlerts,
} from '@postal-io/postal-ui'
import api from 'api/rest'
import { useState } from 'react'

const HELP_ARTICLE_URL = 'https://help.postal.com/helpcenter/s/article/How-to-Upload-Products'

interface DraftProductsImportModalProps {
  isOpen: boolean
  onClose: () => void
}

export const DraftProductsImportModal: React.FC<DraftProductsImportModalProps> = ({ isOpen, onClose }) => {
  const Alert = useAlerts()
  const [productUploading, setProductUploading] = useState(false)

  const handleUploadProduct = async (files: any) => {
    setProductUploading(true)
    try {
      await api.uploadBulkProducts(files)
      Alert.success('We have begun to upload your products. This process may take a few minutes.')
      onClose()
    } catch (err) {
      Alert.error(err)
    } finally {
      setProductUploading(false)
    }
  }

  return (
    <UiModal
      isOpen={isOpen}
      onClose={onClose}
      size={'4xl'}
      isCentered={true}
    >
      <UiModalOverlay />
      <UiModalContent>
        <UiModalCloseButton
          size="sm"
          color="gray.400"
          fontSize={'sm'}
        />
        <UiModalHeader
          backgroundColor={'white'}
          color="gray.800"
          fontSize={'2xl'}
        >
          Upload Products
        </UiModalHeader>
        <UiModalBody>
          <Grid
            templateColumns={'1fr 1fr'}
            gap={10}
          >
            <Flex direction={'column'}>
              <Heading
                fontSize={'lg'}
                mb={2}
              >
                Step 1
              </Heading>
              <Text mb={4}>
                <UiLink
                  fontStyle={'semibold'}
                  fontSize="md"
                  href={HELP_ARTICLE_URL}
                  isExternal
                >
                  Download the template
                </UiLink>{' '}
                from our help section and fill it out.
              </Text>
            </Flex>
            <Flex direction={'column'}>
              <Heading
                fontSize={'lg'}
                mb={2}
              >
                Step 2
              </Heading>
              <Text mb={4}>Upload your file when you are finished.</Text>
              <UiDropzone
                onDrop={handleUploadProduct}
                isLoading={productUploading}
                accept={{
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                }}
                data-cy="dropZone"
                borderRadius="md"
                borderWidth={3}
                minH="150px"
              >
                <Text
                  fontSize={'lg'}
                  fontWeight="bold"
                >
                  Drag files here or click to select
                </Text>
                <Text
                  fontSize={'lg'}
                  fontWeight="bold"
                >
                  (Only .xlsx files)
                </Text>
              </UiDropzone>
            </Flex>
          </Grid>
        </UiModalBody>
        <UiModalFooter
          border={'0px'}
          mx={8}
        >
          <UiModalFooterButtons justifyContent={'space-between'}>
            <UiButton
              fontSize={'md'}
              size="md"
              colorScheme="turquoise"
              color="gray.700"
              maxW={'150px'}
              textTransform="none"
            >
              Upload Files
            </UiButton>
            <UiButton
              size="sm"
              fontSize={'lg'}
              variant="link"
              onClick={onClose}
              color="gray.500"
            >
              Cancel
            </UiButton>
          </UiModalFooterButtons>
        </UiModalFooter>
      </UiModalContent>
    </UiModal>
  )
}
