import { ArrowBackIcon } from '@chakra-ui/icons'
import { Flex, Radio, RadioGroup, Stack, Text, useDisclosure } from '@chakra-ui/react'
import { useGraphqlMutation, useGraphqlQuery } from '@postal-io/postal-graphql'
import {
  UiButton,
  UiCard,
  UiHeading5,
  UiIconButton,
  UiScaleEffect,
  UiSSPagination,
  useAlerts,
} from '@postal-io/postal-ui'
import {
  Collection,
  PageInfo,
  SearchShopifyCollectionsDocument,
  SelectShopifyCollectionDocument,
  ShopifyCollection,
} from 'api'
import { DropBar } from 'components/Common'
import { useBackgroundQueue } from 'hooks'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useImmer } from 'use-immer'

export const DraftShopifyCollectionList: React.FC = () => {
  const [collectionId, setCollectionId] = useState('')
  const [collectionLimit, setCollectionLimit] = useImmer({ before: '', after: '' })
  const { invalidate } = useBackgroundQueue()
  const [page, setPage] = useState(1)
  const navigate = useNavigate()
  const Alert = useAlerts()

  const searchCollections = useGraphqlQuery(SearchShopifyCollectionsDocument, {
    before: collectionLimit.before,
    after: collectionLimit.after,
  })

  const { crntCollectionId, pageInfo, collectionList } =
    searchCollections.data?.searchShopifyCollections || ({} as ShopifyCollection)

  const { hasNextPage } = pageInfo || ({} as PageInfo)

  useEffect(() => {
    if (!!crntCollectionId) {
      setCollectionId(crntCollectionId || '')
    }
  }, [crntCollectionId])

  const onNext = () => {
    if (collectionList?.length) {
      setPage(page + 1)
      setCollectionLimit((draft: any) => void (draft.after = collectionList[collectionList?.length - 1].cursor))
    }
  }

  const onPrevious = () => {
    if (collectionList?.length) {
      setPage(page - 1)
      setCollectionLimit((draft: any) => {
        draft.before = collectionList[0].cursor
        draft.after = ''
      })
    }
  }

  const handleOnFirst = () => {
    if (collectionList?.length) {
      setPage(1)
      setCollectionLimit((draft: any) => {
        draft.before = ''
        draft.after = ''
      })
    }
  }

  const { queue, tasks } = useBackgroundQueue()
  const [isSyncLoading, setIsSyncLoading] = useState<boolean>(
    tasks.some((task) => task.task.taskAction === 'ProductSync')
  )

  const syncCollection = useGraphqlMutation(SelectShopifyCollectionDocument, {
    onSuccess: (data) => {
      queue(data.selectShopifyCollection, () => {
        setIsSyncLoading(false)
        navigate('/products')
        invalidate('searchDraftMarketplaceProduct')
        Alert.info('Collection imported.')
      })
    },
  })

  const handleSelect = async () => {
    setIsSyncLoading(true)
    try {
      if (!!collectionId) {
        await syncCollection.mutateAsync({ shopifyCollectionId: collectionId })
        Alert.info('Integration synced, product collection will be imported shortly.')
        navigate('/products')
        invalidate('searchDraftMarketplaceProduct')
      }
    } catch (err) {
      setIsSyncLoading(false)
      Alert.error(err)
    }
  }

  const isLoading =
    syncCollection.isLoading || isSyncLoading || searchCollections.isFetching || searchCollections.isLoading

  return (
    <Flex
      direction={'column'}
      alignItems="center"
    >
      <DropBar isStatic>
        <UiIconButton
          aria-label="go back to products"
          icon={<ArrowBackIcon />}
          size="xs"
          colorScheme="whiteAlpha"
          fontSize="3xl"
          variant="ghost"
          isDisabled={isLoading}
          onClick={() => navigate('/products')}
        />

        <UiButton
          isDisabled={!collectionId || isSyncLoading}
          onClick={handleSelect}
          isLoading={isLoading}
          size="sm"
          colorScheme="turquoise"
          color="primary.900"
          fontSize="md"
          letterSpacing="0.5px"
          px={6}
        >
          Import
        </UiButton>
      </DropBar>
      <UiHeading5
        my={10}
        textAlign={'center'}
        color="#2D4A59"
      >
        Choose a collection
      </UiHeading5>
      <UiCard
        isLoading={isLoading}
        boxShadow="none"
        border={'none'}
        width="60%"
        overflow={'auto'}
        overflowX="auto"
        h={'80%'}
      >
        <RadioGroup
          mx="auto"
          maxH={'800px'}
          width="100%"
          alignItems={'center'}
          value={collectionId}
        >
          <Stack gap={'5px'}>
            {collectionList?.map((item, idx) => (
              <ShopifyCollectionItem
                key={`${item.legacyResourceId}-${idx}`}
                collection={item}
                setCollectionId={setCollectionId}
                collectionId={collectionId}
              />
            ))}
          </Stack>
        </RadioGroup>
      </UiCard>
      <UiSSPagination
        page={page}
        onFirst={handleOnFirst}
        onNext={onNext}
        onPrevious={onPrevious}
        hasMore={hasNextPage}
      />
    </Flex>
  )
}

export const ShopifyCollectionItem: React.FC<{
  collection: Collection
  setCollectionId: (id: string) => void
  collectionId: string
}> = ({ collection, setCollectionId, collectionId }) => {
  const hoverDisclosure = useDisclosure()
  const { title, productsCount, legacyResourceId } = collection
  const isActive = collectionId === legacyResourceId

  return (
    <UiScaleEffect>
      <Flex
        onMouseEnter={hoverDisclosure.onOpen}
        onMouseLeave={hoverDisclosure.onClose}
        onClick={() => setCollectionId(legacyResourceId as string)}
        backgroundColor={hoverDisclosure.isOpen ? 'gray.50' : 'white'}
        p="15px"
        border={'2px'}
        borderColor={isActive ? 'blue.300' : 'gray.200'}
        justifyContent="space-between"
      >
        <Radio
          size={'sm'}
          value={legacyResourceId || ''}
        >
          <Text fontSize={'medium'}>{title}</Text>
        </Radio>
        <Text color={'gray.500'}>{productsCount} products</Text>
      </Flex>
    </UiScaleEffect>
  )
}
