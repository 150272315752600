import { Text } from '@chakra-ui/react'
import { UiConfirm } from '@postal-io/postal-ui'

interface CancelFormModalProps {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
}

export const CancelFormModal: React.FC<CancelFormModalProps> = ({ isOpen, onClose, onConfirm }) => {
  return (
    <UiConfirm
      title="Cancel Form"
      isOpen={isOpen}
      onConfirm={onConfirm}
      onClose={onClose}
      buttonText="Confirm"
    >
      <Text textAlign={'center'}>This action will revert all of your changes. Would you like to continue?</Text>
    </UiConfirm>
  )
}
