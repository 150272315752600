import { Box, Flex } from '@chakra-ui/react'
import { UiFooter, UiFooterLink } from '@postal-io/postal-ui'
import { BackgroundProvider } from 'hooks'
import { Analytics } from './Analytics'
import { BackgroundTasks } from './BackgroundTasks'
import { LaunchDarkly } from './LaunchDarkly'
import { LogRocketInit } from './LogRocketInit'
import { Navbar } from './Navbar'
import { AppRoutes } from './Routes'
import ScrollTop from './ScrollTop'
import { Timeout } from './Timeout'

export const Main = () => {
  return (
    <LaunchDarkly>
      <BackgroundProvider>
        <Flex
          direction="column"
          minH="100vh"
          alignItems="stretch"
          bg="#ffffff"
        >
          <Navbar />
          <Box
            width="full"
            flexGrow={1}
            minW={0}
          >
            {/* ScrollTop ensures page scrolls to top when previous page was not */}
            <ScrollTop />
            <AppRoutes />
          </Box>
          <UiFooter>
            <UiFooterLink
              isExternal
              title="privacy policy"
              path="https://postal.io/privacy-policy/"
            />
            <UiFooterLink
              isExternal
              title="terms of service"
              path="https://postal.io/terms-of-service/"
            />
            <UiFooterLink
              isExternal
              title="acceptable use policy"
              path="https://postal.io/acceptable-use-policy/"
            />
            <UiFooterLink
              isExternal
              title="platform terms of service"
              path="https://postal.io/platform-usage-terms/"
            />
          </UiFooter>
        </Flex>
        <Timeout />
        <LogRocketInit />
        <Analytics />
        <BackgroundTasks />
      </BackgroundProvider>
    </LaunchDarkly>
  )
}
