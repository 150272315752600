import { Alert as AlertBox, AlertDescription, Box, BoxProps, CloseButton } from '@chakra-ui/react'
import { UiLink } from '@postal-io/postal-ui'
import { DraftMarketplaceProductFragment } from 'api/index'

const IS_HIDDEN = true

interface DraftProductsPageSendForApprovalAlertProps extends BoxProps {
  readyDrafts: DraftMarketplaceProductFragment[]
  onClick?: () => void
  onClose?: () => void
}

export const DraftProductsPageSendForApprovalAlert: React.FC<DraftProductsPageSendForApprovalAlertProps> = ({
  readyDrafts,
  onClick,
  onClose,
  ...rest
}) => {
  // hide until bulk approval is ready
  if (IS_HIDDEN) return null

  return (
    <Box
      pt={4}
      px={8}
      {...rest}
    >
      <AlertBox
        variant="subtle"
        borderColor="#2AE4A980"
        borderRadius="4px"
        borderWidth="1px"
        bg="rgba(42, 228, 169, 0.04)"
        justifyContent="center"
        p={8}
      >
        <CloseButton
          position="absolute"
          right="8px"
          top="8px"
          onClick={onClose}
        />
        <AlertDescription
          maxW="950px"
          textAlign="center"
        >
          {readyDrafts.length} product{readyDrafts.length > 1 ? 's' : ''} {readyDrafts.length > 1 ? 'are' : 'is'} ready
          for approval.
          <UiLink
            fontSize="md"
            mx={1}
            onClick={onClick}
          >
            Click here
          </UiLink>
          to send for approval into the Postal Marketplace, or you can select the products in bulk or individually below
          to start the approval process.
        </AlertDescription>
      </AlertBox>
    </Box>
  )
}
