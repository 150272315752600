import { Box, BoxProps, Flex, Grid, GridProps, Heading, IconButtonProps } from '@chakra-ui/react'
import { UiCardProps, UiLink } from '@postal-io/postal-ui'
import React, { Children, createContext, forwardRef, RefObject, useCallback, useContext, useState } from 'react'
import { LinkProps } from 'react-router-dom'

export interface UiSidebarLinkProps extends LinkProps {
  isActive?: boolean
  subLinks?: React.ReactNode
}

const SidebarLinkContext = createContext(false)

export const SidebarLink: React.FC<UiSidebarLinkProps> = forwardRef(
  ({ children, isActive, subLinks, ...rest }, ref) => {
    const hasSublinks = React.isValidElement(subLinks)
    const isSublink = useContext(SidebarLinkContext)
    return (
      <>
        <Flex alignItems="center">
          <Box
            mb="0.5rem"
            height={4}
            borderLeftColor="blue.600"
            borderLeftWidth={isActive && !isSublink ? 3 : 0}
          />
          <UiLink
            fontSize={'18px'}
            mb="0.5rem"
            display="block"
            pl={isActive && !isSublink ? 2 : 0}
            data-testid="UiSidebar_link"
            color={isActive ? 'blue.600' : 'blue.400'}
            textDecoration="none"
            ref={ref as RefObject<HTMLAnchorElement>}
            {...rest}
          >
            {children}
          </UiLink>
        </Flex>
        {hasSublinks && isActive && (
          <SidebarLinkContext.Provider value={true}>
            <Box pl={3}>{subLinks}</Box>
          </SidebarLinkContext.Provider>
        )}
      </>
    )
  }
)

export interface UiSidebarBlockProps extends BoxProps {
  header: string
  maxLinks?: number
  isLast?: boolean
}

export const UiSidebarBlock: React.FC<UiSidebarBlockProps> = ({
  header,
  children,
  maxLinks = 20,
  isLast = false,
  ...rest
}) => {
  const [isExpanded, setExpanded] = useState(false)

  const handleExpand = () => setExpanded(!isExpanded)

  const navLinks = isExpanded ? Children.toArray(children) : Children.toArray(children).slice(0, maxLinks)

  return (
    <>
      <Box
        data-testid="UiSidebar_block"
        {...rest}
      >
        <Heading
          as="h1"
          fontWeight="medium"
          size="md"
          mb="0.5rem"
          pb={2}
        >
          {header}
        </Heading>
        {navLinks}
        {Children.count(children) > maxLinks && (
          <UiLink
            color="blue.500"
            mt={4}
            onClick={handleExpand}
            textDecoration="underline"
          >
            {isExpanded ? 'Show Less' : 'Show More'}
          </UiLink>
        )}
      </Box>
      {!isLast && (
        <Box
          my={6}
          borderBottom="1px"
          borderColor="gray.200"
        />
      )}
    </>
  )
}

export interface TheSideBarButtonProps extends IconButtonProps {
  onClick: () => void
}

export interface UiSidebarProps extends GridProps {
  blocks: React.ReactNode
  spacing?: GridProps['gap']
  bottomBanner?: React.ReactNode
  cardProps?: UiCardProps
  sidebarProps?: BoxProps
  contentProps?: BoxProps
}

export const SidebarNav: React.FC<UiSidebarProps> = ({
  blocks,

  bottomBanner,
  spacing = 8,
  cardProps,
  sidebarProps,
  contentProps,
  children,
  ...rest
}) => {
  const [contentTop, setContentTop] = useState(0)
  const ref = useCallback((node: HTMLInputElement) => setContentTop(node?.getBoundingClientRect()?.top || 0), [])

  return (
    <Grid
      data-testid="UiSidebar_grid"
      gridTemplateColumns={{
        base: '100%',
        lg: `200px minmax(0,1fr)`,
      }}
      gridGap={spacing}
      alignItems="start"
      as="section"
      {...rest}
    >
      <Box
        w="100%"
        h="95%"
        mt={8}
        borderRight={{ base: 'none', lg: '2px' }}
        color="atomicGray.50"
        {...sidebarProps}
      >
        <Box data-testid="UiSidebar_wrapper">
          <Box
            backgroundColor="white"
            width="full"
            data-testid="UiSidebar_card"
            {...cardProps}
          >
            {blocks}
          </Box>
        </Box>
        <Box data-testid="UiSidebar_bottomBanner">{bottomBanner}</Box>
      </Box>

      <Box
        ref={ref}
        as="section"
        data-testid="UiSidebar_content"
        minH={`calc(100vh - ${contentTop}px)`}
        {...contentProps}
      >
        {children}
      </Box>
    </Grid>
  )
}
