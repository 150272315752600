import { sessionStorage } from '@postal-io/postal-ui'
import { useEffect, useState } from 'react'
import { useNavigationType } from 'react-router-dom'
import { StorageKeys } from '../lib'

export const useCurrentPage = (
  storageKey: StorageKeys,
  useSessionStorage?: boolean
): [number, React.Dispatch<React.SetStateAction<number>>] => {
  // returns "PUSH", "POP", or "REPLACE"
  const navigationType = useNavigationType()

  // If this page is directly accessed default to page 1
  // If this page is from a browser back action, then use the stored page
  const [currentPage, setCurrentPage] = useState(() => {
    return navigationType === 'POP' || useSessionStorage
      ? parseInt(sessionStorage.getItem(storageKey) || '', 10) || 1
      : 1
  })

  // store the currentPage anytme it changes
  useEffect(() => {
    sessionStorage.setItem(storageKey, String(currentPage))
  }, [currentPage, storageKey])

  return [currentPage, setCurrentPage]
}
