import { Heading } from '@chakra-ui/react'
import { UiSubNavbar, UiSubNavbarProps } from '@postal-io/postal-ui'

export const EventsSubNavbar: React.FC<UiSubNavbarProps> = (props) => {
  return (
    <>
      <UiSubNavbar
        left={<Heading fontSize="2xl">Events</Heading>}
        boxShadow="none"
        border="none"
        mb={0}
        maxW="1800px"
        gridProps={{ p: 4, minH: '72px' }}
        {...props}
      />
    </>
  )
}
