import { AddressFragment } from 'api'
import { Row } from 'components/Common/Row'
import { VendorCopyLink } from 'components/Main'

export const OrderAddressRows: React.FC<{
  shippingAddress?: AddressFragment
  shippingAddressRegion: string
}> = ({ shippingAddress, shippingAddressRegion }) => {
  return (
    <Row label="Shipping Address">
      {shippingAddress?.address1 && (
        <VendorCopyLink
          text={shippingAddress.address1}
          variant="text"
          data-private
        >
          {shippingAddress?.address1}
        </VendorCopyLink>
      )}

      {shippingAddress?.address2 && (
        <VendorCopyLink
          text={shippingAddress.address2}
          variant="text"
          data-private
        >
          {shippingAddress.address2}
        </VendorCopyLink>
      )}

      {shippingAddress?.address3 && (
        <VendorCopyLink
          text={shippingAddress.address3}
          variant="text"
          data-private
        >
          {shippingAddress.address3}
        </VendorCopyLink>
      )}

      {shippingAddressRegion && (
        <VendorCopyLink
          text={shippingAddressRegion}
          variant="text"
          data-private
        >
          {shippingAddressRegion}
        </VendorCopyLink>
      )}

      {shippingAddress?.country && (
        <VendorCopyLink
          text={shippingAddress.country}
          variant="text"
          data-private
        >
          {shippingAddress.country}
        </VendorCopyLink>
      )}
    </Row>
  )
}
